import rollbar, { init as initRollbar } from 'Services/Rollbar';

/* eslint-disable no-console */
export default {
    init (payload = {}) {
        initRollbar(payload);
    },
    log (data, payload) {
        this.logConsole('log', data, payload);
    },
    info (data, payload) {
        this.logConsole('info', data, payload);
        this.logRollbar('info', data, payload);
    },
    debug (data, payload) {
        this.logConsole('debug', data, payload);
        this.logRollbar('debug', data, payload);
    },
    warning (data, payload) {
        this.logConsole('error', data, payload);
        this.logRollbar('warning', data, payload);
    },
    error (data, payload) {
        this.logConsole('error', data, payload);
        this.logRollbar('error', data, payload);
    },
    logConsole (level, data, payload = null) {
        if (process.env.APP_ENV === 'development') {
            console[level](data, payload);
        }
    },
    logRollbar (level, data, payload = null) {
        if (process.env.APP_ENV !== 'development') {
            rollbar[level](data, payload);
        }
    },
};
