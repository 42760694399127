import moment from 'moment';

export default class Event {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.updatedAt = data.updatedAt && moment.utc(data.updatedAt).local();
        this.startAt = data.startAt && moment.utc(data.startAt).local();
        this.endAt = data.endAt && moment.utc(data.endAt).local();
        this.doneStatus = data.doneStatus;
        this.paidStatus = data.paidStatus;
        this.canMove = data.canMove;
        this.canDone = data.canDone;
        this.canCancel = data.canCancel;
        this.canCancelFree = data.canCancelFree;
        this.category = data.category;
        this.product = data.product;
        this.user = data.user;
        this.lead = data.lead;
        this.userOffer = data.userOffer;
        this.offer = data.offer;
        this.location = data.location;
        this.moveCount = data.moveCount;
        this.name = data.name;
        this.totalCount = data.totalCount;
        this.order = data.order;
        this.trainingPlan = data.trainingPlan;
        this.eventCancelType = data.eventCancelType;
        this.usersCount = data.usersCount;
        this.createReason = data.createReason;
        this.canUnbonus = data.canUnbonus;
        this.creator = data.creator;
        this.paidReason = data.paidReason;
        this.comment = data.comment;
        this.cyclicEvent = data.cyclicEvent;
        this.eventCycle = data.eventCycle;
        this.canDoneByAdept = data.canDoneByAdept;
    };
}