import { connect } from 'react-redux';
import Component from './component';

import {
    list as listArticles,
    changeAssignment
} from 'Redux/modules/admin/articles/actions';

export default connect(
    state => ({
        articles: state.adminArticles.list,
    }),
    dispatch => ({
        actions: {
            listArticles: dispatch(listArticles),
            changeAssignment: dispatch(changeAssignment),
        },
    })
)(Component);
