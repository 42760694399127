import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

import getStore from 'Redux';

import Wrapper from 'Components/wrappers/Wrapper';

export default class Main extends Component {
    render() {
        return (
            <Provider store={getStore(window.__data || {})}>
                <BrowserRouter>
                    <Route component={Wrapper} />
                </BrowserRouter>
            </Provider>
        );
    }
}
