import React from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ErrorMessage from 'Components/forms/ErrorMessage';

export default class FormComponent extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        children: PropTypes.any,
        title: PropTypes.string,
        className: PropTypes.string,
        onStateChange: PropTypes.func,
        isPending: PropTypes.bool,
        onSubmit: PropTypes.func,
        data: PropTypes.object,
        globalError: PropTypes.string,
        errors: PropTypes.objectOf(
            PropTypes.arrayOf(
                PropTypes.string,
            )
        ),
    };
    static defaultProps = {
        title: null,
        className: '',
        isPending: false,
        data: {},
        globalError: null,
        errors: {},
    };

    supportedComponents = [
        'FormInputComponent',
        'FormCheckboxComponent',
        'FormSelectComponent',
        'FormMultiSelectComponent',
        'FormTextareaComponent',
        'FormFileUploadComponent',
        'FormS3FilMultiUploadComponent',
        'FormDatePickerComponent',
        'FormButtonsGroupComponent',
        'FormInputsListComponent',
    ];
    components = {};
    state = {
        errors: [],
    };

    constructor(props) {
        super(props);

        this.traverseChildren(props.children);
    }

    componentWillReceiveProps = ({ errors }) => {
        const validationErrors = [];

        Object.keys(errors).forEach(errorFieldName => {
            validationErrors.push({
                field: errorFieldName,
                message: errors[errorFieldName] && Array.isArray(errors[errorFieldName]) && errors[errorFieldName].join(', ') || null,
            });
        });

        this.setState({ errors: validationErrors });
    }

    traverseChildren = children => {
        if (Array.isArray(children)) {
            return children.map(child => this.validateChild(child));
        } else if (typeof children === 'object') {
            return this.validateChild(children);
        } else {
            return children;
        }
    }

    validateChild = child => {
        if (!child) return null;

        if (child.hasOwnProperty('type') && typeof child.type === 'function' && child.type.hasOwnProperty('displayName')) {
            if (child.type.displayName && child.type.displayName.startsWith('Connect(')) {
                child.type.displayName = child.type.WrappedComponent.displayName;
            }

            if (this.supportedComponents.indexOf(child.type.displayName) > -1) {
                child = this.injectChildProps(child);

                //Add component to tracked components object
                this.components = {
                    ...this.components,
                    [child.props.name]: child,
                };
            }
        }

        if (child.props && child.props.hasOwnProperty('children')) {
            return {
                ...child,
                props: {
                    ...child.props,
                    children: this.traverseChildren(child.props.children),
                },
            };
        } else {
            return { ...child };
        }
    }

    injectChildProps = child => {
        const { errors } = this.state;
        const { data } = this.props;

        let error = null;
        if(Array.isArray(errors) && errors.length > 0) {
            error = errors.find(error => error.field === child.props.name);
        }

        //Inject child props
        return {
            ...child,
            props: {
                ...child.props,
                value: data[child.props.name],
                errorMessage: error && error.message || null,
                onFormChange: this.onChange,
            },
        };
    }

    validateInput = (component, value) => {
        //Vaidate required state
        const isRequired = Boolean(component.props.required) || false;
        let errors = [];

        if (isRequired && !value) {
            errors.push('Pole jest wymagane');
        }

        return errors;
    }

    onSubmit = event => {
        const { data, onSubmit } = this.props;
        event.preventDefault();

        let errors = [];
        Object.keys(this.components).forEach(name => {
            const inputError = this.validateInput(this.components[name], data[name]);
            if (inputError.length) {
                errors.push({
                    field: name,
                    message: inputError.join(', '),
                });
            }
        });

        if (errors.length) {
            //Set form errors
            this.setState((prevState, props) => ({
                ...prevState,
                errors,
            }));
        } else {
            //Submit form
            if (onSubmit) {
                onSubmit(data);
            }
        }
        return data;
    }

    onChange = data => {
        const { onStateChange } = this.props;

        //Remove previous errors
        const inputError = this.validateInput(this.components[data.name], data.value);

        //Set new form state
        this.setState((prevState, props) => ({
            ...prevState,
            errors: {
                ...prevState.errors,
                [data.name]: inputError,
            },
        }));

        onStateChange(data.name, data.value);

        return data;
    }

    render() {
        const { id, title, className, isPending, children, globalError } = this.props;

        return (
            <StyledComponent styles={require('./styles')}>
                <form id={id} name={id} className={className} onSubmit={this.onSubmit} noValidate autoComplete="off">
                    {isPending ? <Spinner className="no-bg" /> : null}
                    {globalError ? <ErrorMessage message={globalError} style="form" /> : null}
                    {title ? <h4 className="form-title">{title}</h4> : null}
                    {this.traverseChildren(children)}
                </form>
            </StyledComponent>
        );
    }
}
