/*
* PUBLIC ROUTES
*/
export const PUBLIC_HOME = { path: '/', exact: true };
export const PUBLIC_DEEPLINKS = { path: '/deeplinks', exact: true };
export const PUBLIC_SURVEY = { path: '/survey', exact: true };
export const PUBLIC_SURVEY_TRAINER = { path: '/survey/trainer', exact: true };
export const PUBLIC_SURVEY_CHECKOUT = { path: '/survey/checkout', exact: true };
export const PUBLIC_SURVEY_CONFIRM = { path: '/survey/confirm', exact: true };
export const PUBLIC_COUPON_REGISTER = { path: '/karta-podarunkowa', exact: true };
export const PUBLIC_ADEPT_WELCOME = { path: '/dolacz', exact: true };
export const PUBLIC_OFFER = { path: '/oferta/:offerSlug', exact: true };
export const PUBLIC_MOBILE_APP = { path: '/aplikacja', exact: true };
export const PUBLIC_LP_RESERVATIONS = { path: '/rezerwacje', exact: true };
export const PUBLIC_LP_REHABILITATION = { path: '/rehabilitacja', exact: true };
export const PUBLIC_RESERVATIONS = { path: '/rezerwacje/:slug', exact: true };
export const PUBLIC_RESERVATIONS_MASSAGE_SOBIESKI = { path: '/rezerwacje/sobieski-masaze', exact: true };
export const PUBLIC_RESERVATIONS_MASSAGE_RC = { path: '/rezerwacje/rc-masaze', exact: true };
export const PUBLIC_RESERVATIONS_REHABILITATION_SOBIESKI = { path: '/rezerwacje/sobieski-rehabilitacja', exact: true };
export const PUBLIC_RESERVATIONS_REHABILITATION_ONLINE = { path: '/rezerwacje/rehabilitacja-online', exact: true };
export const PUBLIC_RESERVATIONS_MASSAGE_RC_NOW = { path: '/rezerwacje/rc-masaze-now', exact: true };
export const PUBLIC_RESERVATIONS_MASSAGE_HOME = { path: '/rezerwacje/masaze-w-domu', exact: true };
export const PUBLIC_RESERVATIONS_DIETICIANS = { path: '/rezerwacje/dla-dietetykow', exact: true };
export const PUBLIC_RESERVATIONS_R = { path: '/r/:slug', exact: true };
export const PUBLIC_RESERVATIONS_CONFIRM = { path: '/rezerwacje/potwierdzenie', exact: true };
export const PUBLIC_LP_ONLINE = { path: '/online', exact: true };
export const PUBLIC_LP_ONLINE_SURVEY = { path: '/online/ankieta', exact: true };
export const PUBLIC_RESERVATIONS_ONLINE = { path: '/rezerwacje-online', exact: true };
export const PUBLIC_RESERVATIONS_ONLINE_CONFIRM = { path: '/rezerwacje-online/potwierdzenie', exact: true };
export const PUBLIC_RESERVATIONS_ONLINE_CODE = { path: '/rezerwacje-online/:code', exact: true };
export const PUBLIC_RESERVATIONS_PARTNER_ONLINE = { path: '/rezerwacje-partner/online', exact: true };
export const PUBLIC_RESERVATIONS_PARTNER_OFFLINE = { path: '/rezerwacje-partner/offline', exact: true };
export const PUBLIC_SHOP = { path: '/sklep', exact: true };
export const PUBLIC_SHOP_ONLINE = { path: '/sklep-online', exact: true };
export const PUBLIC_SHOP_STUDIO = { path: '/sklep-studio', exact: true };
export const PUBLIC_SHOP_MASSAGE = { path: '/sklep-masaz', exact: true };
export const PUBLIC_SHOP_OFFER = { path: '/sklep/oferta/:slug', exact: true };
export const PUBLIC_SHOP_USER_OFFER = { path: '/sklep/zamowienie/:id', exact: true };
export const PUBLIC_SHOP_PAYMENT = { path: '/sklep/platnosc', exact: true };
export const PUBLIC_SHOP_RESERVATIONS_TRAINER = { path: '/rezerwacje-trener/:slug', exact: true };
export const PUBLIC_PREFERENCES_SURVEY = { path: '/ankieta-preferencji', exact: true };
export const PUBLIC_PREFERENCES_SURVEY_REHAB = { path: '/ankieta-preferencji-rehabilitacja', exact: true };
export const PUBLIC_USER_UPDATE_DATA = { path: '/zaktualizuj-dane/:token', exact: true };
export const PUBLIC_RESERVATION_CONFIRMATION = { path: '/rezerwacje/:slug/potwierdzenie/:id', exact: true };
export const PUBLIC_REGISTER = { path: '/rejestracja', exact: true };

/*
* PUBLIC USER ROUTES
*/
export const PUBLIC_USER_PREFIX = '/user';
export const PUBLIC_USER_ACTIVATE = { path: '/user/activate/:token', exact: true };
export const PUBLIC_USER_LOGIN = { path: '/user/login', exact: true };
export const PUBLIC_USER_PASSWORD_RESET = { path: '/user/password-reset', exact: true };
export const PUBLIC_USER_PASSWORD_CHANGE = { path: '/user/password-change/:token', exact: true };

/*
* ADEPT ROUTES
*/
export const ADEPT_PREFIX = '/adept';
export const ADEPT_DASHBOARD = { path: '/adept', exact: true };
export const ADEPT_PROFILE = { path: '/adept/profile', exact: true };
export const ADEPT_USER_OFFERS = { path: '/adept/zamowienia', exact: true };
export const ADEPT_USER_OFFER = { path: '/adept/zamowienia/:id', exact: true };
export const ADEPT_EVENTS = { path: '/adept/wydarzenia', exact: true };
export const ADEPT_EVENTS_CREATE = { path: '/adept/wydarzenie/zaplanuj', exact: true };
export const ADEPT_EVENTS_MANAGE = { path: '/adept/wydarzenie/:id', exact: true };
export const ADEPT_EVENT_MEET = { path: '/adept/wydarzenie/:id/spotkanie', exact: true };
export const ADEPT_FINANCES = { path: '/adept/finanse', exact: true };
export const ADEPT_USER_PAYMENT_METHOD = { path: '/adept/metoda-platnosci/:id', exact: true };
export const ADEPT_DATA = { path: '/adept/dane', exact: true };
export const ADEPT_MEDICAL_CARD_CREATE = { path: '/adept/wywiad-medyczny/nowy', exact: true };
export const ADEPT_MEASUREMENTS_CREATE = { path: '/adept/pomiary/nowy', exact: true };
export const ADEPT_MEASUREMENTS_MANAGE = { path: '/adept/pomiary/:id', exact: true };
export const ADEPT_MEASUREMENTS_CONFIG_MANAGE = { path: '/adept/pomiary/ustawienia', exact: true };
export const ADEPT_TRAINING_TARGETS_CREATE = { path: '/adept/cele-treningowe/nowy', exact: true };
export const ADEPT_TRAINING_TARGETS_MANAGE = { path: '/adept/cele-treningowe/:id', exact: true };
export const ADEPT_ADEPT_TESTS_MANAGE = { path: '/adept/testy/:id', exact: true };
export const ADEPT_CONTACTS = { path: '/adept/kontakty', exact: true };
export const ADEPT_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE = { path: '/adept/raport-diagnostyczny/:id', exact: true };
export const ADEPT_USER_SURVEY_MANAGE = { path: '/adept/ankiety/:id', exact: true };
export const ADEPT_USER_HOMEWORK_MANAGE = { path: '/adept/praca-domowa/:id', exact: true };
export const ADEPT_CHAT = { path: '/adept/czat', exact: true };
export const ADEPT_EVENT_CYCLE = { path: '/adept/wydarzenie/:id/cykl', exact: true };

/*
* ADEPT Modals
*/
export const ADEPT_MODAL_PAYMENT_METOD_CREATE = '#payment-method-create';

/*
* TRAINER ROUTES
*/
export const TRAINER_PREFIX = '/trener';
export const TRAINER_DASHBOARD = { path: '/trener', exact: true };
export const TRAINER_PROFILE = { path: '/trener/profile', exact: true };
export const TRAINER_ADEPTS = { path: '/trener/adepci', exact: true };
export const TRAINER_ADEPTS_MANAGE = { path: '/trener/adepci/:id', exact: true };
export const TRAINER_EVENTS = { path: '/trener/wydarzenia', exact: true };
export const TRAINER_EVENTS_CREATE = { path: '/trener/wydarzenie/zaplanuj', exact: true };
export const TRAINER_EVENTS_MANAGE = { path: '/trener/wydarzenie/:id', exact: true };
export const TRAINER_EVENT_MEET = { path: '/trener/wydarzenie/:id/spotkanie', exact: true };
export const TRAINER_MEASUREMENTS_CREATE = { path: '/trener/pomiary/nowy', exact: true };
export const TRAINER_MEASUREMENTS_MANAGE = { path: '/trener/pomiary/:id', exact: true };
export const TRAINER_MEASUREMENTS_CONFIG_MANAGE = { path: '/trener/pomiary/ustawienia', exact: true };
export const TRAINER_TRAINING_TARGETS_CREATE = { path: '/trener/cele-treningowe/nowy', exact: true };
export const TRAINER_TRAINING_TARGETS_MANAGE = { path: '/trener/cele-treningowe/:id', exact: true };
export const TRAINER_ADEPT_TESTS_CREATE = { path: '/trener/testy/nowy', exact: true };
export const TRAINER_ADEPT_TESTS_MANAGE = { path: '/trener/testy/:id', exact: true };
export const TRAINER_MEDICAL_CARD_CREATE = { path: '/trener/wywiad-medyczny/nowy', exact: true };
export const TRAINER_USER_OFFERS = { path: '/trener/zamowienia', exact: true };
export const TRAINER_USER_OFFER = { path: '/trener/zamowienia/:id', exact: true };
export const TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE = { path: '/trener/raport-diagnostyczny/nowy', exact: true };
export const TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE = { path: '/trener/raport-diagnostyczny/:id', exact: true };
export const TRAINER_USER_SURVEYS = { path: '/trener/ankiety', exact: true };
export const TRAINER_USER_SURVEY_MANAGE = { path: '/trener/ankiety/:id', exact: true };
export const TRAINER_USER_HOMEWORK_CREATE = { path: '/trener/praca-domowa/nowa', exact: true };
export const TRAINER_USER_HOMEWORK_MANAGE = { path: '/trener/praca-domowa/:id', exact: true };
export const TRAINER_EVENT_CYCLE = { path: '/trener/wydarzenie/:id/cykl', exact: true };
export const TRAINER_CHAT = { path: '/trener/czat', exact: true };
export const TRAINER_USER_EFFECT_CREATE = { path: '/trener/efekt/nowy', exact: true };
export const TRAINER_USER_EFFECT_MANAGE = { path: '/trener/efekt/:id', exact: true };
export const TRAINER_BLOG = { path: '/trener/blog', exact: true };
export const TRAINER_ARTICLE_CREATE = { path: '/trener/artykul/nowy', exact: true };
export const TRAINER_ARTICLE_MANAGE = { path: '/trener/artykul/:id', exact: true };

/*
* ADMIN ROUTES
*/
export const ADMIN_PREFIX = '/admin';
export const ADMIN_DASHBOARD = { path: '/admin', exact: true };
export const ADMIN_CHAT = { path: '/admin/chat', exact: true };
export const ADMIN_CHAT_CONVERSATIONS = { path: '/admin/chat-conversations', exact: true };
export const ADMIN_USERS = { path: '/admin/users', exact: true };
export const ADMIN_USERS_CREATE = { path: '/admin/users/create', exact: true };
export const ADMIN_USERS_MANAGE = { path: '/admin/users/:id/manage', exact: true };
export const ADMIN_USERS_STAFF_DATA_CREATE = { path: '/admin/users/staff-data', exact: true };
export const ADMIN_USERS_STAFF_DATA_MANAGE = { path: '/admin/users/staff-data/:id/manage', exact: true };
export const ADMIN_SALES = { path: '/admin/sales', exact: true };
export const ADMIN_CHAT_MASS_MESSAGING = { path: '/admin/chat-mass-messaging', exact: true };
export const ADMIN_CHAT_MASS_MESSAGING_CREATE = { path: '/admin/chat-mass-messaging/create', exact: true };
export const ADMIN_CHAT_MASS_MESSAGING_MANAGE = { path: '/admin/chat-mass-messaging/:id/manage', exact: true };
export const ADMIN_USER_SEGMENTS_CREATE = { path: '/admin/users/segments/create', exact: true };
export const ADMIN_USER_SEGMENTS_MANAGE = { path: '/admin/users/segments/:id/manage', exact: true };
export const ADMIN_OFFERS = { path: '/admin/offers', exact: true };
export const ADMIN_OFFERS_CREATE = { path: '/admin/offers/create', exact: true };
export const ADMIN_OFFERS_MANAGE = { path: '/admin/offers/:id/manage', exact: true };
export const ADMIN_PRODUCT_CATEGORIES_CREATE = { path: '/admin/product-categories/create', exact: true };
export const ADMIN_PRODUCT_CATEGORIES_MANAGE = { path: '/admin/product-categories/:id/manage', exact: true };
export const ADMIN_PRODUCTS_CREATE = { path: '/admin/products/create', exact: true };
export const ADMIN_PRODUCTS_MANAGE = { path: '/admin/products/:id/manage', exact: true };
export const ADMIN_USER_PRODUCTS_MANAGE = { path: '/admin/user-products/:id/manage', exact: true };
export const ADMIN_USER_OFFERS = { path: '/admin/user-offers', exact: true };
export const ADMIN_USER_OFFERS_CREATE = { path: '/admin/user-offers/create', exact: true };
export const ADMIN_USER_OFFERS_MANAGE = { path: '/admin/user-offers/:id/manage', exact: true };
export const ADMIN_USER_OFFERS_MIGRATE = { path: '/admin/user-offers/migrate', exact: true };
export const ADMIN_OFFER_PRODUCTS_CREATE = { path: '/admin/offer-products/create', exact: true };
export const ADMIN_OFFER_PRODUCTS_MANAGE = { path: '/admin/offer-products/:id/manage', exact: true };
export const ADMIN_USER_OFFER_PRODUCTS_CREATE = { path: '/admin/user-offer-products/create', exact: true };
export const ADMIN_USER_OFFER_PRODUCTS_MANAGE = { path: '/admin/user-offer-products/:id/manage', exact: true };
export const ADMIN_USER_OFFER_INVOICE_ITEMS_CREATE = { path: '/admin/user-offer-invoice-items/create', exact: true };
export const ADMIN_USER_OFFER_INVOICE_ITEMS_MANAGE = { path: '/admin/user-offer-invoice-items/:id/manage', exact: true };
export const ADMIN_OFFER_USPS_CREATE = { path: '/admin/offer-usps/create', exact: true };
export const ADMIN_OFFER_USPS_MANAGE = { path: '/admin/offer-usps/:id/manage', exact: true };
export const ADMIN_PROMO_CODES_CREATE = { path: '/admin/promo-codes/create', exact: true };
export const ADMIN_PROMO_CODES_MANAGE = { path: '/admin/promo-codes/:id/manage', exact: true };
export const ADMIN_LOCATIONS = { path: '/admin/locations', exact: true };
export const ADMIN_LOCATIONS_CREATE = { path: '/admin/locations/create', exact: true };
export const ADMIN_LOCATIONS_MANAGE = { path: '/admin/locations/:id/manage', exact: true };
export const ADMIN_EMPLOYEES = { path: '/admin/employees', exact: true };
export const ADMIN_SPECIALIZATIONS_CREATE = { path: '/admin/specializations/create', exact: true };
export const ADMIN_SPECIALIZATIONS_MANAGE = { path: '/admin/specializations/:id/manage', exact: true };
export const ADMIN_EVENTS_CALENDAR = { path: '/admin/events/calendar', exact: true };
export const ADMIN_EVENTS_CREATE = { path: '/admin/events/:id/create', exact: true };
export const ADMIN_EVENTS_MANAGE = { path: '/admin/events/:id/manage', exact: true };
export const ADMIN_EVENTS_ADD = { path: '/admin/events/:id/add', exact: true };
export const ADMIN_PROFILE = { path: '/admin/profile', exact: true };
export const ADMIN_ADEPT_DATA = { path: '/admin/adept-data', exact: true };
export const ADMIN_REPORTS = { path: '/admin/reports', exact: true };
export const ADMIN_TRAINING_TARGETS_CREATE = { path: '/admin/training-targets/create', exact: true };
export const ADMIN_TRAINING_TARGETS_MANAGE = { path: '/admin/training-targets/:id/manange', exact: true };
export const ADMIN_TRAINING_TARGET_CATEGORIES_CREATE = { path: '/admin/training-target-categories/create', exact: true };
export const ADMIN_TRAINING_TARGET_CATEGORIES_MANAGE = { path: '/admin/training-target-categories/:id/manange', exact: true };
export const ADMIN_ADEPT_TESTS_CREATE = { path: '/admin/tests/create', exact: true };
export const ADMIN_ADEPT_TESTS_MANAGE = { path: '/admin/tests/:id/manage', exact: true };
export const ADMIN_MEASUREMENTS_CREATE = { path: '/admin/measurements/create', exact: true };
export const ADMIN_MEASUREMENTS_MANAGE = { path: '/admin/measurements/:id/manage', exact: true };
export const ADMIN_MEASUREMENTS_CONFIG_MANAGE = { path: '/admin/measurements/config', exact: true };
export const ADMIN_ACTIVITY_LOGS = { path: '/admin/activity-logs', exact: true };
export const ADMIN_DOCS = { path: '/admin/docs', exact: true };
export const ADMIN_MEDICAL_CARDS_MANAGE = { path: '/admin/medical-cards/:id/manage', exact: true };
export const ADMIN_USER_OFFER_DIAGNOSTIC_REPORTS = { path: '/admin/user-offer-diagnostic-reports', exact: true };
export const ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_CREATE = { path: '/admin/user-offer-diagnostic-reports/create', exact: true };
export const ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE = { path: '/admin/user-offer-diagnostic-reports/:id/manage', exact: true };
export const ADMIN_USER_SURVEY_MANAGE = { path: '/admin/user-surveys/:id/manage', exact: true };
export const ADMIN_GR_HISTORY_LOGS = { path: '/admin/gr-history-logs', exact: true };
export const ADMIN_USER_HOMEWORK_CREATE = { path: '/admin/user-homework/create', exact: true };
export const ADMIN_USER_HOMEWORK_MANAGE = { path: '/admin/user-homework/:id/manage', exact: true };
export const ADMIN_EVENT_CYCLE = { path: '/admin/events/:id/event-cycle', exact: true };
export const ADMIN_SHOP_PAGES = { path: '/admin/shop-pages', exact: true };
export const ADMIN_SHOP_PAGE_CREATE = { path: '/admin/shop-pages/create', exact: true };
export const ADMIN_SHOP_PAGE_MANAGE = { path: '/admin/shop-pages/:id/manage', exact: true };
export const ADMIN_USER_EFFECT_CREATE = { path: '/admin/user-effect/create', exact: true };
export const ADMIN_USER_EFFECT_MANAGE = { path: '/admin/user-effect/:id/manage', exact: true };
export const ADMIN_PREFERENCES_SURVEY_CREATE = { path: '/admin/preferences-survey', exact: true };
export const ADMIN_TRAINING_PLANS = { path: '/admin/training-plans', exact: true };
export const ADMIN_TRAINING_PLANS_SURVEY_CREATE = { path: '/admin/training-plans/survey/create', exact: true };
export const ADMIN_TRAINING_PLANS_SURVEY_MANAGE = { path: '/admin/training-plans/survey/:id/manage', exact: true };
export const ADMIN_TRAINING_PLANS_PROFILE_MANAGE = { path: '/admin/training-plans/profile/:id/manage', exact: true };
export const ADMIN_BLOG = { path: '/admin/blog', exact: true };
export const ADMIN_ARTICLE_CREATE = { path: '/admin/article/create', exact: true };
export const ADMIN_ARTICLE_MANAGE = { path: '/admin/article/:id/manage', exact: true };
export const ADMIN_ARTICLE_TAG_CREATE = { path: '/admin/article-tags', exact: true };
export const ADMIN_ARTICLE_TAG_MANAGE = { path: '/admin/article-tags/:id/manage', exact: true };
export const ADMIN_ARTICLE_COMMENT_MANAGE = { path: '/admin/article-comment/:id/manage', exact: true };
export const ADMIN_ARTICLE_CATEGORY_CREATE = { path: '/admin/article-category', exact: true };
export const ADMIN_ARTICLE_CATEGORY_MANAGE = { path: '/admin/article-category/:id/manage', exact: true };
export const ADMIN_CUSTOM_EVENTS = { path: '/admin/custom-events', exact: true };
export const ADMIN_CUSTOM_EVENTS_CREATE = { path: '/admin/custom-events/create', exact: true };
export const ADMIN_CUSTOM_EVENTS_MANAGE = { path: '/admin/custom-events/:id/manage', exact: true };
export const ADMIN_CUSTOM_EVENT_USPS_CREATE = { path: '/admin/custom-events-usps/create', exact: true };
export const ADMIN_CUSTOM_EVENT_USPS_MANAGE = { path: '/admin/custom-events-usps/:id/manage', exact: true };

/*
* ADMIN MODALS ROUTES
*/
export const ADMIN_MODAL_CONVERSATION_CREATE = '#conversation-create';

/*
* OLD ADMIN ROUTES
*/
export const OLD_PANEL_LEAD_MANAGE = `${process.env.OLD_PANEL_URL}/sells/lead/edit/:id`;
export const OLD_PANEL_ORDER_MANAGE = `${process.env.OLD_PANEL_URL}/sells/order/details/:id`;

/*
* OUTGOING
*/
export const FITADEPT_PAGE = 'https://fitadept.com/';