import { connect } from 'react-redux';
import Component from './component';

import {
    list as listComments,
    changeStatus
} from 'Redux/modules/admin/articleComments/actions';

export default connect(
    state => ({
        articlesComments: state.adminArticleComments.list,
    }),
    dispatch => ({
        actions: {
            listComments: dispatch(listComments),
            changeStatus: dispatch(changeStatus),
        },
    })
)(Component);
