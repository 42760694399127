import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFullName } from 'Utils/user';
import { getFormattedDate } from 'Utils/date';
import { withVariables } from 'Utils/string';

import { ADMIN_TRAINING_PLANS_PROFILE_MANAGE } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelTrainingPlanProfilesList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listProfiles: PropTypes.func.isRequired,
        }).isRequired,
        onMapAdditionals: PropTypes.func,
        onMapControls: PropTypes.func,
    };
    static defaultProps = {
        onMapAdditionals: (elem, value) => value,
        onMapControls: (elem, value) => value,
    };

    defaultQuery = {
        page: 1,
        timestamp: undefined,
    };

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        const { actions } = this.props;

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.listProfiles();
        }, 500);
    }

    render() {
        const {
            location,
            history,
            tpProfiles,
            onMapAdditionals,
            onMapControls,
        } = this.props;

        return (
            <StyledComponent
                className="panel-training-plan-profiles-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={tpProfiles}
                    title="Lista profili"
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={getFullName(element.user).label}
                            subtitle={element.name}
                            controls={onMapControls(element, [{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_TRAINING_PLANS_PROFILE_MANAGE.path, { id: element.id }),
                            }])}
                            additionals={onMapAdditionals(element, [{
                                visible: true,
                                name: 'Data utworznenia',
                                value: getFormattedDate(element.createdAt),
                            }])}
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}
