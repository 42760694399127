
export const CONVERSATION_BOK_USER = 'bok@fitadept.com';

export const MASS_MESSAGING_STATUS_QUEUED = 'QUEUED';
export const MASS_MESSAGING_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const MASS_MESSAGING_STATUS_ERROR = 'ERROR';
export const MASS_MESSAGING_STATUS_SUCCESS = 'SUCCESS';

export const CONVERSATION_ROLE_TRAINER = 'trainer';
export const CONVERSATION_ROLE_DIET = 'diet';
export const CONVERSATION_ROLE_BOK = 'bok';
export const CONVERSATION_ROLE_TECH = 'tech';
export const CONVERSATION_ROLE_PHYSIO = 'physio';
export const CONVERSATION_ROLE_CUSTOM = 'custom';
export const CONVERSATION_ROLE_ADEPT = 'adept';
export const CONVERSATION_ROLES = [
    {
        key: CONVERSATION_ROLE_TRAINER,
        label: 'Trener',
    },
    {
        key: CONVERSATION_ROLE_BOK,
        label: 'BOK',
    },
    {
        key: CONVERSATION_ROLE_DIET,
        label: 'Dietetyk',
    },
    {
        key: CONVERSATION_ROLE_TECH,
        label: 'Tech',
    },
    {
        key: CONVERSATION_ROLE_CUSTOM,
        label: 'Inna',
    },
    {
        key: CONVERSATION_ROLE_PHYSIO,
        label: 'Fizjoterapeuta',
    },
];