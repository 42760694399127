import { reduxRequest as request } from 'Services/Api';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_ARTICLE_TAGS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            enabled: typeof params.enabled !== 'undefined'
                ? params.enabled
                : undefined,
            articleId: params.articleId || undefined,
            assigned: fromSelectObject(params.assigned) || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_TAG, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_TAGS, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_TAG, params),
        params,
        requestParams: params,
    }));
};

export const remove = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_TAG, params),
        params,
        requestParams: params,
    }));
};

export const changeAssignment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CHANGE_ASSIGNMENT,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_TAG_CHANGE_ASSIGNMENT, params),
        params,
        requestParams: params,
    }));
};