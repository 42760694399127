export const USER_PERMISSION_MODE_ALLOW = 'allow';
export const USER_PERMISSION_MODE_DENY = 'deny';

export const USER_PERMISSION_GROUP_USERS = 'users';
export const USER_PERMISSION_GROUP_STAFF = 'staff';
export const USER_PERMISSION_GROUP_CHAT = 'chat';
export const USER_PERMISSION_GROUP_SALES = 'sales';
export const USER_PERMISSION_GROUP_USER_OFFERS = 'userOffers';
export const USER_PERMISSION_GROUP_LOGIN_AS_USER = 'loginAsUser';
export const USER_PERMISSION_GROUP_EVENTS = 'events';
export const USER_PERMISSION_PAYMENT_METHODS = 'paymentMethods';

export const USER_PERMISSION_RESOURCE_ALL = 'all';
export const USER_PERMISSION_RESOURCE_CLOSE = 'close';
export const USER_PERMISSION_RESOURCE_REMOVE = 'remove';
export const USER_PERMISSION_RESOURCE_TRAINER = 'trainer';
export const USER_PERMISSION_RESOURCE_UNBONUS = 'unbonus';
export const USER_PERMISSION_RESOURCE_RADISSON_COLLECTION = 'radissonCollection';