import moment from 'moment';

export default class DiagnosticReport {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.user = data.user;
        this.userOffer = data.userOffer;
        this.diagnose = data.diagnose;
        this.trainingTarget = data.trainingTarget;
        this.sellProbability = data.sellProbability;
        this.lead = data.lead;
        this.nextEventDate = data.nextEventDate && moment.utc(data.nextEventDate).local();
        this.eventRating = data.eventRating;
        this.leadRating = data.leadRating;
        this.eventRatingReason = data.eventRatingReason;
        this.leadRatingReason = data.leadRatingReason;
        this.creator = data.creator;
        this.files = data.files;
        this.offer = data.offer;
        this.homework = data.homework;
        this.comment = data.comment;
        this.location = data.location;
        this.product = data.product;
    };
}