/**
 * Public module
 */
//User
export const ENDPOINT_PUBLIC_USER = '/user';
export const ENDPOINT_PUBLIC_PASSWORD = '/user/password';
export const ENDPOINT_PUBLIC_TOKEN = '/user/token';
export const ENDPOINT_PUBLIC_VISITOR = '/web-visitor';
export const ENDPOINT_PUBLIC_PASSWORD_TOKEN_VERIFY = '/user/password-token-verify/:token';

//Leads
export const ENDPOINT_PUBLIC_LEAD = '/lead';
export const ENDPOINT_PUBLIC_SINGLE_LEAD = '/lead/{id}';

//Orders
export const ENDPOINT_PUBLIC_CREATE_ORDER_BY_VOUCHER = '/order/voucher';

//Offers
export const ENDPOINT_PUBLIC_OFFER_BY_SLUG = '/v2/offers/slug/:slug';
export const ENDPOINT_PUBLIC_OFFERS = '/v2/offers';

//Products
export const ENDPOINT_PUBLIC_PRODUCTS = '/v2/products';
export const ENDPOINT_PUBLIC_PRODUCT = '/v2/products/:id';

//User offers
export const ENDPOINT_PUBLIC_USER_OFFERS = '/v2/user-offers';
export const ENDPOINT_PUBLIC_USER_OFFER = '/v2/user-offers/:id';
export const ENDPOINT_PUBLIC_USER_OFFER_PAYMENT = '/v2/user-offers/:id/payment';

//Payment transaction
export const ENDPOINT_PUBLIC_PAYMENT_TRANSACTION_VERIFY = '/v2/payment-transactions/:id/verify';

// Events 
export const ENDPOINT_EVENTS_FREE_SLOTS_BY_SCORE = '/v2/events/free-slots-by-score';

// Locations
export const ENDPOINT_LOCATIONS_SLUG = '/v2/locations/slug/:slug';
export const ENDPOINT_LOCATION_TRAINERS = '/v2/locations/trainers/:slug';

// Users
export const ENDPOINT_PUBLIC_USER_SLUG = '/v2/users/:slug/by-slug';
export const ENDPOINT_PUBLIC_UPDATE_INVITED_USER = '/v2/users/:token/update-invited-user';

// Shop page
export const ENDPOINT_PUBLIC_SHOP_PAGE_SLUG = '/v2/shop-page/slug/:slug';

/**
 * Auth user module
*/
export const ENDPOINT_AUTH_PRESIGN_AVATAR = '/me/presign-avatar';
export const ENDPOINT_AUTH_CHANGE_PASSWORD = '/me/change-password';

//Recomandations
export const ENDPOINT_AUTH_RECOMMENDATIONS = '/recommendations';

// Profile
export const ENDPOINT_AUTH_PROFILE = '/me';

// Chat
export const ENDPOINT_AUTH_CHAT_CONVERSATIONS = '/v2/chat/conversations';
export const ENDPOINT_AUTH_CHAT_CONVERSATION_MESSAGES = '/v2/chat/conversations/{conversationId}/messages';

// Medical Questions
export const ENDPOINT_AUTH_MEDICAL_QUESTIONS = '/medical-questions';

/**
 * Admin user module
*/
export const ENDPOINT_ADMIN_FILE_PRESIGN = '/admin/presign/{type}';

// Users
export const ENDPOINT_ADMIN_USERS = '/admin/users';
export const ENDPOINT_ADMIN_USER_ASSIGN = '/admin/users/assign';
export const ENDPOINT_ADMIN_USER = '/admin/users/:id';
export const ENDPOINT_ADMIN_USER_PERMISSIONS = '/admin/users/:id/permissions';
export const ENDPOINT_ADMIN_USER_TV_ACCESS_CODE = '/admin/users/:id/tv-access-code';
export const ENDPOINT_ADMIN_USER_NOTIFICATION_ACTIVATE = '/admin/users/:id/notification/activate';
export const ENDPOINT_ADMIN_USER_PRESIGN_AVATAR = '/admin/users/:id/presign-avatar';
export const ENDPOINT_ADMIN_USER_ASSIGN_PRODUCT = '/admin/users/:id/product';

// Users segments
export const ENDPOINT_ADMIN_USER_SEGMENTS = '/admin/users/segments';
export const ENDPOINT_ADMIN_USER_SEGMENTS_SINGLE = '/admin/users/segments/:id';
export const ENDPOINT_ADMIN_USER_ASSIGN_TO_SEGMENT = '/admin/users/segments/:id/assign';
export const ENDPOINT_ADMIN_USER_UNASSIGN_FROM_SEGMENT = '/admin/users/segments/:id/unassign';

// User Surveys
export const ENDPOINT_ADMIN_USER_SURVEYS = '/admin/user-surveys';
export const ENDPOINT_ADMIN_USER_SURVEY = '/admin/user-surveys/{id}';

// Chat
export const ENDPOINT_ADMIN_CHAT_CONVERSATIONS = '/admin/chat/conversations';
export const ENDPOINT_ADMIN_CHAT_CONVERSATION_USERS = '/admin/chat/conversations/users';
export const ENDPOINT_ADMIN_CHAT_CONVERSATION_MESSAGES = '/admin/chat/conversations/{conversationId}/messages';
export const ENDPOINT_ADMIN_CHAT_CONVERSATION_ENABLE = '/admin/chat/conversations/{conversationId}/enable';
export const ENDPOINT_ADMIN_CHAT_CONVERSATION_DISABLE = '/admin/chat/conversations/{conversationId}/disable';

// Chat mass messaging
export const ENDPOINT_ADMIN_CHAT_MASS_MESSAGESS = '/admin/chat/mass-messages';
export const ENDPOINT_ADMIN_CHAT_MASS_MESSAGESS_SINGLE = '/admin/chat/mass-messages/{id}';

// Staff data
export const ENDPOINT_ADMIN_STAFF_DATA_LIST = '/admin/staff-data';
export const ENDPOINT_ADMIN_STAFF_DATA = '/admin/staff-data/{id}';

// Training targets
export const ENDPOINT_ADMIN_TRAINING_TARGETS = '/admin/training-targets';
export const ENDPOINT_ADMIN_TRAINING_TARGET = '/admin/training-targets/:id';
export const ENDPOINT_ADMIN_TRAINING_TARGET_ENABLED_STATUS = '/admin/training-targets/:id/enabled-status';
export const ENDPOINT_ADMIN_TRAINING_TARGET_CATEGORIES = '/admin/training-target-categories';
export const ENDPOINT_ADMIN_TRAINING_TARGET_CATEGORY = '/admin/training-target-categories/:id';
export const ENDPOINT_ADMIN_TRAINING_TARGET_CATEGORY_ENABLED_STATUS = '/admin/training-target-categories/:id/enabled-status';

// ActivityLogs
export const ENDPOINT_ADMIN_ACTIVITY_LOGS = '/admin/activity-logs';
export const ENDPOINT_ADMIN_ACTIVITY_LOG = '/admin/activity-logs/:id';

/**
 * Admin API V2
*/

// Adept tests
export const ENDPOINT_ADMIN_ADEPT_TESTS = '/admin/v2/adept-tests';
export const ENDPOINT_ADMIN_ADEPT_TEST = '/admin/v2/adept-tests/:id';

// Measurements
export const ENDPOINT_ADMIN_MEASUREMENTS = '/admin/measurements';
export const ENDPOINT_ADMIN_MEASUREMENT = '/admin/measurements/:id';
export const ENDPOINT_ADMIN_MEASUREMENTS_CONFIG = '/admin/measurements/config';
export const ENDPOINT_ADMIN_MEASUREMENT_TYPE = '/admin/measurements/type';
export const ENDPOINT_ADMIN_MEASUREMENTS_PROGRESS = '/admin/measurements/progress';

// Products
export const ENDPOINT_ADMIN_PRODUCTS = '/admin/v2/products';
export const ENDPOINT_ADMIN_PRODUCT = '/admin/v2/products/:id';
export const ENDPOINT_ADMIN_PRODUCT_ENABLED_STATUS = '/admin/v2/products/:id/enabled-status';
export const ENDPOINT_ADMIN_PRODUCT_SPECIALIZATIONS = '/admin/v2/products/:id/specializations';

// Products categories
export const ENDPOINT_ADMIN_PRODUCT_CATEGORIES = '/admin/v2/products/categories';
export const ENDPOINT_ADMIN_PRODUCT_CATEGORY = '/admin/v2/products/categories/:id';
export const ENDPOINT_ADMIN_PRODUCT_CATEGORY_ENABLED_STATUS = '/admin/v2/products/categories/:id/enabled-status';

// Promo codes
export const ENDPOINT_ADMIN_PROMO_CODES = '/admin/v2/promo-codes';
export const ENDPOINT_ADMIN_PROMO_CODE = '/admin/v2/promo-codes/:id';
export const ENDPOINT_ADMIN_PROMO_CODE_ENABLED_STATUS = '/admin/v2/promo-codes/:id/enabled-status';

// Offers
export const ENDPOINT_ADMIN_OFFERS = '/admin/v2/offers';
export const ENDPOINT_ADMIN_OFFER = '/admin/v2/offers/:id';
export const ENDPOINT_ADMIN_OFFER_ENABLED_STATUS = '/admin/v2/offers/:id/enabled-status';
export const ENDPOINT_ADMIN_OFFER_PRESIGN_BACKGROUND = '/admin/v2/offers/:id/presign-background';

// Offer products
export const ENDPOINT_ADMIN_OFFER_PRODUCTS = '/admin/v2/offer-products';
export const ENDPOINT_ADMIN_OFFER_PRODUCT = '/admin/v2/offer-products/:id';

// User offer products
export const ENDPOINT_ADMIN_USER_OFFER_PRODUCTS = '/admin/v2/user-offer-products';
export const ENDPOINT_ADMIN_USER_OFFER_PRODUCT = '/admin/v2/user-offer-products/:id';

// User offer invoice items
export const ENDPOINT_ADMIN_USER_OFFER_INVOICE_ITEMS = '/admin/v2/user-offer-invoice-items';
export const ENDPOINT_ADMIN_USER_OFFER_INVOICE_ITEM = '/admin/v2/user-offer-invoice-items/:id';

// Offer usps
export const ENDPOINT_ADMIN_OFFER_USPS = '/admin/v2/offer-usps';
export const ENDPOINT_ADMIN_OFFER_USP = '/admin/v2/offer-usps/:id';
export const ENDPOINT_ADMIN_OFFER_USP_ORDER = '/admin/v2/offer-usps/:id/order';

// Payment transactions
export const ENDPOINT_ADMIN_PAYMENT_TANSACTIONS = '/admin/v2/payment-transactions';
export const ENDPOINT_ADMIN_PAYMENT_TANSACTION = '/admin/v2/payment-transactions/:id';
export const ENDPOINT_ADMIN_PAYMENT_TANSACTION_COMPLETE = '/admin/v2/payment-transactions/:id/complete';
export const ENDPOINT_ADMIN_PAYMENT_TANSACTION_REFUND = '/admin/v2/payment-transactions/:id/refund';
export const ENDPOINT_ADMIN_PAYMENT_TRANSACTION_STATUS = '/admin/v2/payment-transactions/:id/status';

// Payment transactions logs
export const ENDPOINT_ADMIN_PAYMENT_TANSACTIONS_LOGS = '/admin/v2/payment-transactions-logs';

// Orders
export const ENDPOINT_ADMIN_ORDERS = '/admin/v2/orders';
export const ENDPOINT_ADMIN_ORDER = '/admin/v2/orders/:id';
export const ENDPOINT_ADMIN_ORDER_CLOSE = '/admin/v2/orders/:id/close';

// User offers
export const ENDPOINT_ADMIN_USER_OFFERS = '/admin/v2/user-offers';
export const ENDPOINT_ADMIN_USER_OFFERS_INCOME = '/admin/v2/user-offers/income';
export const ENDPOINT_ADMIN_USER_OFFER = '/admin/v2/user-offers/:id';
export const ENDPOINT_ADMIN_USER_OFFER_CANCEL = '/admin/v2/user-offers/:id/cancel';
export const ENDPOINT_ADMIN_USER_OFFER_ACTIVATE = '/admin/v2/user-offers/:id/activate';
export const ENDPOINT_ADMIN_USER_OFFER_EXTEND = '/admin/v2/user-offers/:id/extend';
export const ENDPOINT_ADMIN_USER_OFFER_CLOSE = '/admin/v2/user-offers/:id/close';
export const ENDPOINT_ADMIN_USER_OFFER_PAYMENT = '/admin/v2/user-offers/:id/payment';
export const ENDPOINT_ADMIN_USER_OFFER_MIGRATE = '/admin/v2/user-offers/migrate';
export const ENDPOINT_ADMIN_USER_OFFER_NOTIFICATION_SHOP_LINK = '/admin/v2/user-offers/:id/notification/shop-link';
export const ENDPOINT_ADMIN_USER_OFFER_TERMINATION_DATES = '/admin/v2/user-offers/:id/termination-dates';
export const ENDPOINT_ADMIN_USER_OFFER_LOGS = '/admin/v2/user-offer-logs';
export const ENDPOINT_ADMIN_USER_OFFER_ADD_PAYMENT = '/admin/v2/user-offers/:id/add-payment';
export const ENDPOINT_ADMIN_USER_OFFER_INVITE = '/admin/v2/user-offers/:id/invite';
export const ENDPOINT_ADMIN_USER_OFFER_REMOVE_USER = '/admin/v2/user-offers/:id/remove-sub-user';
export const ENDPOINT_ADMIN_USER_OFFER_GET_SUB_USERS = '/admin/v2/user-offers/:id/users';

// User offer diagnostic reports
export const ENDPOINT_ADMIN_USER_OFFER_DIAGNOSTIC_REPORTS = '/admin/v2/user-offer-diagnostic-reports';
export const ENDPOINT_ADMIN_USER_OFFER_DIAGNOSTIC_REPORT = '/admin/v2/user-offer-diagnostic-reports/:id';
export const ENDPOINT_ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_USER_NOTIFICATION = '/admin/v2/user-offer-diagnostic-reports/:id/user-notification';

// User payment methods
export const ENDPOINT_ADMIN_USER_PAYMENT_METHODS = '/admin/v2/user-payment-methods';
export const ENDPOINT_ADMIN_USER_PAYMENT_METHOD = '/admin/v2/user-payment-methods/:id';
export const ENDPOINT_ADMIN_USER_PAYMENT_METHOD_ENABLED_STATUS = '/admin/v2/user-payment-methods/:id/enabled-status';

// Locations
export const ENDPOINT_ADMIN_LOCATIONS = '/admin/v2/locations';
export const ENDPOINT_ADMIN_LOCATION = '/admin/v2/locations/:id';
export const ENDPOINT_ADMIN_LOCATION_ENABLED_STATUS = '/admin/v2/locations/:id/enabled-status';
export const ENDPOINT_ADMIN_LOCATION_USERS = '/admin/v2/locations/:id/users';

// Specializations
export const ENDPOINT_ADMIN_SPECIALIZATIONS = '/admin/v2/specializations';
export const ENDPOINT_ADMIN_SPECIALIZATION = '/admin/v2/specializations/:id';
export const ENDPOINT_ADMIN_SPECIALIZATION_ENABLED_STATUS = '/admin/v2/specializations/:id/enabled-status';
export const ENDPOINT_ADMIN_SPECIALIZATION_USERS = '/admin/v2/specializations/:id/user';

// Events
export const ENDPOINT_ADMIN_EVENTS = '/admin/v2/events';
export const ENDPOINT_ADMIN_EVENTS_ADD = '/admin/v2/events/add';
export const ENDPOINT_ADMIN_EVENTS_STATS = '/admin/v2/events/stats';
export const ENDPOINT_ADMIN_EVENTS_HISTORY = '/admin/v2/events/history';
export const ENDPOINT_ADMIN_EVENTS_CYCLE_SLOTS_AVAILABILITY = '/admin/v2/events/check-availability';
export const ENDPOINT_ADMIN_EVENTS_CREATE_MANY = '/admin/v2/events/create-many';
export const ENDPOINT_ADMIN_EVENT = '/admin/v2/events/:id';
export const ENDPOINT_ADMIN_EVENT_RESET = '/admin/v2/events/:id/reset';
export const ENDPOINT_ADMIN_EVENT_PAID_STATUS = '/admin/v2/events/:id/paid-status';
export const ENDPOINT_ADMIN_EVENT_DONE_STATUS = '/admin/v2/events/:id/done-status';
export const ENDPOINT_ADMIN_EVENT_UNBONUS = '/admin/v2/events/:id/unbonus';
export const ENDPOINT_ADMIN_EVENT_CANCEL_DIAGNOSTIC = '/admin/v2/events/:id/cancel-diagnostic';
export const ENDPOINT_ADMIN_EVENT_CONFIRM_REQUEST = '/admin/v2/events/:id/confirm-request';

// Medical cards
export const ENDPOINT_ADMIN_MEDICAL_CARDS = '/admin/v2/medical-cards';
export const ENDPOINT_ADMIN_MEDICAL_CARDS_LATEST = '/admin/v2/medical-cards/latest';
export const ENDPOINT_ADMIN_MEDICAL_CARD = '/admin/v2/medical-cards/:id';

// Invoicing strategies
export const ENDPOINT_ADMIN_INVOICING_STRATEGIES = '/admin/v2/invoicing-strategies';

// Work hours
export const ENDPOINT_ADMIN_USER_WORK_HOURS_LIST = '/admin/v2/users-work-hours';
export const ENDPOINT_ADMIN_USER_WORK_HOURS = '/admin/v2/users-work-hours/:id';

// User transfer
export const ENDPOINT_ADMIN_USER_TRANSFER = '/admin/v2/user-transfer/:id';

// Notes
export const ENDPOINT_ADMIN_USERS_NOTES = '/admin/users/:id/notes';

// Getresponse history
export const ENDPOINT_ADMIN_GR_HISTORY_LOGS = '/admin/v2/gr-history-logs';
export const ENDPOINT_ADMIN_GR_HISTORY_LOG = '/admin/v2/gr-history-logs/:id';

// User products
export const ENDPOINT_ADMIN_USER_PRODUCTS = '/admin/v2/user-products';
export const ENDPOINT_ADMIN_USER_PRODUCT = '/admin/v2/user-products/:id';

// User comments
export const ENDPOINT_ADMIN_USER_COMMENTS = '/admin/v2/user-comments';
export const ENDPOINT_ADMIN_USER_COMMENT = '/admin/v2/user-comments/:id';

// User Homeworks
export const ENDPOINT_ADMIN_USER_HOMEWORKS = '/admin/v2/user-homework';
export const ENDPOINT_ADMIN_USER_HOMEWORK = '/admin/v2/user-homework/:id';
export const ENDPOINT_ADMIN_USER_HOMEWORK_CHANGE_COMPLETED = '/admin/v2/user-homework/:id/completed-change';

// Cyclic event
export const ENDPOINT_ADMIN_CYCLIC_EVENTS = '/admin/v2/cyclic-event';
export const ENDPOINT_ADMIN_CYCLIC_EVENT = '/admin/v2/cyclic-event/:id';

// Shop pages
export const ENDPOINT_ADMIN_SHOP_PAGES = '/admin/v2/shop-pages';
export const ENDPOINT_ADMIN_SHOP_PAGE = '/admin/v2/shop-pages/:id';

// User Effects
export const ENDPOINT_ADMIN_USER_EFFECTS = '/admin/v2/user-effects';
export const ENDPOINT_ADMIN_USER_EFFECT = '/admin/v2/user-effects/:id';
export const ENDPOINT_ADMIN_USER_EFFECT_CHANGE_ENABLED = '/admin/v2/user-effects/:id/enabled';

// Reports
export const ENDPOINT_ADMIN_REPORTS_LIST_BIGQUERY = '/admin/v2/reports/bigquery';
export const ENDPOINT_ADMIN_REPORTS_BIGQUERY_GENERATE = '/admin/v2/reports/bigquery/export';

// Training Plans Surveys
export const ENDPOINT_ADMIN_TRAINING_PLAN_SURVEYS = '/admin/v2/tp-surveys';
export const ENDPOINT_ADMIN_TRAINING_PLAN_SURVEY = '/admin/v2/tp-surveys/:id';

// Training Plans Profiles
export const ENDPOINT_ADMIN_TRAINING_PLAN_PROFILES = '/admin/v2/tp-profiles';
export const ENDPOINT_ADMIN_TRAINING_PLAN_PROFILE = '/admin/v2/tp-profiles/:id';

// Articles
export const ENDPOINT_ADMIN_ARTICLES = '/admin/v2/articles';
export const ENDPOINT_ADMIN_ARTICLE = '/admin/v2/articles/:id';
export const ENDPOINT_ADMIN_ARTICLE_CHANGE_ASSIGNMENT = '/admin/v2/articles/:id/change-assignment';

// Article Tags
export const ENDPOINT_ADMIN_ARTICLE_TAGS = '/admin/v2/article-tags';
export const ENDPOINT_ADMIN_ARTICLE_TAG = '/admin/v2/article-tags/:id';
export const ENDPOINT_ADMIN_ARTICLE_TAG_CHANGE_ASSIGNMENT = '/admin/v2/article-tags/:id/change-assignment';

// Article Comments
export const ENDPOINT_ADMIN_ARTICLE_COMMENTS = '/admin/v2/article-comments';
export const ENDPOINT_ADMIN_ARTICLE_COMMENT = '/admin/v2/article-comments/:id';
export const ENDPOINT_ADMIN_ARTICLE_COMMENT_CHANGE_STATUS = '/admin/v2/article-comments/:id/status';

// Article Categories
export const ENDPOINT_ADMIN_ARTICLE_CATEGORIES = '/admin/v2/article-categories';
export const ENDPOINT_ADMIN_ARTICLE_CATEGORY = '/admin/v2/article-categories/:id';
export const ENDPOINT_ADMIN_ARTICLE_CATEGORY_CHANGE_ASSIGNMENT = '/admin/v2/article-categories/:id/change-assignment';

// Custom Events
export const ENDPOINT_ADMIN_CUSTOM_EVENTS = '/admin/v2/custom-events';
export const ENDPOINT_ADMIN_CUSTOM_EVENT = '/admin/v2/custom-events/:id';

// Custom Events Usps
export const ENDPOINT_ADMIN_CUSTOM_EVENT_USPS = '/admin/v2/custom-event-usps';
export const ENDPOINT_ADMIN_CUSTOM_EVENT_USP = '/admin/v2/custom-event-usps/:id';

// Custom Events Pop Ups
export const ENDPOINT_ADMIN_CUSTOM_EVENT_POP_UPS = '/admin/v2/custom-event-pop-ups';
export const ENDPOINT_ADMIN_CUSTOM_EVENT_POP_UP = '/admin/v2/custom-event-pop-ups/:id';

/**
 * Adept module
 */

// Tests
export const ENDPOINT_ADEPT_ADEPT_TESTS = '/adept/v2/adept-tests';
export const ENDPOINT_ADEPT_ADEPT_TEST = '/adept/v2/adept-tests/:id';

//Medical cards
export const ENDPOINT_ADEPT_MEDICAL_CARDS = '/adept/medical-cards';
export const ENDPOINT_ADEPT_MEDICAL_CARDS_SINGLE = '/adept/medical-cards/:id';
export const ENDPOINT_ADEPT_MEDICAL_CARDS_LIST = '/adept/medical-cards/list';

//Offers
export const ENDPOINT_ADEPT_OFFERS = '/adept/v2/offers';
export const ENDPOINT_ADEPT_OFFER = '/adept/v2/offers/:id';

//Offer products
export const ENDPOINT_ADEPT_OFFER_PRODUCTS = '/adept/v2/offer-products';
export const ENDPOINT_ADEPT_OFFER_PRODUCT = '/adept/v2/offer-products/:id';

//User offer products
export const ENDPOINT_ADEPT_USER_OFFER_PRODUCTS = '/adept/v2/user-offer-products';
export const ENDPOINT_ADEPT_USER_OFFER_PRODUCT = '/adept/v2/user-offer-products/:id';

//User offers
export const ENDPOINT_ADEPT_USER_OFFERS = '/adept/v2/user-offers';
export const ENDPOINT_ADEPT_USER_OFFER = '/adept/v2/user-offers/:id';
export const ENDPOINT_ADEPT_USER_OFFER_PAYMENT = '/adept/v2/user-offers/:id/payment';
export const ENDPOINT_ADEPT_USER_OFFER_REMOVE = '/adept/v2/user-offers/:id/delete';
export const ENDPOINT_ADEPT_USER_OFFER_PAYMENT_SINGLE = '/adept/v2/user-offers/:id/payment/single';
export const ENDPOINT_ADEPT_USER_OFFER_ACTIVATE = '/adept/v2/user-offers/:id/activate';
export const ENDPOINT_ADEPT_USER_OFFER_INVITE = '/adept/v2/user-offers/:id/invite';
export const ENDPOINT_ADEPT_USER_OFFER_REMOVE_USER = '/adept/v2/user-offers/:id/remove-sub-user';
export const ENDPOINT_ADEPT_USER_OFFER_GET_SUB_USERS = '/adept/v2/user-offers/:id/users';

//User offer diagnostic reports
export const ENDPOINT_ADEPT_USER_OFFER_DIAGNOSTIC_REPORTS = '/adept/v2/user-offer-diagnostic-reports';
export const ENDPOINT_ADEPT_USER_OFFER_DIAGNOSTIC_REPORT = '/adept/v2/user-offer-diagnostic-reports/:id';

//Payment transaction
export const ENDPOINT_ADEPT_PAYMENT_TRANSACTIONS = '/adept/v2/payment-transactions';
export const ENDPOINT_ADEPT_PAYMENT_TRANSACTION = '/adept/v2/payment-transactions/:id';
export const ENDPOINT_ADEPT_PAYMENT_TRANSACTION_VERIFY = '/adept/v2/payment-transactions/:id/verify';
export const ENDPOINT_ADEPT_PAYMENT_TRANSACTION_STATUS = '/adept/v2/payment-transactions/:id/status';

//Promo codes
export const ENDPOINT_ADEPT_PROMO_CODE_VERIFY = '/adept/v2/promo-codes/verify';

//Events
export const ENDPOINT_ADEPT_EVENTS = '/adept/v2/events';
export const ENDPOINT_ADEPT_EVENTS_STATS = '/adept/v2/events/stats';
export const ENDPOINT_ADEPT_EVENTS_LAST_LOCATION = '/adept/v2/events/last-location';
export const ENDPOINT_ADEPT_EVENTS_LAST_LEAD = '/adept/v2/events/last-lead';
export const ENDPOINT_ADEPT_EVENTS_SLOT_OCCUPATION = '/adept/v2/events/slot-occupation';
export const ENDPOINT_ADEPT_EVENTS_CYCLE_SLOTS_AVAILABILITY = '/adept/v2/events/check-availability';
export const ENDPOINT_ADEPT_EVENTS_CREATE_MANY = '/adept/v2/events/create-many';
export const ENDPOINT_ADEPT_EVENT = '/adept/v2/events/:id';
export const ENDPOINT_ADEPT_EVENT_CANCEL = '/adept/v2/events/:id/cancel';
export const ENDPOINT_ADEPT_EVENT_DONE = '/adept/v2/events/:id/done';
export const ENDPOINT_ADEPT_EVENTS_SUGGEST_FREE_SLOTS = '/adept/v2/events/suggest-free-slots';

//User payment methods
export const ENDPOINT_ADEPT_USER_PAYMENT_METHODS = '/adept/v2/user-payment-methods';
export const ENDPOINT_ADEPT_USER_PAYMENT_METHOD = '/adept/v2/user-payment-methods/:id';
export const ENDPOINT_ADEPT_USER_PAYMENT_METHOD_ENABLED_STATUS = '/adept/v2/user-payment-methods/:id/enabled-status';
export const ENDPOINT_ADEPT_USER_PAYMENT_METHOD_DEFAULT = '/adept/v2/user-payment-methods/:id/default';
export const ENDPOINT_ADEPT_USER_PAYMENT_METHOD_CONFIRM = '/adept/v2/user-payment-methods/:id/confirm';

// Measurements
export const ENDPOINT_ADEPT_MEASUREMENTS = '/adept/v2/measurements';
export const ENDPOINT_ADEPT_MEASUREMENTS_CONFIG = '/adept/v2/measurements/config';
export const ENDPOINT_ADEPT_MEASUREMENTS_PROGRESS = '/adept/v2/measurements/progress';
export const ENDPOINT_ADEPT_MEASUREMENT_CHART_DATA = '/adept/v2/measurements/chart-data';
export const ENDPOINT_ADEPT_MEASUREMENT_TYPE = '/adept/v2/measurements/type';
export const ENDPOINT_ADEPT_MEASUREMENT = '/adept/v2/measurements/:id';

// Notifications
export const ENDPOINT_ADEPT_NOTIFICATIONS = '/adept/v2/notification-internal';
export const ENDPOINT_ADEPT_NOTIFICATIONS_READ_ALL = '/adept/v2/notification-internal/read-all'
export const ENDPOINT_ADEPT_NOTIFICATIONS_SINGLE = '/adept/v2/notification-internal/:id';

// Users
export const ENDPOINT_ADEPT_USERS = '/adept/users';
export const ENDPOINT_ADEPT_USER = '/adept/users/{id}';

// Products
export const ENDPOINT_ADEPT_PRODUCTS = '/adept/v2/products';
export const ENDPOINT_ADEPT_PRODUCT = '/adept/v2/products/:id';

// Locations
export const ENDPOINT_ADEPT_LOCATIONS = '/adept/v2/locations';
export const ENDPOINT_ADEPT_LOCATION = '/adept/v2/locations/:id';
export const ENDPOINT_ADEPT_LOCATION_BY_SLUG = '/adept/v2/locations/slug/:slug';

// Training targets
export const ENDPOINT_ADEPT_TRAINING_TARGETS = '/adept/training-targets';
export const ENDPOINT_ADEPT_TRAINING_TARGET = '/adept/training-targets/:id';
export const ENDPOINT_ADEPT_TRAINING_TARGET_ENABLED_STATUS = '/adept/training-targets/:id/enabled-status';
export const ENDPOINT_ADEPT_TRAINING_TARGET_SET_PRIMARY = '/adept/training-targets/:id/set-primary';
export const ENDPOINT_ADEPT_TRAINING_TARGET_FINISH = '/adept/training-targets/:id/finish';
export const ENDPOINT_ADEPT_TRAINING_TARGET_CATEGORIES = '/adept/training-target-categories';
export const ENDPOINT_ADEPT_TRAINING_TARGET_CATEGORY = '/adept/training-target-categories/:id';

// Contacts
export const ENDPOINT_ADEPT_CONTACTS = '/adept/contacts';

// User surveys
export const ENDPOINT_ADEPT_USER_SURVEYS = '/adept/user-surveys';
export const ENDPOINT_ADEPT_USER_SURVEY = '/adept/user-surveys/:id';

// User homeworks
export const ENDPOINT_ADEPT_USER_HOMEWORKS = '/adept/v2/user-homework';
export const ENDPOINT_ADEPT_USER_HOMEWORK = '/adept/v2/user-homework/:id';
export const ENDPOINT_ADEPT_USER_HOMEWORK_SET_COMPLETED = '/adept/v2/user-homework/:id/complete';
export const ENDPOINT_ADEPT_USER_HOMEWORK_CHANGE_COMPLETED = '/adept/v2/user-homework/:id/completed-change';

// Cyclic event
export const ENDPOINT_ADEPT_CYCLIC_EVENTS = '/adept/v2/cyclic-event';
export const ENDPOINT_ADEPT_CYCLIC_EVENT = '/adept/v2/cyclic-event/:id';

/**
 * Trainer module
*/

export const ENDPOINT_TRAINER_FILE_PRESIGN = '/trainer/presign/{type}';

// Users
export const ENDPOINT_TRAINER_USERS = '/trainer/users';
export const ENDPOINT_TRAINER_USER = '/trainer/users/{id}';
export const ENDPOINT_TRAINER_USER_ASSIGN = '/trainer/users/assign';

// Products
export const ENDPOINT_TRAINER_PRODUCTS = '/trainer/v2/products';
export const ENDPOINT_TRAINER_PRODUCT = '/trainer/v2/products/:id';

// Events
export const ENDPOINT_TRAINER_EVENTS = '/trainer/v2/events';
export const ENDPOINT_TRAINER_EVENTS_STATS = '/trainer/v2/events/stats';
export const ENDPOINT_TRAINER_EVENTS_SUGGEST_FREE_SLOTS = '/trainer/v2/events/suggest-free-slots';
export const ENDPOINT_TRAINER_EVENTS_LOCATION_OCCUPANCY = '/trainer/v2/events/occupancy';
export const ENDPOINT_TRAINER_EVENTS_CYCLE_SLOTS_AVAILABILITY = '/trainer/v2/events/check-availability';
export const ENDPOINT_TRAINER_EVENTS_CREATE_MANY = '/trainer/v2/events/create-many';
export const ENDPOINT_TRAINER_EVENT_RESET = '/trainer/v2/events/:id/reset';
export const ENDPOINT_TRAINER_EVENT = '/trainer/v2/events/:id';
export const ENDPOINT_TRAINER_EVENT_CANCEL = '/trainer/v2/events/:id/cancel';
export const ENDPOINT_TRAINER_EVENT_CANCEL24H = '/trainer/v2/events/:id/cancel24h';
export const ENDPOINT_TRAINER_EVENT_CANCEL_DIAGNOSTIC = '/trainer/v2/events/:id/cancel-diagnostic';
export const ENDPOINT_TRAINER_EVENT_DONE = '/trainer/v2/events/:id/done';

// User offers
export const ENDPOINT_TRAINER_USER_OFFERS = '/trainer/v2/user-offers';
export const ENDPOINT_TRAINER_USER_OFFER = '/trainer/v2/user-offers/:id';
export const ENDPOINT_TRAINER_USER_OFFER_GET_SUB_USERS = '/trainer/v2/user-offers/:id/users';

// Offers
export const ENDPOINT_TRAINER_OFFERS = '/trainer/v2/offers';

// User offer diagnostic reports
export const ENDPOINT_TRAINER_USER_OFFER_DIAGNOSTIC_REPORTS = '/trainer/v2/user-offer-diagnostic-reports';
export const ENDPOINT_TRAINER_USER_OFFER_DIAGNOSTIC_REPORT = '/trainer/v2/user-offer-diagnostic-reports/:id';

// Locations
export const ENDPOINT_TRAINER_LOCATIONS = '/trainer/v2/locations';
export const ENDPOINT_TRAINER_LOCATION = '/trainer/v2/locations/:id';

// Tests
export const ENDPOINT_TRAINER_ADEPT_TESTS = '/trainer/adept-tests';
export const ENDPOINT_TRAINER_ADEPT_TEST = '/trainer/adept-tests/:id';

//Medical cards
export const ENDPOINT_TRAINER_MEDICAL_CARDS = '/trainer/v2/medical-cards';
export const ENDPOINT_TRAINER_MEDICAL_CARDS_LATEST = '/trainer/v2/medical-cards/latest';
export const ENDPOINT_TRAINER_MEDICAL_CARD = '/trainer/v2/medical-cards/:id';

// Measurements
export const ENDPOINT_TRAINER_MEASUREMENTS = '/trainer/v2/measurements';
export const ENDPOINT_TRAINER_MEASUREMENTS_CONFIG = '/trainer/v2/measurements/config';
export const ENDPOINT_TRAINER_MEASUREMENTS_PROGRESS = '/trainer/v2/measurements/progress';
export const ENDPOINT_TRAINER_MEASUREMENT_CHART_DATA = '/trainer/v2/measurements/chart-data';
export const ENDPOINT_TRAINER_MEASUREMENT_TYPE = '/trainer/v2/measurements/type';
export const ENDPOINT_TRAINER_MEASUREMENT = '/trainer/v2/measurements/:id';

// Notifications
export const ENDPOINT_TRAINER_NOTIFICATIONS = '/trainer/v2/notification-internal';
export const ENDPOINT_TRAINER_NOTIFICATIONS_READ_ALL = '/trainer/v2/notification-internal/read-all';
export const ENDPOINT_TRAINER_NOTIFICATIONS_SINGLE = '/trainer/v2/notification-internal/:id';

// Training targets
export const ENDPOINT_TRAINER_TRAINING_TARGETS = '/trainer/training-targets';
export const ENDPOINT_TRAINER_TRAINING_TARGET = '/trainer/training-targets/:id';
export const ENDPOINT_TRAINER_TRAINING_TARGET_ENABLED_STATUS = '/trainer/training-targets/:id/enabled-status';
export const ENDPOINT_TRAINER_TRAINING_TARGET_CATEGORIES = '/trainer/training-target-categories';
export const ENDPOINT_TRAINER_TRAINING_TARGET_CATEGORY = '/trainer/training-target-categories/:id';

// User work hours
export const ENDPOINT_TRAINER_USER_WORK_HOURS = '/trainer/v2/users-work-hours/:id';

// Notes
export const ENDPOINT_TRAINER_USERS_NOTES = '/trainer/users/:id/notes';

// User surveys
export const ENDPOINT_TRAINER_USER_SURVEYS = '/trainer/user-surveys';
export const ENDPOINT_TRAINER_USER_SURVEY = '/trainer/user-surveys/:id';

// User comments
export const ENDPOINT_TRAINER_USER_COMMENTS = '/trainer/v2/user-comments';
export const ENDPOINT_TRAINER_USER_COMMENT = '/trainer/v2/user-comments/:id';

// User homeworks
export const ENDPOINT_TRAINER_USER_HOMEWORKS = '/trainer/v2/user-homework';
export const ENDPOINT_TRAINER_USER_HOMEWORK = '/trainer/v2/user-homework/:id';
export const ENDPOINT_TRAINER_USER_HOMEWORK_CHANGE_COMPLETED = '/trainer/v2/user-homework/:id/completed-change';

// Cyclic event
export const ENDPOINT_TRAINER_CYCLIC_EVENTS = '/trainer/v2/cyclic-event';
export const ENDPOINT_TRAINER_CYCLIC_EVENT = '/trainer/v2/cyclic-event/:id';

// User Effects
export const ENDPOINT_TRAINER_USER_EFFECTS = '/trainer/v2/user-effects';
export const ENDPOINT_TRAINER_USER_EFFECT = '/trainer/v2/user-effects/:id';
export const ENDPOINT_TRAINER_USER_EFFECT_CHANGE_ENABLED = '/trainer/v2/user-effects/:id/enabled';

// Articles
export const ENDPOINT_TRAINER_ARTICLES = '/trainer/v2/articles';
export const ENDPOINT_TRAINER_ARTICLE = '/trainer/v2/articles/:id';
export const ENDPOINT_TRAINER_ARTICLE_CHANGE_ASSIGNMENT = '/trainer/v2/articles/:id/change-assignment';

// Article Tags
export const ENDPOINT_TRAINER_ARTICLE_TAGS = '/trainer/v2/article-tags';

// Article Categories
export const ENDPOINT_TRAINER_ARTICLE_CATEGORIES = '/trainer/v2/article-categories';

/**
 * Old Api - Public module
 */
export const ENDPOINT_OLD_FORM_CITIES = '/form/cities';
export const ENDPOINT_OLD_FORM_GYMS = '/form/gyms';
export const ENDPOINT_OLD_FORM_TRAINER_CALENDAR = '/form/trainers/{id}/calendar/{startDate}';
export const ENDPOINT_OLD_FORM_COUPON_CHECK = '/form/discounts/check';
export const ENDPOINT_OLD_FORM_PRODUCT = '/form/products/{id}';
export const ENDPOINT_OLD_FORM_ORDERS = '/form/orders';
export const ENDPOINT_OLD_FORM_ORDERS_PROMO = '/form/orders/promo';
