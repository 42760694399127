import React from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';

export default class PortalNotificationsComponent extends React.Component {
    static propTypes = {
        actions: PropTypes.shape({
            pop: PropTypes.func.isRequired,
        }).isRequired,
        list: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                data: PropTypes.shape({
                    link: PropTypes.string,
                    category: PropTypes.string,
                }).isRequired,
                notification: PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    body: PropTypes.string.isRequired,
                }).isRequired,
            })
        ).isRequired,
    };

    render() {
        const { list, actions } = this.props;

        return (
            <StyledComponent styles={require('./styles')} className="notification-component">
                {list.map(message => (
                    <a
                        key={message.id}
                        className="notification animated fadeInUp"
                        onClick={() => actions.pop({ id: message.id })}
                        href={message.data.link || null}
                    >
                        <h6 className="notification-title">{message.notification.title}</h6>
                        <p className="notification-body">{message.notification.body}</p>
                    </a>
                ))}
            </StyledComponent>
        );
    }
}
