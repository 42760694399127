import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from 'Utils/date';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelBigQueryReportsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listBigQuery: PropTypes.func.isRequired,
        }).isRequired,
        onMapLabels: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        forceReload: PropTypes.bool,
    };
    static defaultProps = {
        onMapLabels: (elem, results) => results,
        onMapAdditionals: (elem, value) => value,
    };

    defaultQuery = {
        page: 1,
        timestamp: undefined,
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...predefinedQuery, ...queryObject },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { forceReload } = this.props;
       
        if (JSON.stringify(prevProps.forceReload) !== JSON.stringify(forceReload)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.listBigQuery({ ...queryObject });
        }, 500);
    }

    render() {
        const {
            location,
            history,
            bigQueryReports,
            onMapLabels,
            onMapAdditionals,
        } = this.props;

        return (
            <StyledComponent
                className="panel-bigquery-reports-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={bigQueryReports}
                    title="Lista raportów BigQuery"
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={getFormattedDate(element.createdAt)}
                            labels={onMapLabels(element, [{
                                isVisible: Boolean(element.status === 'done'),
                                label: 'Zakończony',
                                state: 'success',
                            }, {
                                isVisible: Boolean(element.status === 'failed'),
                                label: 'Błąd',
                                state: 'alert',
                            }, {
                                isVisible: Boolean(element.status === 'active'),
                                label: 'W trakcie',
                                state: 'warning',
                            }])}
                            additionals={onMapAdditionals(element, [{
                                visible: true,
                                name: 'Data startu',
                                value: getFormattedDate(element.startAt),
                            }, {
                                visible: true,
                                name: 'Data końca',
                                value: getFormattedDate(element.endAt),
                            }])}
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}
