import { connect } from 'react-redux';
import Component from './component';

import { create, confirm } from 'Redux/modules/adept/userPaymentMethods/actions';

export default connect(
    state => ({
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            create: dispatch(create),
            confirm: dispatch(confirm),
        },
    }),
)(Component);