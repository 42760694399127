import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import {
    single,
} from 'Redux/modules/admin/users/actions';

import {
    list as listUserWorkHours,
} from 'Redux/modules/admin/userWorkHours/actions';

import {
    assignUsers as assignUsersToSpecialization,
    unassignUsers as unassignUsersFromSpecialization,
} from 'Redux/modules/admin/specializations/actions';

import {
    assignUsers as assignUsersToLocation,
    unassignUsers as unassignUsersFromLocation,
} from 'Redux/modules/admin/locations/actions';

export default connect(
    state => ({
        usersDataMap: state.adminUsers.mapById,
        userWorkHoursList: state.adminUserWorkHours.list,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
            listUserWorkHours: dispatch(listUserWorkHours),
            assignUsersToSpecialization: dispatch(assignUsersToSpecialization),
            unassignUsersFromSpecialization: dispatch(unassignUsersFromSpecialization),
            assignUsersToLocation: dispatch(assignUsersToLocation),
            unassignUsersFromLocation: dispatch(unassignUsersFromLocation),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        user: stateProps.usersDataMap[ownProps.match.params.id],
        userWorkHours: stateProps.userWorkHoursList.elements.find(userWorkHour => userWorkHour.user && userWorkHour.user.id === ownProps.match.params.id),
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-UsersManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);