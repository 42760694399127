import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    ADMIN_ARTICLE_CREATE,
    ADMIN_ARTICLE_TAG_CREATE,
    ADMIN_ARTICLE_CATEGORY_CREATE,
} from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import ArticlesList from 'Components/admin/articles/List';
import ArticleTagsList from 'Components/admin/articleTags/List';
import ArticleCommentList from 'Components/admin/articleComments/List';
import ArticleCategoryList from 'Components/admin/articleCategories/List';

export const TAB_ARTICLES = 'articles';
export const TAB_ARTICLE_TAGS = 'articleTags';
export const TAB_ARTICLE_COMMENTS = 'articleComments';
export const TAB_ARTICLE_CATEGORIES = 'articleCategories';

export default class AdminBlog extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-blog"
                styles={require('./styles')}
            >
                <PageContent>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Blog"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === TAB_ARTICLES),
                            key: 'createArticle',
                            label: 'Nowy artykuł',
                            onClick: () => history.push(ADMIN_ARTICLE_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_ARTICLE_TAGS),
                            key: 'createArticleTag',
                            label: 'Nowy tag',
                            onClick: () => history.push(ADMIN_ARTICLE_TAG_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_ARTICLE_CATEGORIES),
                            key: 'createArticleCategory',
                            label: 'Nowa kategoria',
                            onClick: () => history.push(ADMIN_ARTICLE_CATEGORY_CREATE.path),
                        }]}
                        tabs={[{
                            key: TAB_ARTICLES,
                            label: 'Artykuły',
                            children: (
                                <ArticlesList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_ARTICLE_TAGS,
                            label: 'Tagi',
                            children: (
                                <ArticleTagsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_ARTICLE_COMMENTS,
                            label: 'Komentarze',
                            children: (
                                <ArticleCommentList
                                    location={location}
                                    history={history}
                                />
                            )
                        }, {
                            key: TAB_ARTICLE_CATEGORIES,
                            label: 'Kategorie',
                            children: (
                                <ArticleCategoryList
                                    location={location}
                                    history={history}
                                />
                            )
                        }]}
                    />  
                </PageContent>
            </StyledComponent>
        );
    }
}
