import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { ReactSVG } from 'react-svg';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import MeasurementsBox from 'Components/layout/panel/MeasurementsBox';
import Form from 'Components/forms/Form';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';
import Select from 'Components/forms/MultiSelect';
import Textarea from 'Components/forms/Textarea';
import Switch from 'Components/forms/Switch';
import DatePicker from 'Components/forms/DatePicker';
import S3FileUpload from 'Components/forms/S3FileUpload';
import S3FileMultiUpload from 'Components/forms/S3FileMultiUpload';
import Checkbox from 'Components/forms/Checkbox';
import InputsList from 'Components/forms/InputsList';

export default class PanelFormGenerator extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        data: PropTypes.object,
        errors: PropTypes.object,

        onStateChange: PropTypes.func,
        onSuccess: PropTypes.func,
        onError: PropTypes.func,
        onDataSubmit: PropTypes.func,

        submitVisible: PropTypes.bool,
        submitLabel: PropTypes.string,
        submitAction: PropTypes.func,
        submitButtonDisabled: PropTypes.bool,
        submitButtonStyle: PropTypes.oneOf([
            'faPrimary', 'hollow',
        ]),

        layout: PropTypes.oneOf(['default', 'box']),

        elements: PropTypes.arrayOf(
            PropTypes.shape({
                isVisible: PropTypes.bool,
                type: PropTypes.oneOf([
                    'input',
                    'select',
                    'htmlEditor',
                    'textarea',
                    'switch',
                    'datePicker',
                    's3FileUpload',
                    's3FileMultiUpload',
                    'inputsList',
                    'element',
                ]),
                name: PropTypes.string.isRequired,
                label: PropTypes.string,
                required: PropTypes.bool,
                options: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.string.isRequired,
                        value: PropTypes.any,
                    })
                ),
                inputProps: PropTypes.object,
                boxContent: PropTypes.shape({
                    imageSrc: PropTypes.string,
                    headline: PropTypes.string,
                    footer: PropTypes.element,
                }),
                size: PropTypes.oneOf([
                    'default', 'fullWidth',
                ]),
                iconColor: PropTypes.oneOf([
                    'default', 'white',
                ]),
                position: PropTypes.oneOf([
                    'default', 'hidden',
                ]),
                children: PropTypes.element,
            })
        ),

        showSuccessToast: PropTypes.bool,
    };
    static defaultProps = {
        data: {},
        errors: {},
        onSuccess: null,
        onError: null,
        onDataSubmit: data => data,

        submitVisible: true,
        submitLabel: 'Zapisz',
        submitAction: null,
        submitButtonDisabled: false,
        submitButtonStyle: 'faPrimary',

        layout: 'default',
        showSuccessToast: true,
    };
    state = {
        isHiddenVisible: false,
        globalError: null,
        errors: {},
        isPending: false,

        //eslint-disable-next-line react/destructuring-assignment
        formState: this.props.data,
    };

    componentDidMount = () => {
        const { data } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...data,
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data, errors } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...data,
                },
            }));
        }

        if (errors && JSON.stringify(errors) !== JSON.stringify(prevProps.errors)) {
            this.setState({
                isPending: false,
                errors,
            });
        }
    }

    onSubmit = () => {
        const { formState } = this.state;
        const { name, submitAction, onDataSubmit, onSuccess, onError, showSuccessToast } = this.props;

        this.setState({
            isPending: true,
            errors: {},
            globalError: null,
        });

        return submitAction(
            onDataSubmit(formState)
        )
            .then(response => {
                this.setState({ isPending: false });

                if (onSuccess) {
                    onSuccess(response);
                }

                if (showSuccessToast) {
                    toast('Dane zostały zapisane');
                }
                return response;
            })
            .catch(error => {
                Logger.error(`[${name}] Form Error`, error);
                const errorPayload = error && error.payload;

                this.setState({
                    isPending: false,
                    errors: errorPayload && errorPayload.validationErrors,
                    globalError: errorPayload && errorPayload.message,
                });

                if (onError) {
                    onError(error);
                }

                return error;
            });
    }

    onStateChange = (name, value) => {
        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                [name]: value,
            },
        }), () => {
            const { onStateChange } = this.props;
            const { formState } = this.state;

            if (onStateChange) {
                onStateChange(formState);
            }
        });
    }

    getFormElement = ({ type, name, label, inputProps, required, options, isVisible, children, footer }) => {
        const { formState } = this.state;

        if (isVisible === false) {
            return null;
        }

        if (type === 'element') {
            return children;
        }

        const defaultInputProps = {
            key: name,
            label: label || name,
            name,
            placeholder: label || name,
            required,
            value: formState[name],
            onChange: ({ value }) => this.onStateChange(name, value),
            ...inputProps,
        };

        switch (type) {
            case 'input':
                return (
                    <Input {...defaultInputProps} />
                );
            case 'select':
                return (
                    <Select
                        {...defaultInputProps}
                        options={options}
                    />
                );
            case 'htmlEditor':
            case 'textarea':
                return (
                    <Textarea
                        {...defaultInputProps}
                    />
                );
            case 'switch':
                return (
                    <Switch
                        {...defaultInputProps}
                    />
                );
            case 'switchWithFooter':
                return (
                    <div className="switch-footer">
                        <Switch
                            {...defaultInputProps}
                        />
                        {footer || null}
                    </div>
                );
            case 'datePicker':
                return (
                    <DatePicker
                        {...defaultInputProps}
                    />
                );
            case 's3FileUpload':
                return (
                    <S3FileUpload
                        {...defaultInputProps}
                    />
                );
            case 's3FileMultiUpload':
                return (
                    <S3FileMultiUpload
                        {...defaultInputProps}
                    />
                );
            case 'checkbox':
                return (
                    <Checkbox
                        {...defaultInputProps}
                    />
                );
            case 'inputsList':
                return (
                    <InputsList
                        {...defaultInputProps}
                    />
                );
            default:
                return null;
        }
    }

    getFormElements = (elements) => {
        const { layout } = this.props;

        return elements.map(element => {
            switch (layout) {
                case 'box':
                    if (element.isVisible === false) return null;

                    return (
                        <MeasurementsBox
                            key={element.name}
                            headline={element?.boxContent?.headline || ''}
                            icon={(
                                <ReactSVG className={`box-icon color-${element.iconColor}`}
                                    src={element?.boxContent?.imageSrc}
                                    beforeInjection={svg => {
                                        svg.setAttribute('width', '100');
                                        svg.setAttribute('height', '100');
                                    }}
                                />
                            )}
                            footer={element?.boxContent?.footer}
                        >
                            <div className="box-body">
                                <div className="box-input">{this.getFormElement(element)}</div>
                                {element.units && (<span className="box-units">{element.units}</span>)}
                            </div>
                        </MeasurementsBox>
                    );
                default:
                    return this.getFormElement(element);
            }
        });
    }

    render() {
        const { submitLabel, submitButtonDisabled, layout, name, submitVisible, submitButtonStyle, elements } = this.props;
        const { formState, globalError, errors, isPending, isHiddenVisible } = this.state;
        const hiddenElements = elements.filter(element => element.position === 'hidden');
        const visibleElements = elements.filter(element => element.position !== 'hidden');

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames({
                    'admin-form-generator': true,
                    [`layout-${layout}`]: true,
                })}
            >
                <Form
                    id={name}
                    data={formState}
                    onStateChange={this.onStateChange}
                    errors={{ ...errors, ...(this.props.errors || {}) }}
                    globalError={globalError}
                    onSubmit={this.onSubmit}
                    isPending={isPending}
                >
                    {hiddenElements.length > 0 ?
                        (
                            <div className="form-elements form-elements-with-hidden">
                                <div className="elements-container visible-elements">
                                    <div className="elements-list">
                                        {this.getFormElements(visibleElements)}
                                    </div>
                                </div>
                                <div className="elements-container hidden-elements">
                                    <span
                                        className="hidden-elements-trigger"
                                        onClick={() => this.setState(prevState => ({ isHiddenVisible: !prevState.isHiddenVisible }))}
                                    >
                                        {isHiddenVisible ? 'Ukryj wyłączone' : 'Pokaż wyłączone'}
                                    </span>
                                    {isHiddenVisible && (
                                        <div className="elements-list">
                                            {this.getFormElements(hiddenElements)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                        : (
                            <div className="form-elements">
                                {this.getFormElements(visibleElements)}
                            </div>
                        )
                    }
                    {submitVisible && (
                        <Button
                            className="submit-button"
                            type="submit"
                            icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                            layout="fullWidth"
                            size="large"
                            style={submitButtonDisabled ? 'warning' : submitButtonStyle}
                            disabled={submitButtonDisabled}
                        >
                            {submitLabel}
                        </Button>
                    )}
                </Form>
            </StyledComponent>
        );
    }
}
