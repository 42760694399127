import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { EXPORT_MODELS } from 'Consts/bigQueryExport';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class TrainerEventCycle extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };

    state = {
        isModalEventCycleSlotsVisible: false,
        formState: {
            Users: true,
            LeadUsers: true,
            PaymentTransactions: true,
            Events: true,
            Invoices: true,
            LocationsUsers: true,
            Locations: true,
            Offers: true,
            OfferProducts: true,
            PaymentVendors: true,
            Products: true,
            ProductCategories: true,
            ProductsSpecializations: true,
            PromoCodes: true,
            Specializations: true,
            UsersSurveys: true,
            UserConsents: true,
            UserOffers: true,
            UsersOffersInvoiceItems: true,
            UserOffersProducts: true,
            UserPaymentMethods: true,
            UserSpecializations: true,
            UsersSources: true,
            MedicalCards: true,
            Measurements: true,
            UserOfferDiagnosticReports: true,
            EventCancelTypes: true,
            UserNotes: true,
            UserNotesFiles: true,
            UserData: true,
            UserOfferUsers: true,
            UserEffects: true,
        },
    };

    componentDidMount = () => {
        const { data, predefinedState } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),
                ...predefinedState,
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                ...prevState,
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    getModelsOptions = () => {
        let options = [];

        EXPORT_MODELS.forEach(elem => {
            options.push({
                type: 'switch',
                name: elem.key,
                label: elem.label,
                defaultValue: true,
            });
        })

        return options;
    }

    dataToFormState = data => ({
        ...data,
    })

    render() {
        const { location, history, onSubmit } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent
                className="admin-bigquery-export-select-models"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    layout="box"
                    styleVersion={2} 
                    forms={[{
                        title: 'Wybierz modele do eksportu',
                        name: 'editor',
                        submitAction: onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: this.getModelsOptions(),
                    }]}
                />
            </StyledComponent>
        );
    }
}