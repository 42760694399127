import { connect } from 'react-redux';
import Component from './component';

import {
    list as listProfiles
} from 'Redux/modules/admin/trainingPlanProfiles/actions';

export default connect(
    state => ({
        tpProfiles: state.adminTpProfiles.list,
    }),
    dispatch => ({
        actions: {
            listProfiles: dispatch(listProfiles),
        },
    })
)(Component);
