export function getItem(name) {
    try {
        return JSON.parse(localStorage.getItem(name));
    } catch (e) {
        return false;
    }
}
export function setItem(name, data) {
    try {
        return localStorage.setItem(name, JSON.stringify(data));
    } catch (e) {
        return false;
    }
}
export function removeItem(name) {
    try {
        localStorage.removeItem(name);
        return true;
    } catch (e) {
        return false;
    }
}
