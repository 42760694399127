import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    ADMIN_TRAINING_PLANS_SURVEY_CREATE,
} from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import TrainingPlanSurveyList from 'Components/admin/trainingPlan/Survey/List';
import TrainingPlanProfilesList from 'Components/admin/trainingPlan/Profiles/List';

export const TAB_SURVEY = 'survey';
export const TAB_PROFILES = 'profiles';

export default class AdminTrainingPlans extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-training-plans"
                styles={require('./styles')}
            >
                <PageContent>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Plany treningowe"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === TAB_SURVEY),
                            key: 'createTpSurvey',
                            label: 'Nowa ankieta',
                            onClick: () => history.push(ADMIN_TRAINING_PLANS_SURVEY_CREATE.path),
                        }]}
                        tabs={[{
                            key: TAB_SURVEY,
                            label: 'Ankiety',
                            children: (
                                <TrainingPlanSurveyList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_PROFILES,
                            label: 'Profile użytkowników',
                            children: (
                                <TrainingPlanProfilesList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />  
                </PageContent>
            </StyledComponent>
        );
    }
}
