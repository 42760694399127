import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .container {
            background-size: cover;
            background-position: center center;
            height: 100vh;
            overflow: hidden;
            position: relative;
            width: 100%;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(76,76,76, .4);
                z-index: 0;
            }
        }

        .content {
            z-index: 1;
            padding: 2em;
        }

        .title {
            font-size: 1.5em;
            font-weight: 900;
            margin-bottom: 1em;
            display: flex;
            align-items: center;
            letter-spacing: .05em;
            justify-content: center;
        }

        .icon-wrapper {
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .1em;
            margin-left: .3em;
            min-width: 1.5em;

            .fa-exclamation  {
                color: #ff7200;
            }
        }

        .subtitle {
            font-size: 1.3em;
            letter-spacing: .05em;
        }

        @media (max-width: 768px) {

            .title {
                flex-direction: column;
                text-align: center;

                .icon-wrapper {
                    margin: 1em 0 0;
                }
            }
        }
    `;
