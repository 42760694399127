import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 1000;

    button {
        border: none;
        color: #333333;
        background-color: transparent;
        margin: 0;
        cursor: pointer;
        font-size: 2em;
    }

    &.style-dark {
        top: 2em;
        right: 2em;

        button {
            background: transparent;
            color: white;
            font-size: 2em;
            font-weight: 100;
        }
    }
`;
