import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class StyledComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.any,
        props: PropTypes.object,
        state: PropTypes.object,
        styles: PropTypes.shape({
            default: PropTypes.func.isRequired,
        }),
        className: PropTypes.string,
        id: PropTypes.string,
    };
    static defaultProps = {
        props: null,
        state: null,
        className: '',
        id: '',
        onClick: null,
    }

    render() {
        const { children, props, state, styles, className, id } = this.props;

        return (
            <div
                id={id}
                className={className}
                css={styles.default(props, state)}
            >
                {children}
            </div>
        );
    }
}
