import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        width: 100%;

        .error {
            margin: 1em auto;
            text-align: center;
            color: red;
            font-size: 1.2em;
            font-weight: 900;
        }

        .confirm-container {
            .confirm-headline {
                text-align: center;
                font-size: 1.6em;
            }

            .confirm-description {
                margin-top: 1em;
                text-align: center;
            }
            .component-button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 2em;
            }
        }

        .widget-container {
            width: 100%;
            margin-top: 1em;
            position: relative;
            z-index: 2;
            min-height: 30em;

            #payu-widget {
                display: flex;
                justify-content: center;
                align-items: center;

                #payuFrame {
                    border: 0px;
                    max-width: 451px;
                    overflow: hidden;
                    height: 419px;
                    width: 100%;
                }
            }
        }

        .pending-container {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0,0,0,.8);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .component-spinner {
                position: relative;
                top: auto;
                left: auto;
                width: auto;
                height: auto;
                margin-bottom: 2em;
            }
            .pending-info {
                font-size: 2em;
                color: white;
                font-weight: 900;
                text-align: center;
                margin-top: .5em;
            }
        }

        @media (max-width: ${variables.mobileL}) {}
    `;
