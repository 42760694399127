import { connect } from 'react-redux';
import Component from './component';

import { logout, changeLanguage } from 'Redux/modules/user/actions';

export default connect(
    state => ({
        profile: state.user.profile,
        currentLanguage: state.user.currentLanguage,
    }),
    dispatch => ({
        actions: {
            logout: dispatch(logout),
            changeLanguage: dispatch(changeLanguage),
        },
    }),
)(Component);
