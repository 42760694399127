import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #2d2d2d;

        .sidebar-container {
            position: relative;
            backgroundColor: #2d2d2d;
            max-width: 15vw;
            minHeight: 100vh;
        }
        .view-container {
            position: relative;
            background-color: #f8f8f8;
            width: 100%;
            min-height: 100vh;
            overflow-x: hidden;

            .panel-big-calendar {
                max-width: 80vw;
            }
        }
    `;
