import * as types from './types';

import { COOKIE_UTM_LEAD_DATA } from 'Consts/cookies';

import { reduxRequest as request } from 'Services/Api';
import { createSession, destroySession } from 'Services/Auth';
import { getProvider } from 'Services/Cookies';

import { withVariables } from 'Utils/string';

import getStore from 'Redux';

import {
    ENDPOINT_PUBLIC_USER,
    ENDPOINT_PUBLIC_PASSWORD,
    ENDPOINT_PUBLIC_TOKEN,
    ENDPOINT_PUBLIC_VISITOR,
    ENDPOINT_AUTH_PROFILE,
    ENDPOINT_AUTH_CHANGE_PASSWORD,
    ENDPOINT_PUBLIC_PASSWORD_TOKEN_VERIFY,
    ENDPOINT_AUTH_RECOMMENDATIONS,
} from 'Consts/api';

export const register = dispatch => (params = {}) => {
    const utmData = getStore().getState().user.utm;
    getProvider().set(COOKIE_UTM_LEAD_DATA, JSON.stringify(utmData));

    return dispatch(request({
        reduxType: types.REGISTER,
        method: 'POST',
        path: ENDPOINT_PUBLIC_USER,
        params,
        requestParams: {
            ...params,
            ...utmData,
        },
    }))
        .then(response => {
            createSession(response.payload);
            return response;
        })
        .catch(error => {
            throw error;
        });
};

export const login = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LOGIN,
        method: 'POST',
        path: ENDPOINT_PUBLIC_TOKEN,
        params,
        requestParams: {
            email: params.email || undefined,
            password: params.password || undefined,
            token: params.token || undefined,
        },
    }))
        .then(response => {
            createSession(response.payload);
            return response;
        })
        .catch(error => {
            throw error;
        });
};

export const logout = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LOGOUT,
        method: 'DELETE',
        path: ENDPOINT_PUBLIC_TOKEN,
        params,
    }))
        .then(response => {
            destroySession();
            return response;
        })
        .catch(error => {
            destroySession();
            throw error;
        });
};

export const passwordReset = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.PASSWORD_RESET,
        method: 'DELETE',
        path: ENDPOINT_PUBLIC_PASSWORD,
        params,
        requestParams: {
            email: params.email,
        },
    }));
};

export const passwordChange = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.PASSWORD_CHANGE,
        method: 'POST',
        path: ENDPOINT_PUBLIC_PASSWORD,
        params,
        requestParams: {
            token: params.token,
            password: params.password,
            passwordConfirm: params.passwordConfirm,
        },
    }));
};

export const refreshToken = dispatch => (params = {}) => {
    params = {
        ...params,
        authToken: params.authToken || getStore().getState().user.authToken,
        cid: getStore().getState().user.cid,
        sid: getStore().getState().user.sid,
    };

    return dispatch(request({
        reduxType: types.REFRESH_TOKEN,
        method: 'PUT',
        path: ENDPOINT_PUBLIC_TOKEN,
        params,
        requestParams: {
            authToken: params.authToken,
            cid: params.cid,
            sid: params.sid,
        },
    }))
        .then(response => {
            createSession(response.payload);
            return response;
        })
        .catch(error => {
            throw error;
        });
};

export const getProfile = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.GET_PROFILE,
        method: 'GET',
        path: ENDPOINT_AUTH_PROFILE,
        params,
    }));
};

export const updateProfile = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE_PROFILE,
        method: 'PUT',
        path: ENDPOINT_AUTH_PROFILE,
        params,
        requestParams: params,
    }));
};

export const setVisitor = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SET_VISITOR,
        method: 'POST',
        path: ENDPOINT_PUBLIC_VISITOR,
        params,
        requestParams: {
            uniqueId: params.uniqueId,
            notificationsEnabled: true,
            fcmToken: params.fcmToken || null,
        },
    }));
};

export const setSelectedLocation = dispatch => (params = {}) => {
    return dispatch({
        type: types.SET_SELECTED_LOCATION + '_SUCCESS',
        payload: {
            selectedLocation: params,
        },
    });
};

export const presignFile = dispatch => (params = {}) => {
    return dispatch(request({
        method: 'POST',
        reduxType: types.AVATAR_PRESIGN,
        path: params.presignPath,
        timeout: 360000,
        requestParams: {
            file: {
                type: params.file.type,
                name: params.file.name,
                size: params.file.size,
            },
        },
    }));
};

export const changeLanguage = dispatch => (params = {}) => {
    getProvider().set('language', params);

    return dispatch({
        type: types.LANGUAGE_CHANGE + '_SUCCESS',
        payload: {
            currentLanguage: params,
        },
    });
};

export const authPasswordChange = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.PASSWORD_CHANGE,
        method: 'POST',
        path: ENDPOINT_AUTH_CHANGE_PASSWORD,
        params,
        requestParams: {
            password: params.password,
            passwordConfirm: params.passwordConfirm,
        },
    }));
};

export const passwordTokenVerify = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.PASSWORD_TOKEN_VERIFY,
        method: 'GET',
        path: withVariables(ENDPOINT_PUBLIC_PASSWORD_TOKEN_VERIFY, { token: params.token }),
        params,
    }));
};

export const recommendationCreate = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.RECOMMENDATION_CREATE,
        method: 'POST',
        path: withVariables(ENDPOINT_AUTH_RECOMMENDATIONS),
        params,
        requestParams: params,
    }));
};