import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import {
    single as singleUser, 
} from 'Redux/modules/trainer/users/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            singleUser: dispatch(singleUser),
        },
    }),
)(
    loadable(() => import(
        /* webpackChunkName: "page-adept-EventsCreate" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);
