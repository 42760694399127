import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    font-size: 2em;
    border-bottom: 1px solid #CCCC;
    padding-bottom: .5em;
    margin-bottom: .5em;
    width: 100%;

    &.style-dark {
        border: none;
        color: ${variables.dpc_fontDark};
        font-weight: 600;
        font-size: 1.5em;
    }
`;
