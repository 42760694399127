import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADEPT_MEASUREMENTS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_MEASUREMENT, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADEPT_MEASUREMENTS, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADEPT_MEASUREMENT, params),
        params,
        requestParams: params,
    }));
};

export const config = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CONFIG,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_MEASUREMENTS_CONFIG, params),
        params,
        requestParams: params,
    }));
};

export const progress = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.PROGRESS,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_MEASUREMENTS_PROGRESS, params),
        params,
        requestParams: params,
    }));
};

export const chartData = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CHART_DATA,
        method: 'GET',
        path: endpoints.ENDPOINT_ADEPT_MEASUREMENT_CHART_DATA,
        params,
        requestParams: params,
    }));
};

export const newType = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.NEW_TYPE,
        method: 'POST',
        path: endpoints.ENDPOINT_ADEPT_MEASUREMENT_TYPE,
        params,
        requestParams: params,
    }));
};

export const removeType = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE_TYPE,
        method: 'DELETE',
        path: endpoints.ENDPOINT_ADEPT_MEASUREMENT_TYPE,
        params,
        requestParams: params,
    }));
};