import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

export default connect(
    state => ({}),
    dispatch => ({}),
)(
    loadable(() => import(
        /* webpackChunkName: "page-public-ReservationsLP" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);
