import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { COOKIE_LEAD_ID } from 'Consts/cookies';
import { LOCAL_STORAGE_LEAD_SURVEY } from 'Consts/localStorage';
import { PUBLIC_LP_REHABILITATION } from 'Consts/routes';

import { parseQueryToObject, parseToQueryString } from 'Utils/querystring';

import { getProvider } from 'Services/Cookies';
import { setItem } from 'Services/LocalStorage';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Survey from 'Components/public/PreferencesSurvey';

export default class PagePublicPreferencesSurveyRehab extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({ 
            refreshToken: PropTypes.func.isRequired,
        }).isRequired,

    };
    static defaultProps = {};
    state = {
        values: null,
        response: null,
        step: 'survey',
    }

    saveData = (values, response) => {
        const { history, location } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        this.setState({
            values,
            response,
        }, () => {
            getProvider().set(COOKIE_LEAD_ID, response.payload.leadId);
            delete response.payload.authToken;
            setItem(
                LOCAL_STORAGE_LEAD_SURVEY,
                {
                    ...values,
                    ...response.payload,
                }
            );

            history.push(parseToQueryString(PUBLIC_LP_REHABILITATION.path, { code: queryObject?.code }));          
        });
    }

    render() {
        const { location, history } = this.props;
        const { step } = this.state;

        return (
            <StyledComponent
                className={classnames({
                    'component-public-preferences-survey-rehab': true,
                })}
                styles={require('./styles')}
            >
                <Head
                    title="Ankieta preferencji treningowych"
                    description="Ankieta preferencji treningowych"
                />
                {step === 'survey' && (
                    <div className="step step-survey">
                        <LayoutContainer>
                            <div className="survey-header">
                                Wypełnienie ankiety zajmie Ci tylko 2 minuty
                            </div>
                            <Survey
                                onFinish={this.saveData}
                                location={location}
                                history={history}
                            />
                        </LayoutContainer>
                    </div>
                )}
            </StyledComponent>
        );
    }
}
