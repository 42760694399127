import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, ELEMENT_MARKUP } from 'Consts/redux';
import {
    API_RESOURCE_MEASUREMENTS_USER_CONFIG,
} from 'Consts/apiResources';

export const initialState = {
    mapById: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SINGLE + '_' + INIT_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    ...ELEMENT_MARKUP,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.SINGLE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: action.payload[API_RESOURCE_MEASUREMENTS_USER_CONFIG],
                    error: false,
                },
            };
        case types.SINGLE + '_' + FAIL_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: null,
                    error: true,
                },
            };
        case types.CREATE + '_' + INIT_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    ...ELEMENT_MARKUP,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.CREATE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: action.payload[API_RESOURCE_MEASUREMENTS_USER_CONFIG],
                    error: false,
                },
            };
        case types.CREATE + '_' + FAIL_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: null,
                    error: true,
                },
            };

        case types.REMOVE_TYPE + '_' + INIT_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    ...ELEMENT_MARKUP,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.REMOVE_TYPE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: action.payload[API_RESOURCE_MEASUREMENTS_USER_CONFIG],
                    error: false,
                },
            };
        case types.REMOVE_TYPE + '_' + FAIL_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: null,
                    error: true,
                },
            };

        default:
            return state;
    }
}
