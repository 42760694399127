import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StyledComponent from 'Components/core/StyledComponent';
import Head from 'Components/core/Head';
import ResponseStatus from 'Components/core/ResponseStatus';

export default class ErrorPage extends Component {
    render() {
        return (
            <ResponseStatus statusCode={500}>
                <StyledComponent styles={require('./styles')}>
                    <Head title="Error" />
                    <div className="container">
                        <div className="content">
                            <h2 className="title">
                                Przepraszamy, Wystąpił bład strony
                                <span className="icon-wrapper">
                                    <FontAwesomeIcon icon={['fa', 'exclamation']} />
                                </span>
                            </h2>
                            <p className="subtitle">Administrator witryny został poinformowany o tym błędzie</p>
                        </div>
                    </div>
                </StyledComponent>
            </ResponseStatus>
        );
    }
}
