import React from 'react';
import loadable from '@loadable/component';
import Spinner from 'Components/layout/Spinner';

export default loadable(() => import(
    /* webpackChunkName: "page-admin-Offers" */
    './component'
), {
    fallback: <Spinner />,
});
