import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .headline {
            color: white;
            text-align: center;
            margin-bottom: 1em;
        }
    `;
