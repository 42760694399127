import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: relative;
    width: 100%;

    [class$="control"] {
        height: 4em;
        border: none;
    }

    .link-wrapper {
        text-align: right;
        display: block;
        color: #666666;
        text-decoration: underline;
        margin-top: 1em;
        cursor: pointer;
    }

    &.style-dark {
        text-transform: capitalize;
        &.disabled-true {
            [class$="control"] {
                background-color: transparent;
                border-bottom: none;

                [class$="ndicatorsContainer"] {
                    display: none;
                }
            }
        }

        [class$="control"] {
            background-color: ${variables.dpc_backgroundWhite};
            border-bottom: 1px solid #676666;

            [class$="singleValue"] {
                color: #CCC;
            }

            input {
                color: #EEEEEE !important;
            }
        }

        [class$="menu"] {
            color: white;
            background-color: #333;

            [class$="option"] {
                background-color: #444;
                color: white;

                &:hover {
                    background-color: #666666;
                }
            }
        }
    }

    &.style-transparentV2 {
        text-transform: capitalize;
        &.disabled-true {
            [class$="control"] {
                background-color: transparent;
                border-bottom: none;

                [class$="ndicatorsContainer"], [class$="ndicatorContainer"], [class$="ndicatorsSeparator"], [class$="ndicatorSeparator"]  {
                    display: none;
                }
            }
        }

        [class$="control"] {
            background-color: ${variables.dpc_backgroundWhite};
            border-bottom: 1px solid #676666;

            [class$="singleValue"] {
                color: ${variables.dpc_fontDark};
            }

            input {
                color: #EEEEEE !important;
            }
        }

        [class$="menu"] {
            background-color: ${variables.dpc_backgroundWhite};

            [class$="option"] {
                background-color: ${variables.dpc_backgroundWhite};
                color: ${variables.dpc_fontDark};

                &:hover {
                    background-color: #868686;
                }
            }
        }
    }

    &.visible-false {
        display: none;
    }
`;
