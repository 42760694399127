import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG } from 'Consts/redux';

export const initialState = {};

export default function OrdersReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
