import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .conversation-create-form {
            width: 50em;
            max-width: 100%;

            .company-user-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .multi-select {
                    width: 49%;
                }
            }
            .adept-container {
                margin: 1em 0;
            }
        }
    `;
