import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withVariables } from 'Utils/string';

import {
    ADMIN_PROFILE,
    TRAINER_PROFILE,
    ADEPT_PROFILE,
} from 'Consts/routes';
import {
    USER_ROLE_ADEPT,
    USER_ROLE_TRAINER,
    USER_ROLE_ADMIN,
} from 'Consts/userRoles';

import StyledComponent from 'Components/core/StyledComponent';
import { TAB_DATA as ADEPT_TAB_DATA } from 'Components/pages/adept/Profile/component';
import { TAB_DATA as TRAINER_TAB_DATA } from 'Components/pages/trainer/Profile/component';

const Avatar = ({ user }) => {
    const getProfileUrl = () => {
        switch (user.role) {
            case USER_ROLE_ADMIN:
                return ADMIN_PROFILE.path;
            case USER_ROLE_TRAINER:
                return withVariables(TRAINER_PROFILE.path, {}, { tab: TRAINER_TAB_DATA });
            case USER_ROLE_ADEPT:
                return withVariables(ADEPT_PROFILE.path, {}, { tab: ADEPT_TAB_DATA });
        }
    };

    return (
        <StyledComponent styles={require('./styles')} className='avatar-component'>
            <Link to={getProfileUrl()}>
                <img src={user.avatar || require('Theme/images/placeholders/avatar.png')} />
            </Link>
        </StyledComponent>
    );
};

Avatar.propTypes = {
    user: PropTypes.shape({
        avatar: PropTypes.string,
        role: PropTypes.oneOf([USER_ROLE_ADEPT, USER_ROLE_TRAINER, USER_ROLE_ADMIN]),
    }).isRequired,
};

export default Avatar;