import moment from 'moment';

export default class TpSurvey {
    constructor(data) {
        this.id = data.id,
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.updatedAt = data.updatedAt && moment.utc(data.updatedAt).local();
        this.user = data.user;
        this.questions = data.questions;
    };
}