import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';
import {
    singleSegment,
} from 'Redux/modules/admin/userSegments/actions';

export default connect(
    state => ({
        segmentsDataMap: state.adminUserSegments.mapById,
    }),
    dispatch => ({
        actions: {
            singleSegment: dispatch(singleSegment),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        segment: stateProps.segmentsDataMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-UserSegmentsManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);
