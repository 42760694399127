import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Notification from 'Components/layout/NotificationV2';

export default class PanelNotifications extends React.Component {
    static propTypes = {
        notifications: PropTypes.object,
        unreadNotifications: PropTypes.object,
        onReadNotification: PropTypes.func.isRequired,
        onReadAllNotifications: PropTypes.func.isRequired,
    };

    state = {
        unreadNotificationsCount: 0,
        areNotificationsVisible: false,
        currentNotifications: this.props.notifications, // eslint-disable-line react/destructuring-assignment
        currentKey: 'all',
    }

    componentDidMount = () => {
        this.getUnreadNotificationsAmount();
        document.addEventListener('mousedown', this.handleClick, false);
    };

    componentDidUpdate = (prevProps) => {
        const { notifications, unreadNotifications, currentKey } = this.props;

        if (JSON.stringify(prevProps.notifications) !== JSON.stringify(notifications)) {
            this.setState({
                currentNotifications: notifications,
            });
        }

        if (JSON.stringify(prevProps.unreadNotifications) !== JSON.stringify(unreadNotifications)) {
            if(currentKey !== 'all') {
                this.setState({
                    currentNotifications: unreadNotifications
                });
            }

            this.getUnreadNotificationsAmount();
        }
    };

    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }

        this.setState({
            areNotificationsVisible: false,
        });
    }

    getUnreadNotificationsAmount = () => {
        const { unreadNotifications } = this.props;

        const unreadNotificationsCount = unreadNotifications && unreadNotifications.meta && unreadNotifications.meta.total;

        this.setState({
            unreadNotificationsCount, 
        });
    };

    setNotificationAsRead = (notification) => {
        const { onReadNotification } = this.props; 
        onReadNotification({ id: notification.id })
            .then(() => {
                this.setState(prevState => ({
                    unreadNotifications: prevState.unreadNotifications--,
                }));
            });
    }

    setAllNotificationsAsRead = () => {
        const { onReadAllNotifications } = this.props;
        onReadAllNotifications()

        this.setState({
            currentKey: 'unread',
        });
    }

    render() {
        const { notifications, unreadNotifications } = this.props;
        const { unreadNotificationsCount, areNotificationsVisible, currentNotifications, currentKey } = this.state;

        return (
            <StyledComponent styles={require('./styles')} className="notifications-component">
                <div 
                    className="notifications-wrapper"
                    ref={node => this.node = node} 
                >
                    <div
                        className="notifications-control-enable"
                        onClick={() => this.setState(prevState => ({ areNotificationsVisible: !prevState.areNotificationsVisible }))}
                    >
                        <div className="notifications-bell-icon">
                            <FontAwesomeIcon icon={['fa', 'bell']} />
                        </div>
                        {unreadNotificationsCount > 0 && (
                            <div className="unread-notifications-count">
                                <a>{unreadNotificationsCount}</a>
                            </div>
                        )}
                    </div>
                    {areNotificationsVisible && (
                        <div className="notifications-list-wrapper">
                            <div className="notifications-head">
                                <div className="notifications-title">
                                    Powiadomienia
                                </div>
                                <div className="notifications-controls">
                                    <span
                                        className={classnames(
                                            'control',
                                            `${currentKey == 'all' ? 'active' : null}`
                                        )}
                                        onClick={() => {
                                            this.setState({
                                                currentNotifications: notifications,
                                                currentKey: 'all',
                                            });
                                        }}
                                    >
                                        Wszystkie
                                    </span> 
                                    {' | '} 
                                    <span 
                                        className={classnames(
                                            'control', 
                                            `${currentKey == 'unread' ? 'active' : null}`
                                        )}    
                                        onClick={() => {
                                            this.setState({
                                                currentNotifications: unreadNotifications,
                                                currentKey: 'unread',
                                            });
                                        }}
                                    >
                                        Nieprzeczytane
                                    </span>
                                </div>
                            </div>
                            {currentNotifications && currentNotifications.elements && currentNotifications.elements.length > 0 && (
                                <>
                                    <span
                                        className={classnames(
                                            'set-all-as-read', 
                                        )}
                                        onClick={() => this.setAllNotificationsAsRead()}
                                    >
                                        Oznacz wszystkie jako przeczytane
                                    </span>
                                    {currentNotifications.elements.map(notification => 
                                        (
                                            <div
                                                key={notification.id}
                                            >
                                                <Notification 
                                                    onClick={() => this.setNotificationAsRead(notification)}
                                                    notification={notification}
                                                />
                                            </div>
                                        )
                                    )}
                                </>
                            )}
                            {(!currentNotifications || !currentNotifications.elements || (currentNotifications.elements && !currentNotifications.elements.length)) && (
                                <div className="no-notification">
                                    <FontAwesomeIcon icon={['fa', 'bell-slash']} />
                                    <a className="title">Brak nowych powiadomień</a>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </StyledComponent>
        );
    }
}
