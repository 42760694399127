import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFERS, params),
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            status: params.status || undefined,
            statuses: params.statuses || undefined,
            productCategorySlug: params.productCategorySlug || undefined,
            orderBy: params.orderBy || 'createdAt',
            periodType: params.periodType || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFER, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFERS, params),
        params,
        requestParams: params,
    }));
};

export const cancel = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CANCEL,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFER, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const remove = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFER_REMOVE, params),
        params,
    }));
};

export const payment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.PAYMENT,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFER_PAYMENT, params),
        params,
        requestParams: params,
    }));
};

export const singlePaymentUri = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE_PAYMENT_URI,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFER_PAYMENT_SINGLE, params),
        params,
        requestParams: params,
    }));
}; 

export const activate = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ACTIVATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFER_ACTIVATE, params),
        params,
    }));
};

export const invite = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.INVITE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFER_INVITE, params),
        params,
        requestParams: params,
    }));
};

export const removeUser = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE_USER,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFER_REMOVE_USER, params),
        params,
        requestParams: params,
    }));
};

export const getSubUsers = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.GET_SUB_USERS,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_USER_OFFER_GET_SUB_USERS, params),
        params,
    }));
};
