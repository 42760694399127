import {
    USER_PERMISSION_MODE_ALLOW,
    USER_PERMISSION_RESOURCE_ALL,
} from 'Consts/userPermissions';

export function hasPermission(profile, group, resource = null) {
    return profile.permissions.find(permission => {
        const hasGroupPermission = permission.group === group && permission.mode === USER_PERMISSION_MODE_ALLOW;

        if (hasGroupPermission) {
            if (resource) {
                return permission.resource === resource;
            } else {
                return permission.resource === USER_PERMISSION_RESOURCE_ALL;
            }
        }

        return false;
    });
}
