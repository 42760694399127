export const USER_ACTIVE_STATE_ACTIVE = 'active';
export const USER_ACTIVE_STATE_CLOSED = 'closed';
export const USER_ACTIVE_STATE_TERMINATED = 'terminated';
export const USER_ACTIVE_STATES = [{
    key: USER_ACTIVE_STATE_ACTIVE,
    label: 'Aktywny',
    stateColor: 'success',
}, {
    key: USER_ACTIVE_STATE_CLOSED,
    label: 'Wypowiedzenie',
    stateColor: 'warning',
}, {
    key: USER_ACTIVE_STATE_TERMINATED,
    label: 'Nieaktywny',
    stateColor: 'alert',
}];

export const USER_OFFER_TYPE_DEFAULT = 'default';
export const USER_OFFER_TYPE_DIAGNOSTIC  = 'diagnostic';
export const USER_OFFER_TYPES = [{
    key: USER_OFFER_TYPE_DEFAULT,
    label: 'Abonamentowy',
    stateColor: 'success',
}, {
    key: USER_OFFER_TYPE_DIAGNOSTIC,
    label: 'Diagnostyczny',
    stateColor: 'warning',
}];
