import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ENDPOINT_AUTH_PRESIGN_AVATAR } from 'Consts/api';
import { USER_ROLE_ADEPT, USER_ROLE_TRAINER } from 'Consts/userRoles';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class ProfileForm extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            presignFile: PropTypes.func.isRequired,
            updateProfile: PropTypes.func.isRequired,
        }),
        profile: PropTypes.shape({
            email: PropTypes.string.isRequired,
            name: PropTypes.string,
            surname: PropTypes.string,
            phone: PropTypes.string,
            address: PropTypes.string,
            postalCode: PropTypes.string,
            city: PropTypes.string,
            avatar: PropTypes.string,
        }),
        inputStyleProps: PropTypes.object,
        submitButtonStyle: PropTypes.oneOf([
            'faPrimary', 'hollow', 'gradient',
        ]),
        showBio: PropTypes.bool,
    };
    static defaultProps = {
        submitButtonStyle: 'faPrimary',
        showBio: false,
    };

    state = {
        formState: {
            //eslint-disable-next-line
            ...this.props.profile,
            //eslint-disable-next-line
            avatar: this.props.profile.avatar && {
                //eslint-disable-next-line
                imageUrl: this.props.profile.avatar,
            },
            height: this.props.profile.userData?.height,
            weight: this.props.profile.userData?.weight,
            birthdate: this.props.profile.userData?.birthdate && moment(this.props.profile.userData?.birthdate).toDate(),
        },
    }

    onSubmit = data => {
        const { actions } = this.props;

        return actions.updateProfile(data);
    }
    
    render() {
        const { history, location, actions, inputStyleProps, submitButtonStyle, profile, showBio } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent styles={require('./styles')} className='profile-form'>
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(profile && profile.role === USER_ROLE_ADEPT),
                        label: 'Kod dostępu Fitadept TV',
                        value: profile.accessCode || 'Brak',
                    }]}
                    controls={[]}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        submitButtonStyle,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 's3FileUpload',
                            name: 'avatar',
                            label: 'Avatar',
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_AUTH_PRESIGN_AVATAR,
                                },
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'input',
                            name: 'email',
                            label: 'E-mail',
                            inputProps: {
                                ...inputStyleProps,
                            },
                            required: true,
                        }, {
                            type: 'input',
                            name: 'name',
                            label: 'Imię',
                            inputProps: {
                                ...inputStyleProps,
                            },
                            required: true,
                        }, {
                            type: 'input',
                            name: 'surname',
                            label: 'Nazwisko',
                            inputProps: {
                                ...inputStyleProps,
                            },
                            required: true,
                        }, {
                            type: 'datePicker',
                            name: 'birthdate',
                            label: 'Data urodzin',
                            inputProps: {
                                ...inputStyleProps,
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                    showMonthDropdown: true,
                                    showYearDropdown: true,
                                },
                            },
                            required: true,
                        }, {
                            type: 'input',
                            name: 'height',
                            label: 'Wzrost',
                            inputProps: {
                                ...inputStyleProps,
                            },
                            required: true,
                        }, {
                            type: 'input',
                            name: 'weight',
                            label: 'Waga',
                            inputProps: {
                                ...inputStyleProps,
                            },
                            required: true,
                        }, {
                            type: 'input',
                            name: 'phone',
                            label: 'Numer telefonu',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'input',
                            name: 'address',
                            label: 'Adres',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'input',
                            name: 'postalCode',
                            label: 'Kod pocztowy',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'input',
                            name: 'city',
                            label: 'Miasto',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            isVisible: showBio,
                            type: 'textarea',
                            name: 'bioHeader',
                            label: 'Nagłówek biografii',
                        }, {
                            visible: showBio,
                            type: 'textarea',
                            name: 'bio',
                            label: 'O tobie',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            isVisible: Boolean(profile.role === USER_ROLE_TRAINER),
                            type: 'switch',
                            name: 'visibleOnPage',
                            label: 'Widoczność na stronie',
                        }],
                    }]}
                    {...inputStyleProps}
                />
            </StyledComponent>
        );
    }
}