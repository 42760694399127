import React from 'react';
import { Route } from 'react-router-dom';

import {
    ADEPT_DASHBOARD,
    ADEPT_DATA,
    ADEPT_MEDICAL_CARD_CREATE,
    ADEPT_USER_OFFERS,
    ADEPT_USER_OFFER,
    ADEPT_FINANCES,
    ADEPT_PROFILE,
    ADEPT_USER_PAYMENT_METHOD,
    ADEPT_MEASUREMENTS_MANAGE,
    ADEPT_MEASUREMENTS_CREATE,
    ADEPT_TRAINING_TARGETS_CREATE,
    ADEPT_TRAINING_TARGETS_MANAGE,
    ADEPT_ADEPT_TESTS_MANAGE,
    ADEPT_EVENTS,
    ADEPT_EVENTS_CREATE,
    ADEPT_EVENTS_MANAGE,
    ADEPT_CONTACTS,
    ADEPT_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE,
    ADEPT_MEASUREMENTS_CONFIG_MANAGE,
    ADEPT_USER_SURVEY_MANAGE,
    ADEPT_USER_HOMEWORK_MANAGE,
    ADEPT_CHAT,
    // ADEPT_EVENT_CYCLE,
    ADEPT_EVENT_MEET,
} from 'Consts/routes';

export default [
    <Route
        key="ADEPT_DATA_FORM"
        exact={ADEPT_DASHBOARD.exact}
        path={ADEPT_DASHBOARD.path}
        component={require('Components/pages/adept/Dashboard').default}
    />,
    <Route
        key="ADEPT_DATA"
        exact={ADEPT_DATA.exact}
        path={ADEPT_DATA.path}
        component={require('Components/pages/adept/AdeptData').default}
    />,
    <Route
        key="ADEPT_MEDICAL_CARD_CREATE"
        exact={ADEPT_MEDICAL_CARD_CREATE.exact}
        path={ADEPT_MEDICAL_CARD_CREATE.path}
        component={require('Components/pages/adept/AdeptDataMedicalCardCreate').default}
    />,
    <Route
        key="ADEPT_USER_OFFERS"
        exact={ADEPT_USER_OFFERS.exact}
        path={ADEPT_USER_OFFERS.path}
        component={require('Components/pages/adept/UserOffers').default}
    />,
    <Route
        key="ADEPT_USER_OFFER"
        exact={ADEPT_USER_OFFER.exact}
        path={ADEPT_USER_OFFER.path}
        component={require('Components/pages/adept/UserOffer').default}
    />,
    <Route
        key="ADEPT_FINANCES"
        exact={ADEPT_FINANCES.exact}
        path={ADEPT_FINANCES.path}
        component={require('Components/pages/adept/Finances').default}
    />,
    <Route
        key="ADEPT_PROFILE"
        exact={ADEPT_PROFILE.exact}
        path={ADEPT_PROFILE.path}
        component={require('Components/pages/adept/Profile').default}
    />,
    <Route
        key="ADEPT_USER_PAYMENT_METHOD"
        exact={ADEPT_USER_PAYMENT_METHOD.exact}
        path={ADEPT_USER_PAYMENT_METHOD.path}
        component={require('Components/pages/adept/UserPaymentMethod').default}
    />,
    <Route 
        key="ADEPT_MEASUREMENTS_CREATE"
        exact={ADEPT_MEASUREMENTS_CREATE.exact}
        path={ADEPT_MEASUREMENTS_CREATE.path}
        component={require('Components/pages/adept/MeasurementsCreate').default}
    />,

    <Route
        key="ADEPT_MEASUREMENTS_CONFIG_MANAGE"
        exact={ADEPT_MEASUREMENTS_CONFIG_MANAGE.exact}
        path={ADEPT_MEASUREMENTS_CONFIG_MANAGE.path}
        component={require('Components/pages/adept/MeasurementsConfigManage').default}
    />,

    <Route 
        key="ADEPT_MEASUREMENTS_MANAGE"
        exact={ADEPT_MEASUREMENTS_MANAGE.exact}
        path={ADEPT_MEASUREMENTS_MANAGE.path}
        component={require('Components/pages/adept/MeasurementsManage').default}
    />,
    <Route 
        key="ADEPT_TRAINING_TARGETS_CREATE"
        exact={ADEPT_TRAINING_TARGETS_CREATE.exact}
        path={ADEPT_TRAINING_TARGETS_CREATE.path}
        component={require('Components/pages/adept/TrainingTargetsCreate').default}
    />,
    <Route 
        key="ADEPT_TRAINING_TARGETS_MANAGE"
        exact={ADEPT_TRAINING_TARGETS_MANAGE.exact}
        path={ADEPT_TRAINING_TARGETS_MANAGE.path}
        component={require('Components/pages/adept/TrainingTargetsManage').default}
    />,
    <Route 
        key="ADEPT_ADEPT_TESTS_MANAGE"
        exact={ADEPT_ADEPT_TESTS_MANAGE.exact}
        path={ADEPT_ADEPT_TESTS_MANAGE.path}
        component={require('Components/pages/adept/AdeptTestsManage').default}
    />,
    <Route
        key="ADEPT_EVENTS"
        exact={ADEPT_EVENTS.exact}
        path={ADEPT_EVENTS.path}
        component={require('Components/pages/adept/Events').default}
    />,
    <Route
        key="ADEPT_EVENTS_CREATE"
        exact={ADEPT_EVENTS_CREATE.exact}
        path={ADEPT_EVENTS_CREATE.path}
        component={require('Components/pages/adept/EventsCreate').default}
    />,
    <Route
        key="ADEPT_EVENTS_MANAGE"
        exact={ADEPT_EVENTS_MANAGE.exact}
        path={ADEPT_EVENTS_MANAGE.path}
        component={require('Components/pages/adept/EventsManage').default}
    />,
    <Route
        key="ADEPT_EVENT_MEET"
        exact={ADEPT_EVENT_MEET.exact}
        path={ADEPT_EVENT_MEET.path}
        component={require('Components/pages/adept/EventMeet').default}
    />,
    <Route
        key="ADEPT_CONTACTS"
        exact={ADEPT_CONTACTS.exact}
        path={ADEPT_CONTACTS.path}
        component={require('Components/pages/adept/Contacts').default}
    />,
    <Route
        key="ADEPT_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE"
        exact={ADEPT_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE.exact}
        path={ADEPT_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE.path}
        component={require('Components/pages/adept/UserOfferDiagnosticReport').default}
    />,
    <Route
        key="ADEPT_USER_SURVEY_MANAGE"
        exact={ADEPT_USER_SURVEY_MANAGE.exact}
        path={ADEPT_USER_SURVEY_MANAGE.path}
        component={require('Components/pages/adept/UserSurveyManage').default}
    />,
    <Route
        key="ADEPT_USER_HOMEWORK_MANAGE"
        exact={ADEPT_USER_HOMEWORK_MANAGE.exact}
        path={ADEPT_USER_HOMEWORK_MANAGE.path}
        component={require('Components/pages/adept/UserHomeworkManage').default}
    />,
    // <Route
    //     key="ADEPT_EVENT_CYCLE"
    //     exact={ADEPT_EVENT_CYCLE.exact}
    //     path={ADEPT_EVENT_CYCLE.path}
    //     component={require('Components/pages/adept/EventCycle').default}
    // />,
    <Route
        key="ADEPT_CHAT"
        exact={ADEPT_CHAT.exact}
        path={ADEPT_CHAT.path}
        component={require('Components/pages/adept/Chat').default}
    />,
];
