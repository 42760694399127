import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, LIST_MARKUP, ELEMENT_MARKUP } from 'Consts/redux';

import {
    API_RESOURCE_USER_SEGMENTS,
    API_RESOURCE_USER_SEGMENT,
} from 'Consts/apiResources';

export const initialState = {
    list: { ...LIST_MARKUP },
    mapById: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LIST_SEGMENTS + '_' + INIT_FLAG:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: true,
                },
            };

        case types.LIST_SEGMENTS + '_' + SUCCESS_FLAG:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_USER_SEGMENTS].elements,
                    meta: action.payload[API_RESOURCE_USER_SEGMENTS].meta,
                },
            };

        case types.LIST_SEGMENTS + '_' + FAIL_FLAG:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    elements: [],
                    meta: null,
                },
            };

        case types.SINGLE_SEGMENT + '_' + INIT_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        isLoading: true,
                    },
                },
            };

        case types.SINGLE_SEGMENT + '_' + SUCCESS_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        isLoading: false,
                        isLoaded: true,
                        data: action.payload[API_RESOURCE_USER_SEGMENT],
                    },
                },
            };

        case types.SINGLE_SEGMENT + '_' + FAIL_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        isLoading: false,
                        isLoaded: true,
                        data: null,
                        error: true,
                    },
                },
            };

        case types.UPDATE_SEGMENT + '_' + SUCCESS_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        isLoading: false,
                        isLoaded: true,
                        data: action.payload[API_RESOURCE_USER_SEGMENT],
                    },
                },
            };

        default:
            return state;
    }
}