import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: relative;
    display: flex;
    flex-direction: column;

    .layout-container {
        width: 40% !important;
        margin-top: 5em;
        margin-bottom: 2em;

        .update-invited-header {
            font-weight: 600;
            font-size: 3em;
            width: 100%;
            text-align: center;
            padding: .5em 0;
        }

        .checkbox {
            .trigger {
                background-color: transparent;
                border: 2px solid #CCC;
                width: 1.5em;
                height: 1.5em;
            }
        }
    }

    .submit-button {
        margin: .5em 0;
    }
  
    form {
        .form-error-message-component, .form { 
            color: #ff1414;
        }
    }

    .input-external-wrapper {
        .form-error-message-component {
            color: #ff1414;
        }
    }
                
    .login-link {
        color: #ff0000;
        font-size: 1em;
        font-weight: 600;
        margin: 1em 0;
        margin-bottom: 2em;
        display: block;
        text-decoration: underline;
        cursor: pointer;
    }

    .disclaimer {
        display: inline-block;
        width: 100%;
        height: 3em;
        color: black;
        font-size: .7em;
        line-height: 1.5em;
        white-space: pre-wrap;
        overflow: hidden;

        &.expandDisclaimer {
            height: auto;
        }
    }

    .show-more-text {
        display: inline-block;
        color: black;
        cursor: pointer;
        border-bottom: 1px solid #b7b7b7;
        margin-top: 1em;
        font-size: .75em;
    }

    @media (max-width: ${variables.tabletL}) {
        .form-element {
            width: 100% !important;
        }

        .email-wrapper {
            width: 100% !important;
        }
    }

    @media (max-width: ${variables.tabletS}) {
        .layout-container {
            width: 60% !important;
            margin-top: 5em;
        }
    }    
`;
