import React from 'react';
import loadable from '@loadable/component';
import { connect } from 'react-redux';

import Spinner from 'Components/layout/Spinner';

import {
    single
} from 'Redux/modules/admin/trainingPlanSurveys/actions';

export default connect(
    state => ({
        tpSurveyMap: state.adminTpSurveys.mapById,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        tpSurvey: stateProps.tpSurveyMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-TrainingPlanSurveyManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);