import React from 'react';
import { NavLink } from 'react-router-dom';

import { PUBLIC_HOME } from 'Consts/routes';
import { FITADEPT_REGULATIONS } from 'Consts/externalUrls';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';

const Footer = () => (
    <StyledComponent className='footer-public' styles={require('./styles')}>
        <LayoutContainer>
            <div className="logo">
                <NavLink exact to={PUBLIC_HOME.path}>
                    <img src={require('Theme/images/logo-color.png')} />
                </NavLink>
            </div>
            <nav className="nav">
                <div className="nav-item">
                    <a href={FITADEPT_REGULATIONS} target="_blank" rel="noopener noreferrer">Regulamin</a>
                </div>
            </nav>
        </LayoutContainer>
    </StyledComponent>
);

export default Footer;
