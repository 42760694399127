import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default () =>
    css`
        position: relative;
        width: 100%;

        /* Styles*/
        &.style-version-1 {
            &.style-default {
                border: 1px solid #CCCCCC;
                background-color: white;
                border-radius: .3em;
            }
            &.style-transparent {
                border: .05em solid ${variables.primaryColor};
                background-color: transparent;
            }
            
            &.style-transparentLight {
                border: .05em solid ${variables.lightColor};
                background-color: transparent;
            }

            &.style-transparentV2 {
                background-color: transparent;
            }

            &.error {
                border: 1px solid #e23642;
                box-shadow: 0 0 0 .1em #e23642;
            }
        }

        &.style-version-2 {
            border: 1px solid #686868;
            background-color: transparent;
            border-radius: .3em;

            input {
                background: none;

                &[disabled=""] {
                    border-bottom: none;
                    -webkit-appearance: none;
                    -moz-appearance: textfield;
                }
            }
        }

        &.border-default {
            border: none;
        }

        &.border-light {
            border: 1px solid #686868;
        }
        
        &.border-light-2 {
            border: 1px solid #9e9e9e;
        }
        
        &.border-light-3 {
            border: 1px solid #CCCCCC;
            border-radius: .3em;
        }
    `;
