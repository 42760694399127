import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';
import {
    single,
} from 'Redux/modules/adept/userSurveys/actions';

export default connect(
    state => ({
        userSurveyMap: state.adeptUserSurveys.mapById,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        userSurvey: stateProps.userSurveyMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-adept-UserSurveyManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);