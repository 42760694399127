import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const singleBySlug = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE_BY_SLUG,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_PUBLIC_OFFER_BY_SLUG, params),
        params,
    }));
};

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_PUBLIC_OFFERS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            productSpecializationSlug: params.productSpecializationSlug || undefined,
            orderBy: params.orderBy || 'createdAt',
            enabled: typeof params.enabled !== 'undefined'
                ? params.enabled
                : undefined,
        },
    }));
};
