import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, ELEMENT_MARKUP, LIST_MARKUP } from 'Consts/redux';

import {
    API_RESOURCE_MEDICAL_CARD, API_RESOURCE_MEDICAL_CARDS,
} from 'Consts/apiResources';

export const initialState = {
    current: { ...ELEMENT_MARKUP },
    list: { ...LIST_MARKUP },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.CURRENT + '_' + INIT_FLAG:
            return {
                ...state,
                current: {
                    ...ELEMENT_MARKUP,
                    isLoading: true,
                },
            };
        case types.CURRENT + '_' + SUCCESS_FLAG:
            return {
                ...state,
                current: {
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: action.payload[API_RESOURCE_MEDICAL_CARD],
                },
            };
        case types.CURRENT + '_' + FAIL_FLAG:
            return {
                ...state,
                current: {
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: null,
                    error: true,
                },
            };
        case types.LIST + '_' + INIT_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    isLoading: true,
                },
            };
        case types.LIST + '_' + SUCCESS_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_MEDICAL_CARDS],
                },
            };
        case types.LIST + '_' + FAIL_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    elements: [],
                    error: true,
                },
            };

        default:
            return state;
    }
}
