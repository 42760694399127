export const EXPORT_MODEL_USER = 'Users';
export const EXPORT_MODEL_LEAD_USER = 'LeadUsers';
export const EXPORT_MODEL_PAYMENT_TRANSACTION = 'PaymentTransactions';
export const EXPORT_MODEL_EVENT = 'Events';
export const EXPORT_MODEL_INVOICE = 'Invoices';
export const EXPORT_MODEL_LOCATION_USER = 'LocationsUsers';
export const EXPORT_MODEL_LOCATION = 'Locations';
export const EXPORT_MODEL_OFFER = 'Offers';
export const EXPORT_MODEL_OFFER_PRODUCT = 'OfferProducts';
export const EXPORT_MODEL_PAYMENT_VENDOR = 'PaymentVendors';
export const EXPORT_MODEL_PRODUCT = 'Products';
export const EXPORT_MODEL_PRODUCT_CATEGORY = 'ProductCategories';
export const EXPORT_MODEL_PRODUCT_SPECIALIZATION = 'ProductsSpecializations';
export const EXPORT_MODEL_PROMO_CODE = 'PromoCodes';
export const EXPORT_MODEL_SPECIALIZATION = 'Specializations';
export const EXPORT_MODEL_USER_SURVEY = 'UsersSurveys';
export const EXPORT_MODEL_USER_CONSENT = 'UserConsents';
export const EXPORT_MODEL_USER_OFFER = 'UserOffers';
export const EXPORT_MODEL_USER_OFFER_INVOICE_ITEM = 'UsersOffersInvoiceItems';
export const EXPORT_MODEL_USER_OFFER_PRODUCT = 'UserOffersProducts';
export const EXPORT_MODEL_USER_PAYMENT_METHOD = 'UserPaymentMethods';
export const EXPORT_MODEL_USER_SPECIALIZATION = 'UserSpecializations';
export const EXPORT_MODEL_USER_SOURCE = 'UsersSources';
export const EXPORT_MODEL_MEDICAL_CARD = 'MedicalCards';
export const EXPORT_MODEL_MEASUREMENT = 'Measurements';
export const EXPORT_MODEL_USER_OFFER_DIAGNOSTIC_REPORT = 'UserOfferDiagnosticReports';
export const EXPORT_MODEL_EVENT_CANCEL_TYPE = 'EventCancelTypes';
export const EXPORT_MODEL_USER_NOTE = 'UserNotes';
export const EXPORT_MODEL_USER_NOTE_FILE = 'UserNotesFiles';
export const EXPORT_MODEL_USER_DATA = 'UserData';
export const EXPORT_MODEL_USER_OFFER_USER = 'UserOfferUsers';
export const EXPORT_MODEL_USER_EFFECT = 'UserEffects';

export const EXPORT_MODELS = [
    {
        key: EXPORT_MODEL_USER_SURVEY,
        label: 'Ankiety',
    },
    {
        key: EXPORT_MODEL_USER_DATA,
        label: 'Dane użytkowników (data urodzin, wzrost, waga)',
    },
    {
        key: EXPORT_MODEL_PAYMENT_VENDOR,
        label: 'Dostawcy płatności',
    },
    {
        key: EXPORT_MODEL_USER_EFFECT,
        label: 'Efekty użytkowników',
    },
    {
        key: EXPORT_MODEL_INVOICE,
        label: 'Faktury',
    },
    {
        key: EXPORT_MODEL_PRODUCT_CATEGORY,
        label: 'Kategorie produktów',
    },
    {
        key: EXPORT_MODEL_PROMO_CODE,
        label: 'Kody promocyjne',
    },
    {
        key: EXPORT_MODEL_LOCATION,
        label: 'Lokacje',
    },
    {
        key: EXPORT_MODEL_USER_PAYMENT_METHOD,
        label: 'Metody płatności użytkowników',
    },
    {
        key: EXPORT_MODEL_USER_NOTE,
        label: 'Notatki do użytkowników',
    },
    {
        key: EXPORT_MODEL_OFFER,
        label: 'Oferty',
    },
    {
        key: EXPORT_MODEL_USER_NOTE_FILE,
        label: 'Pliki do notatek do użytkowników',
    },
    {
        key: EXPORT_MODEL_MEASUREMENT,
        label: 'Pomiary użytkowników',
    },
    {
        key: EXPORT_MODEL_EVENT_CANCEL_TYPE,
        label: 'Powody odwołania wydarzeń',
    },
    {
        key: EXPORT_MODEL_USER_OFFER_INVOICE_ITEM,
        label: 'Pozycje na fakturach',
    },
    {
        key: EXPORT_MODEL_PRODUCT,
        label: 'Produkty',
    },
    {
        key: EXPORT_MODEL_USER_OFFER_PRODUCT,
        label: 'Produkty w zamówieniach',
    },
    {
        key: EXPORT_MODEL_LEAD_USER,
        label: 'Przypisanie adeptów do trenerów',
    },
    {
        key: EXPORT_MODEL_OFFER_PRODUCT,
        label: 'Przypisanie produktów do ofert',
    },
    {
        key: EXPORT_MODEL_LOCATION_USER,
        label: 'Przypisanie trenerów do lokacji',
    },
    {
        key: EXPORT_MODEL_PAYMENT_TRANSACTION,
        label: 'Płatności',
    },
    {
        key: EXPORT_MODEL_USER_OFFER_DIAGNOSTIC_REPORT,
        label: 'Raporty diagnostyczne',
    },
    {
        key: EXPORT_MODEL_SPECIALIZATION,
        label: 'Specjalizacje',
    },
    {
        key: EXPORT_MODEL_PRODUCT_SPECIALIZATION,
        label: 'Specjalizacje produków',
    },
    {
        key: EXPORT_MODEL_USER_SPECIALIZATION,
        label: 'Specjalizacje trenerów',
    },
    {
        key: EXPORT_MODEL_USER,
        label: 'Użytkownicy',
    },
    {
        key: EXPORT_MODEL_USER_OFFER_USER,
        label: 'Użytkownicy zaproszeni do zamówień',
    },
    {
        key: EXPORT_MODEL_EVENT,
        label: 'Wydarzenia',
    },
    {
        key: EXPORT_MODEL_MEDICAL_CARD,
        label: 'Wywiady medyczne',
    },
    {
        key: EXPORT_MODEL_USER_OFFER,
        label: 'Zamówienia',
    },
    {
        key: EXPORT_MODEL_USER_CONSENT,
        label: 'Zgody użytkowników',
    },
    {
        key: EXPORT_MODEL_USER_SOURCE,
        label: 'Źródła użytkowników',
    },
];