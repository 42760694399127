import moment from 'moment';
import { TIME_API_FORMAT } from 'Consts/date';

export default class UserWorkHours {
    constructor(data) {
        this.id = data.id;
        this.user = data.user;
        this.mondayStart = data.mondayStart && moment.utc(data.mondayStart, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.mondayEnd = data.mondayEnd && moment.utc(data.mondayEnd, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.tuesdayStart = data.tuesdayStart && moment.utc(data.tuesdayStart, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.tuesdayEnd = data.tuesdayEnd && moment.utc(data.tuesdayEnd, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.wednesdayStart = data.wednesdayStart && moment.utc(data.wednesdayStart, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.wednesdayEnd = data.wednesdayEnd && moment.utc(data.wednesdayEnd, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.thursdayStart = data.thursdayStart && moment.utc(data.thursdayStart, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.thursdayEnd = data.thursdayEnd && moment.utc(data.thursdayEnd, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.fridayStart = data.fridayStart && moment.utc(data.fridayStart, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.fridayEnd = data.fridayEnd && moment.utc(data.fridayEnd, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.saturdayStart = data.saturdayStart && moment.utc(data.saturdayStart, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.saturdayEnd = data.saturdayEnd && moment.utc(data.saturdayEnd, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.sundayStart = data.sundayStart && moment.utc(data.sundayStart, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
        this.sundayEnd = data.sundayEnd && moment.utc(data.sundayEnd, TIME_API_FORMAT).local().format(TIME_API_FORMAT);
    };
}