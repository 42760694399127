import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';
    
export default (props, state) =>
    css`
        position: relative;
        margin-bottom: 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .input-internal-wrapper {
            position: relative;

            .mobile-datepicker-wrapper {
                position: absolute;
                display: block;
                z-index: -5;
                width: 100%;
                height: 100%;
                top: 0;

                &.visible {
                    z-index: 0;
                }
            }
        }

        &.style-version-1 {
            .input-external-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .input-label {
                    font-size: 1.2em;
                }
                .input-internal-wrapper {
                    width: auto;

                    .react-datepicker-wrapper {
                        .react-datepicker__input-container {
                            input {
                                font-weight: 300;
                                width: 100%;
                                outline: none;
                                border: none;
                                padding: 0 1.5em;
                                height: 4em;
                            }
                        }
                    }

                    .react-datepicker {
                        display: flex;
                    }
                }
            }
        }

        &.style-version-2 {
            margin-bottom: 0;

            .input-external-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: 0;

                .input-label {
                    color: ${variables.dpc_fontDark};
                    font-size: .9em;
                    text-transform: uppercase;
                }

                .input-internal-wrapper {
                    width: auto;
                    background: none !important;
                    
                    .react-datepicker-wrapper {
                        .react-datepicker__input-container {
                            input {
                                background: none;
                                font-weight: 300;
                                font-size: .9em;
                                width: 100%;
                                outline: none;
                                border: none;
                                padding: 1em;
                                height: auto;
                                color: ${variables.dpc_fontDark};

                                &[disabled=""] {
                                    font-weight: 300;
                                    color: ${variables.dpc_fontDark};
                                    padding: 0em;
                                }
                            }
                        }
                    }

                    .react-datepicker {
                        display: flex;
                    }
                }
            }

            .form-error-message-component {
                text-align: right;
            }
        }

        @media (max-width: ${variables.mobileL}) {
            .react-datepicker-popper {
                left: 50% !important;
                transform: translateX(-50%) !important;

                .react-datepicker {
                    .react-datepicker__navigation {
                        width: 20px;
                    }
                }
                .react-datepicker__month-container {
                    width: 75vw;

                    .react-datepicker__day-names {
                        .react-datepicker__day-name {
                            width: 12%;
                            font-size: 1.15em;
                        }
                    }

                    .react-datepicker__week {
                        .react-datepicker__day {
                            width: 12%;
                            line-height: 3em;
                            font-size: 1.15em;
                        }
                    }
                }

                .react-datepicker__time-container {
                    .react-datepicker__time {
                        .react-datepicker__time-box {
                            .react-datepicker__time-list {
                                .react-datepicker__time-list-item {
                                    font-size: 1.25em;
                                    height: 35px;
                                } 
                            }
                        }
                    }
                }
            }
        }
    `;
