import React, { Component } from 'react';

import StyledComponent from 'Components/core/StyledComponent';
import Head from 'Components/core/Head';

export default class DeeplinksPage extends Component {
    render() {
        return (
            <StyledComponent styles={require('./styles')}>
                <Head title="Deeplinks" />
                <div className="container">
                    <a href="fitadept://">App</a>
                    <a href="fitadept://profile">Profil</a>
                    <a href="fitadept://trainings">Treningi</a>
                    <a href="fitadept://measurements">Pomiary</a>
                    <a href="fitadept://chat">Chat</a>
                    <a href="fitadept://chat/role/trainer">Chat - Rola:Trener</a>
                    <a href="fitadept://chat/role/diet">Chat - Rola:Dieta</a>
                    <a href="fitadept://chat/role/bok">Chat - Rola:Bok</a>
                    <a href="fitadept://chat/conversation/a7c33363-afaa-4eb9-b446-7975aa7f3f08">Chat - Konwersacja</a>
                </div>
            </StyledComponent>
        );
    }
}
