import { connect } from 'react-redux';

import { pop } from 'Redux/modules/notifications/actions';

import Component from './component';

export default connect(
    state => ({
        list: state.notifications.list,
    }),
    dispatch => ({
        actions: {
            pop: dispatch(pop),
        },
    })
)(Component);

