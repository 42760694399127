import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
       position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        top: 0;
        left: 0;

        &.no-center {
            .spinner {
                top: auto;
                transform: translate(0, 0);
            }
        }

        ${props.fullPage && `
            position: relative;
            width: auto;
            height: auto;
            z-index: 3;
            background: none;
            min-height: 60vh;
        `}

        &.relative {
            position: relative;
            width: auto;
            height: auto;
            z-index: 3;
            background: none;
        }

        &.white {
            .spinner>div {
                background-color: #FFFFFF;
            }
        }

        .spinner-text {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: .8em;
            margin: 0 auto;
            margin-top: 3em;
            color: #666666;
        }

        .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 4em;
            height: 3.2em;
            text-align: center;
            font-size: .7em;
            margin: 0 auto;
        }

        .spinner>div {
            background-color: #f56d0d;
            height: 100%;
            width: .5em;
            display: inline-block;

            -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
            animation: sk-stretchdelay 1.2s infinite ease-in-out;
        }

        .spinner .rect2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        .spinner .rect3 {
            -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
        }

        .spinner .rect4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        .spinner .rect5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }

        @-webkit-keyframes sk-stretchdelay {
            0%,
            40%,
            100% {
                -webkit-transform: scaleY(0.4);
            }

            20% {
                -webkit-transform: scaleY(1.0);
            }
        }

        @keyframes sk-stretchdelay {
            0%,
            40%,
            100% {
                transform: scaleY(0.4);
                -webkit-transform: scaleY(0.4);
            }

            20% {
                transform: scaleY(1.0);
                -webkit-transform: scaleY(1.0);
            }
        }
    `;

