import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import {
    ADMIN_DASHBOARD,
    ADMIN_CHAT,
    ADMIN_CHAT_CONVERSATIONS,
    ADMIN_USERS,
    ADMIN_SALES,
    ADMIN_CHAT_MASS_MESSAGING,
    ADMIN_OFFERS,
    ADMIN_USER_OFFERS,
    ADMIN_LOCATIONS,
    ADMIN_EMPLOYEES,
    ADMIN_ADEPT_DATA,
    ADMIN_ACTIVITY_LOGS,
    ADMIN_DOCS,
    ADMIN_EVENTS_CALENDAR,
    ADMIN_GR_HISTORY_LOGS,
    ADMIN_SHOP_PAGES,
    ADMIN_REPORTS,
    ADMIN_TRAINING_PLANS,
    ADMIN_BLOG,
    ADMIN_CUSTOM_EVENTS,
} from 'Consts/routes';
import {
    USER_PERMISSION_GROUP_USERS,
    USER_PERMISSION_GROUP_CHAT,
    USER_PERMISSION_GROUP_SALES,
} from 'Consts/userPermissions';

import { hasPermission } from 'Utils/permissions';

import StyledComponent from 'Components/core/StyledComponent';
import Avatar from 'Components/layoutAdmin/Avatar';

const PanelSidebar = ({ profile, actions }) => (
    <StyledComponent
        styles={require('./styles')}
        className="admin-sidebar"
    >
        <div className="logo">
            <NavLink to={ADMIN_DASHBOARD.path}>
                <img src={require('Theme/images/logo-color.png')} />
            </NavLink>
        </div>
        <Avatar user={profile} />
        <nav className="user-info">
            <p className="user-email-label">Zalogowany jako:</p>
            <p className="user-email">{profile && (profile.name || profile.email || '...')}</p>
            <a className="user-logout-link" onClick={actions.logout}>Wyloguj</a>
        </nav>
        <div className="navigation">
            {hasPermission(profile, USER_PERMISSION_GROUP_CHAT) &&
                <NavLink
                    to={ADMIN_CHAT_CONVERSATIONS.path}
                    className="navigation-link"
                >
                    Konwersacje
                </NavLink>
            }
            {hasPermission(profile, USER_PERMISSION_GROUP_CHAT) &&
                <NavLink
                    to={ADMIN_CHAT.path}
                    className="navigation-link"
                >
                    Chat
                </NavLink>
            }
            <NavLink
                to={ADMIN_BLOG.path}
                className="navigation-link"
            >
                Blog
            </NavLink>
            {hasPermission(profile, USER_PERMISSION_GROUP_CHAT) &&
                <NavLink
                    to={ADMIN_CHAT_MASS_MESSAGING.path}
                    className="navigation-link"
                >
                    Masowa wysyłka wiadomości
                </NavLink>
            }
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_CUSTOM_EVENTS.path}
                    className="navigation-link"
                >
                    Wydarzenia niestandardowe
                </NavLink>
            )}
            {hasPermission(profile, USER_PERMISSION_GROUP_USERS) &&
                <NavLink
                    to={ADMIN_USERS.path}
                    className="navigation-link"
                >
                    Użytkownicy
                </NavLink>
            }
            {hasPermission(profile, USER_PERMISSION_GROUP_SALES) &&
                <NavLink
                    to={ADMIN_SALES.path}
                    className="navigation-link"
                >
                    Sprzedaż
                </NavLink>
            }
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_SHOP_PAGES.path}
                    className="navigation-link"
                >
                    Sklepy
                </NavLink>
            )}
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_OFFERS.path}
                    className="navigation-link"
                >
                    Oferta
                </NavLink>
            )}
            <NavLink
                to={ADMIN_EVENTS_CALENDAR.path}
                className="navigation-link"
            >
                Wydarzenia
            </NavLink>
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_TRAINING_PLANS.path}
                    className="navigation-link"
                >
                    Plany treningowe
                </NavLink>
            )}
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_USER_OFFERS.path}
                    className="navigation-link"
                >
                    Zamówienia
                </NavLink>
            )}
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_LOCATIONS.path}
                    className="navigation-link"
                >
                    Lokacje
                </NavLink>
            )}
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_ADEPT_DATA.path}
                    className="navigation-link"
                >
                        Kartoteka
                </NavLink>
            )}
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_REPORTS.path}
                    className="navigation-link"
                >
                        Raporty
                </NavLink>
            )}
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_EMPLOYEES.path}
                    className="navigation-link"
                >
                    Pracownicy
                </NavLink>
            )}
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_ACTIVITY_LOGS.path}
                    className="navigation-link"
                >
                    Logi aktywności
                </NavLink>
            )}
            <NavLink
                to={ADMIN_DOCS.path}
                className="navigation-link"
            >
                Dokumentacja
            </NavLink>
            {!profile.isRadissonManager && (
                <NavLink
                    to={ADMIN_GR_HISTORY_LOGS.path}
                    className="navigation-link"
                >
                    Historia getresponse
                </NavLink>
            )}
        </div>
    </StyledComponent>
);

PanelSidebar.propTypes = {
    profile: PropTypes.shape({
        email: PropTypes.string.isRequired,
    }),
    actions: PropTypes.shape({
        logout: PropTypes.func.isRequired,
    }),
};
PanelSidebar.defaultProps = {};
export default PanelSidebar;
