import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import { USER_ROLE_TRAINER } from 'Consts/userRoles';

import * as types from './types';
import * as endpoints from 'Consts/api';

import { fromSelectObject } from 'Utils/object';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_USERS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            enabled: typeof params.enabled === 'boolean'
                ? params.enabled
                : undefined,
            role: params.role || undefined,
            orderBy: params.orderBy || 'createdAt',
            segmentId: params.segmentId || undefined,
            segmentAssigned: params.segmentAssigned || undefined,
            specializationId: fromSelectObject(params.specializationId) || undefined,
            canLeadProductId: fromSelectObject(params.canLeadProductId) || undefined,
            locationId: fromSelectObject(params.locationId) || undefined,
            leadId: fromSelectObject(params.leadId) || undefined,
            offerType: fromSelectObject(params.offerType) || undefined,
            hasUserSurvey: fromSelectObject(params.hasUserSurvey) || undefined,
            sourceSource: fromSelectObject(params.sourceSource) || undefined,
            sourceMedium: fromSelectObject(params.sourceMedium) || undefined,
            startAtFrom: params.startAtFrom || undefined,
            startAtTo: params.startAtTo || undefined,
            hasSpecialPrices: typeof params.hasSpecialPrices === 'boolean'
                ? params.hasSpecialPrices
                : undefined,
            active: typeof params.active === 'boolean'
                ? params.active
                : undefined,
            notTrainerAdepts: params.notTrainerAdepts || undefined,
            notAdeptTrainers: params.notAdeptTrainers || undefined,
            adeptId: params.adeptId || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER, params),
        params,
    }));
};

export const createTVAccessCode = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE_TV_ACCESS_CODE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_TV_ACCESS_CODE, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: endpoints.ENDPOINT_ADMIN_USERS,
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const setPermissions = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SET_PERMISSIONS,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_PERMISSIONS, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const assignToSegment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ASSIGN_TO_SEGMENT,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_ASSIGN_TO_SEGMENT, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const unassignFromSegment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UNASSIGN_FROM_SEGMENT,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_UNASSIGN_FROM_SEGMENT, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const notificationActivate = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.NOTIFICATION_ACTIVATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_NOTIFICATION_ACTIVATE, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const transfer = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.TRANSFER,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_TRANSFER, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const removeUser = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER, params),
        params,
    }));
};

export const presignFile = dispatch => (params = {}) => {
    return dispatch(request({
        method: 'POST',
        reduxType: types.AVATAR_PRESIGN,
        path: params.presignPath,
        params,
        timeout: 360000,
        requestParams: {
            file: {
                type: params.file.type,
                name: params.file.name,
                size: params.file.size,
            },
        },
    }));
};

export const assignProduct = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ASSIGN_PRODUCT,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_ASSIGN_PRODUCT, params),
        params,
        requestParams: params,
    }));
};

export const unassignProduct = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UNASSIGN_PRODUCT,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_ASSIGN_PRODUCT, params),
        params,
        requestParams: params,
    }));
};

export const assignToUser = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ASSIGN_TO_USER,
        method: 'POST',
        path: endpoints.ENDPOINT_ADMIN_USER_ASSIGN,
        params,
        requestParams: params,
    }));
};

export const listNotAssigned = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_NOT_ASSIGNED,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_USERS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            notTrainerAdepts: params.notTrainerAdepts || undefined,
            notAdeptTrainers: params.notAdeptTrainers || undefined,
            leadId: fromSelectObject(params.leadId) || undefined,
            adeptId: params.adeptId || undefined,
        },
    }));
};
