import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';
import {
    single,
    assignSpecialization,
    unassignSpecialization,
} from 'Redux/modules/admin/products/actions';

export default connect(
    state => ({
        productsMap: state.adminProducts.mapById,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
            assignSpecialization: dispatch(assignSpecialization),
            unassignSpecialization: dispatch(unassignSpecialization),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        product: stateProps.productsMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-ProductsManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);