import 'Theme/icons';
import toastifyStyles from 'Theme/styles/toastify';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Global } from '@emotion/core';
import { ToastContainer } from 'react-toastify';

import { ADMIN_PREFIX, ADEPT_PREFIX, TRAINER_PREFIX, PUBLIC_USER_PREFIX } from 'Consts/routes';

import Logger from 'Services/Logger';
import StyledComponent from 'Components/core/StyledComponent';
import ErrorPage from 'Components/pages/service/Error';

import AuthWrapper from 'Components/wrappers/Auth';
import AdminWrapper from 'Components/wrappers/Admin';
import PublicWrapper from 'Components/wrappers/Public';
import PublicUserWrapper from 'Components/wrappers/PublicUser';
import AdeptWrapper from 'Components/wrappers/Adept';
import TrainerWrapper from 'Components/wrappers/Trainer';

moment.locale('pl');

export default class Wrapper extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    state = {
        showErrorPage: false,
    };

    componentDidCatch(error, info) {
        Logger.error('componentDidCatch', error, info);
        this.setState(() => ({ showErrorPage: true }));
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        const { pathname, search } = location;

        if ((pathname !== prevProps[pathname]) && (search == prevProps[search]) ) {
            window.scrollTo(0, 0);
        }
    }

    getModuleWrapper = () => {
        const { location } = this.props;

        switch (true) {
            case location.pathname.startsWith(ADMIN_PREFIX):
                return (
                    <AuthWrapper {...this.props}>
                        <AdminWrapper  {...this.props} />
                    </AuthWrapper>
                );
            case location.pathname.startsWith(TRAINER_PREFIX):
                return (
                    <AuthWrapper {...this.props}>
                        <TrainerWrapper  {...this.props} />
                    </AuthWrapper>
                );
            case location.pathname.startsWith(ADEPT_PREFIX):
                return (
                    <AuthWrapper {...this.props}>
                        <AdeptWrapper  {...this.props} />
                    </AuthWrapper>
                );
            case location.pathname.startsWith(PUBLIC_USER_PREFIX):
                return <PublicUserWrapper  {...this.props} />;
            default:
                return <PublicWrapper {...this.props} />;
        }
    }

    render() {
        const { showErrorPage } = this.state;

        return (
            <StyledComponent
                props={this.props}
                state={this.state}
                styles={require('./styles')}
                errorPage={showErrorPage}
                className="wrapper"
            >
                <Global styles={toastifyStyles} />
                <ToastContainer newestOnTop={true} />
                {showErrorPage ? (
                    <ErrorPage />
                ) : (
                    this.getModuleWrapper()
                )}
            </StyledComponent>
        );
    }
}
