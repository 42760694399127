import moment from 'moment';

export default class UserHomework {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.user = data.user;
        this.description = data.description;
        this.deadlineAt = data.deadlineAt && moment.utc(data.deadlineAt).local();
        this.isCompleted = data.isCompleted;
        this.creator = data.creator;
        this.trainer = data.trainer;
        this.id = data.id;
        this.userId = data.userId;
        this.id = data.id;
        this.trainerId = data.trainerId;
    };
}