import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

import { fromSelectObject } from 'Utils/object';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_USER_OFFERS, params),
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            userId: fromSelectObject(params.userId) || undefined,
            status: params.status || undefined,
            statuses: params.statuses || [],
            canLead: params.canLead || undefined,
            resourceClass: params.resourceClass || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_USER_OFFER, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_USER_OFFERS, params),
        params,
        requestParams: params,
    }));
};

export const getSubUsers = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.GET_SUB_USERS,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_USER_OFFER_GET_SUB_USERS, params),
        params,
    }));
};
