import { reduxRequest as request } from 'Services/Api';
import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_STAFF_DATA_LIST,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_STAFF_DATA
            .replace('{id}', params.id),
        params,
    }));
};

export const set = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SET,
        method: 'POST',
        path: endpoints.ENDPOINT_ADMIN_STAFF_DATA_LIST,
        params,
        requestParams: {
            ...params,
        },
    }));
};