import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class LayoutContainer extends Component {
    static propTypes = {
        children: PropTypes.any,
        size: PropTypes.oneOf([
            'xs', 's', 'm', 'l', 'xs',
        ]),
        maxSize: PropTypes.oneOf([
            'xs', 's', 'm', 'l', 'xs',
        ]),
    };
    static defaultProps = {
        children: null,
        size: 'l',
    };

    render() {
        const { children, size, maxSize } = this.props;

        return (
            <StyledComponent
                className={classnames(
                    'layout-container',
                    `size-${size}`,
                    `max-size-${maxSize}`,
                )}
                styles={require('./styles')}
                props={this.props}
                state={this.state}
            >
                {children}
            </StyledComponent>
        );
    }
}
