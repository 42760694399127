import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import { ENDPOINT_LOCATIONS_SLUG, ENDPOINT_LOCATION_TRAINERS } from 'Consts/api';

export const getLocationBySlug = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.GET_LOCATION_BY_SLUG,
        method: 'GET',
        path: withVariables(ENDPOINT_LOCATIONS_SLUG, params),
        params,
    }));
};

export const freeTrainers = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.FREE_TRAINERS,
        method: 'GET',
        path: withVariables(ENDPOINT_LOCATION_TRAINERS, params),
        params,
        requestParams: {
            startAt: params.startAt || undefined,
            productId: params.productId || undefined,
        },
    }));
}