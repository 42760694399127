import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import {
    single,
} from 'Redux/modules/trainer/adeptTests/actions';

export default connect(
    state => ({
        adeptTestsMap: state.trainerAdeptTests.mapById,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        adeptTest: stateProps.adeptTestsMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-trainer-AdeptTestsManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);