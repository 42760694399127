import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import adminRoutes from 'Routes/admin';
import adminModals from 'Routes/adminModals';

import { PUBLIC_USER_LOGIN } from 'Consts/routes';
import { USER_ROLE_ADMIN } from 'Consts/userRoles';

import {
    init as initFireabse,
    requestToken as requestFcmToken,
} from 'Services/Firebase';
import Logger from 'Services/Logger';

import Sidebar from 'Components/admin/layout/Sidebar';
import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Notification from 'Components/layout/Notification';

export default class AdminWrapper extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        visitorId: PropTypes.string,
        actions: PropTypes.shape({
            logout: PropTypes.func.isRequired,
            setVisitor: PropTypes.func.isRequired,
        }),
        profile: PropTypes.shape({
            roles: PropTypes.arrayOf(
                PropTypes.string.isRequired
            ),
        }),
    };
    static defaultProps = {};
    state = {
        authorized: false,
    };

    componentDidMount = () => {
        const { actions, visitorId } = this.props;

        try {
            if (visitorId) {
                initFireabse();
                requestFcmToken()
                    .then(fcmToken => fcmToken)
                    .catch(error => {
                        return null;
                    })
                    .then(fcmToken => {
                        actions.setVisitor({
                            uniqueId: visitorId,
                            fcmToken,
                        });
                    });
            }
        } catch (error) {
            Logger.warning('[ADMIN_WRAPPER] Cant init init', error);
        }

        this.authorize();
    }

    componentDidUpdate = prevProps => {
        const { profile } = this.props;

        if (JSON.stringify(profile) !== JSON.stringify(prevProps.profile)) {
            this.authorize();
        }
    }

    authorize = () => {
        const { profile, history, actions } = this.props;

        if (profile) {
            if (profile.role === USER_ROLE_ADMIN) {
                this.setState({ authorized: true });
            } else {
                actions.logout();
                history.push(PUBLIC_USER_LOGIN.path);
            }
        }
    }

    render() {
        const { location, history } = this.props;
        const { authorized } = this.state;
        const Modal = adminModals[location.hash] && adminModals[location.hash] || null;

        return (
            !authorized
                ? (
                    <Spinner />
                )
                : (
                    <StyledComponent
                        props={this.props}
                        state={this.state}
                        styles={require('./styles')}
                        className="admin-wrapper"
                    >
                        <div className="sidebar-container">
                            <Sidebar />
                        </div>
                        <div className="view-container">
                            <Switch>
                                {adminRoutes}
                            </Switch>
                        </div>
                        {Modal ? (
                            <Modal
                                location={location}
                                history={history}
                            />
                        ) : null}
                        <Notification />
                    </StyledComponent>
                )
        );
    }
}
