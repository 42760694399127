import { connect } from 'react-redux';
import Component from './component';

import {
    list as listArticleTags,
    changeAssignment,
} from 'Redux/modules/admin/articleTags/actions';

export default connect(
    state => ({
        articleTags: state.adminArticleTags.list,
    }),
    dispatch => ({
        actions: {
            listArticleTags: dispatch(listArticleTags),
            changeAssignment: dispatch(changeAssignment),
        },
    })
)(Component);
