import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import moment from 'moment';

import { ADEPT_PROFILE } from 'Consts/routes';
import { USER_ROLE_ADEPT } from 'Consts/userRoles';

import { init as initTracking } from 'Services/Tracking';

import publicRoutes from 'Routes/public';
import serviceRoutes from 'Routes/service';
import publicModals from 'Routes/publicModals';

import StyledComponent from 'Components/core/StyledComponent';
import Topbar from 'Components/public/layout/Topbar';
import Footer from 'Components/public/layout/Footer';

export default class PublicWrapper extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        showErrorPage: PropTypes.bool,
        profile: PropTypes.object,
        authToken: PropTypes.string,
        actions: PropTypes.shape({
            getProfile: PropTypes.func,
        }),
    };
    static defaultProps = {
        showErrorPage: false,
    };

    componentDidMount = () => {
        const { authToken, actions } = this.props;

        initTracking();

        if (authToken) {
            actions.getProfile();
        }
    };

    componentDidUpdate = (prevProps) => {
        const { actions, authToken } = this.props;

        if (authToken && authToken !== prevProps.authToken) {
            actions.getProfile();
        }
    };

    render() {
        const { location, history, showErrorPage, profile } = this.props;
        const Modal = publicModals[location.hash] && publicModals[location.hash] || null;

        let isPromoBannerVisible = true;

        if(moment().isAfter(moment('2021-04-30 23:59:00'))) {
            isPromoBannerVisible = false;
        }

        return (
            <StyledComponent
                props={this.props}
                state={this.state}
                styles={require('./styles')}
                className="public-wrapper"
            >
                <Topbar 
                    location={location}
                    profilePath={profile && profile.role == USER_ROLE_ADEPT ? ADEPT_PROFILE.path : null} 
                    errorPage={showErrorPage} 
                />
                {isPromoBannerVisible && (
                    <a
                        className="promo-info"
                        href={'https://fitadept.com/f-c'}
                    >
                        Tylko dla klientow FitAdept - Oferta limitowana: 18% rabatu na dowolny catering. Skorzystaj na <span className="promo-link">https://fitadept.com/f-c </span>
                    </a>
                )}
                <div className="page-container">
                    <Switch>
                        {publicRoutes}
                        {serviceRoutes}
                    </Switch>
                </div>
                <Footer />
                {/* {Modal ? (
                    <Modal
                        location={location}
                        history={history}
                    />
                ) : null */}
            </StyledComponent>
        );
    }
}
