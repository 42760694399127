import { connect } from 'react-redux';
import Component from './component';

import { logout, setVisitor } from 'Redux/modules/user/actions';

export default connect(
    state => ({
        profile: state.user.profile,
        visitorId: state.user.visitorId,
    }),
    dispatch => ({
        actions: {
            logout: dispatch(logout),
            setVisitor: dispatch(setVisitor),
        },
    })
)(Component);
