import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputMask from 'react-input-mask';

import StyledComponent from 'Components/core/StyledComponent';
import ExternalWrapper from 'Components/forms/InputExternalWrapper';
import InternalWrapper from 'Components/forms/InputInternalWrapper';
import ErrorMessage from 'Components/forms/ErrorMessage';
import InputHelper from 'Components/forms/InputHelper';
import InputLabel from 'Components/forms/InputLabel';
import InputIcon from 'Components/forms/InputIcon';

export default class FormInputComponent extends React.Component {
    static displayName = 'FormInputComponent';
    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        onFormChange: PropTypes.func,
        type: PropTypes.string,
        background: PropTypes.oneOf([
            'default',
            'transparent',
        ]),
        size: PropTypes.oneOf([
            'small',
            'medium',
            'large',
        ]),
        icon: PropTypes.shape({
            type: PropTypes.oneOf(['fa', 'path', 'element']).isRequired,
            source: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]).isRequired,
        }),
        placeholder: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        errorMessage: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        required: PropTypes.bool,
        helper: PropTypes.string,
        className: PropTypes.string,
        mask: PropTypes.string,
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
        border: PropTypes.oneOf([
            '',
            'light',
            'bottom-light',
        ]),
        composition: PropTypes.oneOf([
            '',
            'measurements',
        ]),
        visible: PropTypes.bool,
    };

    static defaultProps = {
        onChange: null,
        onFormChange: null,
        size: 'medium',
        style: 'default',
        type: 'text',
        icon: null,
        placeholder: '',
        label: '',
        errorMessage: '',
        value: '',
        required: false,
        disabled: false,
        helper: '',
        className: '',
        mask: null,
        styleVersion: 1,
        border: '',
        composition: '',
        visible: true,
    }

    onChange = event => {
        const { onChange, onFormChange } = this.props;

        const data = {
            name: event.target.name,
            value: event.target.value,
        };

        if (onChange) onChange(data);
        if (onFormChange) onFormChange(data);

        return data;
    }

    render() {
        const { 
            value, 
            name, 
            type, 
            icon, 
            placeholder, 
            label, 
            errorMessage, 
            required, 
            disabled, 
            helper, 
            className, 
            style, 
            size, 
            mask, 
            styleVersion, 
            border, 
            layout, 
            composition, 
            visible } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames({
                    'form-element': true,
                    input: true,
                    [className]: true,
                    [name]: true,
                    [`style-${style}`]: true,
                    [`size-${size}`]: true,
                    [`style-version-${styleVersion}`]: true,
                    error: Boolean(errorMessage),
                    [`layout-${layout}`]: true,
                    [`composition-${composition}`]: true,
                },
                `visible-${visible}`,
                )}
            >
                <ExternalWrapper>
                    {label && <InputLabel label={label} style={style} styleVersion={styleVersion} required={required} />}
                    <InternalWrapper
                        style={style}
                        error={Boolean(errorMessage)}
                        styleVersion={styleVersion}
                        border={border}
                        composition={composition}
                    >
                        {mask ?
                            <InputMask mask={mask} value={value} disabled={disabled} onChange={this.onChange}>
                                {(inputProps) => (
                                    <input
                                        name={name}
                                        type={type}
                                        required={required}
                                        placeholder={placeholder}
                                        autoComplete='off'
                                        {...inputProps}
                                    />
                                )}
                            </InputMask>
                            :
                            <input
                                name={name}
                                type={type}
                                onChange={this.onChange}
                                value={value || ''}
                                required={required}
                                disabled={disabled}
                                placeholder={placeholder}
                                autoComplete='off'
                            />
                        }
                        {icon && <InputIcon icon={icon} />}
                    </InternalWrapper>
                    {helper ? <InputHelper message={helper} /> : null}
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </ExternalWrapper>
            </StyledComponent>
        );
    }
}
