import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, LIST_MARKUP, ELEMENT_MARKUP } from 'Consts/redux';
import {
    API_RESOURCE_CYCLIC_EVENTS,
    API_RESOURCE_CYCLIC_EVENT,
} from 'Consts/apiResources';
import CyclicEvent from 'Models/CyclicEvent';

export const initialState = {
    list: { ...LIST_MARKUP },
    mapById: {},
};

export default function (state = initialState, action) {
    const replaceStateWithElement = (identifier = 'id') => ({
        ...state,
        list: {
            ...state.list,
            elements: state.list.elements
                .map(element => element[identifier] === action.params[identifier]
                    ? action.payload[API_RESOURCE_CYCLIC_EVENT]
                    : element
                ),
        },
        mapById: {
            ...state.mapById,
            [action.params[identifier]]: {
                ...ELEMENT_MARKUP,
                ...(state.mapById[action.params[identifier]] || {}),
                data: action.payload[API_RESOURCE_CYCLIC_EVENT],
            },
        },
    });
    switch (action.type) {
        case types.LIST + '_' + INIT_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    params: action.params,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.LIST + '_' + SUCCESS_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_CYCLIC_EVENTS].elements
                        .map(elem => new CyclicEvent(elem)),
                    meta: action.payload[API_RESOURCE_CYCLIC_EVENTS].meta,
                    error: false,
                },
            };
        case types.LIST + '_' + FAIL_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };

        case types.SINGLE + '_' + INIT_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.id] || {}),
                        isLoading: true,
                        isLoaded: false,
                    },
                },
            };
        case types.SINGLE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.id] || {}),
                        isLoading: false,
                        isLoaded: true,
                        data: new CyclicEvent(action.payload[API_RESOURCE_CYCLIC_EVENT]),
                        error: false,
                    },
                },
            };
        case types.SINGLE + '_' + FAIL_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.id] || {}),
                        isLoading: false,
                        isLoaded: true,
                        data: null,
                        error: true,
                    },
                },
            };
        
        case types.UPDATE + '_' + SUCCESS_FLAG:
            return { ...replaceStateWithElement() };

        default:
            return state;
    }
}
