import { reduxRequest as request } from 'Services/Api';

import { withVariables } from 'Utils/string';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_PUBLIC_PRODUCT, params),
        params,
    }));
};

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_PUBLIC_PRODUCTS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            visibility: params.visibility || undefined,
            specializationSlug: params.specializationSlug || undefined,
            locationType: params.locationType || undefined,
            orderBy: params.orderBy || undefined,
        },
    }));
};
