import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, LIST_MARKUP, ELEMENT_MARKUP } from 'Consts/redux';
import {
    API_RESOURCE_SHOP_PAGE,
} from 'Consts/apiResources';

import ShopPage from 'Models/ShopPage';

export const initialState = {
    list: { ...LIST_MARKUP },
    mapById: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SINGLE_BY_SLUG + '_' + INIT_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.slug]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.slug] || {}),
                        isLoading: true,
                        isLoaded: false,
                    },
                },
            };
        case types.SINGLE_BY_SLUG + '_' + SUCCESS_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.slug]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.slug] || {}),
                        isLoading: false,
                        isLoaded: true,
                        data: new ShopPage(action.payload[API_RESOURCE_SHOP_PAGE]),
                        error: false,
                    },
                },
            };
        case types.SINGLE_BY_SLUG + '_' + FAIL_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.slug]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.slug] || {}),
                        isLoading: false,
                        isLoaded: true,
                        data: null,
                        error: true,
                    },
                },
            };
        default:
            return state;
    }
}
