import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    padding: 2em 0;
    display: flex;
    flex-direction: column;

    > * {
        padding: 0 2em;

        &.panel-tab-navigation {
            padding: 0;
        }
    }

    .breadcrumbs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 2em;
        flex-wrap: wrap;

        .breadcrumb {
            padding: 0 .3em;
            color: #999999;
            font-weight: 300;
            font-size: 1.1em;
            cursor: pointer;
            margin-top: .5em;
            
            &.active {
                color: #A71F57;
            }

            &:hover {
                opacity: .8;
            }

            &:after {
                content: '/';
                display: inline-block;
                padding-left: .5em;
                color: #CCCCCC;
            }

            &:last-of-type {
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }
    }
`;
