import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            font-size: 1em;
            font-weight: 900;
            text-decoration: none;
            cursor: pointer;

            border-radius: .3em;
            box-shadow: 0 .1em .1em rgba(0, 0, 0, 0.25);
            border: none;

            &:hover {
                box-shadow: 0 .1em .3em rgba(0, 0, 0, 0.35);
            }
        }

        .button-icon {
            margin-left: .5em;
        }

        .button-content {
            position: relative;

            .component-spinner {
                position: relative;
                min-height: 1.5em;
            }
        }

        /* Layouts */
        .layout-default {
            padding: .7em 1.5em;
        }
        .layout-wide {
            padding: .7em 2.5em;
        }
        .layout-fullWidth {
            padding: .7em 1em;
            width: 100%;
        }


        /* Sizes */
        .size-extraSmall {
            font-size: .7em;
        }
        .size-small {
            font-size: .85em;
        }
        .size-medium {
            font-size: 1em;
        }
        .size-large {
            font-size: 1.2em;
        }
        .size-extraLarge {
            font-size: 1.5em;
        }

        .style-version-1 {

            /* Styles */
            &.style-primary {
                background: transparent linear-gradient(80deg, #FF0031 0%, #A71F57 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 5px #FF003166;
                color: white;
                border-radius: .2em;
                font-weight: 600;
                border: 1px solid #FF0031;
                transition: all .3s ease-in-out;

                &:hover {
                    box-shadow: 0px -.3em .1em rgba(0, 0, 0, .3) inset;
                }

                &:active {
                    box-shadow: 0 .3em .1em rgba(0, 0, 0, 0.3) inset;
                }

                &.disabled {
                    border: 1px solid #666666;
                    color: #c29191;

                    &:hover {
                        box-shadow: 0px 0px 5px #FF003166;
                    }
                }
            }
            &.style-primaryLight {
                background-color: ${variables.primaryLightColor};
                color: ${variables.lightColor};
            }
            &.style-primaryDark {
                background-color: ${variables.primaryDarkColor};
                color: ${variables.lightColor};
            }
            &.style-secondary {
                background-color: ${variables.secondaryColor};
                color: ${variables.lightColor};
            }
            &.style-secondaryLight {
                background-color: ${variables.secondaryLightColor};
                color: ${variables.lightColor};
            }
            &.style-secondaryDark {
                background-color: ${variables.secondaryDarkColor};
                color: ${variables.lightColor};
            }
            &.style-light {
                background-color: ${variables.lightColor};
                color: ${variables.primaryColor};
            }
            &.style-lightReverse {
                background-color: ${variables.lightColor};
                color: ${variables.primaryColor};
                border-width: .1em;
                border-style: solid;
                border-color: ${variables.primaryColor};
                box-shadow: none;

                &:hover {
                    background-color: ${variables.primaryColor};
                    color: ${variables.lightColor};
                }
            }
            &.style-faPrimary {
                background: transparent linear-gradient(80deg, #FF0031 0%, #A71F57 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 5px #FF003166;
                color: white;
                border-radius: .2em;
                font-weight: 600;
                border: 1px solid #FF0031;
                transition: all .3s ease-in-out;

                &:hover {
                    box-shadow: 0px -.3em .1em rgba(0, 0, 0, .3) inset;
                }

                &:active {
                    box-shadow: 0 .3em .1em rgba(0, 0, 0, 0.3) inset;
                }

                &.disabled {
                    border: 1px solid #666666;
                    color: #c29191;

                    &:hover {
                        box-shadow: 0px 0px 5px #FF003166;
                    }
                }
            }
            &.style-hollow {
                background: none;
                box-shadow: none;
                color: #FFFFFF;
                border-radius: .2em;
                font-weight: 400;
                border: 1px solid #DF3846;
                transition: all .3s ease-in-out;
                padding: 1em 3em;
                text-transform: uppercase;

                &:hover {
                    background-image: linear-gradient(to left, #DA3143, #FF6254);
                    color: #FFFFFF;
                }

                &:active {
                    box-shadow: 0 .3em .1em rgba(0, 0, 0, 0.3) inset;
                }

                &.disabled {
                    border: 1px solid #666666;
                    color: #c29191;

                    &:hover {
                        box-shadow: 0px 0px 5px #FF003166;
                    }
                }
            }

            &.style-hollowWhite {
                background: none;
                font-size: .9em;
                box-shadow: 0px 0px 5px #FF003166;
                color: #FFFFFF;
                border-radius: 0em;
                font-weight: 400;
                border: 1px solid white;
                transition: all .3s ease-in-out;
                padding: 1em 3em;
                text-transform: uppercase;

                &:hover {
                    box-shadow: 0px -.3em .1em rgba(0, 0, 0, .3) inset;
                }

                &:active {
                    box-shadow: 0 .3em .1em rgba(0, 0, 0, 0.3) inset;
                }

                &.disabled {
                    border: 1px solid #666666;
                    color: #c29191;

                    &:hover {
                        box-shadow: 0px 0px 5px #FF003166;
                    }
                }
            }

            &.style-gradient {
                background-image: linear-gradient(to left, #DA3143, #FF6254) ;
                box-shadow: 0px 0px 5px #FF003166;
                color: #FFFFFF;
                border-radius: .2em;
                font-weight: 500;
                border: 1px solid #DF3846;
                transition: all .3s ease-in-out;
                padding: 1em 3em;
                text-transform: uppercase;

                &:hover {
                    box-shadow: 0px -.3em .1em rgba(0, 0, 0, .3) inset;
                }

                &:active {
                    box-shadow: 0 .3em .1em rgba(0, 0, 0, 0.3) inset;
                }

                &.disabled {
                    border: 1px solid #666666;
                    color: #c29191;

                    &:hover {
                        box-shadow: 0px 0px 5px #FF003166;
                    }
                }
            }

            &.style-V2Dark {
                font-size: 1.1em;
                background: #252525;
                box-shadow: none;
                color: #FFFFFF;
                border-radius: .2em;
                font-weight: 400;
                border: 1px solid #DF3846;
                transition: all .3s ease-in-out;
                padding: 1em 3em;
                text-transform: uppercase;

                &:hover {
                    background-image: linear-gradient(to left, #DA3143, #FF6254);
                    color: #FFFFFF;
                }

                &:active {
                    box-shadow: 0 .3em .1em rgba(0, 0, 0, 0.3) inset;
                }

                &.disabled {
                    border: 1px solid #666666;
                    color: #c29191;

                    &:hover {
                        box-shadow: 0px 0px 5px #FF003166;
                    }
                }
            }

            &.wide {
                padding: 1em 4em;
            }
        }

        .style-version-2 {

            /* Styles */
            &.style-primary {
                background: transparent linear-gradient(80deg, #FF0031 0%, #A71F57 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 5px #FF003166;
                color: white;
                border-radius: .2em;
                font-weight: 600;
                border: 1px solid #FF0031;
                transition: all .3s ease-in-out;

                &:hover {
                    box-shadow: 0px -.3em .1em rgba(0, 0, 0, .3) inset;
                }

                &.disabled {
                    border: 1px solid #666666;
                    color: #c29191;

                    &:hover {
                        box-shadow: 0px 0px 5px #FF003166;
                    }
                };
            }
        }

        .style-success {};
        .style-info {};
        .style-warning {};
        .style-alert {};

        [disabled] {
            background-color: #909090;
        };
    `;
