import React from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import classnames from 'classnames';
import moment from 'moment';
import { pl } from 'date-fns/locale/';

import { DATETIME_UNICODE_FORMAT } from 'Consts/date';

import StyledComponent from 'Components/core/StyledComponent';
import ExternalWrapper from 'Components/forms/InputExternalWrapper';
import InternalWrapper from 'Components/forms/InputInternalWrapper';
import InputHelper from 'Components/forms/InputHelper';
import InputLabel from 'Components/forms/InputLabel';
import ErrorMessage from 'Components/forms/ErrorMessage';

registerLocale('en-gb', pl);

export default class FormDatePickerComponent extends React.Component {
    static displayName = 'FormDatePickerComponent';

    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        onFormChange: PropTypes.func,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        errorMessage: PropTypes.string,
        value: PropTypes.any,
        className: PropTypes.string,
        style: PropTypes.oneOf([
            'default', 'version-2', 'borderLight',
        ]),
        size: PropTypes.oneOf([
            'small',
            'medium',
            'large',
        ]),
        dateFormat: PropTypes.string,
        helper: PropTypes.any,
        datePickerProps: PropTypes.object,
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
        border: PropTypes.oneOf([
            'default', 'light',
        ]),
        mobileView: PropTypes.bool,
        showTimeSelect: PropTypes.bool,
        required: PropTypes.bool,
    };

    static defaultProps = {
        size: 'medium',
        style: 'default',
        onChange: null,
        onFormChange: null,
        label: '',
        errorMessage: '',
        value: null,
        disabled: false,
        className: '',
        dateFormat: DATETIME_UNICODE_FORMAT,
        helper: null,
        datePickerProps: {},
        styleVersion: 1,
        border: 'default',
        mobileView: false,
        showTimeSelect: true,
        required: false,
    }
    
    state = {
        isMobileWrapperVisible: true,
    }

    componentDidMount = () => {
        window.addEventListener('touchstart', this.handleTouch);
    }

    componentWillUnmount = () => {
        window.removeEventListener('touchstart', this.handleTouch);
    }

    onChange = event => {
        const { name, onChange, onFormChange } = this.props;

        const data = {
            name,
            value: moment(event).isValid()
                ? moment(event)
                : null,
        };

        if (onChange) {
            onChange(data);
        }

        if (onFormChange) {
            onFormChange(data);
        }

        return data;
    }

    onChangeRaw = event => {
        const { name, onChange, onFormChange } = this.props;
        let date = event.target.value;

        if (!moment(date).isValid()) {
            return null;
        }

        const data = {
            name,
            value: moment(date),
        };

        if (onChange) {
            onChange(data);
        }

        if (onFormChange) {
            onFormChange(data);
        }

        return data;
    }

    handleTouch = () => {
        if (!this._calendar?.state?.open) {
            this.setState({
                isMobileWrapperVisible: true,
            });
        }
    }

    render() {
        const { 
            value,
            name,
            label,
            errorMessage,
            disabled, 
            style, 
            size, 
            className, 
            dateFormat, 
            helper, 
            datePickerProps, 
            styleVersion, 
            border, 
            mobileView,
            showTimeSelect,
            required,
        } = this.props;

        const { isMobileWrapperVisible } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames({
                    'form-element': true,
                    'date-picker': true,
                    [className]: true,
                    [`style-${style}`]: true,
                    [`size-${size}`]: true,
                    [`style-version-${styleVersion}`]: true,
                })}
            >
                <ExternalWrapper onClick={() => this._calendar.setOpen(true)}>
                    {label && <InputLabel label={label} required={required}/>}
                    <InternalWrapper border={border}>
                        <StyledComponent
                            styles={require('./datepicker.styles')}
                        >   
                            <DatePicker
                                name={name}
                                selected={value && moment(value).isValid()
                                    ? moment(value).toDate()
                                    : undefined
                                }
                                ref={(datepicker) => this._calendar = datepicker}
                                onChange={this.onChange}
                                onChangeRaw={this.onChangeRaw}
                                readOnly={disabled}
                                dateFormat={dateFormat}
                                placeholderText="Wybierz datę"
                                showTimeSelect={showTimeSelect}
                                autoComplete="off"
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                locale="en-gb"
                                {...datePickerProps}
                            />
                            {mobileView && (
                                <div
                                    className={classnames({
                                        'mobile-datepicker-wrapper': true,
                                        ['visible']: isMobileWrapperVisible,
                                    })} 
                                    onTouchStart={() => {
                                        this._calendar.setOpen(true);
                                        this.setState({
                                            isMobileWrapperVisible: false,
                                        });
                                    }}
                                />
                            )}
                        </StyledComponent>
                    </InternalWrapper>
                    {helper ? <InputHelper message={helper} /> : null}
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </ExternalWrapper>
            </StyledComponent>
        );
    }
}
