import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

const propTypes = {
    onClick: PropTypes.func.isRequired,
    styleVariant: PropTypes.oneOf(['default', 'dark']),
};

const ModalCloseButton = (props) => {
    const { onClick, styleVariant } = props;

    return (
        <StyledComponent 
            className={classnames({
                'modal-cloe-button-component' : true,
                [`style-${styleVariant}`]: true,
            })}
            styles={require('./styles')}
        >
            <button onClick={onClick}>x</button>
        </StyledComponent>
    );
};

ModalCloseButton.propTypes = propTypes;

export default ModalCloseButton;
