import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers, { initialState } from './state';
import { composeWithDevTools } from 'redux-devtools-extension';

export default function (passedState = {}) {
    const middlewares = compose(
        applyMiddleware(thunk),
    );

    return createStore(
        combineReducers({ ...reducers }),
        { ...initialState, ...passedState },
        process.env.APP_ENV === 'development' ? composeWithDevTools(middlewares) : middlewares
    );
}
