import 'Theme/icons';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';

import PublicLogin from 'Components/pages/publicUser/Login';

export default class Auth extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        children: PropTypes.any.isRequired,
        actions: PropTypes.shape({
            logout: PropTypes.func.isRequired,
            getProfile: PropTypes.func.isRequired,
        }).isRequired,
        authToken: PropTypes.string,
        cid: PropTypes.string,
        sid: PropTypes.string,
        profile: PropTypes.object,
    };
    state = {
        isProfilePending: false,
        refreshTokenPending: false,
    };

    componentDidMount = () => {
        const { profile, authToken } = this.props;
        this.handleAuthUser();
    }

    componentDidUpdate = prevProps => {
        const { authToken, profile } = this.props;

        if (
            authToken !== prevProps.authToken
            || JSON.stringify(profile) !== JSON.stringify(prevProps.profile)
        ) {
            this.handleAuthUser();
        }
    }

    handleAuthUser = () => {
        const { authToken, cid, sid, profile, actions, history, location } = this.props;
        const { isProfilePending, refreshTokenPending } = this.state;
        const { search } = location;

        const searchObject = parseQueryToObject(search, true);

        if (!cid && !sid && !authToken && !searchObject.token) {
            actions.logout();
        }

        if (refreshTokenPending) {
            return;
        }

        if (cid && sid && !authToken) {
            return this.setState({ refreshTokenPending: true }, () => {
                return actions.refreshToken()
                    .then(() => {
                        return this.setState({ refreshTokenPending: false });
                    });
            });
        }

        if (isProfilePending) {
            return;
        }

        if (searchObject.token && !profile) {
            return this.setState({ refreshTokenPending: true }, () => {
                return actions.login({ token: searchObject.token })
                    .then(() => {
                        this.setState({ refreshTokenPending: false });
                        return history.push(location.path);
                    });
            });
        }

        if (authToken && !profile) {
            return this.setState({ isProfilePending: true }, () => {
                return actions.getProfile()
                    .then(() => {
                        return this.setState({ isProfilePending: false });
                    });
            });
        }

        if (!isProfilePending && !profile) {
            actions.logout();
        }
    }

    render() {
        const { children, authToken, profile, location, history } = this.props; 
        
        if (authToken && !profile) {
            return null;
        }

        if (!authToken || !profile) {
            return <PublicLogin
                location={location}
                history={history}
            />;
        }

        return (children);
    }
}
