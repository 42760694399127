import React from 'react';
import { Route } from 'react-router-dom';

import {
    PUBLIC_HOME,
    PUBLIC_DEEPLINKS,
    PUBLIC_SURVEY,
    PUBLIC_SURVEY_TRAINER,
    PUBLIC_SURVEY_CHECKOUT,
    PUBLIC_SURVEY_CONFIRM,
    PUBLIC_COUPON_REGISTER,
    PUBLIC_ADEPT_WELCOME,
    PUBLIC_OFFER,
    PUBLIC_MOBILE_APP,
    PUBLIC_LP_RESERVATIONS,
    PUBLIC_RESERVATIONS,
    PUBLIC_RESERVATIONS_R,
    PUBLIC_RESERVATIONS_CONFIRM,
    PUBLIC_LP_ONLINE,
    PUBLIC_LP_ONLINE_SURVEY,
    PUBLIC_RESERVATIONS_ONLINE,
    PUBLIC_RESERVATIONS_ONLINE_CONFIRM,
    PUBLIC_RESERVATIONS_PARTNER_ONLINE,
    PUBLIC_RESERVATIONS_PARTNER_OFFLINE,
    PUBLIC_SHOP,
    PUBLIC_SHOP_ONLINE,
    PUBLIC_SHOP_MASSAGE,
    PUBLIC_SHOP_OFFER,
    PUBLIC_SHOP_USER_OFFER,
    PUBLIC_SHOP_PAYMENT,
    PUBLIC_SHOP_STUDIO,
    PUBLIC_RESERVATIONS_MASSAGE_SOBIESKI,
    PUBLIC_RESERVATIONS_MASSAGE_RC,
    PUBLIC_RESERVATIONS_MASSAGE_RC_NOW,
    PUBLIC_RESERVATIONS_MASSAGE_HOME,
    PUBLIC_RESERVATIONS_DIETICIANS,
    PUBLIC_SHOP_RESERVATIONS_TRAINER,
    PUBLIC_PREFERENCES_SURVEY,
    PUBLIC_RESERVATIONS_ONLINE_CODE,
    PUBLIC_USER_UPDATE_DATA,
    PUBLIC_RESERVATION_CONFIRMATION,
    PUBLIC_RESERVATIONS_REHABILITATION_SOBIESKI,
    PUBLIC_RESERVATIONS_REHABILITATION_ONLINE,
    PUBLIC_PREFERENCES_SURVEY_REHAB,
    PUBLIC_LP_REHABILITATION,
    PUBLIC_REGISTER,
} from 'Consts/routes';

export default [
    <Route
        key="PUBLIC_HOME"
        exact={PUBLIC_HOME.exact}
        path={PUBLIC_HOME.path}
        component={require('Components/pages/publicUser/Login').default}
    />,
    <Route
        key="PUBLIC_DEEPLINKS"
        exact={PUBLIC_DEEPLINKS.exact}
        path={PUBLIC_DEEPLINKS.path}
        component={require('Components/pages/service/Deeplinks').default}
    />,
    <Route
        key="PUBLIC_SURVEY"
        exact={PUBLIC_SURVEY.exact}
        path={PUBLIC_SURVEY.path}
        component={require('Components/pages/public/Survey').default}
    />,
    <Route
        key="PUBLIC_SURVEY_TRAINER"
        exact={PUBLIC_SURVEY_TRAINER.exact}
        path={PUBLIC_SURVEY_TRAINER.path}
        component={require('Components/pages/public/SurveyTrainer').default}
    />,
    <Route
        key="PUBLIC_SURVEY_CHECKOUT"
        exact={PUBLIC_SURVEY_CHECKOUT.exact}
        path={PUBLIC_SURVEY_CHECKOUT.path}
        component={require('Components/pages/public/SurveyCheckout').default}
    />,
    <Route
        key="PUBLIC_SURVEY_CONFIRM"
        exact={PUBLIC_SURVEY_CONFIRM.exact}
        path={PUBLIC_SURVEY_CONFIRM.path}
        component={require('Components/pages/public/SurveyConfirm').default}
    />,
    <Route
        key="PUBLIC_COUPON_REGISTER"
        exact={PUBLIC_COUPON_REGISTER.exact}
        path={PUBLIC_COUPON_REGISTER.path}
        component={require('Components/pages/public/CouponRegister').default}
    />,
    <Route
        key="PUBLIC_ADEPT_WELCOME"
        exact={PUBLIC_ADEPT_WELCOME.exact}
        path={PUBLIC_ADEPT_WELCOME.path}
        component={require('Components/pages/public/AdeptWelcome').default}
    />,
    <Route
        key="PUBLIC_OFFER"
        exact={PUBLIC_OFFER.exact}
        path={PUBLIC_OFFER.path}
        component={require('Components/pages/public/Offer').default}
    />,
    <Route
        key="PUBLIC_MOBILE_APP"
        exact={PUBLIC_MOBILE_APP.exact}
        path={PUBLIC_MOBILE_APP.path}
        component={require('Components/pages/public/MobileApp').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_MASSAGE_SOBIESKI"
        exact={PUBLIC_RESERVATIONS_MASSAGE_SOBIESKI.exact}
        path={PUBLIC_RESERVATIONS_MASSAGE_SOBIESKI.path}
        component={require('Components/pages/public/ReservationsMassageSobieski').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_MASSAGE_RC"
        exact={PUBLIC_RESERVATIONS_MASSAGE_RC.exact}
        path={PUBLIC_RESERVATIONS_MASSAGE_RC.path}
        component={require('Components/pages/public/ReservationsMassageRc').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_REHABILITATION_SOBIESKI"
        exact={PUBLIC_RESERVATIONS_REHABILITATION_SOBIESKI.exact}
        path={PUBLIC_RESERVATIONS_REHABILITATION_SOBIESKI.path}
        component={require('Components/pages/public/ReservationsRehabSobieski').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_REHABILITATION_ONLINE"
        exact={PUBLIC_RESERVATIONS_REHABILITATION_ONLINE.exact}
        path={PUBLIC_RESERVATIONS_REHABILITATION_ONLINE.path}
        component={require('Components/pages/public/ReservationsRehabOnline').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_MASSAGE_RC_NOW"
        exact={PUBLIC_RESERVATIONS_MASSAGE_RC_NOW.exact}
        path={PUBLIC_RESERVATIONS_MASSAGE_RC_NOW.path}
        component={require('Components/pages/public/ReservationsMassageRcNow').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_DIETICIANS"
        exact={PUBLIC_RESERVATIONS_DIETICIANS.exact}
        path={PUBLIC_RESERVATIONS_DIETICIANS.path}
        component={require('Components/pages/public/ReservationsDieticians').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_MASSAGE_HOME"
        exact={PUBLIC_RESERVATIONS_MASSAGE_HOME.exact}
        path={PUBLIC_RESERVATIONS_MASSAGE_HOME.path}
        component={require('Components/pages/public/ReservationsMassageHome').default}
    />,
    <Route
        key="PUBLIC_LP_RESERVATIONS"
        exact={PUBLIC_LP_RESERVATIONS.exact}
        path={PUBLIC_LP_RESERVATIONS.path}
        component={require('Components/pages/public/ReservationsLP').default}
    />,
    <Route
        key="PUBLIC_LP_REHABILITATION"
        exact={PUBLIC_LP_REHABILITATION.exact}
        path={PUBLIC_LP_REHABILITATION.path}
        component={require('Components/pages/public/ReservationRehabLP').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_CONFIRM"
        exact={PUBLIC_RESERVATIONS_CONFIRM.exact}
        path={PUBLIC_RESERVATIONS_CONFIRM.path}
        component={require('Components/pages/public/ReservationsConfirm').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS"
        exact={PUBLIC_RESERVATIONS.exact}
        path={PUBLIC_RESERVATIONS.path}
        component={require('Components/pages/public/Reservations').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_R"
        exact={PUBLIC_RESERVATIONS_R.exact}
        path={PUBLIC_RESERVATIONS_R.path}
        component={require('Components/pages/public/Reservations').default}
    />,
    <Route
        key="PUBLIC_LP_ONLINE"
        exact={PUBLIC_LP_ONLINE.exact}
        path={PUBLIC_LP_ONLINE.path}
        component={require('Components/pages/public/LpOnline').default}
    />,
    <Route
        key="PUBLIC_LP_ONLINE_SURVEY"
        exact={PUBLIC_LP_ONLINE_SURVEY.exact}
        path={PUBLIC_LP_ONLINE_SURVEY.path}
        component={require('Components/pages/public/LpOnlineSurvey').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_ONLINE"
        exact={PUBLIC_RESERVATIONS_ONLINE.exact}
        path={PUBLIC_RESERVATIONS_ONLINE.path}
        component={require('Components/pages/public/ReservationsOnline').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_ONLINE_CONFIRM"
        exact={PUBLIC_RESERVATIONS_ONLINE_CONFIRM.exact}
        path={PUBLIC_RESERVATIONS_ONLINE_CONFIRM.path}
        component={require('Components/pages/public/ReservationsOnlineConfirm').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_ONLINE_WITH_CODE"
        exact={PUBLIC_RESERVATIONS_ONLINE_CODE.exact}
        path={PUBLIC_RESERVATIONS_ONLINE_CODE.path}
        component={require('Components/pages/public/ReservationsOnline').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_PARTNER_ONLINE"
        exact={PUBLIC_RESERVATIONS_PARTNER_ONLINE.exact}
        path={PUBLIC_RESERVATIONS_PARTNER_ONLINE.path}
        component={require('Components/pages/public/ReservationsPartnerOnline').default}
    />,
    <Route
        key="PUBLIC_RESERVATIONS_PARTNER_OFFLINE"
        exact={PUBLIC_RESERVATIONS_PARTNER_OFFLINE.exact}
        path={PUBLIC_RESERVATIONS_PARTNER_OFFLINE.path}
        component={require('Components/pages/public/ReservationsPartnerOffline').default}
    />,
    <Route
        key="PUBLIC_SHOP"
        exact={PUBLIC_SHOP.exact}
        path={PUBLIC_SHOP.path}
        component={require('Components/pages/public/Shop').default}
    />,
    <Route
        key="PUBLIC_SHOP_ONLINE"
        exact={PUBLIC_SHOP_ONLINE.exact}
        path={PUBLIC_SHOP_ONLINE.path}
        component={require('Components/pages/public/ShopOnline').default}
    />,
    <Route
        key="PUBLIC_SHOP_MASSAGE"
        exact={PUBLIC_SHOP_MASSAGE.exact}
        path={PUBLIC_SHOP_MASSAGE.path}
        component={require('Components/pages/public/ShopMassage').default}
    />,
    <Route
        key="PUBLIC_SHOP_STUDIO"
        exact={PUBLIC_SHOP_STUDIO.exact}
        path={PUBLIC_SHOP_STUDIO.path}
        component={require('Components/pages/public/ShopStudio').default}
    />,
    <Route
        key="PUBLIC_SHOP_OFFER"
        exact={PUBLIC_SHOP_OFFER.exact}
        path={PUBLIC_SHOP_OFFER.path}
        component={require('Components/pages/public/ShopOffer').default}
    />,
    <Route
        key="PUBLIC_SHOP_USER_OFFER"
        exact={PUBLIC_SHOP_USER_OFFER.exact}
        path={PUBLIC_SHOP_USER_OFFER.path}
        component={require('Components/pages/public/ShopUserOffer').default}
    />,
    <Route
        key="PUBLIC_SHOP_PAYMENT"
        exact={PUBLIC_SHOP_PAYMENT.exact}
        path={PUBLIC_SHOP_PAYMENT.path}
        component={require('Components/pages/public/ShopPayment').default}
    />,
    <Route 
        key="PUBLIC_SHOP_RESERVATIONS_TRAINER"
        exact={PUBLIC_SHOP_RESERVATIONS_TRAINER.exact}
        path={PUBLIC_SHOP_RESERVATIONS_TRAINER.path}
        component={require('Components/pages/public/ReservationsTrainer').default}
    />,
    <Route
        key="PUBLIC_PREFERENCES_SURVEY"
        exact={PUBLIC_PREFERENCES_SURVEY.exact}
        path={PUBLIC_PREFERENCES_SURVEY.path}
        component={require('Components/pages/public/PreferencesSurvey').default}
    />,
    <Route
        key="PUBLIC_PREFERENCES_SURVEY_REHAB"
        exact={PUBLIC_PREFERENCES_SURVEY_REHAB.exact}
        path={PUBLIC_PREFERENCES_SURVEY_REHAB.path}
        component={require('Components/pages/public/PreferencesSurveyRehab').default}
    />,
    <Route
        key="PUBLIC_USER_UPDATE_DATA"
        exact={PUBLIC_USER_UPDATE_DATA.exact}
        path={PUBLIC_USER_UPDATE_DATA.path}
        component={require('Components/pages/public/UserUpdateData').default}
    />,
    <Route
        key="PUBLIC_RESERVATION_CONFIRMATION"
        exact={PUBLIC_RESERVATION_CONFIRMATION.exact}
        path={PUBLIC_RESERVATION_CONFIRMATION.path}
        component={require('Components/pages/public/ReservationConfirmation').default}
    />,
    <Route
        key="PUBLIC_REGISTER"
        exact={PUBLIC_REGISTER.exact}
        path={PUBLIC_REGISTER.path}
        component={require('Components/pages/public/Register').default}
    />,
];

