import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    text-align: left;
    margin: .5em 0;
    display: flex;
    align-items: center;

        .label {
            font-size: .9em;
            font-weight: 300;
        }

        .required-true {
            color: red;
            font-size: 1.5em;
            margin-right: .2em;
        }

        //Styles
        &.style-default {
            color: ${variables.dpc_fontDark};
        }
        &.style-transparent {
            color: #C1C1C1;
        }
        &.style-transparentLight {
            color: white;
        }
        &.style-hollow {
            color: #E1E1E1;
            text-transform: uppercase;
        }
  
        &.style-version-2 {
            color: ${variables.dpc_fontDark};
        }

        &.style-transparentV2 {
            color: ${variables.dpc_fontDark};
            text-transform: uppercase;
        }
`;
