import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';

import { TRAINER_DASHBOARD, TRAINER_PROFILE } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import ProfileForm from 'Components/common/ProfileForm';
import PasswordChangeModal from 'Components/trainer/modals/PasswordChange';
import UserWorkHoursEditor from 'Components/trainer/userWorkHours/Editor';

import UserWorkHours from 'Models/UserWorkHours';

export const TAB_DATA = 'data';
export const TAB_USER_WORK_HOURS = 'userWorkHours';

export default class TrainerProfile extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.shape({
            email: PropTypes.string.isRequired,
            name: PropTypes.string,
            surname: PropTypes.string,
            phone: PropTypes.string,
            address: PropTypes.string,
            postalCode: PropTypes.string,
            city: PropTypes.string,
            avatar: PropTypes.string,
        }),
        actions: PropTypes.shape({
            singleUserWorkHour: PropTypes.func.isRequired, 
        }).isRequired,
    };
    static defaultProps = {};

    state = {
        changePassword: false,
    }

    render() {
        const { changePassword } = this.state;
        const { profile, history, location } = this.props;
        const queryObject = parseQueryToObject(location.search);

        return (
            <StyledComponent
                className="admin-profile-manage"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: TRAINER_DASHBOARD.path,
                        label: 'Dashboard',
                    }, {
                        to: TRAINER_PROFILE.path,
                        label: 'Profil',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Edycja profilu"
                        controls={[{
                            visible: Boolean(queryObject.tab === TAB_DATA),
                            key: 'changePassword',
                            label: 'Zmień hasło',
                            onClick: () => this.setState({ changePassword: true }),
                            style: 'gradient',
                            layout: 'wide',
                        }]}
                        tabs={[{
                            key: TAB_DATA,
                            label: 'Dane',
                            children: (
                                <ProfileForm 
                                    profile={profile} 
                                    history={history}
                                    location={location}
                                    inputStyleProps={{
                                        styleVersion: 2,
                                        border: 'light',
                                    }}
                                    submitButtonStyle='gradient'
                                    showBio={true}
                                />
                            ),
                        }, {
                            key: TAB_USER_WORK_HOURS,
                            label: 'Godziny pracy',
                            children: (
                                <UserWorkHoursEditor
                                    data={new UserWorkHours(profile.userWorkHours)} 
                                    history={history}
                                    location={location}
                                    style='transparentV2'
                                    submitButtonStyle='gradient'
                                />
                            ),
                        }]}
                    />
                    <PasswordChangeModal 
                        location={location}
                        history={history}
                        isOpen={changePassword}
                        onClose={() => this.setState({ changePassword: false })}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}