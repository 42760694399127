import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';
import { withVariables } from 'Utils/string';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_TRAINING_PLAN_PROFILES,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_TRAINING_PLAN_PROFILE, params),
        params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_TRAINING_PLAN_PROFILE, params),
        params,
        requestParams: params,
    }));
};