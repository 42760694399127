export const LIST_CONVERSATIONS = 'admin/chat/LIST_CONVERSATIONS';
export const LIST_CONVERSATION_USERS = 'admin/chat/LIST_CONVERSATION_USERS';
export const LIST_CONVERSATION_MESSAGES = 'admin/chat/LIST_CONVERSATION_MESSAGES';
export const SEND_CONVERSATION_MESSAGES = 'admin/chat/SEND_CONVERSATION_MESSAGES';
export const CREATE_CONVERSATION = 'admin/chat/CREATE_CONVERSATION';
export const ENABLE_CONVERSATION = 'admin/chat/ENABLE_CONVERSATION';
export const DISABLE_CONVERSATION = 'admin/chat/DISABLE_CONVERSATION';
export const LIST_MASS_MESSAGES = 'admin/chat/LIST_MASS_MESSAGES';
export const CREATE_MASS_MESSAGE = 'admin/chat/CREATE_MASS_MESSAGE';
export const SINGLE_MASS_MESSAGE = 'admin/chat/SINGLE_MASS_MESSAGE';
export const UPDATE_MASS_MESSAGE = 'admin/chat/UPDATE_MASS_MESSAGE';
export const DELETE_MASS_MESSAGE = 'admin/chat/DELETE_MASS_MESSAGE';

