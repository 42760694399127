export const API_RESOURCE_REDIRECT_URI = 'redirectUri';
export const API_RESOURCE_REDIRECT_URL = 'redirectUrl';

export const API_RESOURCE_STATUS = 'status';
export const API_RESOURCE_ME = 'me';

export const API_RESOURCE_LEADS = 'leads';
export const API_RESOURCE_LEAD = 'lead';
export const API_RESOURCE_LEADS_WITH_USERS = 'leadsWithUsers';

export const API_RESOURCE_USER_SURVEYS = 'userSurveys';
export const API_RESOURCE_USER_SURVEY = 'userSurvey';

export const API_RESOURCE_USERS = 'users';
export const API_RESOURCE_USER = 'user';

export const API_RESOURCE_USER_SEGMENTS = 'userSegments';
export const API_RESOURCE_USER_SEGMENT = 'userSegment';

export const API_RESOURCE_PRODUCT_CATEGORIES = 'productCategories';
export const API_RESOURCE_PRODUCT_CATEGORY = 'productCategory';

export const API_RESOURCE_PRODUCTS = 'products';
export const API_RESOURCE_PRODUCT = 'product';

export const API_RESOURCE_PAYMENT_METHODS = 'paymentMethods';
export const API_RESOURCE_PAYMENT_METHOD = 'paymentMethod';

export const API_RESOURCE_PROMO_CODES = 'promoCodes';
export const API_RESOURCE_PROMO_CODE = 'promoCode';

export const API_RESOURCE_OFFERS = 'offers';
export const API_RESOURCE_OFFER = 'offer';
export const API_RESOURCE_OFFER_PRICE = 'offerPrice';

export const API_RESOURCE_OFFER_PRODUCTS = 'offerProducts';
export const API_RESOURCE_OFFER_PRODUCT = 'offerProduct';

export const API_RESOURCE_OFFER_USPS = 'offerUsps';
export const API_RESOURCE_OFFER_USP = 'offerUsp';

export const API_RESOURCE_USER_OFFER_PRODUCTS = 'userOfferProducts';
export const API_RESOURCE_USER_OFFER_PRODUCT = 'userOfferProduct';

export const API_RESOURCE_USER_OFFER_INVOICE_ITEMS = 'userOfferInvoiceItems';
export const API_RESOURCE_USER_OFFER_INVOICE_ITEM = 'userOfferInvoiceItem';

export const API_RESOURCE_USER_OFFERS = 'userOffers';
export const API_RESOURCE_USER_OFFERS_INCOME = 'userOffersIncome';
export const API_RESOURCE_USER_OFFER = 'userOffer';
export const API_RESOURCE_USER_OFFER_TERMINATION_DATES = 'userOfferTerminationDates';
export const API_RESOURCE_USER_OFFER_LOGS = 'userOfferLogs';
export const API_RESOURCE_USER_OFFER_DIAGNOSTIC_REPORTS = 'userOfferDiagnosticReports';
export const API_RESOURCE_USER_OFFER_DIAGNOSTIC_REPORT = 'userOfferDiagnosticReport';
export const API_RESOURCE_USER_OFFER_USERS = 'userOfferUsers';

export const API_RESOURCE_PAYMENT_TRANSACTIONS = 'paymentTransactions';
export const API_RESOURCE_PAYMENT_TRANSACTION = 'paymentTransaction';

export const API_RESOURCE_PAYMENT_TRANSACTIONS_LOGS = 'paymentTransactionsLogs';

export const API_RESOURCE_SPECIALIZATIONS = 'specializations';
export const API_RESOURCE_SPECIALIZATION = 'specialization';

export const API_RESOURCE_LOCATIONS = 'locations';
export const API_RESOURCE_LOCATION = 'location';
export const API_RESOURCE_LOCATION_OCCUPANCY = 'locationOccupancy';

export const API_RESOURCE_EVENTS = 'events';
export const API_RESOURCE_EVENT = 'event';
export const API_RESOURCE_EVENTS_STATS = 'eventsStats';
export const API_RESOURCE_EVENTS_HISTORY = 'eventHistoryList';
export const API_RESOURCE_EVENTS_FREE_SLOTS = 'eventsFreeSlots';
export const API_RESOURCE_EVENTS_FREE_SLOTS_BY_SCORE = 'eventsFreeSlotsByScore';

export const API_RESOURCE_MEASUREMENTS_LIST = 'measurementsList';
export const API_RESOURCE_MEASUREMENTS = 'measurements';
export const API_RESOURCE_MEASUREMENT = 'measurement';
export const API_RESOURCE_MEASUREMENTS_USER_CONFIG = 'measuremenstUserConfig';
export const API_RESOURCE_MEASUREMENTS_PROGRESS = 'measurementsProgress';
export const API_RESOURCE_MEASUREMENTS_CHART_DATA = 'measurementsChartData';

export const API_RESOURCE_MEDICAL_CARD = 'medicalCard';
export const API_RESOURCE_MEDICAL_CARDS = 'medicalCards';
export const API_RESOURCE_MEDICAL_QUESTIONS = 'medicalQuestions';

export const API_RESOURCE_CHAT_CONVERSATIONS = 'chatConversations';
export const API_RESOURCE_CHAT_CONVERSATION = 'chatConversation';
export const API_RESOURCE_CHAT_CONVERSATION_USERS = 'chatConversationUsers';
export const API_RESOURCE_CHAT_MESSAGES = 'chatMessages';

export const API_RESOURCE_CHAT_MASS_MESSAGES = 'chatMassMessages';
export const API_RESOURCE_CHAT_MASS_MESSAGE = 'chatMassMessage';

export const API_RESOURCE_ORDERS = 'orders';
export const API_RESOURCE_ORDER = 'order';

export const API_RESOURCE_PAYMENTS = 'payments';

export const API_RESOURCE_NOTIFICATION_EMAILS = 'notificationEmails';
export const API_RESOURCE_NOTIFICATION_TEXT_MESSAGES = 'notificationTextMessages';

export const API_RESOURCE_STAFF_DATA_LIST = 'staffDataList';
export const API_RESOURCE_STAFF_DATA = 'staffData';

export const API_RESOURCE_CITIES = 'cities';
export const API_RESOURCE_GYMS = 'gyms';

export const API_RESOURCE_TRAINING_TARGET = 'trainingTarget';
export const API_RESOURCE_TRAINING_TARGETS = 'trainingTargets';

export const API_RESOURCE_TRAINING_TARGET_CATEGORY = 'trainingTargetCategory';
export const API_RESOURCE_TRAINING_TARGET_CATEGORIES = 'trainingTargetCategories';

export const API_RESOURCE_ADEPT_TESTS = 'adeptTests';
export const API_RESOURCE_ADEPT_TEST = 'adeptTest';

export const API_RESOURCE_ACTIVITY_LOGS = 'activityLogs';
export const API_RESOURCE_ACTIVITY_LOG = 'activityLog';

export const API_RESOURCE_CONTACTS = 'contacts';
export const API_RESOURCE_CONTACT = 'contact';

export const API_RESOURCE_INVOICING_STRATEGIES = 'invoicingStrategies';

export const API_RESOURCE_PAYU_SINGLE_PAYMENT_URI = 'payuSinglePaymentUri'; 

export const API_RESOURCE_USER_WORK_HOURS_LIST = 'userWorkHoursList';
export const API_RESOURCE_USER_WORK_HOURS = 'userWorkHours';

export const API_RESOURCE_NOTIFICATIONS_INTERNAL = 'notificationsInternal';

export const API_RESOURCE_USER_NOTES = 'userNotes';

export const API_RESOURCE_GR_HISTORY_LOGS = 'grHistoryLogs';
export const API_RESOURCE_GR_HISTORY_LOG = 'grHistoryLog';

export const API_RESOURCE_USER_PRODUCTS = 'userProducts';
export const API_RESOURCE_USER_PRODUCT = 'userProduct';

export const API_RESOURCE_USER_COMMENTS = 'userComments';
export const API_RESOURCE_USER_COMMENT = 'userComment';

export const API_RESOURCE_IS_TOKEN_ACTIVE = 'isTokenActive';

export const API_RESOURCE_USER_HOMEWORKS = 'userHomeworks';
export const API_RESOURCE_USER_HOMEWORK = 'userHomework';

export const API_RESOURCE_CYCLIC_EVENTS = 'cyclicEvents';
export const API_RESOURCE_CYCLIC_EVENT = 'cyclicEvent';

export const API_RESOURCE_SHOP_PAGES = 'shopPages';
export const API_RESOURCE_SHOP_PAGE = 'shopPage';

export const API_RESOURCE_USER_EFFECTS = 'userEffects';
export const API_RESOURCE_USER_EFFECT = 'userEffect';

export const API_RESOURCE_REPORTS = 'reports';

export const API_RESOURCE_TP_SURVEYS = 'tpSurveys';
export const API_RESOURCE_TP_SURVEY = 'tpSurvey';

export const API_RESOURCE_TP_PROFILES = 'tpProfiles';
export const API_RESOURCE_TP_PROFILE = 'tpProfile';

export const API_RESOURCE_ARTICLES = 'articles';
export const API_RESOURCE_ARTICLE = 'article';

export const API_RESOURCE_ARTICLE_TAGS = 'articleTags';
export const API_RESOURCE_ARTICLE_TAG = 'articleTag';

export const API_RESOURCE_ARTICLE_COMMENTS = 'articleComments';
export const API_RESOURCE_ARTICLE_COMMENT = 'articleComment';

export const API_RESOURCE_ARTICLE_CATEGORIES = 'articleCategories';
export const API_RESOURCE_ARTICLE_CATEGORY = 'articleCategory';

export const API_RESOURCE_CUSTOM_EVENTS = 'customEvents';
export const API_RESOURCE_CUSTOM_EVENT = 'customEvent';

export const API_RESOURCE_CUSTOM_EVENT_USPS = 'customEventUsps';
export const API_RESOURCE_CUSTOM_EVENT_USP = 'customEventUsp';

export const API_RESOURCE_CUSTOM_EVENT_POP_UPS = 'customEventPopUps';
export const API_RESOURCE_CUSTOM_EVENT_POP_UP = 'customEventPopUp';
