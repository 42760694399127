import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    min-height: 80vh;
    position: relative;

    > .component-spinner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 80vh;

        .spinner {
            position: relative;
            top: 0;
            left: 0;
        }
    }
`;
