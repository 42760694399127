import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    color: ${variables.dpc_fontDark};
    width: 100%;

    .admin-element-editor {
        width: 100%;
        .editor-wrapper {
            display: contents !important;

            .forms-wrapper {
                width: 100% !important;

                .form-elements {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .form-element {
                        width: 31%;
                        margin-right: 1em;
                    }
                }
            }
        }
    }
`;
