import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    background-color: ${variables.dpc_backgroundWhite};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    .page-container {
        position: relative;
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
    }

    .promo-info {
        padding: 15px 0px 15px;
        width: 100%;
        background: #347deb;
        color: white;
        text-align: center;

        .promo-link {
            text-decoration: underline;
            font-weight: 600;
        }
    }
`;
