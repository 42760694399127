import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        position: relative;
        margin-bottom: 1em;

        .input-internal-wrapper {
            border: none;
            background-color: none;
            border-radius: 0;
        }

        .dropzone-container {
            text-align: center;
            background: #EEE;
            border: 3px dashed #CCC;

            .dropzone-internal {
                width: 100%;
                padding: 3em;
            }
        }

        .upload-queue {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 2em;

            .upload-queue-elem {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #CCC;
                padding-bottom: 1em;
                position: relative;
                min-height: 3em;
                padding: 2em;

                .upload-queue-elem-details {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .upload-queue-elem-image-container {
                        min-width: 15em;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .upload-queue-elem-image {
                            width: auto;
                            margin: 0 auto;
                            max-width: 15em;
                            max-height: 15em;
                        }

                        svg {
                            font-size: 4em;
                            color: #333;
                        }
                    }

                    .upload-queue-elem-filename {
                        margin-left: 1em;
                        word-break: break-all;
                        text-align: left;
                    }
                }

                .upload-queue-elem-controls {
                    margin-left: 1em;

                    .upload-queue-elem-control {
                        display: block;
                        text-align: center;
                        padding: .5em 0;
                        margin: .5em 0;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }

                &:last-of-type {
                    border-bottom: none;
                }
            }
        }

        &.style-transparent {
            .dropzone-container {
                background: transparent;
                border: .1em solid #3E3E3E;

                .dropzone-internal {
                    .info {
                        .dropzone-label {
                            color: #eee;
                        }
                    }
                }
            }

            .upload-queue {
                .upload-queue-elem {
                    border-color: #3E3E3E;

                    .upload-queue-elem-details {
                        .upload-queue-elem-filename {
                            color: #eee;
                        }

                        .upload-queue-elem-image-container {
                            svg {
                                color: #eee;
                            }
                        }
                    }

                    .upload-queue-elem-controls {
                        .upload-queue-elem-control {
                            color: #eee;
                        }
                    }
                }
            }
        }
    `;