import { reduxRequest as request } from 'Services/Api';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_TRAINER_ARTICLES,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            status: fromSelectObject(params.status) || undefined,
            articleTagId: params.articleTagId || undefined,
            assigned: fromSelectObject(params.assigned) || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_ARTICLE, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_ARTICLES, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_TRAINER_ARTICLE, params),
        params,
        requestParams: params,
    }));
};

export const remove = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_TRAINER_ARTICLE, params),
        params,
        requestParams: params,
    }));
};

export const presignFile = dispatch => (params = {}) => {
    return dispatch(request({
        method: 'POST',
        reduxType: types.IMAGE_PRESIGN,
        path: params.presignPath,
        timeout: 360000,
        requestParams: {
            file: {
                type: params.file.type,
                name: params.file.name,
                size: params.file.size,
            },
        },
    }));
};