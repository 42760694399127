import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Logger from 'Services/Logger';

import {
    CONVERSATION_ROLE_TRAINER,
    CONVERSATION_ROLE_DIET,
    CONVERSATION_ROLE_BOK,
    CONVERSATION_ROLE_TECH,
    CONVERSATION_ROLE_ADEPT,
    CONVERSATION_ROLES,
    CONVERSATION_ROLE_PHYSIO,
} from 'Consts/chat';
import {
    USER_ROLE_ADEPT,
    USER_ROLE_TRAINER,
    USER_ROLE_ADMIN,
} from 'Consts/userRoles';

import { parseToQueryString, parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layoutAdmin/modals/ModalWrapper';
import ModalBody from 'Components/layoutAdmin/modals/ModalBody';
import ModalTitle from 'Components/layoutAdmin/modals/ModalTitle';
import Form from 'Components/forms/Form';
import Button from 'Components/layoutAdmin/Button';
import MultiSelect from 'Components/forms/MultiSelect';

export default class AdminModalConversationCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listUsers: PropTypes.func.isRequired,
            createConversation: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {};
    state = {
        globalError: null,
        errors: {},
        isPending: false,
        formState: {
            companyUserRole: {
                value: CONVERSATION_ROLE_TRAINER,
                label: 'Trener',
            },
        },
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { formState } = this.state;

        if (prevState.formState.companyUserRole !== formState.companyUserRole) {
            this.loadUsers('', formState.companyUserRole);
        }
    }

    loadUsers = (search = '', role) => {
        const { actions } = this.props;

        let userRole = undefined;
        if (role === CONVERSATION_ROLE_TRAINER) userRole = USER_ROLE_TRAINER;
        if (role === CONVERSATION_ROLE_DIET) userRole = USER_ROLE_ADMIN;
        if (role === CONVERSATION_ROLE_BOK) userRole = USER_ROLE_ADMIN;
        if (role === CONVERSATION_ROLE_TECH) userRole = USER_ROLE_ADMIN;
        if (role === CONVERSATION_ROLE_ADEPT) userRole = USER_ROLE_ADEPT;
        if (role === CONVERSATION_ROLE_PHYSIO) userRole = USER_ROLE_TRAINER;

        return actions.listUsers({
            page: 1,
            perPage: 100,
            search,
            role: userRole,
            enabled: true,
        });
    }

    onCreate = () => {
        const { formState } = this.state;
        const { actions, history, location } = this.props;

        this.setState({
            isPending: true,
            errors: {},
            globalError: null,
        });

        return actions.createConversation({
            role: formState.companyUserRole && formState.companyUserRole.value,
            userIds: [
                formState.companyUserId.value,
                formState.adeptId.value,
            ],
        })
            .then(response => {
                const { search } = location;
                const queryObject = parseQueryToObject(search);

                this.setState({ isPending: false });
                history.push(parseToQueryString(location.pathname, { ...queryObject, active: '', timestamp: Date.now() }));
            })
            .catch(error => {
                Logger.error('[CREATE_CONVERSATION_FORM] Error', error);

                this.setState({
                    isPending: false,
                    errors: {
                        ...error.payload.validationErrors,
                        adeptId: error.payload.validationErrors.userIds || error.payload.validationErrors.adeptId,
                        companyUserRole: error.payload.validationErrors.role || error.payload.validationErrors.companyUserRole,
                    },
                    globalError: error.payload.message,
                });
            });
    }

    onStateChange = (name, value) =>
        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                [name]: value,
            },
        }))

    render() {
        const { location, history } = this.props;
        const { formState, errors, globalError, isPending } = this.state;

        return (
            <ModalWrapper
                location={location}
                history={history}
            >
                <StyledComponent className="admin-modal-conversation-create" styles={require('./styles')}>
                    <ModalBody>
                        <ModalTitle title="Nowy kanał chatu" />
                        <Form
                            className="conversation-create-form"
                            data={formState}
                            onStateChange={this.onStateChange}
                            errors={errors}
                            globalError={globalError}
                            onSubmit={this.onCreate}
                            isPending={isPending}
                        >
                            <div className="company-user-container">
                                <MultiSelect
                                    name="companyUserRole"
                                    label="Wybierz rolę"
                                    placeholder="Wybierz rolę"
                                    options={CONVERSATION_ROLES.map(role => ({
                                        value: role.key,
                                        label: role.label,
                                    }))}
                                />
                                <MultiSelect
                                    name="companyUserId"
                                    label="Szukaj użytownika"
                                    placeholder="Szukaj użytownika"
                                    required={true}
                                    dynamic={true}
                                    onLoad={query => this.loadUsers(query, formState.companyUserRole?.value)}
                                    onMapResponse={response => response.payload.users.elements}
                                    loadOnOnLoadChange={true}
                                    onMapOption={user => ({
                                        value: user.id,
                                        label: `${user.surname} ${user.name} (${user.email})`,
                                    })}
                                />
                            </div>
                            <div className="adept-container">
                                <MultiSelect
                                    name="adeptId"
                                    label="Szukaj adepta"
                                    placeholder="Szukaj adepta"
                                    required={true}
                                    dynamic={true}
                                    onLoad={query => this.loadUsers(query, USER_ROLE_ADEPT)}
                                    onMapResponse={response => response.payload.users.elements}
                                    loadOnOnLoadChange={true}
                                    onMapOption={user => ({
                                        value: user.id,
                                        label: `${user.surname} ${user.name} (${user.email})`,
                                    })}
                                />
                            </div>
                            <Button
                                className="submit-button"
                                type="submit"
                                icon={{ type: 'fa', source: 'fa fa-plus' }}
                                layout="fullWidth"
                                size="large"
                            >
                                Nowy kanał
                            </Button>
                        </Form>
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
