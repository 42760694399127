import { connect } from 'react-redux';

import Component from './component';
 
export default connect(
    state => ({
        profile: state.user.profile,
        events: state.adeptEvents.list,
    }),
    dispatch => ({}),
)(Component);