import moment from 'moment';

export default class UserOffer {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.updatedAt = data.updatedAt && moment.utc(data.updatedAt).local();
        this.name = data.name;
        this.status = data.status;
        this.periodType = data.periodType;
        this.paidTo = data.paidTo && moment.utc(data.paidTo).local();
        this.activeTo = data.activeTo && moment.utc(data.activeTo).local();
        this.terminatedTo = data.terminatedTo && moment.utc(data.terminatedTo).local();
        this.totalPriceGross = data.totalPriceGross
        this.offer = data.offer;
        this.promoCode = data.promoCode;
        this.products = data.products;
        this.firstPayment = data.firstPayment;
        this.terminateReason = data.terminateReason;
        this.productCategory = data.productCategory;
        this.usersType = data.usersType;
        this.usersLimit = data.usersLimit;
        this.subUsers = data.subUsers;
        this.isOwner = data.isOwner;
        this.lead = data.lead;
        this.user = data.user;
        this.openTo = data.openTo && moment.utc(data.openTo).local();
        this.canBePaid = data.canBePaid;
        this.terminatedAt = data.terminatedAt && moment.utc(data.terminatedAt).local();
        this.closedAt = data.closedAt && moment.utc(data.closedAt).local();
        this.closeReason = data.closeReason;
        this.deleteReason = data.deleteReason;
        this.durationType = data.durationType;
        this.durationValue = data.durationValue;
        this.noticePeriodDurationType = data.noticePeriodDurationType;
        this.noticePeriodDurationValue = data.noticePeriodDurationValue;
        this.vatOverride = data.vatOverride;
        this.medicalPackage = data.medicalPackage;
        this.invoicingStrategy = data.invoicingStrategy;
        this.migratedOrder = data.migratedOrder;
        this.notes = data.notes;
        this.comment = data.comment;
        this.activeToRollback = data.activeToRollback && moment.utc(data.activeToRollback).local();
    };
}