import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import { getProfile } from 'Redux/modules/user/actions';

export default connect(
    state => ({
        authToken: state.user.authToken,
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            getProfile: dispatch(getProfile),
        },
    }),
)(
    loadable(() => import(
        /* webpackChunkName: "page-public-Shop" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);