import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class ModalBodyComponent extends Component {
    static propTypes = {
        children: PropTypes.any,
        styleVariant: PropTypes.oneOf(['default', 'dark', 'darkV2']),
    };
    static defaultProps = {
        children: null,
        styleVariant: 'default',
    };

    render() {
        const { children, styleVariant } = this.props;

        return (
            <StyledComponent 
                className={classnames({
                    'modal-body-component' : true,
                    [`style-${styleVariant}`]: true,
                })}
                styles={require('./styles')}
            >
                {children}
            </StyledComponent>
        );
    }
}
