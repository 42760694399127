import { connect } from 'react-redux';

import { updateInvitedUser } from 'Redux/modules/public/users/actions';
import { passwordTokenVerify } from 'Redux/modules/user/actions';

import Component from './component';

export default connect(
    state => ({
        utmData: state.user.utm,
    }),
    dispatch => ({
        actions: {
            updateInvitedUser: dispatch(updateInvitedUser),
            passwordTokenVerify: dispatch(passwordTokenVerify),
        },
    })
)(Component);

