import moment from 'moment';

export default class ArticleTag {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.name = data.name;
        this.enabled = data.enabled;
        this.slug = data.slug;
    };
}