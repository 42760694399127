import React from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class PasswordChangeForm extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            passwordChange: PropTypes.func.isRequired,
        }),
        submitButtonStyle: PropTypes.oneOf([
            'faPrimary', 'hollow',
        ]),
        onConfirm: PropTypes.func,
        headline: PropTypes.string,
    };
    static defaultProps = {
        style: 'default',
        submitButtonStyle: 'faPrimary',
        headline: null,
    };

    state = {
        formState: {
            //eslint-disable-next-line
            ...this.props.profile,
        },
    }

    onSubmit = data => {
        const { actions, onConfirm } = this.props;

        return actions.passwordChange(data)
            .then(response => {
                if (onConfirm) onConfirm();
            });
    }

    render() {
        const { history, location, submitButtonStyle, headline } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent styles={require('./styles')} className='password-change'>
                {headline && (
                    <div className="headline">
                        <a>{headline}</a>
                    </div>
                )}
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        name: 'password-change',
                        submitAction: this.onSubmit,
                        submitButtonStyle,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'password',
                            label: 'Hasło',
                            inputProps: {
                                styleVersion: 2,
                                type: 'password',
                            },
                            required: true,
                        }, {
                            type: 'input',
                            name: 'passwordConfirm',
                            label: 'Potwierdź hasło',
                            inputProps: {
                                styleVersion: 2,
                                type: 'password',
                            },
                            required: true,
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}