import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        position: relative;
        margin-bottom: 1em;

        .file-preview {
            display: flex;
            
            img {
                height: 30em;
                width: auto;
            }
        }

        .dropzone-container {
            text-align: center;
            background: #EEE;
            border: 3px dashed #CCC;

            .dropzone-internal {
                width: 100%;
                padding: 1em;
                min-height: 6em;
                display: flex;
                justify-content: center;
                align-items: center;

                .dropzone-label {}
                .dropzone-progress {
                        width: 50%;
                        text-align: center;
                        font-weight: 600;
                    }
            }
        }

        &.style-version-2 {
            .input-internal-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .file-preview {
                position: relative;
                display: flex;
                width: 20em;
                height: 20em;
                overflow: hidden;
                border-radius: 50%;
                border: 1px solid ${variables.dpc_backgroundWhite};
                margin-bottom: 1em;
                box-shadow: 0px 3px 6px #00000029;

                img {
                    position: absolute;
                    width: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .dropzone-container {
                text-align: center;
                background: transparent;
                border: 2px dashed #3E3E3E;
                color: ${variables.dpc_fontDark};
                width: 100%;

                .dropzone-internal {
                    width: 100%;
                    padding: 1em;
                    min-height: 6em;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .dropzone-label {}
                    .dropzone-progress {
                            width: 50%;
                            text-align: center;
                            font-weight: 600;
                        }
                }
            }
        }
    `;
