import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from 'Utils/date';
import { withVariables } from 'Utils/string';
import { getStatusLabel } from 'Utils/articleComments';
import { filterKeys } from 'Utils/object';
import { parseQueryToObject } from 'Utils/querystring';

import { ADMIN_ARTICLE_COMMENT_MANAGE } from 'Consts/routes';
import { 
    ARTICLE_COMMENT_STATUSES,
    STATUS_APPROVED,
    STATUS_NOT_APPROVED,
} from 'Consts/articleComments';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelArticleCommentsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listComments: PropTypes.func.isRequired,
            changeStatus: PropTypes.func.isRequired,
        }).isRequired,
        onMapAdditionals: PropTypes.func,
        onMapControls: PropTypes.func,
        onMapFilters: PropTypes.func,
        articlesComments: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        onMapAdditionals: (elem, value) => value,
        onMapControls: (elem, value) => value,
        onMapFilters: elems => elems,
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        timestamp: undefined,
        status: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }
    }

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.listComments({ ...queryObject });
        }, 500);
    }

    render() {
        const {
            location,
            history,
            articlesComments,
            onMapAdditionals,
            onMapControls,
            onMapFilters,
            actions,
        } = this.props;
      
        return (
            <StyledComponent
                className="panel-articles-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={articlesComments}
                    title="Lista komentarzy"
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.comment.substring(0, 100) + '...'}
                            controls={onMapControls(element, [{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_ARTICLE_COMMENT_MANAGE.path, { id: element.id }),
                            }, {
                                type: 'button',
                                label: 'Zatwierdź',
                                visible: element.status !== STATUS_APPROVED,
                                onClick: () => actions.changeStatus({
                                    id: element.id,
                                    status: STATUS_APPROVED
                                })
                            }, {
                                type: 'button',
                                label: 'Niezatwierdzony',
                                visible: element.status !== STATUS_NOT_APPROVED,
                                onClick: () => actions.changeStatus({
                                    id: element.id,
                                    status: STATUS_NOT_APPROVED
                                })
                            }])}
                            additionals={onMapAdditionals(element, [{
                                visible: true,
                                name: 'Data utworznenia',
                                value: getFormattedDate(element.createdAt),
                            }, {
                                visible: true,
                                name: 'Autor',
                                value: element.author
                            }, {
                                visible: Boolean(element.status),
                                name: 'Status',
                                value: getStatusLabel(element).label
                            }])}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'status',
                        label: 'Status',
                        type: 'select',
                        inputProps: {
                            options: [
                                {
                                    label: 'Wszystkie',
                                    value: '',
                                }, 
                                ...ARTICLE_COMMENT_STATUSES.map(option => ({ ...option, value: option.key })),
                            ],
                        },
                    }])}
                />
            </StyledComponent>
        );
    }
}
