import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { PUBLIC_HOME } from 'Consts/routes';
import { FITADEPT_REGULATIONS } from 'Consts/externalUrls';

import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';

const Footer = () => {
    const [ infoExpanded, setInfoExpanded ] = useState(false);
    const shortInfo = 'Właścicielem i administratorem serwisu FitAdept, świadczącym za jego pośrednictwem usługi jest spółka FitAdept sp. z o.o.';
    const longInfo = 'Właścicielem i administratorem serwisu FitAdept, świadczącym za jego pośrednictwem usługi jest spółka FitAdept sp. z o.o. z siedzibą w Warszawie przy Al. Jana Pawła II 27, 00-867, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem 0000622972, NIP 5223065447, REGON 364725646, kapitał zakładowy w wysokości 116.350,00 zł. Postanowienia Regulaminu wraz z przepisami prawa obowiązującego na terytorium Rzeczypospolitej Polskiej, w sposób wyłączny i wyczerpujący określają prawa i obowiązki Użytkowników Serwisu.';

    return (
        <StyledComponent className='footer-public' styles={require('./styles')}>
            <LayoutContainer>
                <div className="logo">
                    <NavLink exact to={PUBLIC_HOME.path}>
                        <img src={require('Theme/images/logo-color.png')} />
                    </NavLink>
                </div>
                <nav className="nav">
                    <div className="nav-item">
                        <a href={FITADEPT_REGULATIONS} target="_blank" rel="noopener noreferrer">Regulamin</a>
                    </div>
                </nav>
            </LayoutContainer>
            <div className="info">
                <LayoutContainer>
                    <div className="info-text">
                        {!infoExpanded && shortInfo}
                        {!infoExpanded && (
                            <a 
                                className="expand-trigger"
                                onClick={() => setInfoExpanded(true)}
                            >
                                Więcej
                            </a>
                        )}
                        {infoExpanded && longInfo}
                    </div>
                </LayoutContainer>
            </div>
        </StyledComponent>
    );
};

export default Footer;
