import React from 'react';
import { Route } from 'react-router-dom';

import {
    PUBLIC_USER_LOGIN,
    PUBLIC_USER_ACTIVATE,
    PUBLIC_USER_PASSWORD_RESET,
    PUBLIC_USER_PASSWORD_CHANGE,
} from 'Consts/routes';

export default [
    <Route
        key="PUBLIC_LOGIN"
        exact={PUBLIC_USER_LOGIN.exact}
        path={PUBLIC_USER_LOGIN.path}
        component={require('Components/pages/publicUser/Login').default}
    />,
    <Route
        key="PUBLIC_USER_ACTIVATE"
        exact={PUBLIC_USER_ACTIVATE.exact}
        path={PUBLIC_USER_ACTIVATE.path}
        component={require('Components/pages/publicUser/Activate').default}
    />,
    <Route
        key="PUBLIC_PASSWORD_RESET"
        exact={PUBLIC_USER_PASSWORD_RESET.exact}
        path={PUBLIC_USER_PASSWORD_RESET.path}
        component={require('Components/pages/publicUser/PasswordReset').default}
    />,
    <Route
        key="PUBLIC_USER_PASSWORD_CHANGE"
        exact={PUBLIC_USER_PASSWORD_CHANGE.exact}
        path={PUBLIC_USER_PASSWORD_CHANGE.path}
        component={require('Components/pages/publicUser/PasswordChange').default}
    />,
];
