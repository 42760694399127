import React from 'react';
import { Route } from 'react-router-dom';

import {
    TRAINER_DASHBOARD,
    TRAINER_PROFILE,
    TRAINER_ADEPTS,
    TRAINER_ADEPTS_MANAGE,
    TRAINER_EVENTS,
    TRAINER_EVENTS_CREATE,
    TRAINER_EVENTS_MANAGE,
    TRAINER_MEASUREMENTS_CREATE,
    TRAINER_MEASUREMENTS_MANAGE,
    TRAINER_TRAINING_TARGETS_CREATE,
    TRAINER_TRAINING_TARGETS_MANAGE,
    TRAINER_ADEPT_TESTS_CREATE,
    TRAINER_ADEPT_TESTS_MANAGE,
    TRAINER_MEDICAL_CARD_CREATE,
    TRAINER_USER_OFFER,
    TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE,
    TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE,
    TRAINER_MEASUREMENTS_CONFIG_MANAGE,
    TRAINER_USER_SURVEY_MANAGE,
    TRAINER_USER_HOMEWORK_CREATE,
    TRAINER_USER_HOMEWORK_MANAGE,
    TRAINER_EVENT_CYCLE,
    TRAINER_CHAT,
    TRAINER_EVENT_MEET,
    TRAINER_USER_EFFECT_CREATE,
    TRAINER_USER_EFFECT_MANAGE,
    TRAINER_BLOG,
    TRAINER_ARTICLE_CREATE,
    TRAINER_ARTICLE_MANAGE,
} from 'Consts/routes';

export default [
    <Route
        key="TRAINER_DASHBOARD"
        exact={TRAINER_DASHBOARD.exact}
        path={TRAINER_DASHBOARD.path}
        component={require('Components/pages/trainer/Dashboard').default}
    />,
    <Route
        key="TRAINER_PROFILE"
        exact={TRAINER_PROFILE.exact}
        path={TRAINER_PROFILE.path}
        component={require('Components/pages/trainer/Profile').default}
    />,
    <Route
        key="TRAINER_ADEPTS"
        exact={TRAINER_ADEPTS.exact}
        path={TRAINER_ADEPTS.path}
        component={require('Components/pages/trainer/Users').default}
    />,
    <Route
        key="TRAINER_ADEPTS_MANAGE"
        exact={TRAINER_ADEPTS_MANAGE.exact}
        path={TRAINER_ADEPTS_MANAGE.path}
        component={require('Components/pages/trainer/UsersManage').default}
    />,
    <Route
        key="TRAINER_EVENTS"
        exact={TRAINER_EVENTS.exact}
        path={TRAINER_EVENTS.path}
        component={require('Components/pages/trainer/Events').default}
    />,
    <Route
        key="TRAINER_EVENTS_CREATE"
        exact={TRAINER_EVENTS_CREATE.exact}
        path={TRAINER_EVENTS_CREATE.path}
        component={require('Components/pages/trainer/EventsCreate').default}
    />,
    <Route
        key="TRAINER_EVENTS_MANAGE"
        exact={TRAINER_EVENTS_MANAGE.exact}
        path={TRAINER_EVENTS_MANAGE.path}
        component={require('Components/pages/trainer/EventsManage').default}
    />,
    <Route
        key="TRAINER_EVENT_MEET"
        exact={TRAINER_EVENT_MEET.exact}
        path={TRAINER_EVENT_MEET.path}
        component={require('Components/pages/trainer/EventMeet').default}
    />,
    <Route
        key="TRAINER_MEASUREMENTS_CONFIG_MANAGE"
        exact={TRAINER_MEASUREMENTS_CONFIG_MANAGE.exact}
        path={TRAINER_MEASUREMENTS_CONFIG_MANAGE.path}
        component={require('Components/pages/trainer/MeasurementsConfigManage').default}
    />,
    <Route
        key="TRAINER_MEASUREMENTS_CREATE"
        exact={TRAINER_MEASUREMENTS_CREATE.exact}
        path={TRAINER_MEASUREMENTS_CREATE.path}
        component={require('Components/pages/trainer/MeasurementsCreate').default}
    />,
    <Route
        key="TRAINER_MEASUREMENTS_MANAGE"
        exact={TRAINER_MEASUREMENTS_MANAGE.exact}
        path={TRAINER_MEASUREMENTS_MANAGE.path}
        component={require('Components/pages/trainer/MeasurementsManage').default}
    />,
    <Route
        key="TRAINER_TRAINING_TARGETS_CREATE"
        exact={TRAINER_TRAINING_TARGETS_CREATE.exact}
        path={TRAINER_TRAINING_TARGETS_CREATE.path}
        component={require('Components/pages/trainer/TrainingTargetsCreate').default}
    />,
    <Route
        key="TRAINER_TRAINING_TARGETS_MANAGE"
        exact={TRAINER_TRAINING_TARGETS_MANAGE.exact}
        path={TRAINER_TRAINING_TARGETS_MANAGE.path}
        component={require('Components/pages/trainer/TrainingTargetsManage').default}
    />,
    <Route
        key="TRAINER_ADEPT_TESTS_CREATE"
        exact={TRAINER_ADEPT_TESTS_CREATE.exact}
        path={TRAINER_ADEPT_TESTS_CREATE.path}
        component={require('Components/pages/trainer/AdeptTestsCreate').default}
    />,
    <Route
        key="TRAINER_ADEPT_TESTS_MANAGE"
        exact={TRAINER_ADEPT_TESTS_MANAGE.exact}
        path={TRAINER_ADEPT_TESTS_MANAGE.path}
        component={require('Components/pages/trainer/AdeptTestsManage').default}
    />,
    <Route
        key="TRAINER_ADEPT_MEDICAL_DATA_CREATE"
        exact={TRAINER_MEDICAL_CARD_CREATE.exact}
        path={TRAINER_MEDICAL_CARD_CREATE.path}
        component={require('Components/pages/trainer/AdeptDataMedicalCardCreate').default}
    />,
    <Route
        key="TRAINER_USER_OFFER"
        exact={TRAINER_USER_OFFER.exact}
        path={TRAINER_USER_OFFER.path}
        component={require('Components/pages/trainer/UserOffer').default}
    />,
    <Route
        key="TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE"
        exact={TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE.exact}
        path={TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE.path}
        component={require('Components/pages/trainer/DiagnosticReportCreate').default}
    />,
    <Route
        key="TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE"
        exact={TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE.exact}
        path={TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE.path}
        component={require('Components/pages/trainer/DiagnosticReportManage').default}
    />,
    <Route
        key="TRAINER_USER_SURVEY_MANAGE"
        exact={TRAINER_USER_SURVEY_MANAGE.exact}
        path={TRAINER_USER_SURVEY_MANAGE.path}
        component={require('Components/pages/trainer/UserSurveyManage').default}
    />,
    <Route
        key="TRAINER_USER_HOMEWORK_CREATE"
        exact={TRAINER_USER_HOMEWORK_CREATE.exact}
        path={TRAINER_USER_HOMEWORK_CREATE.path}
        component={require('Components/pages/trainer/UserHomeworkCreate').default}
    />,
    <Route
        key="TRAINER_USER_HOMEWORK_MANAGE"
        exact={TRAINER_USER_HOMEWORK_MANAGE.exact}
        path={TRAINER_USER_HOMEWORK_MANAGE.path}
        component={require('Components/pages/trainer/UserHomeworkManage').default}
    />,
    <Route
        key="TRAINER_EVENT_CYCLE"
        exact={TRAINER_EVENT_CYCLE.exact}
        path={TRAINER_EVENT_CYCLE.path}
        component={require('Components/pages/trainer/EventCycle').default}
    />,
    <Route
        key="TRAINER_CHAT"
        exact={TRAINER_CHAT.exact}
        path={TRAINER_CHAT.path}
        component={require('Components/pages/trainer/Chat').default}
    />,
    <Route
        key="TRAINER_USER_EFFECT_CREATE"
        exact={TRAINER_USER_EFFECT_CREATE.exact}
        path={TRAINER_USER_EFFECT_CREATE.path}
        component={require('Components/pages/trainer/UserEffectCreate').default}
    />,
    <Route
        key="TRAINER_USER_EFFECT_MANAGE"
        exact={TRAINER_USER_EFFECT_MANAGE.exact}
        path={TRAINER_USER_EFFECT_MANAGE.path}
        component={require('Components/pages/trainer/UserEffectManage').default}
    />,
    <Route
        key="TRAINER_BLOG"
        exact={TRAINER_BLOG.exact}
        path={TRAINER_BLOG.path}
        component={require('Components/pages/trainer/Blog').default}
    />,
    <Route
        key="TRAINER_ARTICLE_CREATE"
        exact={TRAINER_ARTICLE_CREATE.exact}
        path={TRAINER_ARTICLE_CREATE.path}
        component={require('Components/pages/trainer/Blog/ArticleCreate').default}
    />,
    <Route
        key="TRAINER_ARTICLE_MANAGE"
        exact={TRAINER_ARTICLE_MANAGE.exact}
        path={TRAINER_ARTICLE_MANAGE.path}
        component={require('Components/pages/trainer/Blog/ArticleManage').default}
    />,
];
