import createStore from './createStore';
import { isBrowser } from 'Utils/browser';

export default function (initialState = {}, req = null) {
    if(req && req.store) return req.store;
    if(isBrowser() && window.store) return window.store;
    
    const store = createStore(initialState);

    if(req) req.store = store;
    if(isBrowser()) window.store = store;
    
    return store;
}
