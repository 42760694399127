import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import { ReactSVG } from 'react-svg';

import {
    checkLocation,
} from 'Utils/language';

import {
    PUBLIC_HOME,
    PUBLIC_USER_LOGIN,
    FITADEPT_PAGE,
    PUBLIC_SHOP,
    PUBLIC_RESERVATIONS_MASSAGE_RC,
    PUBLIC_RESERVATIONS_MASSAGE_SOBIESKI,
    PUBLIC_RESERVATIONS_REHABILITATION_SOBIESKI,
    PUBLIC_RESERVATIONS_REHABILITATION_ONLINE,
    PUBLIC_RESERVATIONS_MASSAGE_RC_NOW,
    PUBLIC_SHOP_ONLINE,
    PUBLIC_SHOP_STUDIO,
    PUBLIC_SHOP_MASSAGE,
} from 'Consts/routes';

import Button from 'Components/layout/Button';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Avatar from 'Components/layout/Avatar';

export default class Topbar extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        errorPage: PropTypes.bool,
        profile: PropTypes.object,
        profilePath: PropTypes.string,
        actions: PropTypes.shape({
            logout: PropTypes.func.isRequired,
            changeLanguage: PropTypes.func.isRequired,
        }),
        currentLanguage: PropTypes.string,
    };
    static defaultProps = {
        errorPage: false,
    };

    render() {
        const { location, actions, currentLanguage, profile, profilePath, errorPage } = this.props;
        const navButtonsSites = [
            PUBLIC_SHOP.path,
            PUBLIC_RESERVATIONS_MASSAGE_RC.path,
            PUBLIC_RESERVATIONS_MASSAGE_SOBIESKI.path,
            PUBLIC_RESERVATIONS_REHABILITATION_SOBIESKI.path,
            PUBLIC_RESERVATIONS_REHABILITATION_ONLINE.path,
            PUBLIC_RESERVATIONS_MASSAGE_RC_NOW.path,
            PUBLIC_SHOP_ONLINE.path,
            PUBLIC_SHOP_STUDIO.path,
            PUBLIC_SHOP_MASSAGE.path,
        ];

        let navButtonsVisible = false;
        
        if (navButtonsSites.includes(location.pathname)) {
            navButtonsVisible = true;
        }

        return (
            <StyledComponent
                styles={require('./styles')}
                props={this.props}
                state={this.state}
                className="component-public-topbar"
            >
                <LayoutContainer>      
                    <div className="logo">
                        <a href={FITADEPT_PAGE}>
                            <img src={require('Theme/images/logo-color.png')} />
                        </a>
                    </div>
                    <nav className="nav">
                        {profile && profilePath && (
                            <div className="nav-element nav-element-user">
                                <div className="user-avatar">
                                    <Avatar user={profile} />
                                </div>
                                <div className="user-details">
                                    <NavLink className="user-name" to={profilePath}>
                                        {profile && (`${profile.name} ${profile.surname}` || profile.email || '...')}
                                    </NavLink>
                                </div>
                            </div>
                        )}
                        {navButtonsVisible && (
                            <div className="nav-buttons">
                                <div className="buttons-wrapper">
                                    <a className="nav-button" href={PUBLIC_SHOP.path} target="_blank">Oferta</a>
                                    <a className="nav-button" href={"https://fitadept.com/zespol"} target="_blank">Zespół</a>
                                    <a className="nav-button" href={"https://fitadept.com/efekty"} target="_blank">Efekty</a>
                                    <a className="nav-button" href={"https://fitadept.com/#public-layout-footer"} target="_blank">Kontakt</a>
                                </div>
                            </div>
                        )}
                        {!profile && (
                            <div className="nav-element nav-element-login">
                                <NavLink exact to={PUBLIC_USER_LOGIN.path}>
                                    <Button style="faPrimary">
                                        Zaloguj
                                    </Button>
                                </NavLink>
                            </div>
                        )}
                        {checkLocation(location.pathname) && (
                            <div className="nav-element nav-element-lang">
                                {currentLanguage && currentLanguage !== 'pl' && (
                                    <div
                                        className="lang-trigger"
                                        onClick={() => actions.changeLanguage('pl')}
                                    >
                                        <ReactFlagsSelect
                                            defaultCountry="PL"
                                            placeholder=""
                                            showSelectedLabel={false}
                                        />
                                    </div>
                                )}   
                                {currentLanguage && currentLanguage !== 'en' && (
                                    <div
                                        className="lang-trigger"
                                        onClick={() => actions.changeLanguage('en')}
                                    >
                                        <ReactFlagsSelect
                                            defaultCountry="GB"
                                            placeholder=""
                                            showSelectedLabel={false}
                                        />
                                    </div>
                                )}
                            </div>
                        )} 
                        {profile && profilePath && (
                            <div className="nav-element nav-element-logout">
                                <NavLink 
                                    className="logout" 
                                    onClick={actions.logout}
                                    to={PUBLIC_HOME.path}
                                >
                                    <ReactSVG className="logout-icon" src={require('Theme/images/icons/logout.svg')} />
                                </NavLink>
                            </div>
                        )}
                    </nav>             
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
