import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG } from 'Consts/redux';

export const initialState = {
    authToken: null,
    sid: null,
    cid: null,
    profile: null,
    visitorId: null,
    visitor: null,
    utm: {
        utmSource: null,
        utmCampaign: null,
        utmMedium: null,
        utmTerm: null,
        utmContent: null,
    },
    selectedLocation: null,
    currentLanguage: null,
};

export default function UserReducer(state = initialState, action) {
    switch (action.type) {
        /**
         * LOGIN
         */
        case types.LOGIN + '_' + INIT_FLAG:
            return {
                ...state,
                authToken: null,
            };
        case types.LOGIN + '_' + SUCCESS_FLAG:
            return {
                ...state,
                authToken: action.payload.authToken,
                profile: action.payload.me,
            };
        case types.LOGIN + '_' + FAIL_FLAG:
            return {
                ...state,
                authToken: null,
            };

        /**
         * LOGOUT
         */
        case types.LOGOUT + '_' + SUCCESS_FLAG:
            return {
                ...state,
                authToken: null,
                profile: null,
            };
        case types.LOGOUT + '_' + FAIL_FLAG:
            return {
                ...state,
                authToken: null,
                profile: null,
            };

        /**
         * REFRESH_TOKEN
         */
        case types.REFRESH_TOKEN + '_' + SUCCESS_FLAG:
            return {
                ...state,
                authToken: action.payload.authToken,
            };
        case types.REFRESH_TOKEN + '_' + FAIL_FLAG:
            return {
                ...state,
                authToken: null,
            };

        /**
         * GET_PROFILE
         */
        case types.GET_PROFILE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                profile: action.payload.me,
            };

        /**
         * UPDATE_PROFILE
         */
        case types.UPDATE_PROFILE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                profile: action.payload.me,
            };

        /**
         * REGISTER_ADEPT
         */
        case types.REGISTER + '_' + SUCCESS_FLAG:
            return {
                ...state,
                authToken: action.payload.authToken,
                profile: action.payload.me,
            };

        /**
         * SET_VISITOR
         */
        case types.SET_VISITOR + '_' + SUCCESS_FLAG:
            return {
                ...state,
                visitor: action.payload.webVisitor,
            };

        /**
         * SET_SELECTED_LOCATION
         */
        case types.SET_SELECTED_LOCATION + '_' + SUCCESS_FLAG:
            return {
                ...state,
                selectedLocation: action.payload.selectedLocation,
            };

        case types.LANGUAGE_CHANGE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                currentLanguage: action.payload.currentLanguage,
            };

        default:
            return state;
    }
}
