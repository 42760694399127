import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';
import {
    create as createCyclicEvent,
    single as singleCyclicEvent,
} from 'Redux/modules/admin/cyclicEvent/actions';
import {
    single as singleEvent,
} from 'Redux/modules/admin/events/actions';

export default connect(
    state => ({
        eventsMap: state.adminEvents.mapById,
    }),
    dispatch => ({
        actions: {
            createCyclicEvent: dispatch(createCyclicEvent),
            singleEvent: dispatch(singleEvent),
            singleCyclicEvent: dispatch(singleCyclicEvent),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        event: stateProps.eventsMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-EventCycle" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);