import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        color: ${variables.dpc_fontDark};
        width: 100%;
        margin-top: 1em;
        text-align: center;

        .headline {
            font-size: 1.75em;
            font-weight: 600;
        }

        .subheadline {
            font-size: 1.25em;
            font-weight: 300;
            margin-top: .25em;
        }

        .trainer-info-wrapper {
            display: flex;
            margin-top: 2.5em;
            background: #f7f7f7;
            padding: 2em;
            box-shadow: 0 0 0.25em #000000;
            
            .trainer-avatar-wrapper {
                position: relative;
                min-width: 7em;
                width: 7em;
                height: 7em;
                overflow: hidden;
                border-radius: 360em;
                border: 0.01em solid red;
                box-shadow: 0 0 0.25em black;

                .trainer-avatar {
                    position: absolute;    
                    width: inherit;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .trainer-deep-info-wrapper {
                display: flex;
                flex-direction: column;
                color: ${variables.dpc_fontDark};
                margin-left: 1em;
                padding-left: 1.25em;
                border-left: 1px solid #676767;

                .trainer-name {
                    font-size: 1.25em;
                    font-weight: 500;
                    margin-bottom: .5em;
                }
            }
        }

        @media (max-width: ${variables.tabletS}) {
            .trainer-info-wrapper {
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .trainer-deep-info-wrapper {
                    border: 0;
                    padding: 0;
                    margin: 0;

                    .trainer-bio {
                        display: none;
                    }

                    .trainer-name {
                        margin-top: 2em;
                    }
                }
            }
        }
    `;
