import { connect } from 'react-redux';
import Component from './component';

import {
    generateReports,
} from 'Redux/modules/admin/bigQueryExport/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            generateReports: dispatch(generateReports),
        },
    }),
)(Component);