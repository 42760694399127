import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default () =>
    css`
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        width: 100%;

        .headline-container {
            padding: 1em 2em;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .main-headline {
                font-size: 2em;
                font-weight: 300;
                color: #A71F57;
                line-height: 160%;
                display: block;
            }
            .sub-headline {
                font-size: 1em;
                font-weight: 400;
                color: ${variables.dpc_fontDark};
                line-height: 160%;
                display: block;

                b {
                    color: ${variables.dpc_fontDark};
                    font-weight: 300;
                }
            }

            .controls-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .control {
                    margin-left: .5em;
                }
            }
        }
        .tabs-container {
            .nav {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                flex-wrap: wrap;
                background: white;
                border: 1px solid #EEE;
                
                .tab {
                    cursor: pointer;
                    padding: 1em 2em;
                    box-shadow: 0 0 0.5em 0 rgba(0,0,0,.1) inset;

                    &.active {
                        background: white;
                        border-top: 2px solid #A71F57;
                    }

                    &:hover {
                        color: #666666;
                    }
                }
            }

            .content {
                background-color: white;
                padding: 2em 3em;
                box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
            }
        }

        @media (max-width: ${variables.tabletL}) {
            .headline-container {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;

                .controls-container {
                    margin-bottom: 2em;

                    .control {
                        font-size: .8em;
                    }
                }
            }

            .tabs-container {
                .nav {
                    overflow-x: scroll;
                    scrollbar-width: none;

                    &::-webkit-scrollbar {
                        height: 0;
                        width: 0;
                    }

                    &::-webkit-scrollbar-thumb {
                        display: none;
                    } 
                }
            }
        }
    `;
