import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';

export default class ButtonComponent extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        type: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
        style: PropTypes.oneOf([
            'primary',
            'primaryLight',
            'primaryDark',
            'secondary',
            'secondaryLight',
            'secondaryDark',
            'light',
            'lightReverse',
            'success',
            'info',
            'warning',
            'alert',
            'faPrimary',
            'hollow',
            'hollowWhite',
            'gradient',
            'V2Dark',
            'newPage'
        ]),
        size: PropTypes.oneOf([
            'extraSmall',
            'small',
            'medium',
            'large',
            'extraLarge',
        ]),
        layout: PropTypes.oneOf([
            'default',
            'wide',
            'fullWidth',
        ]),
        icon: PropTypes.shape({
            type: PropTypes.oneOf(['fa', 'path']).isRequired,
            source: PropTypes.string.isRequired,
        }),
        disabled: PropTypes.bool,
        confirm: PropTypes.shape({
            enabled: PropTypes.bool,
            message: PropTypes.string,
        }),
        loading: PropTypes.bool,
        form: PropTypes.string,
        buttonProps: PropTypes.object,
    };

    static defaultProps = {
        styleVersion: 1,
        style: 'primary',
        size: 'medium',
        layout: 'default',
        className: '',
        onClick: null,
        type: 'button',
        disabled: false,
        confirm: null,
        loading: false,
        form: null,
    };

    defaultConfirmProps = {
        message: 'Potwierdź swój wybór',
    };

    getIcon = icon => {
        switch (icon.type) {
            case 'fa':
                return (<i className={icon.source} />);
            case 'path':
                return (<img src={icon.source} />);
        }
    }

    onClick = () => {
        const { onClick, confirm, disabled, loading } = this.props;

        if(disabled || loading) {
            return null;
        }

        if (confirm && confirm.enabled !== false) {
            const confirmConfig = { ...this.defaultConfirmProps, ...confirm };

            if (window.confirm(confirmConfig.message)) {
                return onClick && onClick();
            } else {
                return null;
            }
        }

        return onClick && onClick();
    }

    render() {
        const { children, type, className, icon, disabled, styleVersion, size, style, layout, loading, form, buttonProps } = this.props;

        return (
            <StyledComponent styles={require('./styles')} className="component-button">
                <button
                    type={type}
                    onClick={disabled ? null : this.onClick}
                    disabled={disabled}
                    form={form || undefined}
                    className={classnames(
                        'button',
                        className,
                        `style-version-${styleVersion}`,
                        `size-${size}`,
                        `style-${style}`,
                        `layout-${layout}`,
                        {
                            disabled,
                        }
                    )}
                    {...buttonProps}
                >
                    <span className="button-content">{loading ? <Spinner /> : children}</span>
                    {icon && <div className="button-icon">{this.getIcon(icon)}</div>}
                </button>
            </StyledComponent>
        );
    }
}
