import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: relative;
    z-index: 9;

    .nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        padding: 1.5em;
        margin-right: 3em;

        .controls {
            display: flex;
            justify-content: space-between;
            width: 35%;

            .user-info {
                display: flex;
                align-items: center;

                .user-avatar {
                    padding: 0 1em;

                    .avatar-component {
                        margin-bottom: 0;

                        a {
                            color: white;

                            img {
                                width: 3em;
                                height: 3em;
                            }
                        }
                    }
                }

                .user-details {
                    cursor: pointer;

                    .user-name {
                        color: ${variables.dpc_fontWhite};
                    }
                }
            }

            .logout {
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    .logout-label {
                        border-bottom: 1px solid #DD3544;
                    }
                }

                .logout-icon {
                    max-width: 3em;
                    margin-right: 1em;

                    svg {
                        width: 100%;
                        height: 100%;

                        path, line {
                            stroke:  ${variables.dpc_iconWhite};
                        }
                    }
                }
                
                .logout-label {
                    cursor: pointer;
                    color: #DD3544;
                }
            }
        }
    }
    
    @media (max-width: ${variables.tabletL}) {
        .nav {
            padding: .5em 1em;
            margin-right: 0;
            justify-content: space-between;
            backdrop-filter: blur(5px);
            
            .controls {
                width: 100%;
                .user-info {
                    .user-avatar {
                        padding-left: 0;
                    }
                }

                .logout {
                    display: none;
                }
            }
        }
    }
`;
