import * as types from './types';

export const initialState = {
    list: [],
};

export default function NotificationsReducer(state = initialState, action) {
    switch (action.type) {
        case types.PUSH:
            return {
                ...state,
                list: [
                    action.payload,
                    ...state.list,
                ],
            };
        case types.POP:
            if(action.payload.id) {
                state.list = state.list.filter(element => element.id !== action.payload.id);
            } else {
                state.list.shift();
            }

            return {
                ...state,
                list: [ ...state.list ],
            };

        default:
            return state;
    }
}
