import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css({
    margin: '0 auto',
    maxWidth: '1440px',
    width: '96%',
    position: 'relative',
    height: '100%',

    '&.max-size-s': {
        maxWidth: '1100px',
    },

    '&.size-xs': {
        width: '56%',
    },
    '&.size-s': {
        width: '68%',
    },
    '&.size-m': {
        width: '80%',
    },
    '&.size-l': {
        width: '96%',
    },
    '&.size-xl': {
        width: '99%',
    },
    [`@media all and (max-width: ${variables.desktopM})`]: {
        width: '94% !important',
    },
    [`@media all and (max-width: ${variables.desktopS})`]: {
        width: '94% !important',
    },
    [`@media all and (max-width: ${variables.tabletL})`]: {
        width: '94% !important',
    },
    [`@media all and (max-width: ${variables.mobileL})`]: {
        width: '92% !important',
    },
});
