import {
    ARTICLE_COMMENT_STATUSES,
} from 'Consts/articleComments';

export const getStatusLabel = articleComment => {
    if(!articleComment) {
        return { found: false, label: 'Nieznany' };
    }

    const { status } = articleComment;
    const option = ARTICLE_COMMENT_STATUSES.find(commentStatus => commentStatus.key === status);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label, state: option.state };
};