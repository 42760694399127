import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import Logger from 'Services/Logger';

import { PUBLIC_USER_LOGIN } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';
import Checkbox from 'Components/forms/Checkbox';
import LayoutContainer from 'Components/layout/LayoutContainer';

class PublicUserUpdateForm extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            register: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {};

    state = {
        globalError: null,
        errors: {},
        isPending: false,
        inputStyle: 'transparentV2',
        formState: {
            name: null,
            surname: null,
            email: null,
            phone: null,
            password: null,
            passwordConfirm: null,
            consentFitadeptTerms: false,
            consentMarketingPhone: false,
            consentMarketingEmail: false,
            selectAll: false,
        },
        expandDisclaimer: false,
    };

    componentDidMount = () => {

    }

    componentDidUpdate = (prevProps, prevState) => {
        const { formState } = this.state;

        if (formState.selectAll && !prevState.formState.selectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState({
                formState: {
                    ...formState,
                    consentFitadeptTerms: true,
                    consentMarketingPhone: true,
                    consentMarketingEmail: true,
                },
            });
        }

        if (!formState.selectAll && prevState.formState.selectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState({
                formState: {
                    ...formState,
                    consentFitadeptTerms: false,
                    consentMarketingPhone: false,
                    consentMarketingEmail: false,
                },
            });
        }
    }

    onSubmit = () => {
        const { formState } = this.state;
        const { actions, history, location } = this.props;
        const { search } = location;
        this.setState({
            isPending: true,
            errors: {},
            globalError: null,
        });

        const data = {
            name: formState.name,
            surname: formState.surname,
            email: formState.email,
            phone: formState.phone,
            password: formState.password,
            consents: [{
                type: 'terms',
                content: 'Przeczytałem i akceptuję regulamin sklepu',
                value: formState.consentFitadeptTerms,
            }, {
                type: 'marketingEmail',
                content: 'Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną.',
                value: formState.consentMarketingEmail,
            }, {
                type: 'phone',
                content: 'Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu.',
                value: formState.consentMarketingPhone,
            }],
        };

        return actions.register(data)
            .then(response => {
                this.setState({ isPending: false });

                toast('Dane zostały zapisane');
                
                history.push(PUBLIC_USER_LOGIN.path);
            })
            .catch(error => {
                Logger.error('[UPDATE_INVITED_USER_FORM] Error', error);

                const validationErrors = {};
                if (error && error.payload && error.payload.validationErrors) {
                    Object.keys(error.payload.validationErrors).forEach(innerError => {
                        validationErrors[innerError] = error.payload.validationErrors[innerError] ;
                    });

                    if (validationErrors['user.email']) {
                        validationErrors['email'] = validationErrors['user.email'];
                        delete validationErrors['user.email'];
                    }
                }

                this.setState({
                    isPending: false,
                    errors: error.payload.validationErrors,
                    globalError: error.payload.message,
                    formErrors: {
                        ...validationErrors,
                    },
                });
            });
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    render() {
        const { formState, globalError, errors, isPending, inputStyle, expandDisclaimer } = this.state;
        const { location } = this.props;
        const { search } = location;        
    
        return (
            <StyledComponent
                styles={require('./styles')}
                className="public-user-update-invited-form"
            >
                <LayoutContainer>
                    <div className="update-invited-header">
                        Rejestracja konta
                    </div>
                    <Form
                        data={formState}
                        onStateChange={this.onStateChange}
                        errors={errors}
                        globalError={globalError}
                        onSubmit={this.onSubmit}
                        isPending={isPending}
                    >
                        <Input
                            label="Imię"
                            name="name"
                            placeholder="Wprowadź imię"
                            required={true}
                            style={inputStyle}
                            styleVersion={2}
                            border='light'
                        />
                        <Input
                            label="Nazwisko"
                            name="surname"
                            placeholder="Wprowadź nazwisko"
                            required={true}
                            style={inputStyle}
                            styleVersion={2}
                            border='light'
                        />
                        <Input
                            label="E-mail"
                            name="email"
                            placeholder="Wprowadź adres email"
                            type="email"
                            required={true}
                            style={inputStyle}
                            styleVersion={2}
                            border='light'
                        />
                        <Input
                            label="Numer telefonu"
                            name="phone"
                            placeholder="Wprowadź numer telefonu"
                            type="number"
                            required={true}
                            style={inputStyle}
                            styleVersion={2}
                            border='light'
                        />
                        <Input
                            label="Hasło"
                            name="password"
                            placeholder="Wprowadź hasło"
                            type="password"
                            required={true}
                            style={inputStyle}
                            styleVersion={2}
                            border='light'
                        />
                        <Input
                            label="Potwierdź hasło"
                            name="passwordConfirm"
                            placeholder="Potwierdź hasło"
                            type="password"
                            required={true}
                            style={inputStyle}
                            styleVersion={2}
                            border='light'
                        />
                        <Button
                            className="submit-button"
                            type="submit"
                            icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                            layout="fullWidth"
                            size="large"
                            style="gradient"
                        >
                            Zapisz
                        </Button>
                        <div className="regulations-container">
                            <Checkbox
                                name="selectAll"
                                label="Zaznacz wszystkie zgody"
                                className="select-all-checkbox"
                            />
                            <Checkbox
                                label="Przeczytałem i akceptuję regulamin sklepu <a href='https://fitadept.com/regulamin' target='_blank'>Więcej</a>"
                                name="consentFitadeptTerms"
                                required={true}
                            />
                            <Checkbox
                                label="Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną."
                                name="consentMarketingEmail"
                            />
                            <Checkbox
                                label="Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu."
                                name="consentMarketingPhone"
                            />
                            <span className={classnames({
                                disclaimer: true,
                                expandDisclaimer,
                            })}>
                                Administratorem Twoich danych osobowych jest FitAdept sp. z o.o. z siedzibą w Warszawie przy ul. Narbutta 40 lok. 13, 02-541 Warszawa wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem 0000622972, NIP 5223065447, REGON 364725646, kapitał zakładowy w wysokości 53.500,00 zł. Dane osobowe będą przetwarzane wyłącznie w celu umożliwienia korzystania z funkcjonalności Serwisu oraz do rozliczeń za zakupione usługi. Nadto, przysługuje nam prawo przetwarzania Twoich danych na podstawie naszego prawnie uzasadnionego interesu jako administratora tych danych, którego przykłady opisujemy w Regulaminie serwisu. Informujemy, że podanie danych osobowych zawartych w formularzu jest dobrowolne, a także, że masz prawo cofnąć zgodę na wysyłkę informacji handlowych drogą elektroniczną lub na przekazywanie treści marketingowych za pomocą telekomunikacyjnych urządzeń końcowych, w szczególności na podany przez Ciebie mnie numer telefonu w dowolnym momencie oraz żądać od administratora dostępu do swoich danych, ich sprostowania, przenoszenia i usunięcia, a także prawo do ograniczenia ich przetwarzania. Ponadto przysługuje Ci prawo wniesienia sprzeciwu wobec ich przetwarzania oraz prawo skargi do organu nadzorczego. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem. Zachęcamy do zapoznania się z pozostałymi informacjami dostępnymi w Regulaminie serwisu w tym dot. okresów przechowywania danych i kategorii odbiorców danych.
                            </span>
                            <a 
                                className="show-more-text"
                                onClick={() => this.setState(prevState => ({
                                    expandDisclaimer: !prevState.expandDisclaimer,
                                }))}
                            >
                                {expandDisclaimer ? 'Mniej' : 'Więcej'}
                            </a>
                        </div>
                    </Form>
                </LayoutContainer>
            </StyledComponent>
        );
    }
}

export default withRouter(PublicUserUpdateForm);