import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { LEAD_SOURCE_PREFERENCE_SURVEY, LEAD_MEDIUM_LP } from 'Consts/leads';
import {
    EVENT_ONLINE_SURVEY_STEP,
    EVENT_ONLINE_SURVEY_SUBMIT,
} from 'Consts/events';
import { PUBLIC_LP_REHABILITATION, PUBLIC_LP_RESERVATIONS } from 'Consts/routes';

import { trackEvent } from 'Services/Tracking';
import Logger from 'Services/Logger';

import { parseQueryToObject } from 'Utils/querystring';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Input from 'Components/forms/Input';
import Checkbox from 'Components/forms/Checkbox';
import Button from 'Components/layout/Button';
import FormDatePickerComponent from 'Components/forms/DatePicker/component';

export default class PublicPreferencesSurvey extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        onFinish: PropTypes.func.isRequired,
        actions: PropTypes.shape({ 
            createLead: PropTypes.func.isRequired,
        }).isRequired,
        utmData: PropTypes.object,
    };
    static defaultProps = {
        values: {},
    };
    static expandDisclaimer = false;
    state = {
        expandDisclaimer: false,
        currentQuestionName: null,
        values: {
            trainingTarget: null,
            previousExperience: null,
            physicalActivityLevel: null,
            trainingFrequency: null,
            nutrition: null,
            expectedSupport: null,
            name: null,
            surname: null,
            email: null,
            phone: null,
            selectAll: false,
            consentsMarketingPhone: false,
            consentsMarketingEmail: false,
            consentsMarketingTerms: false,
            startDate: null,
            motionSystem: null,
            leadContraindications: null,
            userCity: null,
            userAge: null,
            isRehab: false,
        },
        isPending: false,
        globalError: null,
        errors: {},
        questions: [{
            name: 'trainingTarget',
            displayName: 'Cel',
            valuePattern: '{{trainingTarget}}',
            title: 'Jaki jest Twój główny cel?',
            type: 'select',
            options: [{
                label: 'Chcę rozpocząć rehabilitację',
                value: 'Chcę rozpocząć rehabilitację',
            }, {
                label: 'Chcę schudnąć',
                value: 'Chcę schudnąć',
            }, {
                label: 'Chcę poprawić sylwetkę',
                value: 'Chcę poprawić sylwetkę',
            }, {
                label: 'Chcę pozbyć się dolegliwości bólowych',
                value: 'Chcę pozbyć się dolegliwości bólowych',
            }, {
                label: 'Chcę wrócić do aktywności fizycznej',
                value: 'Chcę wrócić do aktywności fizycznej',
            }, {
                label: 'Chcę zwiększyć siłę i masę mięśniową',
                value: 'Chcę zwiększyć siłę i masę mięśniową',
            }, {
                label: 'Chcę poprawić wydolność i wytrzymałość',
                value: 'Chcę poprawić wydolność i wytrzymałość',
            }, {
                label: 'Chcę poprawić wyniki sportowe',
                value: 'Chcę poprawić wyniki sportowe',
            }],
        }, {
            name: 'leadContraindications',
            displayName: 'Choroby, operacje, przewiwwskazania',
            valuePattern: '{{leadContraindications}}',
            title: 'Czy są choroby, operacje lub przeciwwskazania, o których terapeuta powinien wiedzieć?',
            subtitle: 'Kontuzje, operacje chirurgiczne, choroby, w tym choroby hormonalne - hashimoto, niedoczynność tarczycy, insulinooporność etc.',
            type: 'component',
            component: (
                <>
                    <Input
                        label="Choroby lub przeciwwskazania"
                        name="leadContraindications"
                        placeholder="Choroby lub przeciwwskazania"
                        type="text"
                        onChange={({ leadContraindications }) => this.setQuestionValue(leadContraindications)}
                    />
                    <div className="question-options">
                        <div
                            className="question-option"
                            onClick={() => {
                                const { values } = this.state;
                                let errors = {};

                                if (Object.keys(errors).length) {
                                    return this.setState({
                                        errors,
                                    });
                                }

                                this.setNextCurrentQuestion();
                            }}
                        >
                            Brak
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            this.setNextCurrentQuestion();
                        }}
                        style="gradient"
                    >
                        Dalej
                    </Button>
                </>
            ),
        }, {
            name: 'motionSystem',
            displayName: 'Bóle układu ruchu',
            valuePattern: '{{motionSystem}}',
            title: 'Czy odczuwasz ból lub dyskomfort związany z układem ruchu?',
            subtitle: 'Bóle kręgosłupa, stawów, szyi, nóg i inne',
            type: 'component',
            component: (
                <>
                    <Input
                        label="Bóle układu ruchu"
                        name="motionSystem"
                        placeholder="Bóle układu ruchu"
                        type="text"
                        styleVersion={2}
                        onChange={({ motionSystem }) => this.setQuestionValue(motionSystem)}
                    />
                    <div className="question-options">
                        <div
                            className="question-option"
                            onClick={() => {
                                const { values } = this.state;
                                let errors = {};

                                if (Object.keys(errors).length) {
                                    return this.setState({
                                        errors,
                                    });
                                }

                                this.setNextCurrentQuestion();
                            }}
                        >
                            Brak
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            this.setNextCurrentQuestion();
                        }}
                        style="gradient"
                    >
                        Dalej
                    </Button>
                </>
            ),
        }, {
            name: 'previousExperience',
            displayName: 'Aktywność fizyczna',
            valuePattern: '{{previousExperience}}',
            title: 'Jak oceniasz swoje doświadczenie w aktywności fizycznej?',
            type: 'select',
            options: [{
                label: 'Niewielkie',
                value: 'Niewielkie',
            }, {
                label: 'Przeciętne',
                value: 'Przeciętne',
            }, {
                label: 'Duże',
                value: 'Duże',
            }],
        }, {
            name: 'trainedWithPersonalTrainer',
            displayName: 'Czy ćwiczyłeś już pod okiem fizjoterapeuty lub trenera personalnego?',
            valuePattern: '{{trainedWithPersonalTrainer}}',
            title: 'Czy ćwiczyłaś/eś już pod okiem fizjoterapeuty lub trenera personalnego?',
            type: 'select',
            options: [{
                label: 'Tak - z fizjoterapeutą',
                value: 'Tak - z fizjoterapeutą',
            }, {
                label: 'Tak - z trenerem personalnym',
                value: 'Tak - z trenerem personalnym',
            }, {
                label: 'Nie',
                value: 'Nie',
            }],
        }, {
            name: 'userCity',
            displayName: 'Podaj swoje miasto',
            valuePattern: '{{userCity}}',
            title: 'Podaj swoje miasto',
            type: 'component',
            component: ( 
                <>
                    <Input
                        label="Miasto"
                        name="userCity"
                        placeholder="Miasto"
                        required={true}
                    />
                    <Button
                        onClick={() => {
                            const { values } = this.state;
                            let errors = {};

                            if (!values.userCity) {
                                errors.userCity = ['Pole jest wymagane'];
                            }

                            if (Object.keys(errors).length) {
                                return this.setState({
                                    errors,
                                });
                            }

                            this.setNextCurrentQuestion();
                        }}
                        style="gradient"
                    >
                        Dalej
                    </Button>
                </>
            ),
        }, {
            name: 'userAge',
            displayName: 'Ile masz lat?',
            valuePattern: '{{userAge}}',
            title: 'Ile masz lat?',
            type: 'component',  
            component: ( 
                <>
                    <Input
                        label="Wiek"
                        name="userAge"
                        placeholder="Wiek"
                        type="number"
                        required={true}
                    />
                    <Button
                        onClick={() => {
                            const { values } = this.state;
                            let errors = {};

                            if (!values.userAge) {
                                errors.userAge = ['Pole jest wymagane'];
                            }

                            if (Object.keys(errors).length) {
                                return this.setState({
                                    errors,
                                });
                            }

                            this.setNextCurrentQuestion();
                        }}
                        style="gradient"
                    >
                        Dalej
                    </Button>
                </>
            ),
        }, {
            name: 'startDate',
            displayName: 'Od kiedy chcesz zacząć?',
            valuePattern: '{{startDate}}',
            title: 'Od kiedy chcesz zacząć?',
            type: 'component',
            component: (
                <>
                    <FormDatePickerComponent
                        name='startDate'
                        datePickerProps={{
                            showTimeSelect: false,
                            dateFormat: 'dd.MM.yyyy',
                        }}
                    />
                    <Button
                        onClick={() => {
                            const { values } = this.state;
                            let errors = {};

                            if (!values.startDate) {
                                errors.startDate = ['Pole jest wymagane'];
                            }

                            if (Object.keys(errors).length) {
                                return this.setState(prevState => ({
                                    ...prevState.erros,
                                    errors,
                                }));
                            }

                            this.setNextCurrentQuestion();
                        }}
                        style="gradient"
                    >
                        Dalej
                    </Button>
                </>
            ),
        }, {
            name: 'nutrition',
            displayName: 'Żywienie',
            valuePattern: '{{nutrition}}',
            title: 'Żywienie',
            subtitle: 'Która z poniższych pozycji najlepiej opisuje Twoją obecną dietę?',
            type: 'select',
            options: [{
                label: 'Zbilansowana',
                value: 'Zbilansowana',
            }, {
                label: 'Wegetariańska / wegańska',
                value: 'Wegetariańska / wegańska',
            }, {
                label: 'Paleo / keto',
                value: 'Paleo / keto',
            }, {
                label: 'Mam katering dietetyczny',
                value: 'Mam katering dietetyczny',
            }, {
                label: 'Potrzebuję wsparcia w zakresie żywienia',
                value: 'Potrzebuję wsparcia w zakresie żywienia',
            }],
        }, {
            name: 'contactInformation',
            displayName: 'Dane',
            valuePattern: '',
            title: 'Dane',
            type: 'component',
            component: (
                <>
                    <Input
                        label="Imię"
                        name="name"
                        id="name"
                        key="name"
                        placeholder="Imię"
                        type="text"
                        required    
                        onChange={({ name, value }) => this.setQuestionValue(name, value)}
                    />
                    <Input
                        label="Nazwisko"
                        name="surname"
                        id="surname"
                        key="surname"
                        placeholder="Nazwisko"
                        type="text"
                        required    
                        onChange={({ name, value }) => this.setQuestionValue(name, value)}
                    />
                    <Input
                        label="Adres e-mail"
                        name="email"
                        id="email"
                        key="email"
                        placeholder="Adres e-mail"
                        type="email"
                        required    
                        onChange={({ name, value }) => this.setQuestionValue(name, value)}
                    />
                    <Input
                        label="Numer telefonu"
                        name="phone"
                        id="phone"
                        key="phone"
                        placeholder="Numer telefonu"
                        type="tel"
                        required
                        onChange={({ name, value }) => this.setQuestionValue(name, value)}
                    />
                    <Button
                        className="submit-button"
                        type="submit"
                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                        layout="fullWidth"
                        style="gradient"
                        size="extraLarge"
                        onClick={() => {
                            const { values, errors } = this.state;
                            let formErrors = {};
                           
                            if (!values.name) {
                                formErrors.name = ['Pole jest wymagane'];
                            }

                            if (!values.surname) {
                                formErrors.surname = ['Pole jest wymagane'];
                            }

                            if (!values.email) {
                                formErrors.email = ['Pole jest wymagane'];
                            }

                            if (!values.phone) {
                                formErrors.phone = ['Pole jest wymagane'];
                            }

                            if (Object.keys(formErrors).length || Object.keys(errors).length) {
                                return this.setState({
                                    errors: formErrors,
                                });
                            }
                        }}
                    >
                        Dalej
                    </Button>

                    <Checkbox
                        name="selectAll"
                        label="Zaznacz wszystkie zgody"
                        className="select-all-checkbox"
                    />
                    <Checkbox
                        name="consentsMarketingTerms"
                        label="Oświadczam, że zapoznałem/-am się z <a href='https://fitadept.com/regulamin' target='_blank'>Regulaminem</a> serwisu internetowego FitAdept.com oraz akceptuję jego treść."
                        required={true}
                    />
                    <Checkbox
                        name="consentsMarketingPhone"
                        label="Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu."
                    />
                    <Checkbox
                        name="consentsMarketingEmail"
                        label="Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną."
                    />
                </>
            ),
        }],
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.values.selectAll && this.state.values.selectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    consentsMarketingPhone: true,
                    consentsMarketingEmail: true,
                    consentsMarketingTerms: true,
                },
            }));
        }

        if (prevState.values.selectAll && !this.state.values.selectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState(prevState => ({
                values: {
                    ...prevState.values,
                    consentsMarketingPhone: false,
                    consentsMarketingEmail: false,
                    consentsMarketingTerms: false,
                },
            }));
        }
    }

    renderQuestion = question => {
        return (
            <div
                className={classnames(
                    'question',
                    `question-${question.name}`
                )}
            >
                <h2 className="question-title">{question.title}</h2>
                {question.subtitle && <h3 className="question-subtitle">{question.subtitle}</h3>}
                <div className="question-form">
                    {this.renderQuestionForm(question)}
                </div>
            </div>
        );
    }

    renderQuestionForm = ({ name, type, options, value, component }) => {
        switch (type) {
            case 'select':
                return (
                    <div
                        className={classnames(
                            'question',
                            `question-${name}`
                        )}
                    >
                        <div className="question-options">
                            {options.map(option => (
                                <div
                                    key={option.value}
                                    className={classnames({
                                        'question-option': true,
                                        'question-option-selected': value === option.value,
                                    })}
                                    onClick={() => {
                                        this.setQuestionValue(name, option.value);
                                        this.setNextCurrentQuestion();
                                    }}
                                >
                                    {option.label}
                                </div>
                            ))}
                        </div>
                    </div>
                );
            case 'component':
                return (
                    <div
                        className={classnames(
                            'question',
                            `question-${name}`
                        )}
                    >
                        {component}
                    </div>
                );
        }
    }

    renderCurrentQuestion = () => {
        const { currentQuestionName, questions } = this.state;

        let currentQuestion = questions.find(({ name }) => name === currentQuestionName);
        if (!currentQuestion) {
            currentQuestion = questions[0];
        }

        return this.renderQuestion(currentQuestion);
    }

    renderQuestionResult = ({ name, displayName, valuePattern }, index) => {
        const { values, currentQuestionName, questions } = this.state;

        let questionIndex = 0;
        if (currentQuestionName) {
            questionIndex = questions
                .findIndex(({ name }) => name == currentQuestionName);
        }

        let matches = valuePattern.match(/\{\{(.*?)\}\}/g) || [];
        matches = matches.map(match => {
            let matchValue = match.match(/\{\{(.*?)\}\}/);
            return matchValue.input.replace(matchValue[0], values[matchValue[1]] || '');
        });
        
        if (matches.join('') === 'true') {
            matches = 'Tak';
        }

        const returnMatch = () => {
            if (typeof matches == 'object') {
                if (name === 'startDate') {
                    return matches[0] ? getFormattedDate(matches[0], 'date') : '';
                }
                return matches.join(' ');
            }

            return matches;
        };

        return (
            <div
                key={name}
                className={classnames({
                    'question-result': true,
                    active: questionIndex === index,
                    resolved: questionIndex > index,
                })}
            >
                <div className="question-index">
                    <span className="question-number">
                        {index + 1}
                    </span>
                </div>
                <div className="question-result-content">
                    <h4 className="question-title">
                        {displayName}
                    </h4>
                    <h5 className="question-value">
                        {returnMatch()}
                    </h5>
                </div>
            </div>
        );
    }

    setNextCurrentQuestion = () => {
        const { currentQuestionName, questions, globalError } = this.state;

        if(globalError) {
            this.setState({ globalError: null });
        }

        let questionIndex = 0;
        if (currentQuestionName) {
            questionIndex = questions
                .findIndex(({ name }) => name == currentQuestionName);
        }

        let nextQuestion = null;
        if (typeof questions[questionIndex + 1] !== 'undefined') {
            nextQuestion = questions[questionIndex + 1];
        }

        trackEvent(EVENT_ONLINE_SURVEY_STEP, { stepName: nextQuestion && nextQuestion.name });
        this.setCurrentQuestion(nextQuestion && nextQuestion.name || null);
    }

    setPreviousCurrentQuestion = () => {
        const { currentQuestionName, questions } = this.state;

        let questionIndex = 0;
        if (currentQuestionName) {
            questionIndex = questions
                .findIndex(({ name }) => name == currentQuestionName);
        }

        let nextQuestion = null;
        if (typeof questions[questionIndex - 1] !== 'undefined') {
            nextQuestion = questions[questionIndex - 1];
        }

        trackEvent(EVENT_ONLINE_SURVEY_STEP, { stepName: nextQuestion && nextQuestion.name });
        this.setCurrentQuestion(nextQuestion && nextQuestion.name || null);
    }

    setCurrentQuestion = name => {
        const { questions } = this.state;

        const question = questions
            .find(question => question.name == name);

        this.setState({
            currentQuestionName: question && question.name || null,
        });
    }

    setQuestionValue = (name, value) => {
        this.setState(({ values }) => ({
            values: {
                ...values,
                [name]: value,
            },
        }));
    }

    onFinish = response => {
        const { onFinish } = this.props;
        const { values } = this.state;

        let redirectTo = PUBLIC_LP_RESERVATIONS;

        if (values.trainingTarget === 'Chcę rozpocząć rehabilitację') {
            redirectTo = PUBLIC_LP_REHABILITATION;
        }

        trackEvent(EVENT_ONLINE_SURVEY_SUBMIT, { leadId: response.payload.leadId });
        return onFinish(values, response, redirectTo);
    }

    onSubmit = () => {
        const { values, currentQuestionName, questions } = this.state;
        const { actions, location, utmData } = this.props;

        const queryObject = parseQueryToObject(location.search);

        if(questions[questions.length-1].name === currentQuestionName) {
            this.setState({
                isPending: true,
                errors: {},
                globalError: null,
            });
            
            const consents = [{
                type: 'terms',
                content: 'Oświadczam, że zapoznałem/-am się z Regulaminem serwisu internetowego FitAdept.com oraz akceptuję jego treść.',
                value: values.consentsMarketingTerms,
            }, {
                type: 'marketingEmail',
                content: 'Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną.',
                value: values.consentsMarketingEmail,
            }, {
                type: 'phone',
                content: 'Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu.',
                value: values.consentsMarketingPhone
            }];
            
            return actions.createLead({
                ...values,
                consents,
                contraindications: values.leadContraindications?.trim() ? values.leadContraindications : 'Brak',
                motionSystem: values.motionSystem?.trim() ? values.motionSystem : 'Brak',
                phone: values.phone.split(' ').join(''),
                medium: LEAD_MEDIUM_LP,
                source: LEAD_SOURCE_PREFERENCE_SURVEY,
                withSurvey: true,
                ...(utmData || {}),
            })
                .then(response => {
                    return this.onFinish(response);
                })
                .catch(error => {
                    Logger.error('[PREFRENCES_SURVEY] Error', error);
                    this.setState({
                        isPending: false,
                        errors: error.payload.validationErrors,
                        globalError: error.payload.message,
                    });
                });
        }
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        values: {
            ...prevState.values,
            [name]: value,
        },
    }))

    render() {
        const { questions, values, errors, globalError, isPending, currentQuestionName } = this.state;

        return (
            <StyledComponent
                className={classnames({
                    'component-public-preferences-survey': true,
                })}
                styles={require('./styles')}
            >
                <div className="col-question">
                    <Form
                        data={values}
                        onStateChange={this.onStateChange}
                        errors={errors}
                        globalError={globalError}   
                        onSubmit={this.onSubmit}
                        isPending={isPending}
                    >
                        {this.renderCurrentQuestion()}
                    </Form>
                    {questions.findIndex(({ name }) => name == currentQuestionName) > 0 && (
                        <Button
                            className="back-button"
                            style="gradient"
                            onClick={this.setPreviousCurrentQuestion}
                            icon={{ type: 'fa', source: 'fas fa-arrow-left' }}
                        >
                            Cofnij
                        </Button>
                    )}
                </div>
                <div className="col-results">
                    <div className="results">
                        {questions.map((question, index) => this.renderQuestionResult(question, index))}
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
