import * as types from './types';

import { reduxRequest as request } from 'Services/Api';
import { getProvider } from 'Services/Cookies';

import { COOKIE_UTM_LEAD_DATA } from 'Consts/cookies';

import getStore from 'Redux';

import { ENDPOINT_PUBLIC_LEAD } from 'Consts/api';

export const create = dispatch => (params = {}) => {
    const utmData = getStore().getState().user.utm;
    getProvider().set(COOKIE_UTM_LEAD_DATA, JSON.stringify(utmData));

    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: ENDPOINT_PUBLIC_LEAD,
        params,
        requestParams: {
            ...params,
            ...utmData,
        },
    }));
};
