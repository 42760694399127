import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .password-change {
        width: 100%;

        .editor-wrapper {
            display: flex;
            justify-content: center;

            .forms-wrapper {
                .form-wrapper {
                    padding-top: 0;
                    margin-top: 0;
                    border: none;
                }
            }

            .data-wrapper {
                display: none;
            }
        }
    }

    @media (min-width: ${variables.tabletL}) {
        width: 40vw;
    }
`;
