export const PAYMENT_TRANSACTION_STATUS_NEW = 'new';
export const PAYMENT_TRANSACTION_STATUS_PENDING = 'pending';
export const PAYMENT_TRANSACTION_STATUS_COMPLETED = 'completed';
export const PAYMENT_TRANSACTION_STATUS_CANCELED = 'canceled';
export const PAYMENT_TRANSACTION_STATUS_ERROR = 'error';
export const PAYMENT_TRANSACTION_STATUS_UNDEFINED = 'undefined';
export const PAYMENT_TRANSACTION_STATUS_REFUNDED = 'refunded';
export const PAYMENT_TRANSACTION_STATUS_CESSION = 'cession';
export const PAYMENT_TRANSACTION_STATUSES = [{
    key: PAYMENT_TRANSACTION_STATUS_NEW,
    label: 'Nowa',
    stateColor: 'warning',
}, {
    key: PAYMENT_TRANSACTION_STATUS_PENDING,
    label: 'W trakcie realizacji',
    stateColor: 'warning',
}, {
    key: PAYMENT_TRANSACTION_STATUS_COMPLETED,
    label: 'Potwierdzona',
    stateColor: 'success',
}, {
    key: PAYMENT_TRANSACTION_STATUS_CANCELED,
    label: 'Anulowana',
    stateColor: 'alert',
}, {
    key: PAYMENT_TRANSACTION_STATUS_REFUNDED,
    label: 'Zwrócona',
    stateColor: 'informational',
}, {
    key: PAYMENT_TRANSACTION_STATUS_CESSION,
    label: 'Cesja',
    stateColor: 'informational',
}, {
    key: PAYMENT_TRANSACTION_STATUS_ERROR,
    label: 'Błędna',
    stateColor: 'alert',
}, {
    key: PAYMENT_TRANSACTION_STATUS_UNDEFINED,
    label: 'Brak odpowiedzi',
    stateColor: 'alert',
}];

export const PAYMENT_TRANSACTION_TYPE_DEFAULT = 'default';
export const PAYMENT_TRANSACTION_TYPE_CARD_CONFIRM = 'cardConfirm';
export const PAYMENT_TRANSACTION_TYPE_SINGLE = 'single';
export const PAYMENT_TRANSACTION_TYPES = [{
    key: PAYMENT_TRANSACTION_TYPE_DEFAULT,
    label: 'Karta płatnicza',
}, {
    key: PAYMENT_TRANSACTION_TYPE_SINGLE,
    label: 'Szybki przelew',
}, {
    key: PAYMENT_TRANSACTION_TYPE_CARD_CONFIRM,
    label: 'Potwierdzenie karty',
}];
