import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css({
        backgroundColor: variables.dpc_backgroundWhite,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',

        '.page-wrapper': {
            width: '100%',
        },
    });
