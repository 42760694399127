import { PUBLIC_SHOP_ONLINE, PUBLIC_SHOP_STUDIO, PUBLIC_SHOP_MASSAGE } from 'Consts/routes';

export const PRODUCT_VAT_PERCENT_ZW = 'ZW';
export const PRODUCT_VAT_PERCENT_0 = '0';
export const PRODUCT_VAT_PERCENT_3 = '3';
export const PRODUCT_VAT_PERCENT_7 = '7';
export const PRODUCT_VAT_PERCENT_23 = '23';
export const PRODUCT_VAT_PERCENTS = [
    {
        key: PRODUCT_VAT_PERCENT_ZW,
        label: 'ZW',
    },
    {
        key: PRODUCT_VAT_PERCENT_0,
        label: '0',
    },
    {
        key: PRODUCT_VAT_PERCENT_3,
        label: '3',
    },
    {
        key: PRODUCT_VAT_PERCENT_7,
        label: '7',
    },
    {
        key: PRODUCT_VAT_PERCENT_23,
        label: '23',
    },
];

export const PRODUCT_DURATION_TYPE_MINUTES = 'minutes';
export const PRODUCT_DURATION_TYPE_HOURS = 'hours';
export const PRODUCT_DURATION_TYPE_DAYS = 'days';
export const PRODUCT_DURATION_TYPES = [
    {
        key: PRODUCT_DURATION_TYPE_MINUTES,
        label: 'Minut',
    },
    {
        key: PRODUCT_DURATION_TYPE_HOURS,
        label: 'Godzin',
    },
    {
        key: PRODUCT_DURATION_TYPE_DAYS,
        label: 'Dni',
    },
];

export const PRODUCT_VISIBILITY_HIDDEN = 'hidden';
export const PRODUCT_VISIBILITY_SHOP_CREATOR = 'shopCreator';
export const PRODUCT_VISIBILITIES = [
    {
        key: PRODUCT_VISIBILITY_HIDDEN,
        label: 'Ukryty',
    },
    {
        key: PRODUCT_VISIBILITY_SHOP_CREATOR,
        label: 'Sklep - konfigurator',
    },
];

export const PRODUCT_TYPE_PLANNABLE = true;
export const PRODUCT_TYPE_NOT_PLANNABLE = false;
export const PRODUCT_TYPES = [
    {
        key: PRODUCT_TYPE_PLANNABLE,
        label: 'Planowalny',
    }, 
    {
        key: PRODUCT_TYPE_NOT_PLANNABLE,
        label: 'Nieplanowalny',
    },
];

export const PRODUCT_CATEGORY_SLUG_ONLINE = 'treningi-online';
export const PRODUCT_CATEGORY_SLUG_OFFLINE = 'treningi-offline';
export const PRODUCT_CATEGORY_SLUG_MASSAGE = 'masaz';
export const PRODUCT_CATEGORY_SLUG_OFFLINE_OLD = 'treningi-2019';

export const PRODUCT_CATEGORY_SHOP_SLUG_ONLINE = 'online';
export const PRODUCT_CATEGORY_SHOP_SLUG_OFFLINE = 'studio';
export const PRODUCT_CATEGORY_SHOP_SLUG_MASSAGE = 'masage';

export const PRODUCT_CATEGORY_SHOP_MAP = [{
    productCategorySlug: PRODUCT_CATEGORY_SLUG_ONLINE,
    shopPath: PUBLIC_SHOP_ONLINE.path,
    shopSlug: PRODUCT_CATEGORY_SHOP_SLUG_ONLINE,
}, {
    productCategorySlug: PRODUCT_CATEGORY_SLUG_OFFLINE,
    shopPath: PUBLIC_SHOP_STUDIO.path,
    shopSlug: PRODUCT_CATEGORY_SHOP_SLUG_OFFLINE,
}, {
    productCategorySlug: PRODUCT_CATEGORY_SLUG_MASSAGE,
    shopPath: PUBLIC_SHOP_MASSAGE.path,
    shopSlug: PRODUCT_CATEGORY_SHOP_SLUG_MASSAGE,
}, {
    productCategorySlug: PRODUCT_CATEGORY_SLUG_OFFLINE_OLD,
    shopPath: PUBLIC_SHOP_STUDIO.path,
    shopSlug: PRODUCT_CATEGORY_SHOP_SLUG_OFFLINE,
}];