import pl from 'Server/languages/pl';
import en from 'Server/languages/en';

import { TRANSLATED_PAGES } from 'Consts/languages';

import Logger from 'Services/Logger';

export const translate = (keyname, language, location) => {
    if (checkLocation(location)) {
        switch(language) {
            case 'pl':
                return pl[keyname];
            case 'en':
                return en[keyname];
            default:
                return en[keyname];
        }
    }

    return pl[keyname];
};

export const checkLocation = (location) => {
    let locationPath = location;

    if (typeof location === 'object') {
        locationPath = location?.pathname;
    }

    if(!locationPath) {
        locationPath = '';
    }

    try {
        return TRANSLATED_PAGES?.includes(locationPath.toLowerCase()) || false;
    } catch (error) {
        Logger.error('[UTILS_LANGUAGE_CHECK_LOCATION] Error', error);
    }
};