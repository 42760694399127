import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

const Hamburger = ({ isOpen, onClick }) => {
    return (
        <StyledComponent
            className='hamburger'
            styles={require('./styles')}
        >
            <div
                className={classNames({
                    'mobile-trigger-icon': true,
                    open: isOpen,
                })}
                onClick={onClick}
            >
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
            </div>
        </StyledComponent>
    );
};

Hamburger.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};
Hamburger.defaultProps = {};

export default Hamburger;
