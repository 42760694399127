import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import ExternalWrapper from 'Components/forms/InputExternalWrapper';
import InternalWrapper from 'Components/forms/InputInternalWrapper';
import ErrorMessage from 'Components/forms/ErrorMessage';
import InputLabel from 'Components/forms/InputLabel';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';
import Textarea from 'Components/forms/Textarea';
import DatePicker from 'Components/forms/DatePicker';
import Select from 'Components/forms/MultiSelect';
import Switch from 'Components/forms/Switch';

export default class FormInputsListComponent extends React.Component {
    static displayName = 'FormInputsListComponent';
    static propTypes = {
        name: PropTypes.string.isRequired,

        label: PropTypes.string,
        value: PropTypes.arrayOf(
            PropTypes.object
        ),
        config: PropTypes.arrayOf(
            PropTypes.shape({
                defaultValue: PropTypes.any,
                width: PropTypes.number,
                element: PropTypes.oneOf([
                    'input',
                    'textarea',
                    'datePicker',
                    'select',
                    'switch',
                ]),
            }),
        ).isRequired,

        predefinedOptions: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                values: PropTypes.object.isRequired,
            })
        ),

        onChange: PropTypes.func,
        onFormChange: PropTypes.func,

        disabled: PropTypes.bool,
        required: PropTypes.bool,
        errorMessage: PropTypes.string,
        className: PropTypes.string,
        allowCreate: PropTypes.bool,
        link: PropTypes.shape({
            enabled: PropTypes.bool.isRequired,
            label: PropTypes.any,
            onClick: PropTypes.func,
        }),
    };

    static defaultProps = {
        value: [],
        onChange: null,
        onFormChange: null,
        disabled: false,
        required: false,
        placeholder: '',
        label: '',
        errorMessage: '',
        className: '',
        predefinedOptions: [],
        allowCreate: true,
        link: null,
    }

    getEmptyRowData = () => {
        const { config } = this.props;

        let emptyRowData = {};

        config.forEach(configElem => {
            emptyRowData[configElem.name] = configElem.defaultValue || '';
        });

        return emptyRowData;
    }

    validateRowData = data => {
        const { config } = this.props;

        let valid = true;

        config.forEach(configElem => {
            if (data[configElem.name].length === 0) {
                valid = false;
            }
        });

        return valid;
    }

    onDeleteRow = index => {
        const { value } = this.props;

        const nextValue = [ ...(value || []) ];

        nextValue.splice(index, 1);

        this.notifyChange(nextValue, index);
    }

    fillRow = value => {
        const { config } = this.props;
        value = { ...(value || {}) };

        config.forEach(configElem => {
            if (typeof value[configElem.name] === 'undefined' || value[configElem.name] === null) {
                value[configElem.name] = configElem.defaultValue || undefined;
            }
        });

        return value;
    }

    onChange = (data, rowIndex) => {
        const { value } = this.props;
        const fieldName = data.name.split('-')[0];

        let nextValue = [...(value || [])];
        nextValue = nextValue.map(nextSingleValue => this.fillRow(nextSingleValue));

        if (typeof nextValue[rowIndex] !== 'undefined') {
            //Update row data
            nextValue[rowIndex][fieldName] = data.value;
        } else {
            //Create row data
            nextValue = [...nextValue, { [fieldName]: data.value }];
        }

        this.notifyChange(nextValue, rowIndex);
    }

    notifyChange = (value, rowIndex) => {
        const { name, onChange, onFormChange } = this.props;

        if (onChange) onChange({ name, value, rowIndex });
        if (onFormChange) onFormChange({ name, value, rowIndex });
    }

    renderElement = (configElem, value, index, disabled, row) => {
        const onChange = data => {
            this.onChange(data, index);

            if(configElem.onChange) {
                configElem.onChange({ ...data, rowIndex: index });
            }
        };

        configElem = {
            ...configElem,
            disabled: disabled || configElem.disabled,
        };
            
        const overrideStyles = {
            width: configElem.width
                ? configElem.width + '%'
                : undefined,
        };

        switch (configElem.element) {      
            case 'datePicker':
                return (
                    <div 
                        className={`input-row-element datepicker ${configElem.name}`}
                        key={configElem.name + index}
                        style={overrideStyles}
                    >
                        <DatePicker
                            {...configElem}
                            name={`${configElem.name}-${index}`}
                            value={value || configElem.defaultValue || ''}
                            onChange={onChange}
                        />
                    </div>
                );  
            case 'select':
                return (
                    <div 
                        className={`input-row-element select ${configElem.name}`}
                        key={configElem.name + index}
                        style={overrideStyles}
                    >
                        <Select
                            {...configElem}
                            name={`${configElem.name}-${index}`}
                            value={value || configElem.defaultValue || ''}
                            onChange={onChange}
                            additionalProps={configElem.inputProps && configElem.inputProps(configElem, row, index) || {}}
                        />
                    </div>
                );  
            case 'textarea':
                return (
                    <div 
                        className={`input-row-element textarea ${configElem.name}`}
                        key={configElem.name + index}
                        style={overrideStyles}
                    >
                        <Textarea
                            {...configElem}
                            name={`${configElem.name}-${index}`}
                            value={value || configElem.defaultValue || ''}
                            onChange={onChange}
                        />
                    </div>
                );  
            case 'switch':
                return (
                    <div 
                        className={`input-row-element switch ${configElem.name}`}
                        key={configElem.name + index}
                        style={overrideStyles}
                    >
                        <Switch
                            {...configElem}
                            name={`${configElem.name}-${index}`}
                            value={value || configElem.defaultValue || ''}
                            onChange={onChange}
                        />
                    </div>
                );  
            default:
                return (
                    <div 
                        className={`input-row-element input ${configElem.name}`}
                        key={configElem.name + index}
                        style={overrideStyles}
                    >
                        <Input
                            {...configElem}
                            name={`${configElem.name}-${index}`}
                            value={value || configElem.defaultValue || ''}
                            onChange={onChange}  
                        />
                    </div>
                );
        }
    }

    setPredefinedValues = (values, inputRow, clear = false) => {
        const { value } = this.props;
        let nextValue = value || [];

        let lastValue = {};
        if(nextValue.length) {
            lastValue = nextValue[inputRow] || {};
        }

        lastValue = {
            ...lastValue,
            ...values,
        };

        let lastTimeout = 0;
        Object.keys(lastValue).forEach(lastValueField => {
            lastTimeout = lastTimeout + 100;
            setTimeout(() => {
                this.onChange(
                    { 
                        name: lastValueField, 
                        value: clear
                            ? ''
                            : lastValue[lastValueField],
                    }, 
                    inputRow
                );
            }, lastTimeout);
        });
    }

    render() {
        const { name, errorMessage, config, value, label, className, disabled, predefinedOptions, allowCreate, link } = this.props;
        let inputsState = [...(value || [])];

        if(allowCreate) {
            inputsState = [...inputsState, this.getEmptyRowData()];
        }

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames({
                    'form-element': true,
                    'inputs-list': true,
                    [className]: true,
                    [name]: true,
                    error: Boolean(errorMessage),
                })}
            >
                <ExternalWrapper>
                    {label && <InputLabel label={label} style="default" />}
                    {link && link.enabled && (
                        <a className="link-wrapper" onClick={link.onClick}>
                            {link.label}
                        </a>
                    )}
                    <InternalWrapper error={Boolean(errorMessage)}>
                        <div className="list">
                            {inputsState.map((row, rowIndex) => (
                                <div className="inputs-row" key={rowIndex} id={`row-${rowIndex}`}>
                                    <div className="row-number">
                                        <div className="row-number-text">
                                            {rowIndex+1}.
                                        </div>
                                        {!disabled && allowCreate && (
                                            <div className={`input-row-element input-row-element-controls ${rowIndex !== (inputsState.length) - 1 ? '': 'hidden'}`}>
                                                <Button
                                                    type="button"
                                                    size="small"
                                                    color="danger"
                                                    onClick={() => this.onDeleteRow(rowIndex)}
                                                >
                                                    <i className="fa fa-trash-alt" />
                                                </Button>
                                            </div>
                                        )}
                                        {predefinedOptions.length > 0 && (
                                            <div className="predefined-options">
                                                <a 
                                                    className="redefined-option"
                                                    onClick={() => this.setPredefinedValues({}, rowIndex, true)}
                                                >
                                                    Wyczyść
                                                </a>
                                                {predefinedOptions.map(predefinedOption => (
                                                    <a 
                                                        key={predefinedOption.key}
                                                        className="redefined-option"
                                                        onClick={() => this.setPredefinedValues(predefinedOption.values, rowIndex)}
                                                    >
                                                        {predefinedOption.label}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                    <div className="row-inputs">
                                        {config
                                            .filter(configElem => {
                                                if(!configElem.isVisible) return true;
                                                return configElem.isVisible(configElem, row, rowIndex);
                                            })
                                            .map(configElem => this.renderElement(configElem, inputsState[rowIndex][configElem.name], rowIndex, disabled, row))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </InternalWrapper>
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </ExternalWrapper>
            </StyledComponent>
        );
    }
}
