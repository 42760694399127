import Rollbar from 'rollbar';

let instance;
export default instance ? instance : init();
export function init(payload = {}) {
    if (instance) {
        return instance;
    } else {
        return instance = new Rollbar({
            accessToken: typeof window === 'undefined' ? process.env.ROLLBAR_SERVER_KEY : process.env.ROLLBAR_CLIENT_KEY,
            environment: process.env.APP_ENV,
            handleUncaughtExceptions: true,
            handleUnhandledRejections: true,
            autoInstrument: { log: false },
            verbose: process.env.APP_ENV === 'development',
            payload: {
                ...payload,
            },
        });
    }
}
