import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, LIST_MARKUP, ELEMENT_MARKUP } from 'Consts/redux';
import {
    API_RESOURCE_LOCATIONS,
    API_RESOURCE_LOCATION,
} from 'Consts/apiResources';

export const initialState = {
    list: { ...LIST_MARKUP },
    mapById: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LIST + '_' + INIT_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    params: action.params,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.LIST + '_' + SUCCESS_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_LOCATIONS].elements,
                    meta: action.payload[API_RESOURCE_LOCATIONS].meta,
                    error: false,
                },
            };
        case types.LIST + '_' + FAIL_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };

        case types.SINGLE + '_' + INIT_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.id] || {}),
                        isLoading: true,
                        isLoaded: false,
                    },
                },
            };
        case types.SINGLE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.id] || {}),
                        isLoading: false,
                        isLoaded: true,
                        data: action.payload[API_RESOURCE_LOCATION],
                        error: false,
                    },
                },
            };
        case types.SINGLE + '_' + FAIL_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.id] || {}),
                        isLoading: false,
                        isLoaded: true,
                        data: null,
                        error: true,
                    },
                },
            };
            
        default:
            return state;
    }
}
