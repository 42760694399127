export const initialState = {
    user: require('./modules/user/reducer').initialState,
    notifications: require('./modules/notifications/reducer').initialState,

    //Public
    publicCities: require('./modules/public/cities/reducer').initialState,
    publicGyms: require('./modules/public/gyms/reducer').initialState,
    publicTrainers: require('./modules/public/trainers/reducer').initialState,
    publicCoupons: require('./modules/public/coupons/reducer').initialState,
    publicProducts: require('./modules/public/products/reducer').initialState,
    publicLeads: require('./modules/public/leads/reducer').initialState,
    publicOffers: require('./modules/public/offers/reducer').initialState,
    publicUserOffers: require('./modules/public/userOffers/reducer').initialState,
    publicShopPages: require('./modules/public/shopPages/reducer').initialState,

    //Adept
    adeptMedicalCards: require('./modules/adept/medicalCards/reducer').initialState,
    adeptMedicalQuestions: require('./modules/adept/medicalQuestions/reducer').initialState,
    adeptUserOffers: require('./modules/adept/userOffers/reducer').initialState,
    adeptOfferProducts: require('./modules/adept/offerProducts/reducer').initialState,
    adeptUserOfferProducts: require('./modules/adept/userOfferProducts/reducer').initialState,
    adeptUserPaymentMethods: require('./modules/adept/userPaymentMethods/reducer').initialState,
    adeptPaymentTransactions: require('./modules/adept/paymentTransactions/reducer').initialState,
    adeptMeasurements: require('./modules/adept/measurements/reducer').initialState,
    adeptMeasurementsConfig: require('./modules/adept/measurementsConfig/reducer').initialState,
    adeptTrainingTargets: require('./modules/adept/trainingTargets/reducer').initialState,
    adeptTrainingTargetCategories: require('./modules/adept/trainingTargetCategories/reducer').initialState,
    adeptAdeptTests: require('./modules/adept/adeptTests/reducer').initialState,
    adeptPromoCodes: require('./modules/adept/promoCodes/reducer').initialState,
    adeptEvents: require('./modules/adept/events/reducer').initialState,
    adeptUsers: require('./modules/adept/users/reducer').initialState,
    adeptProducts: require('./modules/adept/products/reducer').initialState,
    adeptLocations: require('./modules/adept/locations/reducer').initialState,
    adeptContacts: require('./modules/adept/contacts/reducer').initialState,
    adeptNotifications: require('./modules/adept/notifications/reducer').initalState,
    adeptUserOfferDiagnosticReports: require('./modules/adept/userOfferDiagnosticReports/reducer').initialState,
    adeptUserSurveys: require('./modules/adept/userSurveys/reducer').initialState,
    adeptUserHomeworks: require('./modules/adept/userHomeworks/reducer').initialState,
    adeptChatConversations: require('./modules/adept/chat/reducer').initialState,
    adeptCyclicEvents: require('./modules/adept/cyclicEvent/reducer').initialState,

    //Admin
    adminOrders: require('./modules/admin/orders/reducer').initialState,
    adminUsers: require('./modules/admin/users/reducer').initialState,
    adminStaffData: require('./modules/admin/staffData/reducer').initialState,
    adminUserSegments: require('./modules/admin/userSegments/reducer').initialState,
    adminChat: require('./modules/admin/chat/reducer').initialState,
    adminProductCategories: require('./modules/admin/productCategories/reducer').initialState,
    adminProducts: require('./modules/admin/products/reducer').initialState,
    adminPaymentMethods: require('./modules/admin/paymentMethods/reducer').initialState,
    adminPromoCodes: require('./modules/admin/promoCodes/reducer').initialState,
    adminOffers: require('./modules/admin/offers/reducer').initialState,
    adminOfferProducts: require('./modules/admin/offerProducts/reducer').initialState,
    adminUserOfferProducts: require('./modules/admin/userOfferProducts/reducer').initialState,
    adminUserOfferInvoiceItems: require('./modules/admin/userOfferInvoiceItems/reducer').initialState,
    adminOfferUsps: require('./modules/admin/offerUsps/reducer').initialState,
    adminUserOffers: require('./modules/admin/userOffers/reducer').initialState,
    adminPaymentTransactions: require('./modules/admin/paymentTransactions/reducer').initialState,
    adminPaymentTransactionsLogs: require('./modules/admin/paymentTransactionsLogs/reducer').initialState,
    adminLocations: require('./modules/admin/locations/reducer').initialState,
    adminSpecializations: require('./modules/admin/specializations/reducer').initialState,
    adminEvents: require('./modules/admin/events/reducer').initialState,
    adminEventsHistory: require('./modules/admin/eventsHistory/reducer').initialState,
    adminTrainingTargets: require('./modules/admin/trainingTargets/reducer').initialState,
    adminTrainingTargetCategories: require('./modules/admin/trainingTargetCategories/reducer').initialState,
    adminAdeptTests: require('./modules/admin/adeptTests/reducer').initialState,
    adminMeasurements: require('./modules/admin/measurements/reducer').initialState,
    adminMeasurementsConfig: require('./modules/admin/measurementsConfig/reducer').initialState,
    adminActivityLogs: require('./modules/admin/activityLogs/reducer').initialState,
    adminMedicalCards: require('./modules/admin/medicalCards/reducer').initialState,
    adminInvoicingStrategies: require('./modules/admin/invoicingStrategies/reducer').initialState,
    adminUserWorkHours: require('./modules/admin/userWorkHours/reducer').initialState,
    adminUserOfferDiagnosticReports: require('./modules/admin/userOfferDiagnosticReports/reducer').initialState,
    adminUserSurveys: require('./modules/admin/userSurveys/reducer').initialState,
    adminGrHistoryLogs: require('./modules/admin/grHistoryLogs/reducer').initialState,
    adminUserProducts: require('./modules/admin/userProducts/reducer').initialState,
    adminUserComments: require('./modules/admin/userComments/reducer').initialState,
    adminUserHomeworks: require('./modules/admin/userHomeworks/reducer').initialState,
    adminShopPages: require('./modules/admin/shopPages/reducer').initialState,
    adminUserEffects: require('./modules/admin/userEffects/reducer').initialState,
    adminBigQueryReports: require('./modules/admin/bigQueryExport/reducer').initialState,
    adminTpSurveys: require('./modules/admin/trainingPlanSurveys/reducer').initialState,
    adminTpProfiles: require('./modules/admin/trainingPlanProfiles/reducer').initialState,
    adminArticles: require('./modules/admin/articles/reducer').initialState,
    adminArticleTags: require('./modules/admin/articleTags/reducer').initialState,
    adminArticleComments: require('./modules/admin/articleComments/reducer').initialState,
    adminArticleCategories: require('./modules/admin/articleCategories/reducer').initialState,
    adminCustomEvents: require('./modules/admin/customEvents/reducer').initialState,
    adminCustomEventUsps: require('./modules/admin/customEventUsps/reducer').initialState,
    adminCustomEventPopUps: require('./modules/admin/customEventPopUps/reducer').initialState,

    // Trainer
    trainerUsers: require('./modules/trainer/users/reducer').initialState,
    trainerEvents: require('./modules/trainer/events/reducer').initialState,
    trainerProducts: require('./modules/trainer/products/reducer').initialState,
    trainerUserOffers: require('./modules/trainer/userOffers/reducer').initialState,
    trainerLocations: require('./modules/trainer/locations/reducer').initialState,
    trainerTrainingTargets: require('./modules/trainer/trainingTargets/reducer').initialState,
    trainerTrainingTargetCategories: require('./modules/trainer/trainingTargetCategories/reducer').initialState,
    trainerAdeptTests: require('./modules/trainer/adeptTests/reducer').initialState,
    trainerMeasurements: require('./modules/trainer/measurements/reducer').initialState,
    trainerMeasurementsConfig: require('./modules/trainer/measurementsConfig/reducer').initialState,
    trainerMedicalCards: require('./modules/trainer/medicalCards/reducer').initialState,
    trainerMedicalQuestions: require('./modules/trainer/medicalQuestions/reducer').initialState,
    trainerNotifications: require('./modules/trainer/notifications/reducer').initialState,
    trainerUserOfferDiagnosticReports: require('./modules/trainer/userOfferDiagnosticReports/reducer').initialState,
    trainerUserSurveys: require('./modules/trainer/userSurveys/reducer').initialState,
    trainerUserComments: require('./modules/trainer/userComments/reducer').initialState,
    trainerUserHomeworks: require('./modules/trainer/userHomeworks/reducer').initialState,
    trainerOffers: require('./modules/trainer/offers/reducer').initialState,
    trainerCyclicEvent: require('./modules/trainer/cyclicEvent/reducer').initialState,
    trainerChatConversations: require('./modules/trainer/chat/reducer').initialState,
    trainerUserEffects: require('./modules/trainer/userEffects/reducer').initialState,
    trainerArticles: require('./modules/trainer/articles/reducer').initialState,
    trainerArticleTags: require('./modules/trainer/articleTags/reducer').initialState,
    trainerArticleCategories: require('./modules/trainer/articleCategories/reducer').initialState,
};

export default {
    user: require('./modules/user/reducer').default,
    notifications: require('./modules/notifications/reducer').default,

    //Public
    publicCities: require('./modules/public/cities/reducer').default,
    publicGyms: require('./modules/public/gyms/reducer').default,
    publicTrainers: require('./modules/public/trainers/reducer').default,
    publicCoupons: require('./modules/public/coupons/reducer').default,
    publicProducts: require('./modules/public/products/reducer').default,
    publicLeads: require('./modules/public/leads/reducer').default,
    publicOffers: require('./modules/public/offers/reducer').default,
    publicUserOffers: require('./modules/public/userOffers/reducer').default,
    publicShopPages: require('./modules/public/shopPages/reducer').default,

    //Adept
    adeptMedicalCards: require('./modules/adept/medicalCards/reducer').default,
    adeptMedicalQuestions: require('./modules/adept/medicalQuestions/reducer').default,
    adeptUserOffers: require('./modules/adept/userOffers/reducer').default,
    adeptOfferProducts: require('./modules/adept/offerProducts/reducer').default,
    adeptUserOfferProducts: require('./modules/adept/userOfferProducts/reducer').default,
    adeptUserPaymentMethods: require('./modules/adept/userPaymentMethods/reducer').default,
    adeptPaymentTransactions: require('./modules/adept/paymentTransactions/reducer').default,
    adeptMeasurements: require('./modules/adept/measurements/reducer').default,
    adeptMeasurementsConfig: require('./modules/adept/measurementsConfig/reducer').default,
    adeptTrainingTargets: require('./modules/adept/trainingTargets/reducer').default,
    adeptTrainingTargetCategories: require('./modules/adept/trainingTargetCategories/reducer').default,
    adeptAdeptTests: require('./modules/adept/adeptTests/reducer').default,
    adeptPromoCodes: require('./modules/adept/promoCodes/reducer').default,
    adeptEvents: require('./modules/adept/events/reducer').default,
    adeptUsers: require('./modules/adept/users/reducer').default,
    adeptProducts: require('./modules/adept/products/reducer').default,
    adeptLocations: require('./modules/adept/locations/reducer').default,
    adeptContacts: require('./modules/adept/contacts/reducer').default,
    adeptNotifications: require('./modules/adept/notifications/reducer').default,
    adeptUserOfferDiagnosticReports: require('./modules/adept/userOfferDiagnosticReports/reducer').default,
    adeptUserSurveys: require('./modules/adept/userSurveys/reducer').default,
    adeptUserHomeworks: require('./modules/adept/userHomeworks/reducer').default,
    adeptChatConversations: require('./modules/adept/chat/reducer').default,
    adeptCyclicEvents: require('./modules/adept/cyclicEvent/reducer').default,

    //Admin
    adminOrders: require('./modules/admin/orders/reducer').default,
    adminUsers: require('./modules/admin/users/reducer').default,
    adminStaffData: require('./modules/admin/staffData/reducer').default,
    adminUserSegments: require('./modules/admin/userSegments/reducer').default,
    adminChat: require('./modules/admin/chat/reducer').default,
    adminProductCategories: require('./modules/admin/productCategories/reducer').default,
    adminProducts: require('./modules/admin/products/reducer').default,
    adminPaymentMethods: require('./modules/admin/paymentMethods/reducer').default,
    adminPromoCodes: require('./modules/admin/promoCodes/reducer').default,
    adminOffers: require('./modules/admin/offers/reducer').default,
    adminOfferProducts: require('./modules/admin/offerProducts/reducer').default,
    adminUserOfferProducts: require('./modules/admin/userOfferProducts/reducer').default,
    adminUserOfferInvoiceItems: require('./modules/admin/userOfferInvoiceItems/reducer').default,
    adminOfferUsps: require('./modules/admin/offerUsps/reducer').default,
    adminUserOffers: require('./modules/admin/userOffers/reducer').default,
    adminPaymentTransactions: require('./modules/admin/paymentTransactions/reducer').default,
    adminPaymentTransactionsLogs: require('./modules/admin/paymentTransactionsLogs/reducer').default,
    adminLocations: require('./modules/admin/locations/reducer').default,
    adminSpecializations: require('./modules/admin/specializations/reducer').default,
    adminEvents: require('./modules/admin/events/reducer').default,
    adminEventsHistory: require('./modules/admin/eventsHistory/reducer').default,
    adminTrainingTargets: require('./modules/admin/trainingTargets/reducer').default,
    adminTrainingTargetCategories: require('./modules/admin/trainingTargetCategories/reducer').default,
    adminAdeptTests: require('./modules/admin/adeptTests/reducer').default,
    adminMeasurements: require('./modules/admin/measurements/reducer').default,
    adminMeasurementsConfig: require('./modules/admin/measurementsConfig/reducer').default,
    adminActivityLogs: require('./modules/admin/activityLogs/reducer').default,
    adminMedicalCards: require('./modules/admin/medicalCards/reducer').default,
    adminInvoicingStrategies: require('./modules/admin/invoicingStrategies/reducer').default,
    adminUserWorkHours: require('./modules/admin/userWorkHours/reducer').default,
    adminUserOfferDiagnosticReports: require('./modules/admin/userOfferDiagnosticReports/reducer').default,
    adminUserSurveys: require('./modules/admin/userSurveys/reducer').default,
    adminGrHistoryLogs: require('./modules/admin/grHistoryLogs/reducer').default,
    adminUserProducts: require('./modules/admin/userProducts/reducer').default,
    adminUserComments: require('./modules/admin/userComments/reducer').default,
    adminUserHomeworks: require('./modules/admin/userHomeworks/reducer').default,
    adminShopPages: require('./modules/admin/shopPages/reducer').default,
    adminUserEffects: require('./modules/admin/userEffects/reducer').default,
    adminBigQueryReports: require('./modules/admin/bigQueryExport/reducer').default,
    adminTpSurveys: require('./modules/admin/trainingPlanSurveys/reducer').default,
    adminTpProfiles: require('./modules/admin/trainingPlanProfiles/reducer').default,
    adminArticles: require('./modules/admin/articles/reducer').default,
    adminArticleTags: require('./modules/admin/articleTags/reducer').default,
    adminArticleComments: require('./modules/admin/articleComments/reducer').default,
    adminArticleCategories: require('./modules/admin/articleCategories/reducer').default,
    adminCustomEvents: require('./modules/admin/customEvents/reducer').default,
    adminCustomEventUsps: require('./modules/admin/customEventUsps/reducer').default,
    adminCustomEventPopUps: require('./modules/admin/customEventPopUps/reducer').default,

    // Trainer
    trainerUsers: require('./modules/trainer/users/reducer').default,
    trainerEvents: require('./modules/trainer/events/reducer').default,
    trainerProducts: require('./modules/trainer/products/reducer').default,
    trainerUserOffers: require('./modules/trainer/userOffers/reducer').default,
    trainerLocations: require('./modules/trainer/locations/reducer').default,
    trainerTrainingTargets: require('./modules/trainer/trainingTargets/reducer').default,
    trainerTrainingTargetCategories: require('./modules/trainer/trainingTargetCategories/reducer').default,
    trainerAdeptTests: require('./modules/trainer/adeptTests/reducer').default,
    trainerMeasurements: require('./modules/trainer/measurements/reducer').default,
    trainerMeasurementsConfig: require('./modules/trainer/measurementsConfig/reducer').default,
    trainerMedicalCards: require('./modules/trainer/medicalCards/reducer').default,
    trainerMedicalQuestions: require('./modules/trainer/medicalQuestions/reducer').default,
    trainerNotifications: require('./modules/trainer/notifications/reducer').default,
    trainerUserOfferDiagnosticReports: require('./modules/trainer/userOfferDiagnosticReports/reducer').default,
    trainerUserSurveys: require('./modules/trainer/userSurveys/reducer').default,
    trainerUserComments: require('./modules/trainer/userComments/reducer').default,
    trainerUserHomeworks: require('./modules/trainer/userHomeworks/reducer').default,
    trainerChatConversations: require('./modules/trainer/chat/reducer').default,
    trainerOffers: require('./modules/trainer/offers/reducer').default,
    trainerCyclicEvent: require('./modules/trainer/cyclicEvent/reducer').default,
    trainerUserEffects: require('./modules/trainer/userEffects/reducer').default,
    trainerArticles: require('./modules/trainer/articles/reducer').default,
    trainerArticleTags: require('./modules/trainer/articleTags/reducer').default,
    trainerArticleCategories: require('./modules/trainer/articleCategories/reducer').default,
};