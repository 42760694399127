import { SPECIALIZATION_TYPE_PRIMARY } from 'Consts/specializations';
import { USER_ACTIVE_STATES } from 'Consts/users';
import { USER_ROLE_ADMIN, USER_ROLE_ADEPT, USER_ROLE_TRAINER } from 'Consts/userRoles';
import { ADEPT_DASHBOARD, TRAINER_DASHBOARD, ADMIN_DASHBOARD } from 'Consts/routes';

export const getFullName = (user, format) => {
    if(!user) {
        return { found: false, label: 'Nieznany' };
    }

    const { name, surname, email } = user;
    switch (format) {
        case 'nameSurname':
            return { found: false, label: `${name} ${surname}` };    
        case 'name': 
            return { found: false, label: name };
        default:
            if (!email) {
                return { found: false, label: `${name} ${surname}` };
            }
        
            return { found: true, label: `${name} ${surname} (${email})` };
    }
};

export const getUserSpecializations = (user, primaryOnly) => {
    if(!user) {
        return { found: false, label: '' };
    }

    let { userSpecializations } = user;
    
    if (primaryOnly && userSpecializations) {
        userSpecializations = userSpecializations.filter(userSpecialization => userSpecialization.type && ( 
            userSpecialization.type == SPECIALIZATION_TYPE_PRIMARY ||
            userSpecialization.type.toLowerCase() == 'primary')
        );

        return userSpecializations.map(userSpecialization => userSpecialization && userSpecialization.specialization.name).join(', ');
    }

    return null;
};

export const getActiveStateLabel = user => {
    if(!user) {
        return { found: false, label: 'Nieznany', stateColor: 'alert' };
    }

    const { activeState } = user;
    const option = USER_ACTIVE_STATES.find(option => option.key === activeState);
    if (!option) {
        return { found: false, label: 'Nieznana', stateColor: 'alert' };
    }

    return { ...option, found: true };
};

export const getRolePath = (profile) => {
    if (profile?.role) {
        switch (profile.role) {
            case USER_ROLE_ADEPT:
                return ADEPT_DASHBOARD;
            case USER_ROLE_TRAINER:
                return TRAINER_DASHBOARD;
            case USER_ROLE_ADMIN:
                return ADMIN_DASHBOARD;
        }
    }

    return null;
};