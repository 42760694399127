import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const listSegments = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_SEGMENTS,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_USER_SEGMENTS,
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const createSegment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE_SEGMENT,
        method: 'POST',
        path: endpoints.ENDPOINT_ADMIN_USER_SEGMENTS,
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const updateSegment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE_SEGMENT,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_SEGMENTS_SINGLE, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const singleSegment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE_SEGMENT,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_SEGMENTS_SINGLE, params),
        params,
    }));
};

export const deleteSegment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE_SEGMENT,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_SEGMENTS_SINGLE, params),
        params,
    }));
};

export const presignFile = dispatch => (params = {}) => {
    return dispatch(request({
        method: 'POST',
        reduxType: types.IMAGE_PRESIGN,
        path: params.presignPath,
        timeout: 360000,
        requestParams: {
            file: {
                type: params.file.type,
                name: params.file.name,
                size: params.file.size,
            },
        },
    }));
};