import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, ELEMENT_MARKUP, LIST_MARKUP } from 'Consts/redux';

import {
    API_RESOURCE_MEDICAL_CARD,
    API_RESOURCE_MEDICAL_CARDS,

} from 'Consts/apiResources';

export const initialState = {
    current: { ...ELEMENT_MARKUP },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LIST + '_' + INIT_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    params: action.params,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.LIST + '_' + SUCCESS_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_MEDICAL_CARDS].elements,
                    meta: action.payload[API_RESOURCE_MEDICAL_CARDS].meta,
                    error: false,
                },
            };
        case types.LIST + '_' + FAIL_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };
        case types.SINGLE + '_' + INIT_FLAG:
            return {
                ...state,
                single: {
                    isLoading: true,
                },
            };
        case types.SINGLE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                single: {
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: action.payload[API_RESOURCE_MEDICAL_CARD],
                },
            };
        case types.SINGLE + '_' + FAIL_FLAG:
            return {
                ...state,
                single: {
                    ...ELEMENT_MARKUP,
                    isLoading: false,
                    isLoaded: true,
                    data: null,
                    error: true,
                },
            };
        default:
            return state;
    }
}
