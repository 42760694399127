import moment from 'moment';

export default class CustomEventUsp {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.title = data.title;
        this.image = data.image;
        this.content = data.content;
        this.customEvent = data.customEvent;
    };
}