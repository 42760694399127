export default {
    fontPrimary: 'Lato, sans-serif',

    desktopXL: '1920px',
    desktopL: '1750px',
    desktopM: '1600px',
    desktopS: '1360px',
    desktopXS: '1200px',

    tabletL: '1024px',
    tabletS: '768px',

    mobileL: '640px',
    mobileM: '480px',
    mobileS: '360px',
    mobileXS: '281px',

    azureColor: '#f7f7f7',
    primaryColor: '#2EB367',
    primaryLightColor: '#38b0fb',
    primaryDarkColor: '#005487',
    secondaryColor: '#f48e3a',
    secondaryLightColor: '#ffb477',
    secondaryDarkColor: '#c15700',
    warningColor: '#f48e3a',
    lightColor: '#ffffff',
    darkColor: '#393939',
    darkSecondaryColor: '#000000',
    greyColor: '#a5a5a5',
    greySecondaryColor: '#f2f2f2',
    greyThirdColor: '#989898',
    greyFourthColor: '#f0f0f0',
    greyFifthColor: '#f6f6f6',
    redNewPageColor: '#FC2B1B',

    dpc_backgroundBlack: 'rgb(0 0 0)',
    dpc_backgroundWhite: 'rgb(255 255 255)',
    dpc_backgroundDarkLight: 'rgb(88 86 86)',
    dpc_backgroundDarkLigher: 'rgb(205, 203, 203)',
    dpc_backgroundLightRed: '#d4d0d0',
    dpc_backgroundRed: '#ED443D',
    dpc_backgroundGreen: '#43BC15',
    
    dpc_fontWhite: 'rgb(255 255 255)',
    dpc_fontDark: 'rgb(1 1 1)',
    dpc_fontRed: 'rgb(184 24 24)',

    dpc_borderDark: 'rgb(47 46 46)',
    dpc_borderRed: 'rgb(184 24 24)',
    dpc_borderLight: '#999999',

    dpc_iconWhite: '#fff',
};
