import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, LIST_MARKUP, ELEMENT_MARKUP } from 'Consts/redux';
import {
    API_RESOURCE_ACTIVITY_LOGS,
} from 'Consts/apiResources';

export const initialState = {
    list: { ...LIST_MARKUP },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LIST + '_' + INIT_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    params: action.params,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.LIST + '_' + SUCCESS_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_ACTIVITY_LOGS].elements,
                    meta: action.payload[API_RESOURCE_ACTIVITY_LOGS].meta,
                    error: false,
                },
            };
        case types.LIST + '_' + FAIL_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };
        default:
            return state;
    }
}
