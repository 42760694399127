import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { generateUniqueId } from 'Utils/crypto';

import StyledComponent from 'Components/core/StyledComponent';
import ExternalWrapper from 'Components/forms/InputExternalWrapper';
import ErrorMessage from 'Components/forms/ErrorMessage';
import InputHelper from 'Components/forms/InputHelper';
import InputLabel from 'Components/forms/InputLabel';
import Spinner from 'Components/layout/Spinner';

export default class FormS3FileMultiUploadComponent extends React.Component {
    static displayName = 'FormS3FilMultiUploadComponent';
    static propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        helper: PropTypes.string,
        onChange: PropTypes.func,
        onFormChange: PropTypes.func,
        errorMessage: PropTypes.string,
        value: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                originalName: PropTypes.string,
                imageUrl: PropTypes.string,
                progress: PropTypes.number,
            })
        ),
        style: PropTypes.oneOf([
            'default',
            'transparent',
            'transparentLight',
        ]),
        accept: PropTypes.string,
        action: PropTypes.func.isRequired,
        s3Config: PropTypes.shape({
            presignPath: PropTypes.string.isRequired,
        }),
    };
    static defaultProps = {
        className: '',
        helper: '',
        disabled: false,
        onChange: null,
        onFormChange: null,
        errorMessage: '',
        value: [],
        style: 'default',
        accept: '',
    }

    uploadFileDefaultState = {
        id: null,
        progress: 0,
        isPending: false,
        uploadError: null,
        uploadedFile: null,
        uploadFile: null,
    };

    state = {
        isPending: false,
        uploadQueue: [],
    }

    componentDidMount = () => {
        const { value } = this.props;
        const { uploadQueue } = this.state;

        this.setState({
            uploadQueue: Array.isArray(value)
                ? value
                : uploadQueue,
        });
    }

    componentDidUpdate = prevProps => {
        const { value } = this.props;
        const { uploadQueue } = this.state;

        if(JSON.stringify(value) !== JSON.stringify(prevProps.value)) {
            this.setState({
                uploadQueue: Array.isArray(value)
                    ? value.map(valueElem => valueElem.uploadedFile
                        ? valueElem
                        : {
                            ...this.uploadFileToFileState(valueElem),
                            id: valueElem.id,
                            uploadedFile: valueElem,
                        }
                    )
                    : uploadQueue,
            });
        }
    }

    uploadFileToFileState = uploadFile => ({
        ...this.uploadFileDefaultState,
        uploadFile,
    })

    presignToFileState = presign => ({
        ...this.uploadFileDefaultState,
        id: presign.id,
        progress: 1,
        uploadedFile: presign,
    })

    notifyChange = () => {
        const { name, onChange, onFormChange } = this.props;
        const { uploadQueue } = this.state;

        if (onChange) {
            onChange({
                name,
                value: uploadQueue,
            });
        }
        if (onFormChange) {
            onFormChange({
                name,
                value: uploadQueue,
            });
        }
    }

    onDrop = files => {
        const { action, s3Config } = this.props;

        this.setState(prevState => ({
            uploadQueue: [
                ...prevState.uploadQueue,
                ...files.map(file => ({
                    ...this.uploadFileToFileState(file),
                    id: generateUniqueId(),
                    isPending: true,
                })),
            ],
        }), () => {
            const { uploadQueue } = this.state;

            uploadQueue
                .filter(uploadQueueElem => !uploadQueueElem.uploadedFile)
                .forEach(uploadQueueElem => {
                    action({ 
                        file: uploadQueueElem.uploadFile,
                        presignPath: s3Config.presignPath,
                    })
                        .then(response => {
                            this.sendToS3(uploadQueueElem, response.payload.fileUpload);
                        })
                        .catch(error => {
                            this.setState({ isPending: false, uploadError: 'Błąd wysyłania' });
                        });
                });
        });
    }

    sendToS3 = (uploadQueueElem, presign) => {
        let data = new FormData();
        data.append('file', uploadQueueElem.uploadFile);

        const req = new XMLHttpRequest();
        req.open('PUT', presign.putUrl);
        req.setRequestHeader('Access-Control-Allow-Origin', '*');
        req.setRequestHeader('Content-Disposition', `inline; filename="${presign.fileName}"`);
        req.setRequestHeader('Cache-Control', 'max-age=86400, public');
        req.setRequestHeader('Pragma', 'public');
        req.setRequestHeader('Content-Type', presign.mimeType);
        req.addEventListener('load', event => {
            this.setState(prevState => ({
                uploadQueue: prevState.uploadQueue.map(uploadQueueCurrentElem => uploadQueueCurrentElem.id === uploadQueueElem.id
                    ? {
                        ...uploadQueueCurrentElem,
                        isPending: false,
                        progress: 1,
                        uploadedFile: presign,
                        id: presign.id,
                    }
                    : uploadQueueCurrentElem
                ),
            }), () => {
                this.notifyChange();
            });
        });

        req.send(uploadQueueElem.uploadFile);
    }

    onDelete = id => {
        this.setState(prevState => ({
            uploadQueue: prevState.uploadQueue.filter(uploadQueueElem => uploadQueueElem.id !== id),
        }), () => {
            this.notifyChange();
        });
    }

    render() {
        const { label, className, accept, helper, style, disabled } = this.props;
        const { uploadQueue } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'form-element',
                    'file-s3-multi-upload',
                    className,
                    `style-${style}`,
                )}
            >
                <ExternalWrapper>
                    {label && <InputLabel label={label} style={style} />}
                    {!disabled && (
                        <div className="dropzone-container">
                            <Dropzone
                                accept={accept}
                                onDropAccepted={this.onDrop}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone-internal" {...getRootProps()}>
                                        <input className="dropzone-input" {...getInputProps()} />
                                        <div className="info">
                                            <p className="dropzone-label">Wybierz lub przeciągnij plik</p>
                                        </div>
                                        {helper ? <InputHelper message={helper} /> : null}
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    )}

                    <div className="upload-queue">
                        {uploadQueue.map(uploadQueueElem => {
                            let isImage = [ 'image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp' ].includes(uploadQueueElem.uploadedFile && uploadQueueElem.uploadedFile.mimeType);
                            
                            return (
                                <div className="upload-queue-elem" key={uploadQueueElem.id}>
                                    {uploadQueueElem.isPending && (<Spinner />)}
                                    {uploadQueueElem.uploadError && <ErrorMessage message={uploadQueueElem.uploadError} />}
                                    {uploadQueueElem.uploadedFile && (
                                    <>
                                        <div className="upload-queue-elem-details">
                                            <div className="upload-queue-elem-image-container">
                                                <a
                                                    href={uploadQueueElem.uploadedFile.getUrl}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >   
                                                    {isImage
                                                        ? (
                                                            <img
                                                                className="upload-queue-elem-image"
                                                                src={uploadQueueElem.uploadedFile.getUrl}
                                                            />
                                                        )
                                                        : (
                                                            <FontAwesomeIcon icon={['fa', 'file']} />
                                                        )
                                                    }                                                
                                                </a>                                             
                                            </div>
                                            <p className="upload-queue-elem-filename">
                                                {uploadQueueElem.uploadedFile && uploadQueueElem.uploadedFile.originalName}
                                            </p>
                                        </div>
                                        <div className="upload-queue-elem-controls">
                                            <a className="upload-queue-elem-control" onClick={() => this.onDelete(uploadQueueElem.id)}>Usuń</a>
                                        </div>
                                    </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </ExternalWrapper>
            </StyledComponent>
        );
    }
}