import { connect } from 'react-redux';
import Component from './component';

import {
    list as listUsers,
} from 'Redux/modules/admin/users/actions';

import {
    createConversation,
} from 'Redux/modules/admin/chat/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            createConversation: dispatch(createConversation),
            listUsers: dispatch(listUsers),
        },
    })
)(Component);
