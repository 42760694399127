import { connect } from 'react-redux';
import Component from './component';

import {
    logout,
    refreshToken,
    getProfile,
    login,
} from 'Redux/modules/user/actions';

export default connect(
    state => ({
        authToken: state.user.authToken,
        cid: state.user.cid,
        sid: state.user.sid,
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            logout: dispatch(logout),
            login: dispatch(login),
            refreshToken: dispatch(refreshToken),
            getProfile: dispatch(getProfile),
        },
    })
)(Component);
