import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layout/modals/ModalWrapper';
import ModalBody from 'Components/layout/modals/ModalBody';
import ModalTitle from 'Components/layout/modals/ModalTitle';
import PaymentMethodCreate from 'Components/adept/userPaymentMethods/CreateForm';

export default class AdeptModalPaymentMethodCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {};

    state = {
        isOpen: true,
    };

    onSuccess = () => {
        const { location, history } = this.props;

        this.setState({ isOpen: false }, () => {
            history.push(
                withVariables(
                    location.pathname,
                    {},
                    { ...parseQueryToObject(location.search), timestamp: Date.now() },
                )
            );
        });
    }

    render() {
        const { location, history } = this.props;
        const { isOpen } = this.state;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
            >
                <StyledComponent
                    className="panel-modal-payment-method-create"
                    styles={require('./styles')}
                >
                    <ModalBody>
                        <ModalTitle title="Nowa metoda płatności" />
                        <PaymentMethodCreate
                            location={location}
                            history={history}
                            onSuccess={this.onSuccess}
                            confirm={true}
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}