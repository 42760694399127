export const LIST = 'admin/events/LIST';
export const SINGLE = 'admin/events/SINGLE';
export const STATS = 'admin/events/STATS';
export const CREATE = 'admin/events/CREATE';
export const ADD = 'admin/events/ADD';
export const UPDATE = 'admin/events/UPDATE';
export const REMOVE = 'admin/events/REMOVE';
export const RESET = 'admin/events/RESET';
export const SET_PAID_STATUS = 'admin/events/SET_PAID_STATUS';
export const SET_DONE_STATUS = 'admin/events/SET_DONE_STATUS';
export const UNBONUS = 'admin/events/UNBONUS';
export const CANCEL_DIAGNOSTIC = 'admin/events/CANCEL_DIAGNOSTIC';
export const CHECK_CYCLE_SLOTS_AVAILABILITY = 'admin/events/CHECK_CYCLE_SLOTS_AVAILABILITY';
export const CREATE_MANY = 'admin/events/CREATE_MANY';
export const CONFIRM_REQUEST = 'admin/events/CONFIRM_REQUEST';