import { connect } from 'react-redux';
import Component from './component';

import { logout } from 'Redux/modules/user/actions';
import { 
    list as listNotifications,
    readNotification,
    setAllAsRead,
} from 'Redux/modules/adept/notifications/actions'; 
import { current as currentMedicalCard } from 'Redux/modules/adept/medicalCards/actions';
import { list as listEvents } from 'Redux/modules/adept/events/actions';

export default connect(
    state => ({
        profile: state.user.profile,
        notifications: state.adeptNotifications.list,
    }),
    dispatch => ({
        actions: {
            logout: dispatch(logout),
            listNotifications: dispatch(listNotifications),
            readNotification: dispatch(readNotification),
            currentMedicalCard: dispatch(currentMedicalCard),
            listEvents: dispatch(listEvents),
            setAllAsRead: dispatch(setAllAsRead),
        },
    })
)(Component);
