import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';
import { fromSelectObject } from 'Utils/object';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENTS),
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            userOfferId: fromSelectObject(params.userOfferId) || undefined,
            leadId: fromSelectObject(params.leadId) || undefined,
            leadIds: params.leadIds || undefined,
            specializationId: fromSelectObject(params.specializationId) || undefined,
            locationId: fromSelectObject(params.locationId) || undefined,
            userId: fromSelectObject(params.userId )|| undefined,
            paidStatus: fromSelectObject(params.paidStatus) || undefined,
            doneStatus: fromSelectObject(params.doneStatus) || undefined,
            planned: typeof params.planned !== 'undefined'
                ? fromSelectObject(params.planned)
                : undefined,
            startAtFrom: params.startAtFrom || undefined,
            startAtTo: params.startAtTo || undefined,
            category: fromSelectObject(params.category) || undefined,
            fromDate: params.fromDate || undefined,
            untilDate: params.untilDate || undefined,
            type: params.type || undefined,
            productId: fromSelectObject(params.productId) || undefined,
            eventCycleId: params.eventCycleId || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENT, params),
        params,
    }));
};

export const stats = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.STATS,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENTS_STATS, params),
        params,
        requestParams: {
            userOfferId: params.userOfferId || undefined,
            leadId: params.leadId || undefined,
            userId: params.userId || undefined,
            paidStatus: params.paidStatus || undefined,
            doneStatus: params.doneStatus || undefined,
            planned: params.planned || undefined,
            startAtFrom: params.startAtFrom || undefined,
            startAtTo: params.startAtTo || undefined,
        },
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENTS, params),
        params,
        requestParams: params,
    }));
};

export const add = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ADD,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENTS_ADD, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENT, params),
        params,
        requestParams: params,
    }));
};

export const remove = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENT, params),
        params,
        requestParams: params,
    }));
};

export const reset = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.RESET,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENT_RESET, params),
        params,
        requestParams: params,
    }));
};

export const setPaidStatus = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SET_PAID_STATUS,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENT_PAID_STATUS, params),
        params,
        requestParams: params,
    }));
};

export const setDoneStatus = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SET_DONE_STATUS,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENT_DONE_STATUS, params),
        params,
        requestParams: params,
    }));
};

export const unbonus = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UNBONUS,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENT_UNBONUS, params),
        params,
        requestParams: params,
    }));
};

export const cancelDiagnostic = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CANCEL_DIAGNOSTIC,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENT_CANCEL_DIAGNOSTIC, params),
        params,
        requestParams: params,
    }));
};

export const checkCycleSlotsAvailability = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CHECK_CYCLE_SLOTS_AVAILABILITY,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENTS_CYCLE_SLOTS_AVAILABILITY, params),
        params,
        requestParams: params,
    }));
};

export const createMany = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE_MANY,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENTS_CREATE_MANY, params),
        params,
        requestParams: params,
    }));
};

export const confirmRequest = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CONFIRM_REQUEST,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_EVENT_CONFIRM_REQUEST, params),
        params,
        requestParams: params,
    }));
}