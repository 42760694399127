import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import { PUBLIC_USER_LOGIN } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import Avatar from 'Components/layout/Avatar';
import Notifications from 'Components/layout/NotificationsV2';

const PanelTopbar = ({ profile, actions, profilePath, notifications, unreadNotifications, onReadNotification, history, onReadAllNotifications }) => {
    const [isMobileNavVisible] = useState(false);

    return (
        <StyledComponent
            styles={require('./styles')}
            className="panel-topbar"
        >
            <nav
                className={classNames({
                    nav: true,
                    'mobile-visible': isMobileNavVisible,
                })}
            >
                <div className="controls">
                    <nav className="user-info">
                        <div className="user-avatar">
                            <Avatar user={profile} />
                        </div>
                        <div className="user-details">
                            <NavLink className="user-name" to={profilePath}>
                                {profile && (`${profile.name} ${profile.surname}` || profile.email || '...')}
                            </NavLink>
                        </div>
                    </nav> 
                    <nav className="notifications">
                        <Notifications 
                            notifications={notifications}
                            unreadNotifications={unreadNotifications}
                            onReadNotification={onReadNotification}
                            onReadAllNotifications={onReadAllNotifications}
                        />
                    </nav>
                    <nav className="logout" onClick={() => {
                        actions.logout()
                            .then(() => {
                                history.push(PUBLIC_USER_LOGIN.path);
                            });
                    }}>
                        <ReactSVG className="logout-icon" src={require('Theme/images/icons/logout.svg')} />
                    </nav>
                </div>
            </nav>
        </StyledComponent>
    );
};

PanelTopbar.propTypes = {
    history: PropTypes.object.isRequired,
    profilePath: PropTypes.string.isRequired,
    profile: PropTypes.shape({
        email: PropTypes.string.isRequired,
    }),
    actions: PropTypes.shape({
        logout: PropTypes.func.isRequired,
    }),
    notifications: PropTypes.object,
    unreadNotifications: PropTypes.object,
    onReadNotification: PropTypes.func,
    onReadAllNotifications: PropTypes.func,
};
PanelTopbar.defaultProps = {};
export default PanelTopbar;
