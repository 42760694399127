import moment from 'moment-timezone';

import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const listConversations = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_CONVERSATIONS,
        method: 'GET',
        path: endpoints.ENDPOINT_AUTH_CHAT_CONVERSATIONS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            enabled: typeof params.enabled === 'boolean' ? params.enabled : undefined,
            active: typeof params.active === 'boolean' ? params.active : undefined,
            conversationRole: params.conversationRole || undefined,
        },
    }));
};

export const listAllConversations = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_CONVERSATIONS,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_CHAT_CONVERSATIONS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            enabled: typeof params.enabled === 'boolean' ? params.enabled : undefined,
            active: typeof params.active === 'boolean' ? params.active : undefined,
            conversationRole: params.conversationRole || undefined,
            userId: params.userId || undefined,
        },
    }));
};

export const listAllConversationUsers = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_CONVERSATION_USERS,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_CHAT_CONVERSATION_USERS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            role: params.role || undefined,
        },
    }));
};

export const listConversationMessages = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_CONVERSATION_MESSAGES,
        method: 'GET',
        ignoreStore: params.ignoreStore || false,
        path: endpoints.ENDPOINT_AUTH_CHAT_CONVERSATION_MESSAGES
            .replace('{conversationId}', params.conversationId),
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 100,
        },
        params,
    }));
};

export const sendConversationMessages = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SEND_CONVERSATION_MESSAGES,
        method: 'POST',
        path: endpoints.ENDPOINT_AUTH_CHAT_CONVERSATION_MESSAGES
            .replace('{conversationId}', params.conversationId),
        params,
        requestParams: {
            messages: params.messages,
        },
    }));
};

export const createConversation = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE_CONVERSATION,
        method: 'POST',
        path: endpoints.ENDPOINT_ADMIN_CHAT_CONVERSATIONS,
        params,
        requestParams: {
            role: params.role,
            userIds: params.userIds,
        },
    }));
};

export const enableConversation = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ENABLE_CONVERSATION,
        method: 'POST',
        path: endpoints.ENDPOINT_ADMIN_CHAT_CONVERSATION_ENABLE
            .replace('{conversationId}', params.id),
        params,
    }));
};

export const disableConversation = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.DISABLE_CONVERSATION,
        method: 'POST',
        path: endpoints.ENDPOINT_ADMIN_CHAT_CONVERSATION_DISABLE
            .replace('{conversationId}', params.id),
        params,
    }));
};

export const listMassMessages = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_MASS_MESSAGES,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_CHAT_MASS_MESSAGESS,
        params,
        requestParams: params,
    }));
};

export const createMassMessage = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE_MASS_MESSAGE,
        method: 'POST',
        path: endpoints.ENDPOINT_ADMIN_CHAT_MASS_MESSAGESS,
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const singleMassMessage = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE_MASS_MESSAGE,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_CHAT_MASS_MESSAGESS_SINGLE.replace('{id}', params.id),
        params,
    }));
};

export const updateMassMessage = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE_MASS_MESSAGE,
        method: 'PUT',
        path: endpoints.ENDPOINT_ADMIN_CHAT_MASS_MESSAGESS_SINGLE.replace('{id}', params.id),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const deleteMassMessage = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.DELETE_MASS_MESSAGE,
        method: 'DELETE',
        path: endpoints.ENDPOINT_ADMIN_CHAT_MASS_MESSAGESS_SINGLE.replace('{id}', params.id),
        params,
        requestParams: params,
    }));
};

