import { connect } from 'react-redux';
import Component from './component';

import { 
    create as createLead,
} from 'Redux/modules/public/leads/actions';

export default connect(
    state => ({
        utmData: state.user.utm,
    }),
    dispatch => ({ 
        actions: {
            createLead: dispatch(createLead),
        },
    })
)(Component);
