import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import {
    listConversations,
    listConversationMessages,
    sendConversationMessages,
} from 'Redux/modules/trainer/chat/actions';

export default connect(
    state => ({
        conversationsList: state.trainerChatConversations.list
    }),
    dispatch => ({
        actions: {
            listConversations: dispatch(listConversations),
            listConversationMessages: dispatch(listConversationMessages),
            sendConversationMessages: dispatch(sendConversationMessages),
        },
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-trainer-Chat" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);
