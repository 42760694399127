import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import { withVariables } from 'Utils/string';

import trainerRoutes from 'Routes/trainer';
import trainerModals from 'Routes/trainerModals';

import {
    PUBLIC_USER_LOGIN,
    TRAINER_DASHBOARD,
    TRAINER_ADEPTS,
    TRAINER_EVENTS,
    TRAINER_PROFILE,
    PUBLIC_MOBILE_APP,
    PUBLIC_SHOP,
    TRAINER_CHAT,
    TRAINER_BLOG,
} from 'Consts/routes';
import { USER_ROLE_TRAINER } from 'Consts/userRoles';
import { API_RESOURCE_NOTIFICATIONS_INTERNAL } from 'Consts/apiResources';
import { NOTIFICATION_STATUS_UNREAD } from 'Consts/notification';

import Sidebar from 'Components/layout/panel/Sidebar';
import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Topbar from 'Components/layout/panel/Topbar';
import Footer from 'Components/layout/panel/Footer';
import { TAB_DATA } from 'Components/pages/trainer/Profile/component';

export default class TrainerWrapper extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            logout: PropTypes.func.isRequired,
            listNotifications: PropTypes.func.isRequired,
            readNotification: PropTypes.func.isRequired,
            setAllAsRead: PropTypes.func.isRequired,
        }),
        profile: PropTypes.shape({
            roles: PropTypes.arrayOf(
                PropTypes.string.isRequired
            ),
        }),
    };
    static defaultProps = {};
    state = {
        authorized: false,
        width: 0,
        height: 0,
    };
    loadDataInterval = null;
    loadDataTimeout = null;

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.authorize();

        this.loadData();
        this.setIntervals();
        window.addEventListener('visibilitychange', this.loadDataWhenActiveTab);
    }

    componentDidUpdate = prevProps => {
        const { profile } = this.props;

        if (JSON.stringify(profile) !== JSON.stringify(prevProps.profile)) {
            this.authorize();
        }
    }

    updateWindowDimensions = () => {
        this.setState({ 
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    componentWillUnmount = () => {
        this.clearIntervals();
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('visibilitychange', this.loadDataWhenActiveTab);
    }

    setIntervals = () => {
        this.loadDataInterval = setInterval(() => this.loadData(), 60 * 3 * 1000); 
        this.loadDataTimeout = setTimeout(() => this.loadData(), 3000);
    }

    clearIntervals = () => {
        clearInterval(this.loadDataInterval);
        clearTimeout(this.loadDataTimeout);
    }

    loadDataWhenActiveTab = () => {
        if (document.visibilityState == 'visible') {
            this.setIntervals();
        }

        if (document.visibilityState == 'hidden') {
            this.clearIntervals();
        }
    }

    loadData = () => {
        this.loadNotifications();
    }

    loadNotifications = () => {
        const { actions } = this.props;

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.listNotifications()
                .then(response => {
                    const result = response.payload[API_RESOURCE_NOTIFICATIONS_INTERNAL];
                    this.setState({
                        notifications: result,
                    });
                });

            actions.listNotifications({ state: NOTIFICATION_STATUS_UNREAD })
                .then(response => {
                    const result = response.payload[API_RESOURCE_NOTIFICATIONS_INTERNAL];
                    this.setState({
                        unreadNotifications: result,
                    });
                });
        }, 500);
    }

    authorize = () => {
        const { profile, history, actions } = this.props;

        //Break if profile isn't available yet
        if (!profile) {
            return false;
        }

        //Go back to login if user has wrong role
        if (profile.role !== USER_ROLE_TRAINER) {
            actions.logout();
            history.push(PUBLIC_USER_LOGIN.path);
            return false;
        }

        //Show content
        this.setState({ authorized: true });

        return true;
    }

    readAllNotifications = () => {
        const { actions } = this.props;
        
        let confirmed = window.confirm('Czy na pewno chcesz oznaczyć wszystkie powiadomienia jako przeczytane?');

        if (confirmed) {
            actions.setAllAsRead().then(this.loadNotifications());
        }
    }

    render() {
        const { location, history, actions } = this.props;
        const { authorized, width, notifications, unreadNotifications } = this.state;
        const { readNotification, setAllAsRead } = actions;
        const Modal = trainerModals[location.hash] && trainerModals[location.hash] || null;

        return (
            !authorized
                ? (
                    <Spinner />
                )
                : (
                    <StyledComponent
                        props={this.props}
                        state={this.state}
                        styles={require('./styles')}
                        className="trainer-wrapper"
                    >
                        <div className="sidebar-container">
                            <Sidebar 
                                mainPath={TRAINER_DASHBOARD.path}
                                logoHeadline="Kalendarz"
                                navigation={[{
                                    key: 'dashboard',
                                    title: 'Kalendarz',
                                    link: TRAINER_DASHBOARD.path,
                                    icon: require('Theme/images/icons/calendar.svg'),
                                    visible: width < 1024,
                                }, {
                                    key: 'adepts',
                                    title: 'Adepci',
                                    link: TRAINER_ADEPTS.path,
                                    icon: require('Theme/images/icons/user.svg'),
                                }, {
                                    key: 'events',
                                    title: 'Wydarzenia',
                                    link: TRAINER_EVENTS.path,
                                    icon: require('Theme/images/icons/training.svg'),
                                }, {
                                    key: 'chat',
                                    title: 'Czat',
                                    link: TRAINER_CHAT.path,
                                    icon: require('Theme/images/icons/chat.svg'),
                                }, {
                                    key: 'application',
                                    title: 'Aplikacja FitAdept',
                                    link: PUBLIC_MOBILE_APP.path,
                                    icon: require('Theme/images/icons/mobile-solid.svg'),
                                }, {
                                    key: 'blog',
                                    title: 'Blog',
                                    link: TRAINER_BLOG.path,
                                    icon: require('Theme/images/icons/blog.png'),
                                    isIconPng: true
                                }, {
                                    key: 'sklep',
                                    title: 'Sklep',
                                    icon: require('Theme/images/icons/wallet.svg'),
                                    link: PUBLIC_SHOP.path,
                                }, {
                                    key: 'logout',
                                    title: 'Wyloguj',
                                    icon: require('Theme/images/icons/logout.svg'),
                                    onClick: () => actions.logout()
                                        .then(() => {
                                            history.push(PUBLIC_USER_LOGIN.path);
                                        }),
                                }]}
                            />
                        </div>
                        <div className="view-container">
                            <div className="topbar-container">
                                <Topbar 
                                    profilePath={withVariables(TRAINER_PROFILE.path, {}, { tab: TAB_DATA })}
                                    unreadNotifications={unreadNotifications}
                                    notifications={notifications}
                                    onReadNotification={(id) => readNotification(id).then(this.loadNotifications)}
                                    onReadAllNotifications={() => this.readAllNotifications()}
                                    history={history}
                                />
                            </div>
                            <div className="view-page-container">
                                <Switch>
                                    {trainerRoutes}
                                </Switch>
                            </div>
                            <Footer />
                        </div>
                        {Modal ? (
                            <Modal
                                location={location}
                                history={history}
                            />
                        ) : null}
                    </StyledComponent>
                )
        );
    }
}
