import { connect } from 'react-redux';
import Component from './component';

import {
    listBigQuery,
} from 'Redux/modules/admin/bigQueryExport/actions';

export default connect(
    state => ({
        bigQueryReports: state.adminBigQueryReports.list,
    }),
    dispatch => ({
        actions: {
            listBigQuery: dispatch(listBigQuery),
        },
    })
)(Component);
