import moment from 'moment';
import { TIME_API_FORMAT } from 'Consts/date';

export default class CyclicEvent {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.originEvent = data.originEvent;
        this.lead = data.lead;
        this.user = data.user;
        this.frequencyType = data.frequencyType;
        this.eventStartTime = data.eventStartTime && moment.utc(data.eventStartTime, TIME_API_FORMAT).local().format(TIME_API_FORMAT)
        this.startAt = data.startAt && moment.utc(data.startAt).local();
        this.endAt = data.endAt && moment.utc(data.endAt).local();
        this.hasEvents = data.hasEvents;
        this.lastEvent = data.lastEvent;
        this.userOffer = data.userOffer;
    };
}