import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layoutAdmin/modals/ModalWrapper';
import ModalBody from 'Components/layoutAdmin/modals/ModalBody';
import ModalTitle from 'Components/layoutAdmin/modals/ModalTitle';
import Editor from 'Components/admin/bigQueryExport/SelectModels';

export default class AdminModalExportSelectModels extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSuccess: PropTypes.func,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        onSuccess: null,
        data: null,
    };
    state = {};

    onSuccess = () => {
        const { onSuccess } = this.props;
        return onSuccess();
    }

    render() {
        const { location, history, isOpen, onClose, onSubmit, data } = this.props;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
            >
                <StyledComponent className="admin-modal-export-select-models" styles={require('./styles')}>
                    <ModalBody>
                        <ModalTitle title="Modele do eksportu BigQuery" />
                        <Editor
                            location={location}
                            history={history}
                            onSuccess={this.onSuccess}
                            onSubmit={onSubmit}
                            data={data}
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
