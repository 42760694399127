import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { parseToQueryString, parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Input from 'Components/forms/Input';
import Select from 'Components/forms/MultiSelect';

export default class AdminPaginatedListFilterbar extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        filters: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                type: PropTypes.oneOf([
                    'select',
                    'text',
                ]),
                label: PropTypes.string.isRequired,
                placeholder: PropTypes.string,
                onChange: PropTypes.func,
                inputProps: PropTypes.object,
                visible: PropTypes.bool,
            })
        ),
        queryConfig: PropTypes.object,
        styleProps: PropTypes.object,
    };
    static defaultProps = {
        filters: [],
        queryConfig: {},
    };

    onChange = (name, value) => {
        const { location, history } = this.props;
        const { pathname, search } = location;
       
        if (search && search.includes('page')) {
            return history.push(
                parseToQueryString(pathname, {
                    ...parseQueryToObject(search),
                    [name]: value,
                    ['page']: '',
                })
            );
        }
        
        history.push(
            parseToQueryString(pathname, {
                ...parseQueryToObject(search),
                [name]: value,
            })
        );
    }

    getFilter = ({ name, label, type, inputProps, onChange, placeholder, styleProps, visible }) => {
        const { location } = this.props;
        const { search } = location;
        let value = parseQueryToObject(search)[name] || '';

        switch (type) {
            case 'text':
                return (
                    <Input
                        name={name}
                        label={label}
                        placeholder={placeholder || label}
                        value={value}
                        onChange={({ value }) => onChange ? onChange(name, value) : this.onChange(name, value)}
                        visible={visible}
                        {...(inputProps || {})}
                        {...styleProps}
                    />
                );
            case 'select':
                try {
                    value = JSON.parse(value);
                } catch (error) {
                    //Ignore
                }

                return (
                    <Select
                        name={name}
                        label={label}
                        value={value}
                        visible={visible}
                        onChange={({ value }) => {
                            value == null 
                                ? value = '' 
                                : value = JSON.stringify(value);

                            if (onChange) {
                                return onChange(name, value);
                            }

                            return this.onChange(name, value);
                        }}
                        {...(inputProps || {})}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const {
            filters,
            styleVersion,
            style,
        } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames([
                    'admin-paginated-list-filterbar',
                    `style-version-${styleVersion}`,
                    `style-${style}`,
                ])}
            >
                {filters.filter(elem => elem.visible !== false).map(filter => (
                    <div className="filter" key={filter.name}>
                        {this.getFilter(filter)}
                    </div>
                ))}
            </StyledComponent>
        );
    }
}
