import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_TRAINER_EVENTS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            userOfferId: params.userOfferId || undefined,
            leadId: params.leadId || undefined,
            userId: params.userId || undefined,
            locationId: params.locationId || undefined,
            allAdeptEvents: params.allAdeptEvents || undefined,
            category: params.category || undefined,
            excludedCategory: params.excludedCategory || undefined,
            startAtFrom: params.startAtFrom || undefined,
            startAtTo: params.startAtTo || undefined,
            fromDate: params.fromDate || undefined,
            untilDate: params.untilDate || undefined,
            eventCycleId: params.eventCycleId || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENT, params),
        params,
    }));
};

export const stats = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.STATS,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENTS_STATS, params),
        params,
        requestParams: {
            userOfferId: params.userOfferId || undefined,
            userId: params.userId || undefined,
            paidStatus: params.paidStatus || undefined,
            doneStatus: params.doneStatus || undefined,
            planned: params.planned || undefined,
            startAtFrom: params.startAtFrom || undefined,
            startAtTo: params.startAtTo || undefined,
        },
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENTS, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENT, params),
        params,
        requestParams: params,
    }));
};

export const cancel = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CANCEL,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENT_CANCEL, params),
        params,
        requestParams: params,
    }));
};

export const done = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.DONE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENT_DONE, params),
        params,
        requestParams: params,
    }));
};

export const suggestFreeSlots = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SUGGEST_FREE_SLOTS,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENTS_SUGGEST_FREE_SLOTS, params),
        params,
        requestParams: {
            duration: params.duration,
            startAt: params.startAt,
            endAt: params.endAt,
            userId: params.userId,
            locationId: params.locationId,
        },
    }));
};

export const reset = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.RESET,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENT_RESET, params),
        params,
        requestParams: params,
    }));
};

export const cancel24h = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CANCEL24H,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENT_CANCEL24H, params),
        params,
        requestParams: params,
    }));
};

export const cancelDiagnostic = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CANCEL_DIAGNOSTIC,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENT_CANCEL_DIAGNOSTIC, params),
        params,
        requestParams: params,
    }));
};

export const locationOccupancy = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LOCATION_OCCUPANCY,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENTS_LOCATION_OCCUPANCY, params),
        params,
        requestParams: params,
    }));
}

export const checkCycleSlotsAvailability = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CHECK_CYCLE_SLOTS_AVAILABILITY,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENTS_CYCLE_SLOTS_AVAILABILITY, params),
        params,
        requestParams: params,
    }));
}

export const createMany = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE_MANY,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_EVENTS_CREATE_MANY, params),
        params,
        requestParams: params,
    }));
}