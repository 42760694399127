export const ACTIVATE = 'adept/userOffers/ACTIVATE';
export const LIST = 'adept/userOffers/LIST';
export const SINGLE = 'adept/userOffers/SINGLE';
export const CREATE = 'adept/userOffers/CREATE';
export const CANCEL = 'adept/userOffers/CANCEL';
export const PAYMENT = 'adept/userOffers/PAYMENT';
export const REMOVE = 'adept/userOffers/REMOVE';
export const SINGLE_PAYMENT_URI = 'adept/userOffers/SINGLE_PAYMENT_URI';
export const DIAGNOSTIC_REPORTS = 'adept/userOffers/DIAGNOSTIC_REPORTS';
export const DIAGNOSTIC_REPORT = 'adept/userOffers/DIAGNOSTIC_REPORT';
export const INVITE = 'adept/userOffers/INVITE';
export const REMOVE_USER = 'adept/userOffers/REMOVE_USER';
export const GET_SUB_USERS = 'adept/userOffers/GET_SUB_USERS';