import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import PanelSidebarElement from 'Components/layout/panel/SidebarElement';
import Hamburger from 'Components/layout/Hamburger';

const PanelSidebar = ({ mainPath, navigation, logoHeadline, mainPathVisible }) => {
    const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);

    return (
        <StyledComponent
            styles={require('./styles')}
            className="panel-sidebar"
        >
            {mainPathVisible && (
                <div className="logo">
                    <NavLink to={mainPath}>
                        <img src={require('Theme/images/logo-color-glyph.png')} />
                        <p className="headline">{logoHeadline}</p>
                    </NavLink>
                </div>
            )}
            <Hamburger
                onClick={() => setIsMobileNavVisible(!isMobileNavVisible)}
                isOpen={isMobileNavVisible}
            />
            <nav
                className={classNames({
                    nav: true,
                    'mobile-visible': isMobileNavVisible,
                })}
            >
                <div className="navigation">
                    {navigation
                        .filter(element => element.visible !== false)
                        .map(element => 
                            <PanelSidebarElement
                                key={element.key}
                                name={element.key}
                                title={element.title}
                                icon={element.icon}
                                link={element.link}
                                onClick={() => {
                                    if(element.onClick) {
                                        element.onClick();
                                    }
                                    setIsMobileNavVisible(false);
                                }}
                                isLinkOutgoing={element.isLinkOutgoing}
                                isIconPng={element.isIconPng}
                            />
                        )
                    }
                </div>
            </nav>
        </StyledComponent>
    );
};

PanelSidebar.propTypes = {
    mainPath: PropTypes.string.isRequired,
    navigation: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            link: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
    logoHeadline: PropTypes.string,
};
PanelSidebar.defaultProps = {
    navigation: [],
    mainPathVisible: true,
};
export default PanelSidebar;
