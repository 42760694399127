import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        &.style-default{
            position: relative;
            margin-bottom: 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .input-external-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .input-label {
                    font-size: 1.2em;
                }
                .input-internal-wrapper {
                    border: none;
                    background-color: transparent;
                    width: auto;
                }
            }
        }

        &.style-light {
            position: relative;
            margin-bottom: 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .input-external-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .input-label {
                    font-size: 1.2em;
                    color: rgb(1 1 1);
                }
                .input-internal-wrapper {
                    border: none;
                    background-color: transparent;
                    width: auto;
                }
            }
        }
    `;
