import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    padding: 1em 0;
    background-color: ${variables.dpc_backgroundBlack};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0em;

    .layout-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        .logo {
            width: 8.5em;
        }

        .nav {
            display: flex;

            margin-left: 2em;
            .nav-item {
                margin-right: 1em;

                a {
                    color: white;
                }
            }
        }
    }

    .info {
        color: #666666;
        margin-top: 1em;
        margin-bottom: 1em;
        width: 100%;

        .info-text {
            font-weight: 300;
            text-align: left;
            font-size: .9em;
            text-align-last: center;
            color: white;
        }

        .expand-trigger { 
            font-weight: 600;
            margin-left: .5em;
            text-decoration: underline;
            cursor: pointer;
            
            &:hover {
                color: white;
            }
        }
    }
`;
