import { css } from '@emotion/core';

export default () =>
    css({
        position: 'fixed',
        bottom: '1em',
        right: '1em',
        maxWidth: '85%',
        width: '20em',
        zIndex: 9,

        '.notification': {
            cursor: 'pointer',
            background: 'white',
            marginTop: '.5em',
            textAlign: 'left',
            padding: '1em',
            borderTop: '.3em solid #333333',
            boxShadow: '0 0 1em 0 rgba(0,0,0,0.3)',
            transition: '.1s opacity ease-in-out',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',

            '&:hover': {
                opacity: .7,
            },

            '.notification-title': {
                color: '#333333',
                fontSize: '1em',
            },
            '.notification-body': {
                color:' #666666',
                fontSize: '.9em',
            },
        },
    });

