import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';
import {
    single,
} from 'Redux/modules/trainer/events/actions';

export default connect(
    state => ({
        eventsMap: state.trainerEvents.mapById,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        event: stateProps.eventsMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-adept-EventsManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);