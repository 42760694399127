import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';
import { getProvider } from 'Services/Cookies';

import * as types from './types';
import * as endpoints from 'Consts/api';
import { COOKIE_UTM_LEAD_DATA } from 'Consts/cookies';

import getStore from 'Redux';

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_PUBLIC_USER_OFFER, params),
        params,
    }));
};

export const payment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.PAYMENT,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_PUBLIC_USER_OFFER_PAYMENT, params),
        params,
        requestParams: params,
    }));
};

export const reservation = dispatch => (params = {}) => {
    const utmData = getStore().getState().user.utm;
    getProvider().set(COOKIE_UTM_LEAD_DATA, JSON.stringify(utmData));
    
    return dispatch(request({
        reduxType: types.RESERVATION,
        method: 'POST',
        path: endpoints.ENDPOINT_PUBLIC_USER_OFFERS,
        params,
        requestParams: {
            ...params,
            ...utmData,
        },
    }));
};
