import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import ExternalWrapper from 'Components/forms/InputExternalWrapper';
import InternalWrapper from 'Components/forms/InputInternalWrapper';
import ErrorMessage from 'Components/forms/ErrorMessage';
import InputHelper from 'Components/forms/InputHelper';
import InputLabel from 'Components/forms/InputLabel';
import InputIcon from 'Components/forms/InputIcon';

export default class FormMultiSelectComponent extends React.Component {
    static displayName = 'FormMultiSelectComponent';

    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.any,
        onChange: PropTypes.func,
        onFormChange: PropTypes.func,

        icon: PropTypes.string,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        required: PropTypes.bool,
        className: PropTypes.string,
        style: PropTypes.oneOf([
            'default',
            'dark',
            'transparent',
            'transparentLight',
            'transparentV2',
            'version-2',
        ]),
        size: PropTypes.oneOf([
            'small',
            'medium',
            'large',
        ]),

        errorMessage: PropTypes.string,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any,
                label: PropTypes.string.isRequired,
            })
        ),
        multi: PropTypes.bool,
        reactSelectProps: PropTypes.object,

        dynamic: PropTypes.bool,
        onLoad: PropTypes.func,
        onMapResponse: PropTypes.func,
        onMapOption: PropTypes.func,
        
        link: PropTypes.shape({
            enabled: PropTypes.bool.isRequired,
            label: PropTypes.any,
            onClick: PropTypes.func,
        }),
        visible: PropTypes.bool,
        loadOnOnLoadChange: PropTypes.bool,
        loadTimestamp: PropTypes.number,
    };

    static defaultProps = {
        onChange: null,
        onFormChange: null,
        icon: null,
        placeholder: null,
        label: '',
        errorMessage: '',
        multi: false,
        reactSelectProps: {},
        value: '',
        required: false,
        disabled: false,
        className: '',

        options: [],

        dynamic: false,
        onLoad: null,
        onMapResponse: null,
        onMapOption: null,

        size: 'medium',
        style: 'default',

        link: null,
        visible: true,
        loadOnOnLoadChange: false,
        loadTimestamp: null,
    }

    state = {
        defaultOptions: [],
    };

    componentDidMount = () => {
        const { dynamic, value } = this.props;

        if (dynamic) {
            this.onDynamicLoad(value)
                .then(options => {
                    this.setState({ defaultOptions: options });
                });
        }
    }

    componentDidUpdate = prevProps => {
        const { onLoad, dynamic, value, loadOnOnLoadChange, loadTimestamp } = this.props;

        if(dynamic && loadOnOnLoadChange && prevProps.onLoad !== onLoad) {
            this.onDynamicLoad(value)
                .then(options => {
                    this.setState({ defaultOptions: options });
                });
        }

        if (loadTimestamp !== prevProps.loadTimestamp) {
            this.onDynamicLoad(value)
                .then(options => {
                    this.setState({ defaultOptions: options });
                });
        }
    }

    onChange = event => {
        const { name, onChange, onFormChange } = this.props;

        const data = {
            name,
            value: event,
        };

        if (onChange) {
            onChange(data);
        }

        if (onFormChange) {
            onFormChange(data);
        }

        return data;
    }

    onMapOption = element => {
        const { onMapOption } = this.props;

        if (onMapOption) {
            return onMapOption(element);
        }

        return {
            value: element && element.id || element,
            label: element && element.name || element,
        };
    }

    onDynamicLoad = input => {
        const { onLoad, onMapResponse } = this.props;

        return onLoad(input)
            .then(response => {
                if (!response) return [];

                const elements = onMapResponse(response);

                return elements.map(element => this.onMapOption(element));
            })
            .catch(error => {
                return [];
            });
    }

    render() {
        const { 
            className, 
            value, 
            name, 
            icon, 
            placeholder, 
            label, 
            errorMessage, 
            required,
            disabled, 
            multi, 
            options, 
            dynamic, 
            style, 
            size, 
            reactSelectProps,
            link,
            visible,
        } = this.props;
        const { defaultOptions } = this.state;

        const inputProps = {
            name,
            required,
            isDisabled: disabled,
            placeholder,
            multi,
            joinValues: true,
            simpleValue: dynamic ? false : true,
            onChange: this.onChange,
            value: value || '',
            isClearable: true,
            ...reactSelectProps,
            visible,
        };

        if (!dynamic && typeof value !== 'object') {
            inputProps.value = options.find(option => option.value === value);
        }

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames({
                    'multi-select': true,
                    [className]: true,
                    [`style-${style}`]: true,
                    [`size-${size}`]: true,
                    [`disabled-${disabled}`]: true,
                    error: Boolean(errorMessage),
                },
                `visible-${visible}`
                )}
            >
                <ExternalWrapper>
                    {label || placeholder ? <InputLabel label={label || placeholder} floating={true} style={style} required={required} /> : null}
                    <InternalWrapper style={style} error={Boolean(errorMessage)}>
                        {dynamic ?
                            <AsyncSelect
                                {...inputProps}
                                loadOptions={this.onDynamicLoad}
                                defaultOptions={defaultOptions}
                                cacheOptions={false}
                                ignoreAccents={false}
                                placeholder={label || placeholder}
                            />
                            :
                            <Select
                                {...inputProps}
                                options={options}
                            />
                        }
                    </InternalWrapper>
                    {icon &&
                        <div className="input-icon">
                            <i className={`${icon}`} />
                        </div>
                    }
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                    {link && link.enabled && (
                        <a className="link-wrapper" onClick={link.onClick}>
                            {link.label}
                        </a>
                    )}
                </ExternalWrapper>
            </StyledComponent>
        );
    }
}
