import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_TRAINER_MEASUREMENTS_CONFIG, params),
        params,
        requestParams: {
            ...params,
            userId: params.id,
        },
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_TRAINER_MEASUREMENTS_CONFIG, params),
        params,
        requestParams: params,
    }));
};

export const removeType = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE_TYPE,
        method: 'DELETE',
        path: endpoints.ENDPOINT_TRAINER_MEASUREMENT_TYPE,
        params,
        requestParams: params,
    }));
};