import TagManager from 'react-gtm-module';

import { isBrowser } from 'Utils/browser';

export function init() {
    if (!isBrowser()) return false;

    TagManager.initialize({
        gtmId: process.env.GTM_CONTAINER_ID,
        dataLayer: {
            originalLocation:
                document.location.protocol +
                '//' +
                document.location.hostname +
                document.location.pathname +
                document.location.search,
        },
    });

    return true;
}

export function trackEvent(name, payload = {}) {
    if (isGtm()) {
        dataLayer.push({
            event: name,
            ...payload,
        });
    }
}

function isGtm() {
    return typeof dataLayer !== 'undefined';
}