import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StyledComponent from 'Components/core/StyledComponent';
import Head from 'Components/core/Head';
import ResponseStatus from 'Components/core/ResponseStatus';

export default class NotFoundPage extends Component {
    render() {
        return (
            <ResponseStatus statusCode={404}>
                <StyledComponent styles={require('./styles')}>
                    <Head title="Not found" />
                    <div className="container">
                        <div className="content">
                            <h2 className="title">
                                Przepraszamy, strona o podanym adresie nie istnieje
                                <span className="icon-wrapper">
                                    <FontAwesomeIcon icon={['fa', 'exclamation']} />
                                </span>
                            </h2>
                            <NavLink
                                exact
                                to="/"
                                className="link"
                                activeclassname="active"
                            >
                                Wróć na stronę główną
                            </NavLink>
                        </div>
                    </div>
                </StyledComponent>
            </ResponseStatus>
        );
    }
}
