import React from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class InvoiceForm extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            presignFile: PropTypes.func.isRequired,
            updateProfile: PropTypes.func.isRequired,
        }),
        profile: PropTypes.shape({
            companyName: PropTypes.string,
            nip: PropTypes.string,
            address: PropTypes.string,
            postalCode: PropTypes.string,
            city: PropTypes.string,
        }),
        inputStyleProps: PropTypes.object,
        submitButtonStyle: PropTypes.oneOf([
            'faPrimary', 'hollow',
        ]),
    };
    static defaultProps = {
        submitButtonStyle: 'faPrimary',
    };

    state = {
        formState: {
            //eslint-disable-next-line
            ...this.props.profile,
        },
    }

    onSubmit = data => {
        const { actions } = this.props;

        return actions.updateProfile(data);
    }

    render() {
        const { history, location, inputStyleProps, submitButtonStyle } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent styles={require('./styles')} className='profile-form'>
                <ElementEditor
                    location={location}
                    history={history}
                    details={[]}
                    controls={[]}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        submitButtonStyle,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'companyName',
                            label: 'Firma',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'input',
                            name: 'nip',
                            label: 'NIP',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'input',
                            name: 'address',
                            label: 'Adres',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'input',
                            name: 'postalCode',
                            label: 'Kod pocztowy',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'input',
                            name: 'city',
                            label: 'Miasto',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}