import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        width: 100%;
        
        .controls {
            display: flex;
            justify-content: space-between;
            margin-top: 3em;
        }

    @media (max-width: ${variables.tabletS}) {
        .controls {
            flex-direction: column;

            .component-button {
                .button {
                    width: 100%;
                    margin-top: 1em
                }

                &:first-of-type {
                    .button {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    `;
