import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { shorten as shortenString } from 'Utils/string';

export default class Head extends Component {
    static propTypes = {
        children: PropTypes.any,
        title: PropTypes.string,
        description: PropTypes.string,
        canonical: PropTypes.string,
        image: PropTypes.string,
        isSmallImage: PropTypes.bool,
        robots: PropTypes.string,
        relPrev: PropTypes.string,
        relNext: PropTypes.string,
        latitude: PropTypes.string,
        longitude: PropTypes.string,
    };

    static defaultProps = {
        children: null,
        title: '',
        description: '',
        canonical: '',
        robots: 'index, follow',
        image: null,
        isSmallImage: false,
        relPrev: '',
        relNext: '',
        latitude: null,
        longitude: null,
    };

    render() {
        let { title, description, canonical, image, isSmallImage, relPrev, relNext, latitude, longitude, children, robots } = this.props;

        if (process.env.APP_ENV !== 'production') {
            robots = 'noindex';
        }

        title = title.replace(/<(?:.|\n)*?>/gm, '');
        description = shortenString(description.replace(/<(?:.|\n)*?>/gm, ''), 160);

        //Remove trailing slash
        if (canonical.slice(-1) === '/') {
            canonical = canonical.substring(0, canonical.length - 1);
        }

        return (
            <Helmet>
                <title>{`${title} - ${process.env.SEO_TITLE}`}</title>
                <meta name="twitter:title" content={`${title} - ${process.env.SEO_TITLE}`} />
                <meta property="og:title" content={`${title} - ${process.env.SEO_TITLE}`} />

                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta name="twitter:description" content={description} />

                <link rel="canonical" href={`${canonical}`} />
                <meta property="og:url" content={`${canonical}`} />
                <meta name="twitter:url" content={`${canonical}`} />

                <meta property="og:image" content={image} />
                <meta name="twitter:image" content={image} />
                {!isSmallImage ? <meta name="twitter:card" content="summary_large_image" /> : null}

                {relPrev ? <link rel="prev" href={`${relPrev}`} /> : null}
                {relNext ? <link rel="next" href={`${relNext}`} /> : null}
                {latitude ? <meta property="place:location:latitude" content={latitude} /> : null}
                {longitude ? <meta property="place:location:longitude" content={longitude} /> : null}

                <meta name="robots" content={robots} />
                {children}
            </Helmet>
        );
    }
}
