import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import StyledComponent from 'Components/core/StyledComponent';

const NotFound = ({ content }) => (
    <StyledComponent
        className="not-found"
        styles={require('./styles')}
    >
        <section className="content">
            <h2 className="text-statement">{content}</h2>
            <FontAwesomeIcon icon={['fa', 'search']} />
        </section>
    </StyledComponent>
);

NotFound.propTypes = {
    content: PropTypes.string,
};

NotFound.defaultProps = {
    content: 'Element nie istnieje',
};

export default NotFound;
