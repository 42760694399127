import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .form-title {
            margin-bottom: 1.5em;
            padding-bottom: .5em;
            color: ${variables.dpc_fontDark};
            font-size: 1.4em;
            font-weight: 300;
            border-bottom: 1px solid #EEEEEE;
            text-align: left;
        }

        form > #form-error-message-component {
            text-align: center;
            margin-bottom: 1em;
            font-size: 1em;

        }

        .form-section {
            margin-bottom: 2em;
            border-radius: .5em;
            box-shadow: 0 0 1em 0 rgba(0,0,0,0.1);
            background: white;

            .form-section-title {
                border-radius: .5em .5em 0 0;
                padding: .5em 1em;
                background: #fecc37;
                font-weight: 300;
                font-size: 1.4em;
                color: white;
            }

            .form-section-content {
                padding: 2em;
            }
        }

        .input-group {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .form-element {
                margin: 0 .5em;

                &:first-of-type {
                    margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        &.fullpage {
            max-width: 30em;

            .form-footer {
                width: 100%;
                padding-top: 1em;
                margin-top: 1em;
                border-top: 1px solid #EEEEEE;
            }
        }
    `;

