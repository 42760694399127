import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';

export default class FormInputIconComponent extends React.Component {
    static propTypes = {
        icon: PropTypes.shape({
            type: PropTypes.oneOf(['fa', 'path', 'element']).isRequired,
            source: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]).isRequired,
        }),
    }
    static defaultProps = {}

    getIcon = icon => {
        switch (icon.type) {
            case 'fa':
                return (<i className={icon.source} />);
            case 'path':
                return (<img src={icon.source} />);
            case 'element':
                return (icon.source);
        }
    }

    render() {
        const { icon } = this.props;

        return (
            <div role="component" id="form-input-icon">
                {this.getIcon(icon)}
            </div>
        );
    }
}
