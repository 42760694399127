import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';
import moment from 'moment-timezone';

import { DATE_DEFAULT_TIMEZONE } from 'Consts/date';

import Main from './main';
import Logger from 'Services/Logger';

Logger.init();

moment.tz.link('Europe/Warsaw|Poland');
moment.tz.setDefault(DATE_DEFAULT_TIMEZONE);

loadableReady(() => {
    ReactDOM.render(
        <Main />,
        document.getElementById('root')
    );
});

// if (module.hot) {
//     // keep in mind - here you are configuring HMR to accept CHILDREN MODULE
//     // while `hot` would configure HMR for the CURRENT module
//     module.hot.accept('./main', () => {
//         // if you are using harmony modules ({modules:false})
//         ReactDOM.render(
//             <Main />,
//             document.getElementById('root')
//         );
//     });
// }