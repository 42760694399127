import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        position: relative;
        width: 100%;

        input {
            font-weight: 300;
            width: 100%;
            outline: none;
            border: none;

            padding: 0 1.5em;

            height: 4em;

            &::placeholder {
                font-weight: 400;
            }
        }
        
        #form-error-message-component {
            text-align: left;
            margin-top: .2em;
            margin-left: 1em;
        }

        /* Sizes */
        &.size-small {
            input {
                font-size: .8em;
            }
        }

        &.size-medium {
            input {
                font-size: 1em;
            }
        }

        &.size-large {
            input {
                font-size: 1.3em;
            }
        }

         /* Style versions */
         &.style-version-1 {
            input {
                color: #333333;

                &::placeholder {
                    color: #979797;
                }
            }

            &.style-transparentV2 {
                input {
                    background: transparent;
                    color: #EEEEEE;
                }
            }
        }

        &.style-version-2 {
            input {
                color: ${variables.dpc_fontDark};
                
                width: 100%;
                
                font-size: 1em;
                font-weight: 300;

                &:focus {
                    color: ${variables.dpc_fontDark};
                }

                &::placeholder {
                    color: #979797;
                }
            }
        }

        &.visible-false {
            display: none;
        }
    `;
