export const LEAD_SOURCE_FRONT_APP = 'frontApp';
export const LEAD_SOURCE_LEADADS = 'leadAds';
export const LEAD_SOURCE_RECOMMENDATION = 'recomendation';
export const LEAD_SOURCE_REGISTRATIONS = 'registrations';
export const LEAD_SOURCE_LANDINGI = 'landingi';
export const LEAD_SOURCE_VOUCHER = 'voucher';
export const LEAD_SOURCE_RESERVATIONS = 'reservations';
export const LEAD_SOURCE_SHOP = 'shop';
export const LEAD_SOURCE_PREFERENCE_SURVEY = 'preferenceSurvey';
export const LEAD_SOURCE_SURVEY_ONLINE = 'online';
export const LEAD_SOURCE_UNDEFINED = 'udefined';

export const LEAD_SOURCES = [{
    value: LEAD_SOURCE_FRONT_APP,
    label: 'LP - Dołącz',
}, {
    value: LEAD_SOURCE_LEADADS,
    label: 'Lead Ads',
}, {
    value: LEAD_SOURCE_RECOMMENDATION,
    label: 'Polecenie',
}, {
    value: LEAD_SOURCE_LANDINGI,
    label: 'Landingi',
}, {
    value: LEAD_SOURCE_VOUCHER,
    label: 'Voucher',
},  {
    value: LEAD_SOURCE_RESERVATIONS,
    label: 'Rezerwacje',
},  {
    value: LEAD_SOURCE_SHOP,
    label: 'Sklep',
}, {
    value: LEAD_SOURCE_PREFERENCE_SURVEY,
    label: 'Ankieta preferencji',
}, {
    value: LEAD_SOURCE_SURVEY_ONLINE,
    label: 'Ankieta - online',
}, {
    value: LEAD_SOURCE_REGISTRATIONS,
    label: 'Rejestracja',
}, {
    value: LEAD_SOURCE_UNDEFINED,
    label: 'Niezdefiniowane',
}];

export const LEAD_SOURCE_LABELS = {
    [LEAD_SOURCE_FRONT_APP]: 'LP - Dołącz',
    [LEAD_SOURCE_LEADADS]: 'Lead Ads',
    [LEAD_SOURCE_RECOMMENDATION]: 'Polecenie',
    [LEAD_SOURCE_LANDINGI]: 'Landingi',
    [LEAD_SOURCE_VOUCHER]: 'Voucher',
    [LEAD_SOURCE_PREFERENCE_SURVEY]: 'Ankieta preferencji',
    [LEAD_SOURCE_SURVEY_ONLINE]: 'Ankieta - online',
    [LEAD_SOURCE_REGISTRATIONS]: 'Rejestracja',
};

export const LEAD_MEDIUM_LP = 'lp';
export const LEAD_MEDIUM_APP = 'app';
export const LEAD_MEDIUM_WEB_APP = 'webApp';
export const LEAD_MEDIUM_SHOP = 'shop';
export const LEAD_MEDIUM_ADMIN = 'admin';
export const LEAD_MEDIUM_TRAINER = 'trainer';
export const LEAD_MEDIUM_ZAPIER = 'zapier';
export const LEAD_MEDIUM_UNDEFINED = 'udefined';

export const LEAD_MEDIUMS = [{
    value: LEAD_MEDIUM_LP,
    label: 'Landing page',
}, {
    value: LEAD_MEDIUM_APP,
    label: 'Aplikacja mobilna',
}, {
    value: LEAD_MEDIUM_WEB_APP,
    label: 'Aplikacja webowa',
}, {
    value: LEAD_MEDIUM_SHOP,
    label: 'Sklep',
}, {
    value: LEAD_MEDIUM_ADMIN,
    label: 'Panel admina',
}, {
    value: LEAD_MEDIUM_TRAINER,
    label: 'Panel trenera',
}, {
    value: LEAD_MEDIUM_ZAPIER,
    label: 'Zapier',
}, {
    value: LEAD_MEDIUM_UNDEFINED,
    label: 'Niezdefiniowane',
}];

export const LEAD_MEDIUM_LABELS = {
    [LEAD_MEDIUM_LP]: 'Landing page',
    [LEAD_MEDIUM_APP]: 'Aplikacja mobilna',
    [LEAD_MEDIUM_WEB_APP]: 'Aplikacja webowa',
    [LEAD_MEDIUM_SHOP]: 'Sklep',
    [LEAD_MEDIUM_ADMIN]: 'Panel admina',
    [LEAD_MEDIUM_TRAINER]: 'Panel trenera',
    [LEAD_MEDIUM_ZAPIER]: 'Zapier',
};
