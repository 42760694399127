import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';
import { getLocationBySlug } from 'Redux/modules/public/locations/actions';
import { singleBySlug as singleOfferBySlug } from 'Redux/modules/public/offers/actions';
import { single as singleProduct } from 'Redux/modules/public/products/actions';
import { singleBySlug as singleShopBySlug } from 'Redux/modules/public/shopPages/actions';

export default connect(
    state => ({
        shopPageMap: state.publicShopPages.mapById,
    }),
    dispatch => ({
        actions: {
            getLocationBySlug: dispatch(getLocationBySlug),
            singleProduct: dispatch(singleProduct),
            singleOfferBySlug: dispatch(singleOfferBySlug),
            singleShopBySlug: dispatch(singleShopBySlug),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        shopPage: stateProps.shopPageMap['sobieski'],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-public-ReservationsRehabSobieski" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);
