import moment from 'moment';

export default class ShopPage {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.name = data.name;
        this.slug = data.slug;
        this.lagValue = data.lagValue;
        this.lagType = data.lagType;
        this.shopType = data.shopType;
    };
}