import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class FormInputInternalWrapperComponent extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        error: PropTypes.bool,
        style: PropTypes.oneOf([
            'default',
            'transparent',
            'transparentLight',
            'faPrimary',
            'transparentV2',
            'version-2',
            'light',
        ]),
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
        border: PropTypes.oneOf([
            '', 'default', 'light', 'light-2',
        ]),
        composition: PropTypes.oneOf([
            '',
            'measurements',
        ]),
    }
    static defaultProps = {
        children: null,
        error: false,
        styleVersion: 1,
        border: 'default',
        composition: '',
    }

    render() {
        const { children, error, style, styleVersion, border, composition } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames({
                    'input-internal-wrapper': true,
                    [`style-${style}`]: true,
                    error,
                    [`style-version-${styleVersion}`]: true,
                    [`border-${border}`]: true,
                    [`composition-${composition}`]: true,
                })}
            >
                {children}
            </StyledComponent>
        );
    }
}
