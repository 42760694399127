import {
    ARTICLE_STATUSES,
} from 'Consts/articles';

export const getStatusLabel = article => {
    if(!article) {
        return { found: false, label: 'Nieznany' };
    }

    const { status } = article;
    const option = ARTICLE_STATUSES.find(articleStatus => articleStatus.key === status);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label, state: option.state };
};