import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import ExternalWrapper from 'Components/forms/InputExternalWrapper';
import InternalWrapper from 'Components/forms/InputInternalWrapper';
import ErrorMessage from 'Components/forms/ErrorMessage';

export default class FormSwitchComponent extends React.Component {
    static displayName = 'FormSwitchComponent';

    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        onFormChange: PropTypes.func,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        errorMessage: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        className: PropTypes.string,
        style: PropTypes.oneOf([
            'default', 'light',
        ]),
        size: PropTypes.oneOf([
            'small',
            'medium',
            'large',
        ]),
    };

    static defaultProps = {
        size: 'medium',
        style: 'default',
        onChange: null,
        onFormChange: null,
        label: '',
        errorMessage: '',
        value: '',
        disabled: false,
        className: '',
    }

    onChange = value => {
        const { name, onChange, onFormChange } = this.props;

        const data = {
            name,
            value,
        };

        if (onChange) {
            onChange(data);
        }

        if (onFormChange) {
            onFormChange(data);
        }

        return data;
    }

    render() {
        const { value, name, label, errorMessage, disabled, style, size, className } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'form-element',
                    'switch',
                    className,
                    `style-${style}`,
                    `size-${size}`
                )}
            >
                <ExternalWrapper>
                    {label && <p className="input-label">{label}</p>}
                    <InternalWrapper style={style}>
                        <Switch
                            name={name}
                            checked={value ? true : false}
                            onChange={this.onChange}
                            disabled={disabled}
                        />
                    </InternalWrapper>
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </ExternalWrapper>
            </StyledComponent>
        );
    }
}
