import moment from 'moment';

export default class BigQueryExport {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.updatedAt = data.updatedAt && moment.utc(data.updatedAt).local();
        this.startAt = data.startAt && moment.utc(data.startAt).local();
        this.endAt = data.endAt && moment.utc(data.endAt).local();
        this.status = data.status;
        this.scope = data.scope;
    };
}