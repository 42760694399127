import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class FormErrorMessageComponent extends React.Component {
    static propTypes = {
        message: PropTypes.string,
        style: PropTypes.oneOf(['default', 'form']),
    };
    static defaultProps = {
        style: 'default',
    };

    render() {
        const { message, style } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'form-error-message-component',
                    style
                )}>
                {message}
            </StyledComponent>
        );
    }
}
