import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import {
    single,
} from 'Redux/modules/trainer/userOffers/actions';

export default connect(
    state => ({
        userOffersMap: state.trainerUserOffers.mapById,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        userOffer: stateProps.userOffersMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-trainer-UserOffer" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);