import { connect } from 'react-redux';

import { authPasswordChange as passwordChange } from 'Redux/modules/user/actions';

import Component from './component';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            passwordChange: dispatch(passwordChange),
        },
    })
)(Component);