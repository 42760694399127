import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import variables from 'Theme/styles/vars';
import CloseButton from 'Components/layout/modals/ModalCloseButton';

Modal.setAppElement('#root');

export default class ModalWrapperComponent extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        CloseOnOverlayClick: PropTypes.bool,
        children: PropTypes.any,
        onClose: PropTypes.func,
        styleVariant: PropTypes.oneOf(['default', 'dark']),
        showCloseButton: PropTypes.bool,
    };

    static defaultProps = {
        isOpen: true,
        CloseOnOverlayClick: true,
        children: null,
        onClose: null,
        styleVariant: 'default',
        showCloseButton: true,
    };

    onClose = () => {
        const { history, location, onClose } = this.props;

        if (onClose) {
            onClose();
        } else {
            //Remove hash from url
            history.push(`${location.pathname}${location.search ? '?' + location.search : ''}`);
        }
    }

    render() {
        const { isOpen, CloseOnOverlayClick, children, styleVariant, showCloseButton } = this.props;

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={this.onClose}
                style={{
                    content: {
                        backgroundColor: 'transparent',
                        border: 'none',
                        outline: 'none',
                        fontFamily: variables.fontPrimary,

                        zIndex: 99,
                        maxWidth: '96%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                    },
                    overlay: {
                        zIndex: 999,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,.85)',
                        overflow: 'auto',
                    },
                }}
                shouldCloseOnOverlayClick={CloseOnOverlayClick}
                className="modal-wrapper"
            >
                {showCloseButton && (
                    <CloseButton onClick={this.onClose} styleVariant={styleVariant} />
                )}
                {children}
            </Modal>
        );
    }
}
