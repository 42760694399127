import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css({
        display: 'block',
        width: '100%',
        position: 'relative',

        '&.default': {
            fontSize: '.8em',
            color: '#FF1414;',
            textAlign: 'left',
            marginTop: '.8em',
        },
        '&.form': {
            fontSize: '1.2em',
            color: '#FF1414;',
            textAlign: 'center',
        },
    });
