import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: relative;
    width: 100%;

    .list {
        padding: 1em;
    }

    .link-wrapper {
        text-align: right;
        display: block;
        color: #666666;
        text-decoration: underline;
        margin-top: 1em;
        cursor: pointer;
    }

    .inputs-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        
        .row-number {
            padding-right: 1em;
            margin-top: 1em;

            .row-number-text {
                font-size: 3em;
                color: #e61a37;
                font-weight: 900;
            }

            .input-row-element-controls {
               margin-top: 1em;

                .component-button {
                    width: 100%;

                    button {
                        width: 100%;

                        span {
                            svg {
                                margin-right: 0;
                            }
                        }
                    }
                }

                &.hidden {
                    visibility: hidden;
                }
           }

           .predefined-options {
                margin-top: 1em;
                padding-top: 1em;
                border-top: 1px solid #EEE;

                .redefined-option {
                    display: block;
                    margin-bottom: 1em;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
        .row-inputs {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 1em;
            padding-bottom: 1em;
            border-bottom: 1px solid #EEE;

            .input-row-element {
                width: 100%;

                .switch {
                    margin-bottom: 0;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
                margin-right: 0;
            }
        }
    }
`;
