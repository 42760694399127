import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    padding: 1em 0;
    background-color: ${variables.dpc_backgroundBlack};
    width: 100%;

    .layout-container {
        display: flex;
        align-items: center;
    
        .logo {
            width: 8.5em;
        }

        .nav {
            display: flex;

            margin-left: 2em;
            .nav-item {
                margin-right: 1em;

                a {
                    color: white;
                }
            }

        }
    }
    


`;
