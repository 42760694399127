import React from 'react';
import loadable from '@loadable/component';
import Spinner from 'Components/layout/Spinner';
import { connect } from 'react-redux';

import {
    passwordTokenVerify,
} from 'Redux/modules/user/actions';

export default connect(
    state => ({
        profile: state.user.profile,
        authToken: state.user.authToken,
    }),
    dispatch => ({
        actions: {
            passwordTokenVerify: dispatch(passwordTokenVerify),
        },
    }),
)( 
    loadable(() => import(
        /* webpackChunkName: "page-publicUser-Activate" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);