import { reduxRequest as request } from 'Services/Api';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_ARTICLE_COMMENTS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            status: fromSelectObject(params.status) || undefined,
            articleId: params.articleId || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_COMMENT, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_COMMENTS, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_COMMENT, params),
        params,
        requestParams: params,
    }));
};

export const remove = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_COMMENT, params),
        params,
        requestParams: params,
    }));
};

export const changeStatus = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CHANGE_STATUS,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ARTICLE_COMMENT_CHANGE_STATUS, params),
        params,
        requestParams: params,
    }));
};