import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import {
    singleMassMessage,
} from 'Redux/modules/admin/chat/actions';

export default connect(
    state => ({
        massMessagesDataMap: state.adminChat.massMessagesByIdMap,
    }),
    dispatch => ({
        actions: {
            singleMassMessage: dispatch(singleMassMessage),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        massMessage: stateProps.massMessagesDataMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-ChatMassMessageManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);
