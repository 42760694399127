import moment from 'moment';
import { extendMoment } from 'moment-range';

import { 
    DATETIME_API_FORMAT, 
    DATETIME_DEFAULT_FORMAT,
    DATE_DEFAULT_FORMAT,
    DATE_API_FORMAT,
    TIME_DEFAULT_FORMAT,
    TIME_API_FORMAT,
    TRAINER_PLANNER_TIME_INTERVALS,
} from 'Consts/date';
import { PRODUCT_DURATION_TYPE_HOURS } from 'Consts/products';

export function getFormattedDate(date, format = 'datetime') {
    if (date == null) return null;

    switch (format) {
        case 'datetime':
            format = DATETIME_DEFAULT_FORMAT;
            break;
        case 'date':
            format = DATE_DEFAULT_FORMAT;
            break;
        case 'time':
            format = TIME_DEFAULT_FORMAT;
            break;
    }

    return moment(date).format(format);
}

export function toApiFormat(date, format = 'datetime', setUtc = false) {
    if (date == null) return null;
    
    switch (format) {
        case 'datetime':
            format = DATETIME_API_FORMAT;
            break;
        case 'date':
            format = DATE_API_FORMAT;
            break;
        case 'time':
            format = TIME_API_FORMAT;
            break;
    }

    if (setUtc) {
        return moment(date).utc().format(format);
    }

    return moment(date).format(format);
}

export function toAppFormat(date, format = 'datetime') {
    if (date == null) return null;
    
    switch (format) {
        case 'datetime':
            format = DATETIME_API_FORMAT;
            break;
        case 'date':
            format = DATE_API_FORMAT;
            break;
        case 'time':
            format = TIME_API_FORMAT;
            break;
    }

    return moment.utc(date).local().format(format);
}

export function translateDate(date, amount) {    
    if (amount == 1) {
        switch(date) {
            case 'day':
                return 'dzień';
            case 'days':
                return 'dzień';
            case 'month':
                return 'miesiąc';
            case 'months':
                return 'miesiąc';
            case 'year':
                return 'rok';
            case 'years':
                return 'rok';
        }
    }

    switch(date) {
        case 'day':
            return 'dzień';
        case 'days':
            return 'dni';
        case 'month':
            return 'miesiąc';
        case 'months':
            return 'miesiące';
        case 'year':
            return 'rok';
        case 'years':
            return 'lat';
    }
}

export function getDateRange(start, end, timeUnits, step, format, excludeEnd = true) {
    const Moment = extendMoment(moment);

    start = moment.utc(start).local();
    end = moment.utc(end).local();
    
    if(start.minutes() % step) {
        start.add(step - (start.minutes() % step), 'minutes');
    }

    if(!end.isSame(end.clone().endOf('day')) && end.minutes() % step) {
        end.subtract(end.minutes() % step, 'minutes');
    }
    if(end.isAfter(start)) {
        const range = Moment.range(start, end);

        const freeSlots = Array.from(range.by(timeUnits, { excludeEnd, step }));
        switch (format) {
            case 'toDate': 
                return freeSlots.map(freeSlot => moment(freeSlot).toDate());
            default: 
                return freeSlots.map(freeSlot => freeSlot.format(format));
        } 
    }
}
    
export function getFreeTimeDateRange(dates, durationType, durationValue) {
    if (durationType && durationValue && dates && Array.isArray(dates)) {
        if (durationType === PRODUCT_DURATION_TYPE_HOURS) {
            durationValue *= 60;
            durationType = 'minutes';
        }

        const freeDatesRange = dates.map(freeSlot => getDateRange(
            freeSlot.freeFrom, 
            freeSlot.freeUntil,
            durationType,
            TRAINER_PLANNER_TIME_INTERVALS,
            'toDate',
            false,
        ));
       
        const includedDates = [];
        freeDatesRange.map((slot, index, array) => {
            const currentArray = array[index];
            currentArray.map((slot) => {
                const freeUntil = moment.utc((dates[index].freeUntil)).local();
                const freeDate = moment.utc((slot)).local();
                    
                if (freeUntil.diff(freeDate, 'minutes') >= durationValue) {
                    includedDates.push(slot);
                }
            });
        });
    
        return includedDates;
    }

    return [];
}

export function translateDateToDay(date) {
    const weekNumber = moment(date).day();

    switch (weekNumber) {
        case 0: return 'Niedziela';
        case 1: return 'Poniedziałek';
        case 2: return 'Wtorek';
        case 3: return 'Środa';
        case 4: return 'Czwartek';
        case 5: return 'Piątek';
        case 6: return 'Sobota';
    }
}

export const translateTimeUnit = product => {
    if(!product) {
        return 'Brak';
    }

    const { displayDurationValue, displayDurationType } = product;

    switch(displayDurationType) {
        case 'minutes': 
            return translateTime('Minuta', 'Minuty', 'Minut', displayDurationValue);
        case 'hours':
            return translateTime('Godzina', 'Godziny', 'Godzin', displayDurationValue);
        case 'days':
            return translateTime('Dzień', 'Dni', 'Dni', displayDurationValue);
    }
}

export function translateTime(firstForm, secondForm, thirdForm, durationValue) {
    let lastTwoNumbers = durationValue % 100;
    
    if (lastTwoNumbers >= 20) {
        let lastNumber = durationValue % 10;
        switch (lastNumber) {
            case 1:
                return thirdForm;
            case 2:
            case 3:
            case 4:
                return secondForm;
            case 5:
            case 6:
            case 7:
            case 8: 
            case 9:
            case 0:
                return thirdForm;
        }
    } else if (lastTwoNumbers <= 20 && lastTwoNumbers > 4) {
        return thirdForm;
    } else if (lastTwoNumbers <= 4 && lastTwoNumbers != 1) {
        return secondForm;
    } else if (lastTwoNumbers === 1) {
        return firstForm;
    } else {
        return 'Brak';
    } 
}