export const USER_ROLE_ADEPT = 'adept';
export const USER_ROLE_TRAINER = 'trainer';
export const USER_ROLE_ADMIN = 'admin';

export const USER_ROLES = [{
    key: USER_ROLE_ADEPT,
    label: 'Adept',
}, {
    key: USER_ROLE_TRAINER,
    label: 'Trener',
}, {
    key: USER_ROLE_ADMIN,
    label: 'Admin',
}];
