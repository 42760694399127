import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { setCookie } from 'Utils/cookies';
import { COOKIE_ONBOARDING_MODAL } from 'Consts/cookies';

import StyledComponent from 'Components/core/StyledComponent';
import Welcome from 'Components/adept/onboarding/steps/Welcome';
import MedicalCard from 'Components/adept/onboarding/steps/MedicalCard';
// import Training from 'Components/adept/onboarding/steps/Training';
// import App from 'Components/adept/onboarding/steps/App';
import Button from 'Components/layout/Button/component';

export default class PanelAdeptOnboarding extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
    };
    static defaultProps = {
        onClose: null,
    };

    state = {
        currentStep: 0,
        lastStep: 1,
    }

    setCurrentStep = (type) => {
        const { currentStep } = this.state;

        switch(type) {
            case 'forward':
                return this.setState({ currentStep: currentStep + 1 });
            case 'backward':
                return this.setState({ currentStep: currentStep - 1 });
        }
    };

    isControlDisabled = (type) => {
        const { currentStep, lastStep } = this.state;

        switch(type) {
            case 'forward':
                return currentStep + 1 > lastStep + 1;
            case 'backward':
                return currentStep - 1 < 0;
        }
    }

    onLastStepButtonHandler = () => {
        const { lastStep, currentStep } = this.state;
        const { onClose } = this.props;

        const isCurrentStepLast = currentStep === lastStep;

        return {
            onClick: isCurrentStepLast && onClose ? onClose : () => this.setCurrentStep('forward'),
            label: isCurrentStepLast && onClose ? 'Zamknij' : 'Dalej',
        };
    }

    setOnboardingCookie = () => {
        let expirationDate = moment().add(1, 'year')
            .toDate()
            .toUTCString();
        
        setCookie(COOKIE_ONBOARDING_MODAL, true, { expires: expirationDate });
    }

    render() {
        const { currentStep } = this.state;

        return (
            <StyledComponent styles={require('./styles')} className='panel-adept-onboarding'>
                {currentStep === 0 && (
                    <Welcome />
                )}
                {currentStep === 1 && (
                    <MedicalCard />
                )}
                {/* {currentStep === 2 && (
                    <Training />
                )}
                {currentStep === 3 && (
                    <App />
                )} */}
                <div className="controls">
                    <Button
                        disabled={this.isControlDisabled('backward')}
                        onClick={() => this.setCurrentStep('backward')}
                        style='gradient' 
                    >
                        Cofnij
                    </Button>
                    <Button
                        disabled={this.isControlDisabled('forward')}
                        onClick={() => { 
                            this.onLastStepButtonHandler().onClick();
                            this.setOnboardingCookie();
                        }}
                        style='gradient' 
                    >
                        {this.onLastStepButtonHandler().label}
                    </Button>
                </div>
            </StyledComponent>
        );
    }
}