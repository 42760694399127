import { connect } from 'react-redux';

import Component from './component';

import {
    getProfile,
} from 'Redux/modules/user/actions';

export default connect(
    state => ({
        authToken: state.user.authToken,
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            getProfile: dispatch(getProfile),
        },
    }) 
)(Component);

