import React from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import NotFound from 'Components/layout/panel/NotFound';

const Page = ({ elementMode, data, children }) => {
    if (elementMode) {
        if (!data || (data && data.isLoading)) {
            return (
                <StyledComponent
                    styles={require('./styles')}
                    className="panel-page"
                >
                    <Spinner />
                </StyledComponent>
            );
        }

        if (data && data.isLoaded && !data.data) {
            return (
                <StyledComponent
                    styles={require('./styles')}
                    className="panel-page"
                >
                    <NotFound />
                </StyledComponent>
            );
        }
    }

    return (
        <StyledComponent
            styles={require('./styles')}
            className="panel-page"
        >
            {children}
        </StyledComponent>
    );
};

Page.propTypes = {
    elementMode: PropTypes.bool,
    data: PropTypes.shape({
        isLoaded: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        data: PropTypes.object,
    }),
    children: PropTypes.any,
};
Page.defaultProps = {
    elementMode: false,
    data: null,
    children: null,
};

export default Page;
