import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import { parseQueryToObject } from 'Utils/querystring';

import Spinner from 'Components/layout/Spinner';

import { single } from 'Redux/modules/admin/measurementsConfig/actions';

export default connect(
    state => ({
        measurementsConfigMap: state.adminMeasurementsConfig.mapById,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
        },
    }),
    (stateProps, dispatchProps, ownProps) => {
        const { location } = ownProps;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return {
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
            measurementConfig: stateProps.measurementsConfigMap[queryObject.userId],
        };
    }
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-MeasurementsConfigManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);