import React from 'react';
import { Route } from 'react-router-dom';

import {
    ADMIN_DASHBOARD,
    ADMIN_CHAT,
    ADMIN_CHAT_CONVERSATIONS,
    ADMIN_USERS,
    ADMIN_USERS_CREATE,
    ADMIN_USERS_MANAGE,
    ADMIN_USERS_STAFF_DATA_CREATE,
    ADMIN_USERS_STAFF_DATA_MANAGE,
    ADMIN_SALES,
    ADMIN_CHAT_MASS_MESSAGING,
    ADMIN_CHAT_MASS_MESSAGING_CREATE,
    ADMIN_CHAT_MASS_MESSAGING_MANAGE,
    ADMIN_USER_SEGMENTS_CREATE,
    ADMIN_USER_SEGMENTS_MANAGE,
    ADMIN_OFFERS,
    ADMIN_OFFERS_CREATE,
    ADMIN_OFFERS_MANAGE,
    ADMIN_PRODUCT_CATEGORIES_CREATE,
    ADMIN_PRODUCT_CATEGORIES_MANAGE,
    ADMIN_PRODUCTS_CREATE,
    ADMIN_PRODUCTS_MANAGE,
    ADMIN_USER_PRODUCTS_MANAGE,
    ADMIN_USER_OFFERS,
    ADMIN_USER_OFFERS_CREATE,
    ADMIN_USER_OFFERS_MANAGE,
    ADMIN_USER_OFFERS_MIGRATE,
    ADMIN_OFFER_PRODUCTS_CREATE,
    ADMIN_OFFER_PRODUCTS_MANAGE,
    ADMIN_USER_OFFER_PRODUCTS_CREATE,
    ADMIN_USER_OFFER_PRODUCTS_MANAGE,
    ADMIN_USER_OFFER_INVOICE_ITEMS_CREATE,
    ADMIN_USER_OFFER_INVOICE_ITEMS_MANAGE,
    ADMIN_OFFER_USPS_CREATE,
    ADMIN_OFFER_USPS_MANAGE,
    ADMIN_PROMO_CODES_CREATE,
    ADMIN_PROMO_CODES_MANAGE,
    ADMIN_LOCATIONS,
    ADMIN_LOCATIONS_CREATE,
    ADMIN_LOCATIONS_MANAGE,
    ADMIN_EMPLOYEES,
    ADMIN_SPECIALIZATIONS_CREATE,
    ADMIN_SPECIALIZATIONS_MANAGE,
    ADMIN_EVENTS_CREATE,
    ADMIN_EVENTS_MANAGE,
    ADMIN_EVENTS_ADD,
    ADMIN_PROFILE,
    ADMIN_ADEPT_DATA,
    ADMIN_TRAINING_TARGETS_CREATE,
    ADMIN_TRAINING_TARGETS_MANAGE,
    ADMIN_TRAINING_TARGET_CATEGORIES_CREATE,
    ADMIN_TRAINING_TARGET_CATEGORIES_MANAGE,
    ADMIN_ADEPT_TESTS_CREATE,
    ADMIN_ADEPT_TESTS_MANAGE,
    ADMIN_MEASUREMENTS_CREATE,
    ADMIN_MEASUREMENTS_MANAGE,
    ADMIN_ACTIVITY_LOGS,
    ADMIN_DOCS,
    ADMIN_MEDICAL_CARDS_MANAGE,
    ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE,
    ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_CREATE,
    ADMIN_MEASUREMENTS_CONFIG_MANAGE,
    ADMIN_USER_SURVEY_MANAGE,
    ADMIN_EVENTS_CALENDAR,
    ADMIN_GR_HISTORY_LOGS,
    ADMIN_USER_HOMEWORK_CREATE,
    ADMIN_USER_HOMEWORK_MANAGE,
    ADMIN_EVENT_CYCLE,
    ADMIN_SHOP_PAGES,
    ADMIN_SHOP_PAGE_MANAGE,
    ADMIN_SHOP_PAGE_CREATE,
    ADMIN_USER_EFFECT_CREATE,
    ADMIN_USER_EFFECT_MANAGE,
    ADMIN_REPORTS,
    ADMIN_PREFERENCES_SURVEY_CREATE,
    ADMIN_TRAINING_PLANS,
    ADMIN_TRAINING_PLANS_SURVEY_CREATE,
    ADMIN_TRAINING_PLANS_SURVEY_MANAGE,
    ADMIN_TRAINING_PLANS_PROFILE_MANAGE,
    ADMIN_BLOG,
    ADMIN_ARTICLE_CREATE,
    ADMIN_ARTICLE_MANAGE,
    ADMIN_ARTICLE_TAG_CREATE,
    ADMIN_ARTICLE_TAG_MANAGE,
    ADMIN_ARTICLE_COMMENT_MANAGE,
    ADMIN_ARTICLE_CATEGORY_CREATE,
    ADMIN_ARTICLE_CATEGORY_MANAGE,
    ADMIN_CUSTOM_EVENTS,
    ADMIN_CUSTOM_EVENTS_CREATE,
    ADMIN_CUSTOM_EVENTS_MANAGE,
    ADMIN_CUSTOM_EVENT_USPS_CREATE,
    ADMIN_CUSTOM_EVENT_USPS_MANAGE,
} from 'Consts/routes';

export default [
    <Route
        key="ADMIN_DASHBOARD"
        exact={ADMIN_DASHBOARD.exact}
        path={ADMIN_DASHBOARD.path}
        component={require('Components/pages/admin/Dashboard').default}
    />,
    <Route
        key="ADMIN_CHAT"
        exact={ADMIN_CHAT.exact}
        path={ADMIN_CHAT.path}
        component={require('Components/pages/admin/Chat').default}
    />,
    <Route
        key="ADMIN_CHAT_CONVERSATIONS"
        exact={ADMIN_CHAT_CONVERSATIONS.exact}
        path={ADMIN_CHAT_CONVERSATIONS.path}
        component={require('Components/pages/admin/ChatConversations').default}
    />,
    <Route
        key="ADMIN_USERS"
        exact={ADMIN_USERS.exact}
        path={ADMIN_USERS.path}
        component={require('Components/pages/admin/Users').default}
    />,
    
    <Route
        key="ADMIN_USERS_CREATE"
        exact={ADMIN_USERS_CREATE.exact}
        path={ADMIN_USERS_CREATE.path}
        component={require('Components/pages/admin/UsersCreate').default}
    />,
    <Route
        key="ADMIN_USERS_MANAGE"
        exact={ADMIN_USERS_MANAGE.exact}
        path={ADMIN_USERS_MANAGE.path}
        component={require('Components/pages/admin/UsersManage').default}
    />,
    <Route
        key="ADMIN_USERS_STAFF_DATA_CREATE"
        exact={ADMIN_USERS_STAFF_DATA_CREATE.exact}
        path={ADMIN_USERS_STAFF_DATA_CREATE.path}
        component={require('Components/pages/admin/UsersStaffDataCreate').default}
    />,
    <Route
        key="ADMIN_USERS_STAFF_DATA_MANAGE"
        exact={ADMIN_USERS_STAFF_DATA_MANAGE.exact}
        path={ADMIN_USERS_STAFF_DATA_MANAGE.path}
        component={require('Components/pages/admin/UsersStaffDataManage').default}
    />,
    <Route
        key="ADMIN_SALES"
        exact={ADMIN_SALES.exact}
        path={ADMIN_SALES.path}
        component={require('Components/pages/admin/Sales').default}
    />,
    <Route
        key="ADMIN_CHAT_MASS_MESSAGING"
        exact={ADMIN_CHAT_MASS_MESSAGING.exact}
        path={ADMIN_CHAT_MASS_MESSAGING.path}
        component={require('Components/pages/admin/ChatMassMessaging').default}
    />,
    <Route
        key="ADMIN_CHAT_MASS_MESSAGING_CREATE"
        exact={ADMIN_CHAT_MASS_MESSAGING_CREATE.exact}
        path={ADMIN_CHAT_MASS_MESSAGING_CREATE.path}
        component={require('Components/pages/admin/ChatMassMessageCreate').default}
    />,
    <Route
        key="ADMIN_CHAT_MASS_MESSAGING_MANAGE"
        exact={ADMIN_CHAT_MASS_MESSAGING_MANAGE.exact}
        path={ADMIN_CHAT_MASS_MESSAGING_MANAGE.path}
        component={require('Components/pages/admin/ChatMassMessageManage').default}
    />,
    <Route
        key="ADMIN_USERS_SEGMENTS_CREATE"
        exact={ADMIN_USER_SEGMENTS_CREATE.exact}
        path={ADMIN_USER_SEGMENTS_CREATE.path}
        component={require('Components/pages/admin/UserSegmentsCreate').default}
    />,
    <Route
        key="ADMIN_USERS_SEGMENTS_CREATE"
        exact={ADMIN_USER_SEGMENTS_MANAGE.exact}
        path={ADMIN_USER_SEGMENTS_MANAGE.path}
        component={require('Components/pages/admin/UserSegmentsManage').default}
    />,
    <Route
        key="ADMIN_OFFERS"
        exact={ADMIN_OFFERS.exact}
        path={ADMIN_OFFERS.path}
        component={require('Components/pages/admin/Offers').default}
    />,
    <Route
        key="ADMIN_OFFERS_CREATE"
        exact={ADMIN_OFFERS_CREATE.exact}
        path={ADMIN_OFFERS_CREATE.path}
        component={require('Components/pages/admin/OffersCreate').default}
    />,
    <Route
        key="ADMIN_OFFERS_MANAGE"
        exact={ADMIN_OFFERS_MANAGE.exact}
        path={ADMIN_OFFERS_MANAGE.path}
        component={require('Components/pages/admin/OffersManage').default}
    />,
    <Route
        key="ADMIN_PRODUCT_CATEGORIES_CREATE"
        exact={ADMIN_PRODUCT_CATEGORIES_CREATE.exact}
        path={ADMIN_PRODUCT_CATEGORIES_CREATE.path}
        component={require('Components/pages/admin/ProductCategoriesCreate').default}
    />,
    <Route
        key="ADMIN_PRODUCT_CATEGORIES_MANAGE"
        exact={ADMIN_PRODUCT_CATEGORIES_MANAGE.exact}
        path={ADMIN_PRODUCT_CATEGORIES_MANAGE.path}
        component={require('Components/pages/admin/ProductCategoriesManage').default}
    />,
    <Route
        key="ADMIN_PRODUCTS_CREATE"
        exact={ADMIN_PRODUCTS_CREATE.exact}
        path={ADMIN_PRODUCTS_CREATE.path}
        component={require('Components/pages/admin/ProductsCreate').default}
    />,
    <Route
        key="ADMIN_PRODUCTS_MANAGE"
        exact={ADMIN_PRODUCTS_MANAGE.exact}
        path={ADMIN_PRODUCTS_MANAGE.path}
        component={require('Components/pages/admin/ProductsManage').default}
    />,
    <Route
        key="ADMIN_USER_PRODUCTS_MANAGE"
        exact={ADMIN_USER_PRODUCTS_MANAGE.exact}
        path={ADMIN_USER_PRODUCTS_MANAGE.path}
        component={require('Components/pages/admin/UserProductsManage').default}
    />,
    <Route
        key="ADMIN_USER_OFFERS"
        exact={ADMIN_USER_OFFERS.exact}
        path={ADMIN_USER_OFFERS.path}
        component={require('Components/pages/admin/UserOffers').default}
    />,
    <Route
        key="ADMIN_USER_OFFERS_CREATE"
        exact={ADMIN_USER_OFFERS_CREATE.exact}
        path={ADMIN_USER_OFFERS_CREATE.path}
        component={require('Components/pages/admin/UserOffersCreate').default}
    />,
    <Route
        key="ADMIN_USER_OFFERS_MANAGE"
        exact={ADMIN_USER_OFFERS_MANAGE.exact}
        path={ADMIN_USER_OFFERS_MANAGE.path}
        component={require('Components/pages/admin/UserOffersManage').default}
    />,
    <Route
        key="ADMIN_USER_OFFERS_MIGRATE"
        exact={ADMIN_USER_OFFERS_MIGRATE.exact}
        path={ADMIN_USER_OFFERS_MIGRATE.path}
        component={require('Components/pages/admin/UserOffersMigrate').default}
    />,
    <Route
        key="ADMIN_OFFER_PRODUCTS_CREATE"
        exact={ADMIN_OFFER_PRODUCTS_CREATE.exact}
        path={ADMIN_OFFER_PRODUCTS_CREATE.path}
        component={require('Components/pages/admin/OfferProductsCreate').default}
    />,    
    <Route
        key="ADMIN_OFFER_PRODUCTS_MANAGE"
        exact={ADMIN_OFFER_PRODUCTS_MANAGE.exact}
        path={ADMIN_OFFER_PRODUCTS_MANAGE.path}
        component={require('Components/pages/admin/OfferProductsManage').default}
    />,
    <Route
        key="ADMIN_USER_OFFER_PRODUCTS_CREATE"
        exact={ADMIN_USER_OFFER_PRODUCTS_CREATE.exact}
        path={ADMIN_USER_OFFER_PRODUCTS_CREATE.path}
        component={require('Components/pages/admin/UserOfferProductsCreate').default}
    />,
    <Route
        key="ADMIN_USER_OFFER_PRODUCTS_MANAGE"
        exact={ADMIN_USER_OFFER_PRODUCTS_MANAGE.exact}
        path={ADMIN_USER_OFFER_PRODUCTS_MANAGE.path}
        component={require('Components/pages/admin/UserOfferProductsManage').default}
    />,
    <Route
        key="ADMIN_USER_OFFER_INVOICE_ITEMS_CREATE"
        exact={ADMIN_USER_OFFER_INVOICE_ITEMS_CREATE.exact}
        path={ADMIN_USER_OFFER_INVOICE_ITEMS_CREATE.path}
        component={require('Components/pages/admin/UserOfferInvoiceItemsCreate').default}
    />,
    <Route
        key="ADMIN_USER_OFFER_INVOICE_ITEMS_MANAGE"
        exact={ADMIN_USER_OFFER_INVOICE_ITEMS_MANAGE.exact}
        path={ADMIN_USER_OFFER_INVOICE_ITEMS_MANAGE.path}
        component={require('Components/pages/admin/UserOfferInvoiceItemsManage').default}
    />,
    <Route
        key="ADMIN_OFFER_USPS_CREATE"
        exact={ADMIN_OFFER_USPS_CREATE.exact}
        path={ADMIN_OFFER_USPS_CREATE.path}
        component={require('Components/pages/admin/OfferUspsCreate').default}
    />,
    <Route
        key="ADMIN_OFFER_USPS_MANAGE"
        exact={ADMIN_OFFER_USPS_MANAGE.exact}
        path={ADMIN_OFFER_USPS_MANAGE.path}
        component={require('Components/pages/admin/OfferUspsManage').default}
    />,
    <Route
        key="ADMIN_PROMO_CODES_CREATE"
        exact={ADMIN_PROMO_CODES_CREATE.exact}
        path={ADMIN_PROMO_CODES_CREATE.path}
        component={require('Components/pages/admin/PromoCodesCreate').default}
    />,
    <Route
        key="ADMIN_PROMO_CODES_MANAGE"
        exact={ADMIN_PROMO_CODES_MANAGE.exact}
        path={ADMIN_PROMO_CODES_MANAGE.path}
        component={require('Components/pages/admin/PromoCodesManage').default}
    />,
    <Route
        key="ADMIN_LOCATIONS"
        exact={ADMIN_LOCATIONS.exact}
        path={ADMIN_LOCATIONS.path}
        component={require('Components/pages/admin/Locations').default}
    />,
    <Route
        key="ADMIN_LOCATIONS_CREATE"
        exact={ADMIN_LOCATIONS_CREATE.exact}
        path={ADMIN_LOCATIONS_CREATE.path}
        component={require('Components/pages/admin/LocationsCreate').default}
    />,
    <Route
        key="ADMIN_LOCATIONS_MANAGE"
        exact={ADMIN_LOCATIONS_MANAGE.exact}
        path={ADMIN_LOCATIONS_MANAGE.path}
        component={require('Components/pages/admin/LocationsManage').default}
    />,
    <Route
        key="ADMIN_EMPLOYEES"
        exact={ADMIN_EMPLOYEES.exact}
        path={ADMIN_EMPLOYEES.path}
        component={require('Components/pages/admin/Employees').default}
    />,
    <Route
        key="ADMIN_SPECIALIZATIONS_CREATE"
        exact={ADMIN_SPECIALIZATIONS_CREATE.exact}
        path={ADMIN_SPECIALIZATIONS_CREATE.path}
        component={require('Components/pages/admin/SpecializationsCreate').default}
    />,
    <Route
        key="ADMIN_SPECIALIZATIONS_MANAGE"
        exact={ADMIN_SPECIALIZATIONS_MANAGE.exact}
        path={ADMIN_SPECIALIZATIONS_MANAGE.path}
        component={require('Components/pages/admin/SpecializationsManage').default}
    />,
    <Route
        key="ADMIN_EVENTS_CALENDAR"
        exact={ADMIN_EVENTS_CALENDAR.exact}
        path={ADMIN_EVENTS_CALENDAR.path}
        component={require('Components/pages/admin/EventsCalendar').default}
    />,
    <Route
        key="ADMIN_EVENTS_CREATE"
        exact={ADMIN_EVENTS_CREATE.exact}
        path={ADMIN_EVENTS_CREATE.path}
        component={require('Components/pages/admin/EventsCreate').default}
    />,
    <Route
        key="ADMIN_EVENTS_MANAGE"
        exact={ADMIN_EVENTS_MANAGE.exact}
        path={ADMIN_EVENTS_MANAGE.path}
        component={require('Components/pages/admin/EventsManage').default}
    />,
    <Route
        key="ADMIN_EVENTS_ADD"
        exact={ADMIN_EVENTS_ADD.exact}
        path={ADMIN_EVENTS_ADD.path}
        component={require('Components/pages/admin/EventsAdd').default}
    />,
    <Route
        key="ADMIN_PROFILE"
        exact={ADMIN_PROFILE.exact}
        path={ADMIN_PROFILE.path}
        component={require('Components/pages/admin/Profile').default}
    />,
    <Route
        key="ADMIN_ADEPT_DATA"
        exact={ADMIN_ADEPT_DATA.exact}
        path={ADMIN_ADEPT_DATA.path}
        component={require('Components/pages/admin/AdeptData').default}
    />,
    <Route
        key="ADMIN_TRAINING_TARGETS_CREATE"
        exact={ADMIN_TRAINING_TARGETS_CREATE.exact}
        path={ADMIN_TRAINING_TARGETS_CREATE.path}
        component={require('Components/pages/admin/TrainingTargetsCreate').default}
    />,
    <Route
        key="ADMIN_TRAINING_TARGETS_MANAGE"
        exact={ADMIN_TRAINING_TARGETS_MANAGE.exact}
        path={ADMIN_TRAINING_TARGETS_MANAGE.path}
        component={require('Components/pages/admin/TrainingTargetsManage').default}
    />,
    <Route
        key="ADMIN_TRAINING_TARGET_CATEGORIES_CREATE"
        exact={ADMIN_TRAINING_TARGET_CATEGORIES_CREATE.exact}
        path={ADMIN_TRAINING_TARGET_CATEGORIES_CREATE.path}
        component={require('Components/pages/admin/TrainingTargetCategoriesCreate').default}
    />,
    <Route
        key="ADMIN_TRAINING_TARGET_CATEGORIES_MANAGE"
        exact={ADMIN_TRAINING_TARGET_CATEGORIES_MANAGE.exact}
        path={ADMIN_TRAINING_TARGET_CATEGORIES_MANAGE.path}
        component={require('Components/pages/admin/TrainingTargetCategoriesManage').default}
    />,
    <Route
        key="ADMIN_ADEPT_TESTS_CREATE"
        exact={ADMIN_ADEPT_TESTS_CREATE.exact}
        path={ADMIN_ADEPT_TESTS_CREATE.path}
        component={require('Components/pages/admin/AdeptTestsCreate').default}
    />,
    <Route
        key="ADMIN_ADEPT_TESTS_MANAGE"
        exact={ADMIN_ADEPT_TESTS_MANAGE.exact}
        path={ADMIN_ADEPT_TESTS_MANAGE.path}
        component={require('Components/pages/admin/AdeptTestsManage').default}
    />,
    <Route
        key="ADMIN_MEASUREMENTS_CREATE"
        exact={ADMIN_MEASUREMENTS_CREATE.exact}
        path={ADMIN_MEASUREMENTS_CREATE.path}
        component={require('Components/pages/admin/MeasurementsCreate').default}
    />,
    <Route
        key="ADMIN_MEASUREMENTS_MANAGE"
        exact={ADMIN_MEASUREMENTS_MANAGE.exact}
        path={ADMIN_MEASUREMENTS_MANAGE.path}
        component={require('Components/pages/admin/MeasurementsManage').default}
    />,
    <Route
        key="ADMIN_ACTIVITY_LOGS"
        exact={ADMIN_ACTIVITY_LOGS.exact}
        path={ADMIN_ACTIVITY_LOGS.path}
        component={require('Components/pages/admin/ActivityLogs').default}
    />,
    <Route
        key="ADMIN_DOCS"
        exact={ADMIN_DOCS.exact}
        path={ADMIN_DOCS.path}
        component={require('Components/pages/admin/Docs').default}
    />,
    <Route
        key="ADMIN_MEDICAL_CARDS_MANAGE"
        exact={ADMIN_MEDICAL_CARDS_MANAGE.exact}
        path={ADMIN_MEDICAL_CARDS_MANAGE.path}
        component={require('Components/pages/admin/MedicalCardsManage').default}
    />,
    <Route
        key="ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_CREATE"
        exact={ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_CREATE.exact}
        path={ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_CREATE.path}
        component={require('Components/pages/admin/DiagnosticReportCreate').default}
    />,
    <Route
        key="ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE"
        exact={ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE.exact}
        path={ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE.path}
        component={require('Components/pages/admin/DiagnosticReportManage').default}
    />,
    <Route
        key="ADMIN_MEASUREMENT_CONFIG_MANAGE"
        exact={ADMIN_MEASUREMENTS_CONFIG_MANAGE.exact}
        path={ADMIN_MEASUREMENTS_CONFIG_MANAGE.path}
        component={require('Components/pages/admin/MeasurementsConfigManage').default}
    />,
    <Route
        key="ADMIN_USER_SURVEY_MANAGE"
        exact={ADMIN_USER_SURVEY_MANAGE.exact}
        path={ADMIN_USER_SURVEY_MANAGE.path}
        component={require('Components/pages/admin/UserSurveyManage').default}
    />,
    <Route
        key="ADMIN_GR_HISTORY_LOGS"
        exact={ADMIN_GR_HISTORY_LOGS.exact}
        path={ADMIN_GR_HISTORY_LOGS.path}
        component={require('Components/pages/admin/GrHistoryLogs').default}
    />,
    <Route
        key="ADMIN_USER_HOMEWORK_MANAGE"
        exact={ADMIN_USER_HOMEWORK_MANAGE.exact}
        path={ADMIN_USER_HOMEWORK_MANAGE.path}
        component={require('Components/pages/admin/UserHomeworkManage').default}
    />,
    <Route
        key="ADMIN_USER_HOMEWORK_CREATE"
        exact={ADMIN_USER_HOMEWORK_CREATE.exact}
        path={ADMIN_USER_HOMEWORK_CREATE.path}
        component={require('Components/pages/admin/UserHomeworkCreate').default}
    />,
    <Route
        key="ADMIN_EVENT_CYCLE"
        exact={ADMIN_EVENT_CYCLE.exact}
        path={ADMIN_EVENT_CYCLE.path}
        component={require('Components/pages/admin/EventCycle').default}
    />,
    <Route
        key="ADMIN_SHOP_PAGES"
        exact={ADMIN_SHOP_PAGES.exact}
        path={ADMIN_SHOP_PAGES.path}
        component={require('Components/pages/admin/ShopPages').default}
    />,
    <Route
        key="ADMIN_SHOP_PAGE_CREATE"
        exact={ADMIN_SHOP_PAGE_CREATE.exact}
        path={ADMIN_SHOP_PAGE_CREATE.path}
        component={require('Components/pages/admin/ShopPageCreate').default}
    />,
    <Route
        key="ADMIN_SHOP_PAGE_MANAGE"
        exact={ADMIN_SHOP_PAGE_MANAGE.exact}
        path={ADMIN_SHOP_PAGE_MANAGE.path}
        component={require('Components/pages/admin/ShopPageManage').default}
    />,
    <Route
        key="ADMIN_USER_EFFECT_CREATE"
        exact={ADMIN_USER_EFFECT_CREATE.exact}
        path={ADMIN_USER_EFFECT_CREATE.path}
        component={require('Components/pages/admin/UserEffectCreate').default}
    />,
    <Route
        key="ADMIN_USER_EFFECT_MANAGE"
        exact={ADMIN_USER_EFFECT_MANAGE.exact}
        path={ADMIN_USER_EFFECT_MANAGE.path}
        component={require('Components/pages/admin/UserEffectManage').default}
    />,
    <Route
        key="ADMIN_REPORTS"
        exact={ADMIN_REPORTS.exact}
        path={ADMIN_REPORTS.path}
        component={require('Components/pages/admin/Reports').default}
    />,
    <Route
        key="ADMIN_PREFERENCES_SURVEY_CREATE"
        exact={ADMIN_PREFERENCES_SURVEY_CREATE.exact}
        path={ADMIN_PREFERENCES_SURVEY_CREATE.path}
        component={require('Components/pages/admin/PreferencesSurveyCreate').default}
    />,
    <Route
        key="ADMIN_TRAINING_PLANS"
        exact={ADMIN_TRAINING_PLANS.exact}
        path={ADMIN_TRAINING_PLANS.path}
        component={require('Components/pages/admin/TrainingPlans').default}
    />,
    <Route
        key="ADMIN_TRAINING_PLANS_SURVEY_CREATE"
        exact={ADMIN_TRAINING_PLANS_SURVEY_CREATE.exact}
        path={ADMIN_TRAINING_PLANS_SURVEY_CREATE.path}
        component={require('Components/pages/admin/TrainingPlans/SurveyCreate').default}
    />,
    <Route
        key="ADMIN_TRAINING_PLANS_SURVEY_MANAGE"
        exact={ADMIN_TRAINING_PLANS_SURVEY_MANAGE.exact}
        path={ADMIN_TRAINING_PLANS_SURVEY_MANAGE.path}
        component={require('Components/pages/admin/TrainingPlans/SurveyManage').default}
    />,
    <Route
        key="ADMIN_TRAINING_PLANS_PROFILE_MANAGE"
        exact={ADMIN_TRAINING_PLANS_PROFILE_MANAGE.exact}
        path={ADMIN_TRAINING_PLANS_PROFILE_MANAGE.path}
        component={require('Components/pages/admin/TrainingPlans/ProfileManage').default}
    />,
    <Route
        key="ADMIN_BLOG"
        exact={ADMIN_BLOG.exact}
        path={ADMIN_BLOG.path}
        component={require('Components/pages/admin/Blog').default}
    />,
    <Route
        key="ADMIN_ARTICLE_CREATE"
        exact={ADMIN_ARTICLE_CREATE.exact}
        path={ADMIN_ARTICLE_CREATE.path}
        component={require('Components/pages/admin/Blog/ArticleCreate').default}
    />,
    <Route
        key="ADMIN_ARTICLE_MANAGE"
        exact={ADMIN_ARTICLE_MANAGE.exact}
        path={ADMIN_ARTICLE_MANAGE.path}
        component={require('Components/pages/admin/Blog/ArticleManage').default}
    />,
    <Route
        key="ADMIN_ARTICLE_TAG_CREATE"
        exact={ADMIN_ARTICLE_TAG_CREATE.exact}
        path={ADMIN_ARTICLE_TAG_CREATE.path}
        component={require('Components/pages/admin/Blog/ArticleTagCreate').default}
    />,
    <Route
        key="ADMIN_ARTICLE_TAG_MANAGE"
        exact={ADMIN_ARTICLE_TAG_MANAGE.exact}
        path={ADMIN_ARTICLE_TAG_MANAGE.path}
        component={require('Components/pages/admin/Blog/ArticleTagManage').default}
    />,
    <Route
        key="ADMIN_ARTICLE_COMMENT_MANAGE"
        exact={ADMIN_ARTICLE_COMMENT_MANAGE.exact}
        path={ADMIN_ARTICLE_COMMENT_MANAGE.path}
        component={require('Components/pages/admin/Blog/ArticleCommentManage').default}
    />,
    <Route
        key="ADMIN_ARTICLE_CATEGORY_CREATE"
        exact={ADMIN_ARTICLE_CATEGORY_CREATE.exact}
        path={ADMIN_ARTICLE_CATEGORY_CREATE.path}
        component={require('Components/pages/admin/Blog/ArticleCategoryCreate').default}
    />,
    <Route
        key="ADMIN_ARTICLE_CATEGORY_MANAGE"
        exact={ADMIN_ARTICLE_CATEGORY_MANAGE.exact}
        path={ADMIN_ARTICLE_CATEGORY_MANAGE.path}
        component={require('Components/pages/admin/Blog/ArticleCategoryManage').default}
    />,
    <Route
        key="ADMIN_CUSTOM_EVENTS"
        exact={ADMIN_CUSTOM_EVENTS.exact}
        path={ADMIN_CUSTOM_EVENTS.path}
        component={require('Components/pages/admin/CustomEvents').default}
    />,
    <Route
        key="ADMIN_CUSTOM_EVENTS_CREATE"
        exact={ADMIN_CUSTOM_EVENTS_CREATE.exact}
        path={ADMIN_CUSTOM_EVENTS_CREATE.path}
        component={require('Components/pages/admin/CustomEventCreate').default}
    />,
    <Route
        key="ADMIN_CUSTOM_EVENTS_MANAGE"
        exact={ADMIN_CUSTOM_EVENTS_MANAGE.exact}
        path={ADMIN_CUSTOM_EVENTS_MANAGE.path}
        component={require('Components/pages/admin/CustomEventManage').default}
    />,
    <Route
        key="ADMIN_CUSTOM_EVENT_USPS_CREATE"
        exact={ADMIN_CUSTOM_EVENT_USPS_CREATE.exact}
        path={ADMIN_CUSTOM_EVENT_USPS_CREATE.path}
        component={require('Components/pages/admin/CustomEventUspCreate').default}
    />,
    <Route
        key="ADMIN_CUSTOM_EVENT_USPS_MANAGE"
        exact={ADMIN_CUSTOM_EVENT_USPS_MANAGE.exact}
        path={ADMIN_CUSTOM_EVENT_USPS_MANAGE.path}
        component={require('Components/pages/admin/CustomEventUspManage').default}
    />,
];