import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class FormInputLabelComponent extends React.Component {
    static propTypes = {
        label: PropTypes.string.isRequired,
        style: PropTypes.oneOf([
            'default',
            'transparent',
            'transparentLight',
            'transparentV2',
            'version-2',
            'hollow',
        ]),
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
        required: PropTypes.bool,
    };
    static defaultProps = {
        required: false,
    }

    render() {
        const { label, style, styleVersion, required } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'input-label',
                    `style-${style}`,
                    `style-version-${styleVersion}`,
                )}
            >
                {required && <span className="required-true">*</span>}
                <span className="label">{label}</span>
            </StyledComponent>
        );
    }
}
