import * as types from './types';

import { withVariables } from 'Utils/string';

import { reduxRequest as request } from 'Services/Api';

import {
    ENDPOINT_PUBLIC_USER_SLUG,
    ENDPOINT_PUBLIC_UPDATE_INVITED_USER,
} from 'Consts/api';

export const getUserBySlug = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.GET_TRAINER_BY_SLUG,
        method: 'GET',
        path: withVariables(ENDPOINT_PUBLIC_USER_SLUG, { slug: params.slug }),
        params,
    }));
};

export const updateInvitedUser = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE_INVITED_USER,
        method: 'POST',
        path: withVariables(ENDPOINT_PUBLIC_UPDATE_INVITED_USER, { token: params.token }),
        params,
        requestParams: params,
    }));
};