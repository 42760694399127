import React from 'react';
import loadable from '@loadable/component';
import Spinner from 'Components/layout/Spinner';
import { connect } from 'react-redux';

import {
    getProfile,
} from 'Redux/modules/user/actions';

export default connect(
    state => ({
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            getProfile: dispatch(getProfile),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-EventsCalendar" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);