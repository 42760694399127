import moment from 'moment';

export default class Article {
    constructor(data) {
        this.id = data.id,
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.title = data.title;
        this.lead = data.lead;
        this.body = data.body;
        this.status = data.status;
        this.author = data.author;
        this.image = data.image;
        this.isPromoted = data.isPromoted;
        this.articleTags = data.articleTags;
        this.articleCategories = data.articleCategories;
    };
}