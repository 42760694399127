import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

const PageContent = ({ children, breadcrumbs, className }) => (
    <StyledComponent
        styles={require('./styles')}
        className={classNames({
            'page-content': true,
            [className]: true,
        })}
    >
        {breadcrumbs.length > 0 && (
            <div className="breadcrumbs">
                {breadcrumbs
                    .filter(breadcrumb => breadcrumb.visible !== false)
                    .map(breadcrumb => (
                        <NavLink 
                            to={breadcrumb.to} 
                            key={breadcrumb.to + breadcrumb.label}
                            className="breadcrumb"
                            activeClassName="active"
                            exact={breadcrumb.exact || true}
                        >
                            {breadcrumb.label}
                        </NavLink>
                    ))}
            </div>
        )}
        {children}
    </StyledComponent>
);

PageContent.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            visible: PropTypes.bool,
            to: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            exact: PropTypes.bool,
        })
    ),
};
PageContent.defaultProps = {
    children: null,
    layout: '',
    breadcrumbs: [],
};

export default PageContent;
