import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: relative;
    width: 100%;
    height: 5em;
    margin-bottom: 2em;

    &::last-of-type {
        margin-bottom: none;
    }

    .bg {
        position: absolute;
        background-color: #D83143;
        opacity: 0;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        transition: .2s all ease-in-out;
        outline: none;
    }

    .link {
        color: white;
        padding: 1.5em 2.5em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 2;
        transition: .2s all ease-in-out;
        outline: 0;

        .icon {
            min-width: 3em;

            svg {
                width: 100%;
                height: 100%;

                path, line {
                    stroke:  ${variables.dpc_iconWhite};
                }
            }
        }

        .title {
            font-size: 1.3em;
            color: white;
            font-size: 300;
            margin-left: -2.5em;
            opacity: 0;
            transition: .2s all ease-in-out;
        }
    }

    &:hover {
        .bg {
            opacity: 1;
            width: 100%;
        }
        .link {
            background-color: #D83143;
            transition: none;

            .icon {
                svg {
                    path, line {
                        stroke:  ${variables.dpc_iconWhite};
                    }
                }
            }

            .title {
                opacity: 1;
                margin-left: 2em;
            }
        }
    }

    &:active {
        .link {
            outline: 1px dotted white;
        }
    }

    @media (max-width: ${variables.tabletL}) {
        .bg {
            display: none;
        }
        .link {
            width: 100%;
            justify-content: flex-start;

            .icon {
                width: 2em;

                svg {
                    path, line {
                        stroke:  ${variables.dpc_iconWhite};
                    }
                }
            }

            .title {
                opacity: 1;
                margin-left: 2em;
            }
        }
    }
`;