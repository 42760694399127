import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { parseToQueryString, parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';

export default class PanelTabNavigation extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        headline: PropTypes.string,
        subHeadline: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        tabs: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                key: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                children: PropTypes.any.isRequired,
            })
        ).isRequired,
        controls: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                key: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                onClick: PropTypes.func,
                to: PropTypes.string,
                style: PropTypes.string,
            })
        ),
    };
    static defaultProps = {
        headline: null,
        subHeadline: null,
        controls: [],
    };

    defaultQuery = {
        tab: null,
    };

    getQueryConfig = () => {
        const { location } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    onChangeTab = key => {
        const { location, history } = this.props;
        const { pathname, search } = location;

        history.push(
            parseToQueryString(pathname, {
                tab: key,
            })
        );
    }

    render() {
        const {
            headline,
            subHeadline,
            tabs,
            controls,
        } = this.props;

        const queryObject = this.getQueryConfig();
        let currentTab = tabs.find(tab => tab.key === queryObject.tab);
        if (!currentTab) {
            currentTab = tabs[0];
        }

        return (
            <StyledComponent
                className="panel-tab-navigation"
                styles={require('./styles')}
            >
                <div className="headline-container">
                    <h2 className="headline">
                        <span className="main-headline">
                            {headline}
                        </span>

                        {subHeadline &&
                            <span className="sub-headline">
                                {subHeadline}
                            </span>
                        }
                    </h2>

                    <div className="controls-container">
                        {controls
                            .filter(control => control.visible !== false)
                            .map(control => (
                                <a
                                    key={control.key}
                                    href={control.to || null}
                                    onClick={control.onClick}
                                    className="control"
                                >
                                    <Button style={control.style || null}>{control.label}</Button>
                                </a>
                            ))}
                    </div>
                </div>
                <div className="tabs-container">
                    <div className="nav">
                        {tabs
                            .filter(tab => tab.visible !== false)
                            .map(({ key, label }) => (
                                <a
                                    className={classnames({
                                        tab: true,
                                        active: currentTab.key === key,
                                    })}
                                    key={key}
                                    onClick={() => this.onChangeTab(key)}
                                >
                                    {label}
                                </a>
                            ))}
                    </div>
                    <div className="content">
                        {currentTab.children}
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
