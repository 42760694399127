import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';

export default class ResponseStatus extends Component {
    static propTypes = {
        children: PropTypes.any,
        statusCode: PropTypes.number,
    };
    static defaultProps = {
        statusCode: 200,
    };

    render() {
        const { children, statusCode } = this.props;

        return (
            <Route
                render={({ staticContext }) => {
                    if (staticContext) staticContext.statusCode = statusCode;
                    return children;
                }}
            />
        );
    }
}
