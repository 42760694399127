import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import { single as singleUserOffer } from 'Redux/modules/trainer/userOffers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            singleUserOffer: dispatch(singleUserOffer),
        },
    }),
)(
    loadable(() => import(
        /* webpackChunkName: "page-trainer-DiagnosticReportCreate" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);