import React from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class ConsentForm extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            presignFile: PropTypes.func.isRequired,
            updateProfile: PropTypes.func.isRequired,
        }),
        profile: PropTypes.shape({
            companyName: PropTypes.string,
            nip: PropTypes.string,
            address: PropTypes.string,
            postalCode: PropTypes.string,
            city: PropTypes.string,
        }),
        submitButtonStyle: PropTypes.oneOf([
            'faPrimary', 'hollow',
        ]),
        inputStyleProps: PropTypes.object,
    };
    static defaultProps = {
        submitButtonStyle: 'faPrimary',
    };

    state = {
        formState: {
            //eslint-disable-next-line
            ...this.props.profile.userConsents,
        },
    }

    onSubmit = data => {
        const { actions } = this.props;
        const { formState } = this.state;

        data.consents.forEach(consent => consent.value = formState['consent_' + consent.type]);

        return actions.updateProfile(data);
    }

    componentDidMount = () => {
        const { profile } = this.props;
        const { consents } = profile;
        const consentsObject = {}; 

        consents.forEach(consent => consentsObject['consent_' + consent.type] = Boolean(consent.value));

        this.setState({
            formState: {
                ...profile,
                ...consentsObject,
            },
        });
    }

    render() {
        const { history, location, submitButtonStyle, inputStyleProps } = this.props;
        const { formState } = this.state;

        return (
            <StyledComponent styles={require('./styles')} className='consent-form'>
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        submitButtonStyle,
                        submitButtonDisabled: formState.consent_terms == false,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'checkbox',
                            name: 'consent_terms',
                            label: 'Przeczytałem i akceptuję regulamin sklepu',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'checkbox',
                            name: 'consent_marketingEmail',
                            label: 'Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną.',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }, {
                            type: 'checkbox',
                            name: 'consent_phone',
                            label: 'Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu.',
                            inputProps: {
                                ...inputStyleProps,
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}