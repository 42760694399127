import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import ExternalWrapper from 'Components/forms/InputExternalWrapper';
import InternalWrapper from 'Components/forms/InputInternalWrapper';
import ErrorMessage from 'Components/forms/ErrorMessage';
import InputHelper from 'Components/forms/InputHelper';

export default class FormCheckboxComponent extends React.Component {
    static displayName = 'FormCheckboxComponent';
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.any,
        helper: PropTypes.string,
        onChange: PropTypes.func,
        onFormChange: PropTypes.func,
        errorMessage: PropTypes.string,
        value: PropTypes.bool,
        required: PropTypes.bool,
        expandable: PropTypes.bool,
        expandInfo: PropTypes.string,
        labelExpanded: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.oneOf([
            'default',
        ]),
        size: PropTypes.oneOf([
            'medium',
        ]),
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
    };
    static defaultProps = {
        style: 'default',
        size: 'medium',
        className: '',
        errorMessage: '',
        helper: '',
        value: false,
        required: false,
        expandable: false,
        expandInfo: null,
        labelExpanded: null,
    };

    state = {
        currentLabel: this.props.label,  //eslint-disable-line react/destructuring-assignment
        isLabelExpanded: false,
    };

    onChange = event => {
        const { onChange, onFormChange } = this.props;
        const data = {
            name: event.target.name,
            value: event.target.checked,
        };

        if (onChange) {
            onChange(data);
        }

        if (onFormChange) {
            onFormChange(data);
        }

        return data;
    }

    onExpand = () => {
        const { expandable, labelExpanded } = this.props;

        if (!expandable || !labelExpanded) {
            return false;
        }

        this.setState({
            ...this.state,
            isLabelExpanded: true,
            currentLabel: labelExpanded,
        });
    }

    render() {
        const { className, value, name, label, expandable, expandInfo, helper, errorMessage, required, style, size, styleVersion } = this.props;
        const { isLabelExpanded, currentLabel } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'form-element',
                    'checkbox',
                    className,
                    name,
                    `style-${style}`,
                    `size-${size}`,
                    `styleVersion-${styleVersion}`,

                )}
            >
                <ExternalWrapper>
                    <InternalWrapper>
                        <label htmlFor={name}>
                            <input
                                id={name}
                                name={name}
                                onChange={this.onChange}
                                type="checkbox"
                                value={value}
                                checked={value ? 'checked' : ''}
                                required={required}
                            />
                            <span className="trigger" />
                        </label>
                        <span className="label">
                            <span dangerouslySetInnerHTML={{ __html: currentLabel || label }} />
                            {!isLabelExpanded && expandable && <span className="expand-trigger" onClick={this.onExpand}>{expandInfo}</span>}
                        </span>
                    </InternalWrapper>
                    {helper ? <InputHelper message={helper} /> : null}
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </ExternalWrapper>
            </StyledComponent>
        );
    }
}
