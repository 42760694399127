import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

export default connect(
    state => ({
        profile: state.user.profile,
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-adept-Profile" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);