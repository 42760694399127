export const STATUS_APPROVED = 'approved';
export const STATUS_NOT_APPROVED = 'notApproved';
export const STATUS_AWAITING = 'awaiting';

export const ARTICLE_COMMENT_STATUSES = [{
    key: STATUS_APPROVED,
    label: 'Udostępniony',
}, {
    key: STATUS_NOT_APPROVED,
    label: 'Nieudostępniony',
}, {
    key: STATUS_AWAITING,
    label: 'Oczekujący',
}];