import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';

export default class FormInputHelperComponent extends React.Component {
    static propTypes = {
        message: PropTypes.string.isRequired,
    };
    static defaultProps = {}

    render() {
        const { message } = this.props;

        return (
            <div role="component" id="form-input-helper">
                <span>{message}</span>
            </div>
        );
    }
}
