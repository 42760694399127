import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class FormInputExternalWrapperComponent extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        style: PropTypes.oneOf([
            'default',
            'transparent',
            'transparentLight',
        ]),
    }
    static defaultProps = {
        children: null,
        style: 'default',
    }

    render() {
        const { children, style } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'input-external-wrapper',
                    `style-${style}`
                )}
            >
                {children}
            </StyledComponent>
        );
    }
}
