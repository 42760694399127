import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_USER_OFFER_DIAGNOSTIC_REPORTS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            userId: params.userId || undefined,
            userOfferId: params.userOfferId || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_DIAGNOSTIC_REPORT, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_DIAGNOSTIC_REPORTS, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_DIAGNOSTIC_REPORT, params),
        params,
        requestParams: params,
    }));
};

export const remove = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_DIAGNOSTIC_REPORT, params),
        params,
        requestParams: params,
    }));
};

export const presignFile = dispatch => (params = {}) => {
    return dispatch(request({
        method: 'POST',
        reduxType: types.IMAGE_PRESIGN,
        path: params.presignPath,
        timeout: 360000,
        requestParams: {
            file: {
                type: params.file.type,
                name: params.file.name,
                size: params.file.size,
            },
        },
    }));
};

export const userNotification = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.USER_NOTIFICATION,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_USER_NOTIFICATION, params),
        params,
        requestParams: params,
    }));
};
