import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .notification-wrapper {
            position: relative;
            display: flex;
            width: 25em;
            height: 8em;
            background-color: #393939;
            border-bottom: 1px solid #555;
            padding: .5em;

            .notification-details-wrapper {
                display: flex;
                overflow-wrap: anywhere;
                align-self: center;
                height: 100%;
                align-items: center;
                padding: .5em;

                .notification-image-wrapper {
                    display: flex;
                    align-self: center;
                    min-width: 3.5em;
                    max-width: 3.5em;
                    max-height: 3.5em;
                    overflow: hidden;
                    margin-right: .75em;
                    border-radius: 360em;
                }

                .notification-image {
                    width: 100%;
                }

                .notification-text-wrapper {
                    color: white;
                    overflow: hidden;
                    max-height: 10em;

                    .notification-title {
                        font-weight: 600;
                    }

                    .notification-subtitle {
                        max-height: 3em;
                        margin-top: .5em;
                        font-size: .9em;
                        font-weight: 300;
                    }
                }
            }

            .notification-controller {
                display: flex;
                flex-direction: column;
                border-radius: .5em;
                transition: .1s all;
                width: 100%;

                .notification-link {
                    display: flex;
                    height: 100%;
                }

                .mark-as-read {
                    display: inline-block;
                    align-items: center;
                    padding: .5em;
                    color: #ccc;
                    font-size: .9em;
                    cursor: pointer;
                }

                &:hover {
                    background-color: #292929;
                }
            }


            .no-notification {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                color: #666;

                .fa-bell-slash {
                    margin-right: .5em;
                }
            }

            &.read {
                background-color: #333;
            }

            &.unread {
                border: 1px solid #DF3846;  
            }
        }
    `;

