export const DATE_DEFAULT_TIMEZONE = 'Europe/Warsaw';

export const DATE_API_FORMAT = 'YYYY-MM-DD';
export const DATE_DEFAULT_FORMAT = 'DD.MM.YYYY';
export const DATE_UNICODE_FORMAT = 'dd.MM.yyyy';

export const DATETIME_API_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATETIME_DEFAULT_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATETIME_UNICODE_FORMAT = 'dd.MM.yyyy HH:mm';

export const TIME_API_FORMAT = 'HH:mm';
export const TIME_DEFAULT_FORMAT = 'HH:mm';
export const TIME_UNICODE_FORMAT = 'HH:mm';

export const DATE_WEEKDAYS = {
    Monday: 'Poniedziałek',
    Tuesday: 'Wtorek',
    Wednesday: 'Środa',
    Thursday: 'Czwartek',
    Friday: 'Piątek',
    Saturday: 'Sobota',
    Sunday: 'Niedziela',
};

export const DEFAULT_TIME_INTERVALS = 15;
export const TRAINER_PLANNER_TIME_INTERVALS = 15;