import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';

import { TIME_API_FORMAT } from 'Consts/date';

import { toApiFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import ElementEditor from 'Components/layout/panel/ElementEditor';

export default class TrainerUserWorkHoursEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        submitButtonStyle: PropTypes.oneOf([
            'faPrimary', 'hollow', 'gradient',
        ]),
    };
    static defaultProps = {
        data: null,
        submitButtonStyle: 'faPrimary',
    };

    state = {
        formState: {},
    };

    componentDidMount = () => {
        const { data } = this.props;

        if (data) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        mondayStart: moment(data.mondayStart, TIME_API_FORMAT),
        mondayEnd: moment(data.mondayEnd, TIME_API_FORMAT),
        tuesdayStart: moment(data.tuesdayStart, TIME_API_FORMAT),
        tuesdayEnd: moment(data.tuesdayEnd, TIME_API_FORMAT),
        wednesdayStart: moment(data.wednesdayStart, TIME_API_FORMAT),
        wednesdayEnd: moment(data.wednesdayEnd, TIME_API_FORMAT),
        thursdayStart: moment(data.thursdayStart, TIME_API_FORMAT),
        thursdayEnd: moment(data.thursdayEnd, TIME_API_FORMAT),
        fridayStart: moment(data.fridayStart, TIME_API_FORMAT),
        fridayEnd: moment(data.fridayEnd, TIME_API_FORMAT),
        saturdayStart: moment(data.saturdayStart, TIME_API_FORMAT),
        saturdayEnd: moment(data.saturdayEnd, TIME_API_FORMAT),
        sundayStart: moment(data.sundayStart, TIME_API_FORMAT),
        sundayEnd: moment(data.sundayEnd, TIME_API_FORMAT),
    })

    onSubmit = formState => {
        return this.onUpdate(formState);
    }
    onUpdate = formState => {
        const { actions } = this.props;

        return actions.update({
            ...formState,
            mondayStart: toApiFormat(formState.mondayStart, 'time', true),
            mondayEnd: toApiFormat(formState.mondayEnd, 'time', true),
            tuesdayStart: toApiFormat(formState.tuesdayStart, 'time', true),
            tuesdayEnd: toApiFormat(formState.tuesdayEnd, 'time', true),
            wednesdayStart: toApiFormat(formState.wednesdayStart, 'time', true),
            wednesdayEnd: toApiFormat(formState.wednesdayEnd, 'time', true),
            thursdayStart: toApiFormat(formState.thursdayStart, 'time', true),
            thursdayEnd: toApiFormat(formState.thursdayEnd, 'time', true),
            fridayStart: toApiFormat(formState.fridayStart, 'time', true),
            fridayEnd: toApiFormat(formState.fridayEnd, 'time', true),
            saturdayStart: toApiFormat(formState.saturdayStart, 'time', true),
            saturdayEnd: toApiFormat(formState.saturdayEnd, 'time', true),
            sundayStart: toApiFormat(formState.sundayStart, 'time', true),
            sundayEnd: toApiFormat(formState.sundayEnd, 'time', true),
        });
    }

    render() {
        const { data, location, history, submitButtonStyle } = this.props;
        const { formState } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="trainer-user-work-hours-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        title: '',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        submitButtonStyle,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'datePicker',
                            name: 'mondayStart',
                            label: 'Poniedziałek od',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Poniedziałek od',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'mondayEnd',
                            label: 'Poniedziałek do',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Poniedziałek do',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'tuesdayStart',
                            label: 'Wtorek od',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Wtorek od',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'tuesdayEnd',
                            label: 'Wtorek do',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Wtorek do',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'wednesdayStart',
                            label: 'Środa od',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Środa od',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'wednesdayEnd',
                            label: 'Środa do',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Środa do',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'thursdayStart',
                            label: 'Czwartek od',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Czwartek od',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'thursdayEnd',
                            label: 'Czwartek do',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Czwartek do',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'fridayStart',
                            label: 'Piątek od',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Piątek od',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'fridayEnd',
                            label: 'Piątek do',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Piątek do',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'saturdayStart',
                            label: 'Sobota od',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Sobota od',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'saturdayEnd',
                            label: 'Sobota do',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Sobota do',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'sundayStart',
                            label: 'Niedziela od',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Niedziela od',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }, {
                            type: 'datePicker',
                            name: 'sundayEnd',
                            label: 'Niedziela do',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: true,
                                    showTimeSelectOnly: true,
                                    timeIntervals: 15,
                                    timeCaption: 'Niedziela do',
                                    dateFormat: 'HH:mm',
                                },
                                styleVersion: 2,
                                border: 'light',
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}