import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    position: relative;
    z-index: 100;
 
    .logo {
        padding: 2em;
        position: relative;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;

        .headline {
            margin-top: .25em;
            color: white;
            font-weight: 300;
        }
    }

    .hamburger {
        display: none;
        position: relative;
        z-index: 100;
    }

    .nav {
        .panel-topbar {
            display: none;
        }
        .user-info {
            padding: 0 2em;
            color: #CCCCCC;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .user-avatar {
                width: 4em;

                .avatar-component { 
                    margin-bottom: 0;

                    img {
                        width: 5em;
                        height: 5em;
                        border-radius: 360em;
                    }
                }
            }

            .user-details {
                text-align: left;
                margin-left: 1em;

                .user-email {
                    font-size: 1.3em;
                    font-weight: 600;
                };
                .user-logout-link {
                    cursor: pointer;
                    margin-top: .5em;
                    display: block;
                    font-size: .9em;
                    color: white;
                    font-weight: 600;

                    &:hover {
                        color: #EEEEEE;
                    };
                }
            }
        }
    }

    .navigation {
        display: flex;
        flex-direction: column;
        margin-top: 2em;
    }

    @media (max-width: ${variables.tabletL}) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .logo {
            padding: .5em;
        }

        .hamburger {
            display: block;
        }

        >.nav {
            display: none;

            &.mobile-visible {
                position: fixed;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: scroll;

                background-color: rgba(0, 0, 0, .9);
                padding: 4em 0em 2em 0em;
                text-align: center;
                flex-direction: column;
                z-index: 99;
                font-size: 1.2em;

                .panel-topbar {
                    display: block;
                }
            }
        }
    }
`;
