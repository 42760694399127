import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADEPT_NOTIFICATIONS, { id: params.id }),
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            state: params.state,
        },
    }));
};

export const readNotification = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.READ_NOTIFICATION,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADEPT_NOTIFICATIONS_SINGLE, { id: params.id }),
        params,
        requestParams: {
            id: params.id,
        },
    }));
};

export const setAllAsRead = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.READ_ALL_NOTIFICATIONS,
        method: 'PUT',
        path: endpoints.ENDPOINT_ADEPT_NOTIFICATIONS_READ_ALL,
        params,
    }));
}