import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import { 
    single as getSingleUser, 
} from 'Redux/modules/admin/users/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            getSingleUser: dispatch(getSingleUser),
        },
    }),
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-UserOffersCreate" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);