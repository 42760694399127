import { reduxRequest as request } from 'Services/Api';
import * as types from './types';
import * as endpoints from 'Consts/api';

import { fromSelectObject } from 'Utils/object';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_TRAINER_USERS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            canLeadProductId: fromSelectObject(params.canLeadProductId) || undefined,
            offerType: fromSelectObject(params.offerType) || undefined,
            activeAdepts: params.activeAdepts || undefined,
            inactiveAdepts: params.inactiveAdepts || undefined,
            offerStatus: params.offerStatus || undefined,
            notTrainerAdepts: params.notTrainerAdepts || undefined,
            role: params.role || undefined,
            enabled: typeof params.enabled === 'boolean'
                ? params.enabled
                : undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: endpoints.ENDPOINT_TRAINER_USER
            .replace('{id}', params.id),
        params,
    }));
};

export const assignToTrainer = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ASSIGN,
        method: 'POST',
        path: endpoints.ENDPOINT_TRAINER_USER_ASSIGN,
        params,
        requestParams: params,
    }));
};

export const listNotAssignedToTrainer = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_ASSIGNED_TO_TRAINER,
        method: 'GET',
        path: endpoints.ENDPOINT_TRAINER_USERS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            notTrainerAdepts: true,
        },
    }));
};
