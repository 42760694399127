import * as firebase from 'firebase/app';
import 'firebase/messaging';

import { push } from 'Redux/modules/notifications/actions';

export function init() {
    let messaging;
    if (firebase.apps.length) {
        firebase.app();
        messaging = firebase.messaging();
    } else {
        firebase.initializeApp({
            messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
            apiKey: process.env.FIREBASE_MESSAGING_API_KEY,
            authDomain: process.env.FIREBASE_MESSAGING_AUTH_DOMAIN,
            databaseURL: process.env.FIREBASE_MESSAGING_DATABASE_URL,
            projectId: process.env.FIREBASE_MESSAGING_PROJECT_ID,
            storageBucket: process.env.FIREBASE_MESSAGING_STORAGE_BUCKET,
            appId: process.env.FIREBASE_MESSAGING_APP_ID,
        });
        messaging = firebase.messaging();
        messaging.usePublicVapidKey(process.env.FIREBASE_VAPID_KEY);
    }

    listen();
    return firebase;
}

export function requestToken() {
    return new Promise((resolve, reject) => {
        const messaging = firebase.messaging();

        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                messaging.getToken().then(currentToken => {
                    if (currentToken) {
                        //sendTokenToServer(currentToken);
                        //updateUIForPushEnabled(currentToken);
                        resolve(currentToken);
                    } else {
                        // Show permission request.
                        // Show permission UI.
                        //updateUIForPushPermissionRequired();
                        //setTokenSentToServer(false);
                        reject(null);
                    }
                })
                    .catch((err) => {
                        // showToken('Error retrieving Instance ID token. ', err);
                        // setTokenSentToServer(false);
                        reject(null);
                    });

                messaging.onTokenRefresh(() => {
                    messaging.getToken().then(refreshedToken => {
                        // Indicate that the new Instance ID token has not yet been sent to the
                        // app server.
                        // setTokenSentToServer(false);
                        // Send Instance ID token to app server.
                        // sendTokenToServer(refreshedToken);
                        // ...
                        resolve(refreshedToken);
                    })
                        .catch(err => {
                            // showToken('Unable to retrieve refreshed token ', err);
                            reject(null);
                        });
                });
            }
        });
    });
}

export function listen() {
    firebase.messaging().onMessage(payload => {
        const store = window.store;
        if (!store) return false;

        store.dispatch(
            push(payload)
        );
        // ...
    });
}
