import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faBars,
    faChevronDown,
    faChevronRight,
    faEnvelope,
    faExclamation,
    faFileDownload,
    faLongArrowAltRight,
    faSearch,
    faWrench,
    faRunning,
    faHandshake,
    faDollarSign,
} from '@fortawesome/free-solid-svg-icons';
import {
    faTwitter,
    faYoutube,
    faFacebookF,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faBars,
    faChevronDown,
    faChevronRight,
    faEnvelope,
    faExclamation,
    faFileDownload,
    faLongArrowAltRight,
    faSearch,
    faWrench,
    faRunning,
    faHandshake,
    faDollarSign,

    faTwitter,
    faYoutube,
    faFacebookF
);
