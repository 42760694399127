import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';

import { pureRequest } from 'Services/Api';
import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import ExternalWrapper from 'Components/forms/InputExternalWrapper';
import InternalWrapper from 'Components/forms/InputInternalWrapper';
import ErrorMessage from 'Components/forms/ErrorMessage';
import InputHelper from 'Components/forms/InputHelper';
import InputLabel from 'Components/forms/InputLabel';
import Button from 'Components/layout/Button';
import Spinner from 'Components/layout/Spinner';

export default class FormS3FileUploadComponent extends React.Component {
    static displayName = 'FormFileUploadComponent';

    static propTypes = {
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        label: PropTypes.string.isRequired,
        helper: PropTypes.string,
        onChange: PropTypes.func,
        action: PropTypes.func.isRequired,
        onFormChange: PropTypes.func,
        errorMessage: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired,
                    originalName: PropTypes.string,
                    imageUrl: PropTypes.string,
                    progress: PropTypes.number,
                })
            ),
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                originalName: PropTypes.string,
                imageUrl: PropTypes.string,
                progress: PropTypes.number,
            }),
        ]),
        style: PropTypes.oneOf([
            'default',
            'transparent',
            'transparentLight',
            'transparentV2',
        ]),
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
        accept: PropTypes.string,
        payload: PropTypes.object,
        s3Config: PropTypes.shape({
            presignPath: PropTypes.string.isRequired,
        }),
        required: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        helper: '',
        onChange: null,
        onFormChange: null,
        errorMessage: '',
        value: null,
        style: 'default',
        accept: '',
        payload: {},
        required: false,
    }

    state = {
        isPending: false,
        progress: 0,
        uploadError: null,
        uploadFile: null,
    }

    notifyChange = url => {
        const { name, onChange, onFormChange } = this.props;

        if (onChange) {
            onChange({
                name,
                value: url,
            });
        }

        if (onFormChange) {
            onFormChange({
                name,
                value: url,
            });
        }
    }

    onDrop = files => {
        const { s3Config, action } = this.props;

        this.setState(() => ({ isPending: true }));
        files.forEach(file => {
            action({
                file,
                presignPath: s3Config.presignPath,
            })
                .then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        uploadFile: {
                            id: response.payload.fileUpload.id,
                            originalName: file.name,
                        },
                    }));
                    this.sendToS3(file, response.payload.fileUpload);
                })
                .catch(error => {
                    Logger.error('File upload error', error);
                    this.setState(prevState => ({ isPending: false, uploadError: 'Upload failed' }));
                });
        });
    }

    sendToS3 = (file, presign) => {
        let data = new FormData();

        data.append('file', file);

        const req = new XMLHttpRequest();

        req.open('PUT', presign.putUrl);
        req.setRequestHeader('Access-Control-Allow-Origin', '*');
        req.setRequestHeader('Content-Disposition', `inline; filename="${presign.fileName}"`);
        req.setRequestHeader('Cache-Control', 'max-age=86400, public');
        req.setRequestHeader('Pragma', 'public');
        req.setRequestHeader('Content-Type', presign.type);
        req.addEventListener('load', event => {
            //Set uploaded progress
            this.setState(prevState => ({
                ...prevState,
                isPending: false,
                progress: 1,
                uploadFile: {
                    ...prevState.uploadFile,
                    imageUrl: presign.getUrl,
                },
            }), () => {
                const { uploadFile } = this.state;
                this.notifyChange(uploadFile);
            });
        });
        req.send(file);
    }

    render() {
        const { label, className, accept, helper, errorMessage, style, value, styleVersion, required } = this.props;
        const { isPending, uploadError, uploadFile, progress } = this.state;
        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'input',
                    className,
                    `style-${style}`,
                    `style-version-${styleVersion}`,
                )}
            >
                <ExternalWrapper>
                    {label && <InputLabel label={label} style={style} required={required} />}
                    <InternalWrapper style={style} styleVersion={styleVersion}>
                        {isPending && <Spinner />}
                        {!progress && !value ? null : (
                            <div className="file-preview">
                                <img
                                    src={progress
                                        ? uploadFile.imageUrl
                                        : value.imageUrl
                                    }
                                />
                            </div>
                        )}
                        <div className="dropzone-container">
                            <Dropzone
                                accept={accept}
                                onDropAccepted={this.onDrop}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone-internal" {...getRootProps()}>
                                        <input className="dropzone-input" {...getInputProps()} />
                                        {uploadFile
                                            ? (
                                                <>
                                                    <p className="dropzone-label">{uploadFile.name}</p>
                                                    <span className="dropzone-progress">
                                                        {progress
                                                            ? 'Wysłano'
                                                            : 'Wysyłam'
                                                        }
                                                    </span>
                                                </>
                                            )
                                            : (
                                                <p className="dropzone-label">Upuść lub przeciągnij plik</p>
                                            )
                                        }
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </InternalWrapper>
                    {helper ? <InputHelper message={helper} /> : null}
                    {errorMessage || uploadError && <ErrorMessage message={errorMessage || uploadError} />}
                </ExternalWrapper>
            </StyledComponent>
        );
    }
}
