import { connect } from 'react-redux';
import Component from './component';

import { refreshToken } from 'Redux/modules/user/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            refreshToken: dispatch(refreshToken),
        },
    })
)(Component);
