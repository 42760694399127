import moment from 'moment';

export default class CustomEvent {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.title = data.title,
        this.titleBackground = data.titleBackground,
        this.content = data.content,
        this.activeFrom = data.activeFrom && moment.utc(data.activeFrom).local();
        this.activeTo = data.activeTo && moment.utc(data.activeTo).local();
        this.offer = data.offer;
        this.popUp = data.popUp;
    };
}