import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    padding: 3em;
    border-radius: 1em;
    max-width: 98vw;
    max-height: 98vh;
    background: #EEEEEE;
    overflow: auto;
    
    &.style-dark {
        padding: 2em;
        border-radius: 0;
        background: #333333;
        background: transparent;
    }

    &.style-darkV2 {
        background: #333333;

        .modal-title-component {
            color: white;
        }
    }
`;