import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Box from 'Components/layout/panel/Box';

export default class PanelMeasurementsBox extends Component {
    static propTypes = {
        icon: PropTypes.element,
        headline: PropTypes.string,
        children: PropTypes.element,
        footer: PropTypes.element,
    };
    static defaultProps = {
        icon: null,
        headline: null,
        children: null,
        footer: null,
    };

    render() {
        const { icon, headline, children, footer } = this.props;

        return (
            <StyledComponent
                className="panel-measurements-box"
                styles={require('./styles')}
            >  
                <Box>
                    <div className="measurements-box-content">
                        {icon && (
                            <div className="measurements-box-icon">
                                {icon}
                            </div>
                        )}
                        <div className="measurements-box-body">
                            {headline && (
                                <h4 className="measurements-box-headline">
                                    {headline}
                                </h4>
                            )}
                            {children && (
                                <div className="measurements-box-children">
                                    {children}
                                </div>
                            )}
                        </div>
                    </div>
                    {footer && (
                        <div className="measurement-box-footer">
                            {footer}
                        </div>
                    )}
                </Box>
            </StyledComponent>
        );
    }
}
