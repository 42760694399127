import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: ${variables.dpc_backgroundBlack};

    .topbar-container {
        width: 100%;
        background-color: ${variables.dpc_backgroundBlack};
        border: 1px solid ${variables.dpc_backgroundBlack};
    }

    .sidebar-container {
        position: relative;
        background-color: ${variables.dpc_backgroundBlack};
        min-width: 9em;
        min-height: 100vh;
        box-shadow: 0px 3px 16px #00000029;
        border: 1px solid ${variables.dpc_backgroundBlack};
        z-index: 10;

        .navigation {
            .panel-sidebar-link {
                &.application {
                    .icon {
                        min-width: 2em;
                        max-width: 2.5em;
                        margin-left: .45em;
                        svg {
                            path { 
                                fill: none !important; 
                                stroke-width: 1.25em; 
                            }
                        }
                    }

                    &:hover {
                        .icon {
                            svg {
                                path { 
                                    stroke: ${variables.dpc_iconWhite} !important; 
                                }
                            }
                        }
                    }
                }

                &.invite { 
                    .icon {
                        svg {
                            path { 
                                fill: none !important; 
                                stroke-width: 1.25em; 
                            }
                        }
                    }

                    &:hover {
                        .icon {
                            svg {
                                path { 
                                    stroke: ${variables.dpc_iconWhite} !important; 
                                }
                            }
                        }
                    }
                }

                &.fitadept-tv { 
                    &:hover {
                        .icon {
                            svg {
                                path { 
                                    fill: #FFFFFF !important; 
                                }
                            }
                        }
                    }
                }

                &.chat {
                    .icon {
                        svg {
                            width: 4em !important;
                            height: 4em !important;
                        }
                    }
                }
            }
        }
    }

    .page-content {
        .breadcrumbs {
            padding: 0;
        }
    }

    .view-container {
        position: relative;
        background-color: ${variables.dpc_backgroundWhite};
        width: 100%;
        min-height: 100vh;
        overflow-x: hidden;
        
        .view-page-container {
            padding: 3em 0;
            width: 94%;
            position: relative;
            left: 3%;
        }

        .promo-info {
            padding: 15px 0px 15px;
            width: 100%;
            background: #347deb;
            color: white;
            text-align: center;
            display: block;

            .promo-link {
                text-decoration: underline;
                font-weight: 600;
            }
        }
    }

    .panel-tab-navigation {
        .headline-container {
            padding: 0;
            padding: 1em 0;

            .headline {
                .main-headline {
                    color: ${variables.dpc_fontDark};
                    font-weight: 400;
                }

                .sub-headline {
                    color: #777777;
                }
            }
        }
        .tabs-container {
            margin-top: 1em;
            
            .nav {
                padding: 0;
                background: transparent;
                border: none;
                border-bottom: 2px solid #282828;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                overflow-x: auto;
                width: 100%;
                margin-bottom: 2.5em;
                flex-wrap: nowrap;

                .tab {
                    color: ${variables.dpc_fontWhite};
                    background: ${variables.dpc_backgroundDarkLight};
                    padding: 0px;
                    font-size: 1em;
                    font-weight: 500;
                    line-height: 1.3em;
                    margin-right: 3em;
                    box-shadow: none;
                    margin-right: .5em;
                    cursor: pointer;
                    padding: 1em 2em;
                    white-space: nowrap;
                    border: none;
                    border-bottom: 2px solid #282828;
                    
                    &.active {
                        background: ${variables.dpc_backgroundBlack};
                        color: ${variables.dpc_fontWhite};
                        border-bottom: 2px solid #ea3f48;
                        border-top: none;
                    }

                    &:hover {
                        color: ${variables.dpc_fontWhite};
                        background: ${variables.dpc_backgroundBlack};
                        border-bottom: 2px solid #ea3f48;
                    }
                }
            }

            .content {
                background-color: transparent;
                box-shadow: none;
                padding: 0;
                margin-top: 1em;
            }
        }
    }

    .panel-big-calendar {
        .big-calendar-wrapper {
            .rbc-calendar {
                * {
                    border-color: #444;
                }

                .rbc-today {
                    background-color: #373737;
                }

                .rbc-off-range-bg {
                    background-color: #373737;
                }

                .rbc-header {
                    color: #CCCCCC;
                }

                .rbc-label {
                    color: #CCC;
                }

                .rbc-date-cell {
                    color: #CCC;
                }

                .rbc-agenda-date-cell,
                .rbc-agenda-time-cell,
                .rbc-agenda-event-cell {
                    color: #CCC;
                }

                .rbc-toolbar {
                    .rbc-toolbar-label {
                        color: #CCC;
                    }
                    .rbc-btn-group {
                        button {
                            background-color: #EEE;
                        }
                    }
                }

                .rbc-time-view {
                    .rbc-time-header {
                        .rbc-time-header-gutter {}
                        .rbc-time-header-content {

                        }
                    }
                    .rbc-time-content {
                        .rbc-timeslot-group {
                            .rbc-time-slot {
                                .rbc-label {}
                            }
                        }

                        .rbc-time-gutter {
                            .rbc-label {}
                        }
                        .rbc-day-slot {
                            .rbc-timeslot-group {}
                        }
                    }
                }
            }
        }
    }

    .admin-paginated-list {
        .list-header {}
        .list-body {
            .admin-paginated-list-element {
                border-bottom: 1px solid #222222;

                .title-block {
                    .title-content {
                        .title {
                            color: ${variables.dpc_fontDark};
                        }
                    }
                }
            }
        }
        .list-footer {
            .admin-paginated-list-pagination {
                .pagination-buttons {
                    .pagination-btn {
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px solid #666;

                        &.pagination-btn-active {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

    .admin-element-editor {
        .editor-wrapper {
            .data-wrapper {
                .block {
                    .block-title  {
                        color: ${variables.dpc_fontDark};
                    }
                    .details {
                        .detail {
                            color: ${variables.dpc_fontDark};
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {
        flex-direction: column;

        .sidebar-container {
            width: 100%;
            min-height: auto;
        }
        
        .view-container {
            width: 100%;
            min-height: auto;

            .view-page-container {
                padding: 2em 0;
                width: 94%;
                left: 3%;
            }
        }
    }
`;
