import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import {
    list as listEvents,
    cancel as cancelEvent,
} from 'Redux/modules/adept/events/actions';

import {
    single as singleUserOffer
} from 'Redux/modules/adept/userOffers/actions';
import {
    getLocationBySlug
} from 'Redux/modules/adept/locations/actions';
import {
    single as singleProduct
} from 'Redux/modules/adept/products/actions';

export default connect(
    state => ({
        events: state.adeptEvents.list,
        userOffersMap: state.adeptUserOffers.mapById,
    }),
    dispatch => ({
        actions: {
            listEvents: dispatch(listEvents),
            singleUserOffer: dispatch(singleUserOffer),
            getLocationBySlug: dispatch(getLocationBySlug),
            cancelEvent: dispatch(cancelEvent),
            singleProduct: dispatch(singleProduct),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        userOffer: stateProps.userOffersMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-adept-ReservationConfirmation" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);

