import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';
import { fromSelectObject } from 'Utils/object';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_USER_OFFERS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            userId: params.userId || undefined,
            promoCodeId: params.promoCodeId || undefined,
            status: fromSelectObject(params.status) || undefined,
            hasPaidTo: fromSelectObject(params.hasPaidTo) || undefined,
            hasActiveTo: fromSelectObject(params.hasActiveTo) || undefined,
            hasEvents: fromSelectObject(params.hasEvents) || undefined,
            overduePayments: fromSelectObject(params.overduePayments) || undefined,
            periodType: fromSelectObject(params.periodType) || undefined,
            offerType: fromSelectObject(params.offerType) || undefined,
            productCategory: fromSelectObject(params.productCategory) || undefined,
            completedTransactions: params.completedTransactions || undefined,
        },
    }));
};

export const income = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.INCOME,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_USER_OFFERS_INCOME,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            userId: params.userId || undefined,
            status: fromSelectObject(params.status) || undefined,
            hasActiveTo: fromSelectObject(params.hasActiveTo) || undefined,
            overduePayments: fromSelectObject(params.overduePayments) || undefined,
            paidToFrom: params.paidToFrom || undefined,
            paidToTo: params.paidToTo || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFERS, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER, params),
        params,
        requestParams: params,
    }));
};

export const cancel = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CANCEL,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_CANCEL, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const activate = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ACTIVATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_ACTIVATE, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const extend = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.EXTEND,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_EXTEND, params),
        params,
    }));
};

export const payment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.PAYMENT,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_PAYMENT, params),
        params,
    }));
};

export const migrate = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.MIGRATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_MIGRATE, params),
        params,
        requestParams: params,
        timeout: 60000,
    }));
};

export const remove = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const close = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CLOSE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_CLOSE, params),
        params,
        requestParams: {
            ...params,
        },
    }));
};

export const notificationShopLink = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.NOTIFICATION_SHOP_LINK,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_NOTIFICATION_SHOP_LINK, params),
        params,
    }));
};

export const terminationDate = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.TERMINATION_DATES,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_TERMINATION_DATES, params),
        params,
    }));
};

export const logs = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LOGS,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_USER_OFFER_LOGS,
        params,
        requestParams: {
            userOfferId: params.userOfferId || undefined,
            creatorId: params.creatorId || undefined,
        },
    }));
};

export const addPayment = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ADD_PAYMENT,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_ADD_PAYMENT, params),
        params,
        requestParams: {
            value: params.value || undefined,
            createReason: params.createReason || undefined,
            shouldExtend: params.shouldExtend || undefined,
            createdAt: params.createdAt || undefined,
        },
    }));
};

export const invite = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.INVITE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_INVITE, params),
        params,
        requestParams: params,
    }));
};

export const removeUser = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REMOVE_USER,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_REMOVE_USER, params),
        params,
        requestParams: params,
    }));
};

export const getSubUsers = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.GET_SUB_USERS,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_USER_OFFER_GET_SUB_USERS, params),
        params,
    }));
};
