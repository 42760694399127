import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';
import { fromSelectObject } from 'Utils/object';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_PRODUCTS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            userOfferId: params.userOfferId || undefined,
            visibility: params.visibility || undefined,
            leadId: params.leadId || undefined,
            offerId: params.offerId || undefined,
            productCategory: fromSelectObject(params.productCategory) || undefined,
            assigned: fromSelectObject(params.assigned) || undefined,
            userId: params.userId || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PRODUCT, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PRODUCTS, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PRODUCT, params),
        params,
        requestParams: params,
    }));
};

export const setEnabledStatus = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SET_ENABLED_STATUS,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PRODUCT_ENABLED_STATUS, params),
        params,
        requestParams: params,
    }));
};

export const assignSpecialization = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ASSIGN_SPECIALIZATION,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PRODUCT_SPECIALIZATIONS, params),
        params,
        requestParams: params,
    }));
};

export const unassignSpecialization = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UNASSIGN_SPECIALIZATION,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PRODUCT_SPECIALIZATIONS, params),
        params,
        requestParams: params,
    }));
};

export const presignFile = dispatch => (params = {}) => {
    return dispatch(request({
        method: 'POST',
        reduxType: types.IMAGE_PRESIGN,
        path: params.presignPath,
        timeout: 360000,
        requestParams: {
            file: {
                type: params.file.type,
                name: params.file.name,
                size: params.file.size,
            },
        },
    }));
};