import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFormattedDate } from 'Utils/date';
import { withVariables } from 'Utils/string';
import { filterKeys } from 'Utils/object';
import { parseQueryToObject } from 'Utils/querystring';

import { ADMIN_ARTICLE_CATEGORY_MANAGE } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelArticleCategoriesList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listCategories: PropTypes.func.isRequired,
            changeAssignment: PropTypes.func.isRequired,
        }).isRequired,
        onMapAdditionals: PropTypes.func,
        onMapControls: PropTypes.func,
        onMapFilters: PropTypes.func,
        articleCategories: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        onMapAdditionals: (elem, value) => value,
        onMapControls: (elem, value) => value,
        onMapFilters: elems => elems,
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        timestamp: undefined,
        enabled: undefined,
        search: '',
        assigned: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }
    }

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.listCategories({ ...queryObject });
        }, 500);
    }

    render() {
        const {
            location,
            history,
            articleCategories,
            onMapAdditionals,
            onMapControls,
            onMapFilters,
            actions,
        } = this.props;

        const queryObject = this.getQueryConfig();

        return (
            <StyledComponent
                className="panel-article-categories-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={articleCategories}
                    title="Lista kategorii"
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.name}
                            controls={onMapControls(element, [{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_ARTICLE_CATEGORY_MANAGE.path, { id: element.id }),
                            }, {
                                type: 'button',
                                label: 'Przypisz',
                                visible: queryObject.assigned === 'false' && Boolean(queryObject.articleId),
                                onClick: () => actions.changeAssignment({ 
                                    id: element.id, 
                                    articleId: queryObject.articleId, 
                                    state: 'assign'
                                }).then(() => this.loadData())
                            }, {
                                type: 'button',
                                label: 'Odpisz',
                                visible: !queryObject.assigned && Boolean(queryObject.articleId),
                                onClick: () => actions.changeAssignment({ 
                                    id: element.id, 
                                    articleId: queryObject.articleId, 
                                    state: 'unassign'
                                }).then(() => this.loadData())
                            }])}
                            additionals={onMapAdditionals(element, [{
                                visible: true,
                                name: 'Data utworznenia',
                                value: getFormattedDate(element.createdAt),
                            }])}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                    }, {
                        name: 'enabled',
                        label: 'Aktywne/Nieaktywne',
                        type: 'select',
                        inputProps: {
                            options: [{
                                label: 'Wszystkie',
                                value: undefined,
                            }, {
                                label: 'Aktywne',
                                value: true,
                            }, {
                                label: 'Nieaktywne',
                                value: false,
                            }],
                        },
                    }, {
                        name: 'assigned',
                        label: 'Przypisanie',
                        type: 'select',
                        visible: Boolean(queryObject.articleId),
                        inputProps: {
                            options: [{
                                label: 'Przypisane',
                                value: '',
                            }, {
                                label: 'Nieprzypisane',
                                value: 'false',
                            }],
                        }
                    }])}
                />
            </StyledComponent>
        );
    }
}
