export const LIST = 'trainer/events/LIST';
export const SINGLE = 'trainer/events/SINGLE';
export const STATS = 'trainer/events/STATS';
export const CREATE = 'trainer/events/CREATE';
export const UPDATE = 'trainer/events/UPDATE';
export const CANCEL = 'trainer/events/CANCEL';
export const DONE = 'trainer/events/DONE';
export const SUGGEST_FREE_SLOTS = 'trainer/events/SUGGEST_FREE_SLOTS';
export const RESET = 'traner/events/RESET';
export const CANCEL24H = 'trainer/events/CANCEL24H';
export const CANCEL_DIAGNOSTIC = 'trainer/events/CANCEL_DIAGNOSTIC';
export const LOCATION_OCCUPANCY = 'trainer/events/LOCATION_OCCUPANCY';
export const CHECK_CYCLE_SLOTS_AVAILABILITY = 'trainer/events/CHECK_CYCLE_SLOTS_AVAILABILITY';
export const CREATE_MANY = 'trainer/events/CREATE_MANY';