import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import ProfileForm from 'Components/common/ProfileForm';
import InvoiceForm from 'Components/adept/invoice/Form';
import PasswordChangeModal from 'Components/adept/modals/PasswordChange';
import ConsentForm from 'Components/adept/consent/Form';

export const TAB_DATA = 'data';
export const TAB_INVOICE_DATA = 'invoiceData';
export const TAB_CONSENTS = 'consents';

export default class AdeptProfile extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.shape({
            email: PropTypes.string.isRequired,
            name: PropTypes.string,
            surname: PropTypes.string,
            phone: PropTypes.string,
            address: PropTypes.string,
            postalCode: PropTypes.string,
            city: PropTypes.string,
            avatar: PropTypes.string,
        }),
    };
    static defaultProps = {};

    state = {
        changePassword: false,
    }

    render() {
        const { changePassword } = this.state;
        const { profile, history, location } = this.props;
        const queryObject = parseQueryToObject(location.search);

        return (
            <StyledComponent
                className="admin-profile-manage"
                styles={require('./styles')}
            >
                <Page>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Edycja profilu"
                        controls={[{
                            visible: Boolean(queryObject.tab === TAB_DATA),
                            key: 'changePassword',
                            label: 'Zmień hasło',
                            onClick: () => this.setState({ changePassword: true }),
                            style: 'gradient',
                            layout: 'wide',
                        }]}
                        tabs={[{
                            key: TAB_DATA,
                            label: 'Dane adepta',
                            children: (
                                <ProfileForm 
                                    profile={profile} 
                                    history={history}
                                    location={location}
                                    inputStyleProps={{
                                        styleVersion: 2,
                                    }}
                                    submitButtonStyle='gradient'
                                />
                            ),
                        }, {
                            key: TAB_INVOICE_DATA,
                            label: 'Dane do faktury',
                            children: (
                                <InvoiceForm 
                                    profile={profile} 
                                    history={history}
                                    location={location}
                                    inputStyleProps={{
                                        styleVersion: 1,
                                    }}
                                    submitButtonStyle='gradient'
                                />
                            ),
                        }, {
                            key: TAB_CONSENTS,
                            label: 'Zgody',
                            children: (
                                <ConsentForm 
                                    profile={profile} 
                                    history={history}
                                    location={location}
                                    inputStyleProps={{
                                        styleVersion: 1,
                                    }}
                                    submitButtonStyle='gradient'
                                />
                            ),
                        }]}
                    />
                    <PasswordChangeModal 
                        location={location}
                        history={history}
                        isOpen={changePassword}
                        onClose={() => this.setState({ changePassword: false })}
                    />
                </Page>
            </StyledComponent>
        );
    }
}