import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        &.style-version-1 {
            .editor-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .block {
                    padding-top: 2em;
                    margin-top: 2em;
                    border-top: 1px solid #CCCCCC;

                    .block-title {
                        font-size: 1.5em;
                        margin-bottom: 1em;
                    }
                }

                .forms-wrapper {
                    width: 60%;
                    padding-right: 2%;
                }
                .data-wrapper {
                    width: 40%;

                    .details-wrapper {
                        .detail {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: .5em;
                            margin-bottom: .5em;
                            border-bottom: 1px solid #EEEEEE;

                            .detail-value {
                                margin-left: 5em;
                                word-break: break-all;
                                text-align: right;
                            }

                            .detail-label {
                                min-width: 10em;

                                &.detail-label-link {
                                    color: inherit;
                                    text-decoration: underline;
                                }
                            }

                            .detail-value {
                                word-break: break-all;
                                text-align: right;
                                padding-left: 1em;
                            }

                            &:last-of-type {
                                padding-bottom: 0;
                                margin-bottom: 0;
                                border-bottom: none;
                            }
                        }
                    }

                    .controls-wrapper {
                        .control {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: .5em;
                            margin-bottom: .5em;
                            border-bottom: 1px solid #EEEEEE;

                            .control-label {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                padding-right: 1em;

                                .control-title {
                                    font-size: 1.2em;
                                }

                                .control-subtitle {
                                    font-weight: 300;
                                    margin-top: .5em;
                                    font-size: 1em;
                                    color: #666666;
                                }
                            }

                            &:last-of-type {
                                padding-bottom: 0;
                                margin-bottom: 0;
                                border-bottom: none;
                            }
                        }
                    }

                    .help-images-wrapper {
                        .details {
                            .help-image {
                                margin-bottom: 3em;
                                
                                .help-image-label {
                                    margin-bottom: 1em;
                                    display: flex;
                                    flex-direction: column;

                                    .help-image-subtitle {
                                        margin-top: 1em;
                                    }
                                }

                                .help-image-container {
                                    width: 18em;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.style-version-2 {
            .editor-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                .block {
                    padding-top: 2em;
                    margin-top: 2em;
                    border-top: 1px solid #CCCCCC;

                    .block-title {
                        font-size: 1.5em;
                        margin-bottom: 1em;
                        color: ${variables.dpc_fontDark};
                    }
                }

                .forms-wrapper {
                    width: 60%;
                    padding-right: 2%;
                }
                .data-wrapper {
                    width: 40%;

                    .details-wrapper {
                        .detail {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: .5em;
                            margin-bottom: .5em;
                            border-bottom: 1px solid #EEEEEE;

                            .detail-value {
                                margin-left: 5em;
                                word-break: break-all;
                                text-align: right;
                            }

                            .detail-label {
                                min-width: 10em;

                                &.detail-label-link {
                                    color: inherit;
                                    text-decoration: underline;
                                }
                            }

                            .detail-value {
                                word-break: break-all;
                                text-align: right;
                                padding-left: 1em;
                            }

                            &:last-of-type {
                                padding-bottom: 0;
                                margin-bottom: 0;
                                border-bottom: none;
                            }
                        }
                    }

                    .controls-wrapper {
                        .control {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-bottom: .5em;
                            margin-bottom: .5em;
                            border-bottom: 1px solid #EEEEEE;

                            .control-label {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: flex-start;
                                padding-right: 1em;

                                .control-title {
                                    font-size: 1.2em;
                                    color: #C1C1C1;
                                }

                                .control-subtitle {
                                    font-weight: 300;
                                    margin-top: .5em;
                                    font-size: 1em;
                                    color: #666666;
                                }
                            }

                            &:last-of-type {
                                padding-bottom: 0;
                                margin-bottom: 0;
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.tabletL}) {
            &.style-version-2 {
                .header-wrapper {
                    .col-right {
                        width: 100%;

                        .component-button {
                            width: 100%;

                            button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    `;
