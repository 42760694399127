import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, LIST_MARKUP, ELEMENT_MARKUP } from 'Consts/redux';

import {
    API_RESOURCE_CHAT_CONVERSATIONS,
    API_RESOURCE_CHAT_CONVERSATION,
    API_RESOURCE_CHAT_CONVERSATION_USERS,
    API_RESOURCE_CHAT_MESSAGES,
    API_RESOURCE_CHAT_MASS_MESSAGES,
    API_RESOURCE_CHAT_MASS_MESSAGE,
} from 'Consts/apiResources';

export const initialState = {
    conversationsList: { ...LIST_MARKUP },
    conversationUsersList: { ...LIST_MARKUP },
    conversationsMessagesMap: {},
    massMessagingList: { ...LIST_MARKUP },
    massMessagesByIdMap: {},
};

export default function ChatReducer(state = initialState, action) {
    switch (action.type) {
        case types.LIST_CONVERSATIONS + '_' + INIT_FLAG:
            return {
                ...state,
                conversationsList: {
                    ...state.conversationsList,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.LIST_CONVERSATIONS + '_' + SUCCESS_FLAG:
            return {
                ...state,
                conversationsList: {
                    ...state.conversationsList,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_CHAT_CONVERSATIONS].elements,
                    meta: action.payload[API_RESOURCE_CHAT_CONVERSATIONS].meta,
                    error: false,
                },
            };
        case types.LIST_CONVERSATIONS + '_' + FAIL_FLAG:
            return {
                ...state,
                conversationsList: {
                    ...state.conversationsList,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };

        case types.LIST_CONVERSATION_USERS + '_' + INIT_FLAG:
            return {
                ...state,
                conversationUsersList: {
                    ...state.conversationUsersList,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.LIST_CONVERSATION_USERS + '_' + SUCCESS_FLAG:
            return {
                ...state,
                conversationUsersList: {
                    ...state.conversationUsersList,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_CHAT_CONVERSATION_USERS].elements,
                    meta: action.payload[API_RESOURCE_CHAT_CONVERSATION_USERS].meta,
                    error: false,
                },
            };
        case types.LIST_CONVERSATION_USERS + '_' + FAIL_FLAG:
            return {
                ...state,
                conversationUsersList: {
                    ...state.conversationUsersList,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };

        case types.LIST_CONVERSATION_MESSAGES + '_' + INIT_FLAG:
            //eslint-disable-next-line no-case-declarations
            const previousObjectOnInit = state.conversationsMessagesMap[action.params.conversationId] || { ...LIST_MARKUP };
            return {
                ...state,
                conversationsMessagesMap: {
                    ...state.conversationsMessagesMap,
                    [action.params.conversationId]: {
                        ...previousObjectOnInit,
                        isLoading: true,
                        isLoaded: false,
                    },
                },
            };
        case types.LIST_CONVERSATION_MESSAGES + '_' + SUCCESS_FLAG:
            //eslint-disable-next-line no-case-declarations
            const previousObjectOnSuccess = state.conversationsMessagesMap[action.params.conversationId] || { ...LIST_MARKUP };
            //eslint-disable-next-line no-case-declarations
            const previousMessages = previousObjectOnSuccess.elements;
            //eslint-disable-next-line no-case-declarations
            const nextMessages = action.payload[API_RESOURCE_CHAT_MESSAGES].elements;
            //eslint-disable-next-line no-case-declarations
            const newMessages = nextMessages.filter(nextMessage => previousMessages.findIndex(message => message.id === nextMessage.id) === -1);

            return {
                ...state,
                conversationsMessagesMap: {
                    ...state.conversationsMessagesMap,
                    [action.params.conversationId]: {
                        ...previousObjectOnSuccess,
                        isLoading: false,
                        isLoaded: true,
                        elements: [...newMessages, ...previousMessages],
                        meta: action.payload[API_RESOURCE_CHAT_MESSAGES].meta,
                        error: false,
                    },
                },
            };
        case types.LIST_CONVERSATION_MESSAGES + '_' + FAIL_FLAG:
            //eslint-disable-next-line no-case-declarations
            const previousObjectOnFail = state.conversationsMessagesMap[action.params.conversationId] || { ...LIST_MARKUP };
            return {
                ...state,
                conversationsMessagesMap: {
                    ...state.conversationsMessagesMap,
                    [action.params.conversationId]: {
                        ...previousObjectOnFail,
                        isLoading: false,
                        isLoaded: true,
                        error: false,
                    },
                },
            };

        case types.ENABLE_CONVERSATION + '_' + SUCCESS_FLAG:
            return {
                ...state,
                conversationsList: {
                    ...state.conversationsList,
                    elements: state.conversationsList.elements.map(element => element.id === action.params.id
                        ? { ...element, ...action.payload[API_RESOURCE_CHAT_CONVERSATION] }
                        : { ...element }
                    ),
                },
            };

        case types.DISABLE_CONVERSATION + '_' + SUCCESS_FLAG:
            return {
                ...state,
                conversationsList: {
                    ...state.conversationsList,
                    elements: state.conversationsList.elements.map(element => element.id === action.params.id
                        ? { ...element, ...action.payload[API_RESOURCE_CHAT_CONVERSATION] }
                        : { ...element }
                    ),
                },
            };

        case types.LIST_MASS_MESSAGES + '_' + INIT_FLAG:
            return {
                ...state,
                massMessagingList: {
                    ...state.massMessagingList,
                    isLoading: true,
                },
            };

        case types.LIST_MASS_MESSAGES + '_' + SUCCESS_FLAG:
            return {
                ...state,
                massMessagingList: {
                    ...state.massMessagingList,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_CHAT_MASS_MESSAGES].elements,
                    meta: action.payload[API_RESOURCE_CHAT_MASS_MESSAGES].meta,
                },
            };

        case types.LIST_MASS_MESSAGES + '_' + FAIL_FLAG:
            return {
                ...state,
                massMessagingList: {
                    ...state.massMessagingList,
                    isLoading: false,
                    isLoaded: true,
                    elements: null,
                    meta: null,
                    error: true,
                },
            };

        case types.SINGLE_MASS_MESSAGE + '_' + INIT_FLAG:
            return {
                ...state,
                massMessagesByIdMap: {
                    ...state.massMessagesByIdMap,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        isLoading: true,
                    },
                },
            };

        case types.SINGLE_MASS_MESSAGE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                massMessagesByIdMap: {
                    ...state.massMessagesByIdMap,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        isLoading: false,
                        isLoaded: true,
                        data: action.payload[API_RESOURCE_CHAT_MASS_MESSAGE],
                    },
                },
            };

        case types.SINGLE_MASS_MESSAGE + '_' + FAIL_FLAG:
            return {
                ...state,
                massMessagesByIdMap: {
                    ...state.massMessagesByIdMap,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        isLoading: false,
                        isLoaded: true,
                        data: null,
                        error: true,
                    },
                },
            };

        default:
            return state;
    }
}
