import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_OFFER_PRODUCTS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            offerId: params.offerId || undefined,
            orderByDirection: params.orderByDirection || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_OFFER_PRODUCT, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_OFFER_PRODUCTS, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_OFFER_PRODUCT, params),
        params,
        requestParams: params,
    }));
};

export const destroy = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.DESTROY,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_OFFER_PRODUCT, params),
        params,
        requestParams: params,
    }));
};

