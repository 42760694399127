import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';
import { single } from 'Redux/modules/admin/trainingTargetCategories/actions';

export default connect(
    state => ({
        trainingTargetCategoriesMap: state.adminTrainingTargetCategories.mapById,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        trainingTargetCategory: stateProps.trainingTargetCategoriesMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-admin-TrainingTargetCategoriesManage" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);