import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import { withVariables } from 'Utils/string';
import { getCookie, setCookie } from 'Utils/cookies';

import adeptRoutes from 'Routes/adept';
import adeptModals from 'Routes/adeptModals';

import { USER_ROLE_ADEPT } from 'Consts/userRoles';
import {
    PUBLIC_USER_LOGIN,
    ADEPT_DASHBOARD,
    ADEPT_EVENTS,
    ADEPT_DATA,
    ADEPT_USER_OFFERS,
    ADEPT_FINANCES,
    ADEPT_CONTACTS,
    ADEPT_PROFILE,
    PUBLIC_MOBILE_APP,
    PUBLIC_SHOP,
    ADEPT_CHAT,
} from 'Consts/routes';
import { 
    API_RESOURCE_NOTIFICATIONS_INTERNAL,
    API_RESOURCE_MEDICAL_CARD,
    API_RESOURCE_EVENTS,
} from 'Consts/apiResources';
import { NOTIFICATION_STATUS_UNREAD } from 'Consts/notification';
import { COOKIE_ONBOARDING_MODAL, COOKIE_MEDICAL_CARD_NOTIFICATION } from 'Consts/cookies';

import Sidebar from 'Components/layout/panel/Sidebar';
import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Topbar from 'Components/layout/panel/Topbar';
import Footer from 'Components/layout/panel/Footer';
import { TAB_DATA } from 'Components/pages/adept/Profile/component';
import PasswordChangeModal from 'Components/adept/modals/PasswordChange';
import OnboardingModal from 'Components/adept/modals/Onboarding';

export default class AdeptWrapper extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            logout: PropTypes.func.isRequired,
            listNotifications: PropTypes.func.isRequired,
            readNotification: PropTypes.func.isRequired,
            currentMedicalCard: PropTypes.func.isRequired,
            listEvents: PropTypes.func.isRequired,
            setAllAsRead: PropTypes.func.isRequired,
        }),
        profile: PropTypes.shape({
            roles: PropTypes.arrayOf(
                PropTypes.string.isRequired
            ),
        }),
        notifications: PropTypes.object,
    };
    static defaultProps = {};
    state = {
        authorized: false,
        changePassword: false,
        notifications: null,
        unreadNotifications: null,
        onboardingModal: false,
    };
    loadDataInterval = null;
    loadDataTimeout = null;

    componentDidMount = () => {
        const { profile, actions } = this.props;
        const medicalCardNotificationCookie = getCookie(COOKIE_MEDICAL_CARD_NOTIFICATION);
        const onboardingCookie = getCookie(COOKIE_ONBOARDING_MODAL);

        if (!onboardingCookie) {
            actions.listEvents({ userId: profile.id, orderBy: 'future' })
                .then(response => {
                    const result = response.payload[API_RESOURCE_EVENTS].elements;
                    
                    if (result.length > 1) {
                        return this.setOnboardingCookie();
                    }

                    this.setState({ onboardingModal: true });
                });
        }

        if (!medicalCardNotificationCookie) {
            actions.currentMedicalCard()
                .then(response => {
                    const result = response.payload[API_RESOURCE_MEDICAL_CARD];               
                    if (!result) {
                        let expirationDate = moment().add(1, 'day')
                            .toDate()
                            .toUTCString();
                        
                        setCookie(COOKIE_MEDICAL_CARD_NOTIFICATION, true, { expires: expirationDate });

                        return toast.error('Nie posiadasz wywiadu medycznego. Uzupełnij go, aby w pełni korzystać z panelu.', { 
                            autoClose: 1000 * 20, 
                        });
                    } else {
                        let expirationDate = moment().add(1, 'year')
                            .toDate()
                            .toUTCString();
                        
                        setCookie(COOKIE_MEDICAL_CARD_NOTIFICATION, true, { expires: expirationDate });                        
                    } 
                });
        }
        
        this.authorize();

        if (!profile.hasPassword) {
            this.setState({ changePassword: true });
        }

        this.loadData();
        this.setIntervals();

        window.addEventListener('visibilitychange', this.loadDataWhenActiveTab);
    }

    componentDidUpdate = prevProps => {
        const { profile } = this.props;

        if (JSON.stringify(profile) !== JSON.stringify(prevProps.profile)) {
            this.authorize();
        }
    }

    componentWillUnmount = () => {
        this.clearIntervals();
        window.removeEventListener('visibilitychange', this.loadDataWhenActiveTab);
    }

    setIntervals = () => {
        this.loadDataInterval = setInterval(() => this.loadData(), 60 * 3 * 1000); 
        this.loadDataTimeout = setTimeout(() => this.loadData(), 3000);
    }

    clearIntervals = () => {
        clearInterval(this.loadDataInterval);
        clearTimeout(this.loadDataTimeout);
    }

    loadDataWhenActiveTab = () => {
        if (document.visibilityState == 'visible') {
            this.setIntervals();
        }

        if (document.visibilityState == 'hidden') {
            this.clearIntervals();
        }
    }

    loadData = () => {
        this.loadNotifications();
    }

    loadNotifications = () => {
        const { actions } = this.props;

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.listNotifications()
                .then(response => {
                    const result = response.payload[API_RESOURCE_NOTIFICATIONS_INTERNAL];
                    this.setState({
                        notifications: result,
                    });
                });

            actions.listNotifications({ state: NOTIFICATION_STATUS_UNREAD })
                .then(response => {
                    const result = response.payload[API_RESOURCE_NOTIFICATIONS_INTERNAL];
                    this.setState({
                        unreadNotifications: result,
                    });
                });
        }, 500);
    }

    authorize = () => {
        const { profile, history, actions } = this.props;

        //Break if profile isn't available yet
        if (!profile) {
            return false;
        }

        //Go back to login if user has wrong role
        if (profile.role !== USER_ROLE_ADEPT) {
            actions.logout();
            history.push(PUBLIC_USER_LOGIN.path);
            return false;
        }

        //Show content
        this.setState({ authorized: true });

        return true;
    }

    setOnboardingCookie = () => {
        let expirationDate = moment().add(1, 'year')
            .toDate()
            .toUTCString();
        
        setCookie(COOKIE_ONBOARDING_MODAL, true, { expires: expirationDate });
    }

    readAllNotifications = () => {
        const { actions } = this.props;
        
        let confirmed = window.confirm('Czy na pewno chcesz oznaczyć wszystkie powiadomienia jako przeczytane?');

        if (confirmed) {
            actions.setAllAsRead().then(this.loadNotifications());
        }
    }
    
    render() {
        const { location, history, actions, profile } = this.props;
        const { authorized, changePassword, notifications, unreadNotifications, onboardingModal } = this.state;
        const { readNotification, setAllAsRead } = actions;
        const Modal = adeptModals[location.hash] && adeptModals[location.hash] || null;

        let isPromoBannerVisible = true;

        if(moment().isAfter(moment('2021-04-30 23:59:00'))) {
            isPromoBannerVisible = false;
        }

        return (
            !authorized
                ? (
                    <Spinner />
                )
                : (
                    <StyledComponent
                        props={this.props}
                        state={this.state}
                        styles={require('./styles')}
                        className="adept-wrapper"
                    >
                        <div className="sidebar-container">
                            <Sidebar
                                mainPath={ADEPT_DASHBOARD.path}
                                navigation={[{
                                    key: 'trainings',
                                    title: 'Wydarzenia',
                                    link: ADEPT_EVENTS.path,
                                    icon: require('Theme/images/icons/training.svg'),
                                }, {
                                    key: 'medical-cards',
                                    title: 'Kartoteka',
                                    link: ADEPT_DATA.path,
                                    icon: require('Theme/images/icons/health-data.svg'),
                                }, {
                                    key: 'user-offers',
                                    title: 'Zamówienia',
                                    link: ADEPT_USER_OFFERS.path,
                                    icon: require('Theme/images/icons/star.svg'),
                                }, {
                                    key: 'finances',
                                    title: 'Finanse',
                                    link: ADEPT_FINANCES.path,
                                    icon: require('Theme/images/icons/finance.svg'),
                                }, {
                                    key: 'chat',
                                    title: 'Czat',
                                    link: ADEPT_CHAT.path,
                                    icon: require('Theme/images/icons/chat.svg'),
                                }, {
                                    key: 'contacts',
                                    title: 'Kontakty',
                                    link: ADEPT_CONTACTS.path,
                                    icon: require('Theme/images/icons/user.svg'),
                                }, {
                                    key: 'application',
                                    title: 'Aplikacja FitAdept',
                                    link: PUBLIC_MOBILE_APP.path,
                                    icon: require('Theme/images/icons/mobile-solid.svg'),
                                }, {
                                    key: 'fitadept-tv',
                                    title: 'FitAdept TV',
                                    link: profile.accessCode
                                        ? `${process.env.FATV_APP_URL}/klienci-korporacyjni?accessCode=${profile.accessCode}&email=${profile.email}&name=${profile.name}&surname=${profile.surname}`
                                        : process.env.FATV_APP_URL,
                                    icon: require('Theme/images/icons/tv-solid.svg'),
                                    isLinkOutgoing: true,
                                }, {
                                    key: 'invite',
                                    title: 'Zaproś przyjaciół',
                                    link: 'https://fitadept.com/program-polecen',
                                    icon: require('Theme/images/icons/user-friends.svg'),
                                    isLinkOutgoing: true,
                                }, {
                                    key: 'sklep',
                                    title: 'Sklep',
                                    icon: require('Theme/images/icons/wallet.svg'),
                                    link: PUBLIC_SHOP.path,
                                }, {
                                    key: 'logout',
                                    title: 'Wyloguj',
                                    icon: require('Theme/images/icons/logout.svg'),
                                    onClick: () => actions.logout()
                                        .then(() => {
                                            history.push(PUBLIC_USER_LOGIN.path);
                                        }),
                                }]}
                            />
                        </div>
                        <div className="view-container">
                            <div className="topbar-container">
                                <Topbar 
                                    profilePath={withVariables(ADEPT_PROFILE.path, {}, { tab: TAB_DATA })} 
                                    unreadNotifications={unreadNotifications}
                                    notifications={notifications}
                                    onReadNotification={(id) => readNotification(id).then(this.loadNotifications)}
                                    onReadAllNotifications={() => this.readAllNotifications()}
                                    history={history}
                                />
                            </div>
                                {isPromoBannerVisible && (
                                    <a
                                        className="promo-info"
                                        href={'https://fitadept.com/f-c'}
                                    >
                                        Tylko dla klientow FitAdept - Oferta limitowana: 18% rabatu na dowolny catering. Skorzystaj na <span className="promo-link">https://fitadept.com/f-c </span>
                                    </a>
                                )}
                            <div className="view-page-container">
                                <Switch>
                                    {adeptRoutes}
                                </Switch>
                            </div>
                            <Footer />
                        </div>
                        <PasswordChangeModal 
                            location={location}
                            history={history}
                            isOpen={changePassword}
                            onClose={() => this.setState({ changePassword: false })}
                            fillToClose={true}
                            title="Utwórz hasło do swojego konta"
                        />
                        <OnboardingModal 
                            location={location}
                            history={history}
                            isOpen={onboardingModal}
                            onClose={() => this.setState({ onboardingModal: false }, this.setOnboardingCookie)}
                        />
                        {Modal ? (
                            <Modal
                                location={location}
                                history={history}
                            />
                        ) : null}
                    </StyledComponent>
                )
        );
    }
}
