import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import FormGenerator from 'Components/layout/panel/FormGenerator';
import Button from 'Components/layout/Button';

export default class PanelElementEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        header: PropTypes.any,
        forms: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                name: PropTypes.string.isRequired,
            })
        ),
        details: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                label: PropTypes.string.isRequired,
                value: PropTypes.any,
            })
        ),
        additionalBlocks: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                data: PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.string.isRequired,
                        value: PropTypes.string.isRequired,
                    })
                ),
            })
        ),
        controls: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                title: PropTypes.string.isRequired,
                subtitle: PropTypes.string,
                buttonProps: PropTypes.object.isRequired,
            })
        ),
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
        helpImages: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                title: PropTypes.string.isRequired,
                subtitle: PropTypes.string,
                imageSrc: PropTypes.string.isRequired,
            })
        ),
    };
    static defaultProps = {
        header: null,
        forms: [],
        details: [],
        controls: [],
        additionalBlocks: [],
        styleVersion: 1,
        helpImages: [],
    };

    blockUnlisten = null;

    state = {
        //eslint-disable-next-line react/destructuring-assignment
        formState: this.props.forms,
        isDirty: false,
    }

    componentDidMount = () => {
        const { history } = this.props;

        this.blockUnlisten = history.block((tx) => {
            const { isDirty } = this.state;

            if (isDirty && tx.pathname !== history.location.pathname) { 
                return 'Czy na pewno chcesz wyjść i utracić niezapisane zmiany?';
            } else {
                this.blockUnlisten();
            }
        });
    }

    componentWillUnmount = () => {
        if (this.blockUnlisten) {
            this.blockUnlisten();
        }
    }

    getDetail = ({ label, value, visible, to, href, target }) => (
        <div className="detail" key={label}>
            {to && (
                <>
                    <Link className="detail-label detail-label-link" to={to}>
                        {label}
                    </Link>
                    <span className="detail-value">
                        {value}
                    </span>
                </>
            )}
            {href && (
                <>
                    <a 
                        className="detail-label detail-label-link" 
                        href={href} 
                        target={target || '_self'}
                    >
                        {label}
                    </a>
                    <span className="detail-value">
                        {value}
                    </span>
                </>
            )}
            {!to && !href &&(
                <>
                    <span className="detail-label">
                        {label}
                    </span>
                    <span className="detail-value">
                        {value}
                    </span>
                </>
            )}
        </div>
    )

    getControl = ({ title, subtitle, buttonProps, visible }) => visible === false
        ? null
        : (
            <div className="control" key={title}>
                <div className="control-label">
                    <span className="control-title">
                        {title}
                    </span>
                    {subtitle && (
                        <span className="control-subtitle">
                            {subtitle}
                        </span>
                    )}
                </div>
                <span className="control-control">
                    <Button {...buttonProps} />
                </span>
            </div>
        )

    getHelpImages = ({ visible, title, subtitle, imageSrc }) => visible === false
        ? null
        : (
            <div className="help-image" key={title}>
                <div className="help-image-label">
                    <span className="help-image-title">
                        {title}
                    </span>
                    {subtitle && (
                        <span className="help-image-subtitle">
                            {subtitle}
                        </span>
                    )}
                </div>
                <div 
                    className="help-image-container"
                >
                    <img className="help-image-img" src={imageSrc} />
                </div>
            </div>
        )

    onChangeFormState(formState) {
        this.setState(({
            formState,
            isDirty: true,
        }));
    }
    
    render() {
        const { header, forms, styleVersion } = this.props;

        let { details, controls, additionalBlocks, helpImages } = this.props;
        details = details.filter(detail => detail.visible !== false);
        controls = controls.filter(control => control.visible !== false);
        helpImages = helpImages.filter(helpImage => helpImage.visible !== false);
        additionalBlocks = additionalBlocks.filter(additionalBlock => additionalBlock.visible !== false);
        
        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'admin-element-editor',
                    `style-version-${styleVersion}`
                )}
            >
                <div className="header-wrapper">
                    {header}
                </div>
                <div className="editor-wrapper">
                    <div className="forms-wrapper">
                        {forms.map(form => (
                            <div className="block form-wrapper" key={form.name}>
                                {form.title && (<h2 className="block-title form-title">{form.title}</h2>)}
                                <FormGenerator 
                                    {...form} 
                                    onStateChange={(formState) => {
                                        if (form.onStateChange) {
                                            form.onStateChange(formState);
                                        }
                                        this.onChangeFormState(formState);
                                    }}
                                    submitAction={data => {
                                        return new Promise(resolve => {
                                            this.setState({ isDirty: false }, resolve);
                                        }).then(() => {
                                            return form.submitAction(data)
                                                .catch(err => {
                                                    throw err;
                                                });
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="data-wrapper">
                        {details.length ?
                            <div className="block details-wrapper">
                                <h2 className="block-title details-title">Szczegóły</h2>
                                <div className="details">
                                    {details.map(this.getDetail)}
                                </div>
                            </div>
                            : null}
                        {additionalBlocks.length ?
                            additionalBlocks.map(additionalBlock => (
                                <div key={additionalBlock.key} className="block details-wrapper">
                                    <h2 className="block-title details-title">{additionalBlock.title}</h2>
                                    <div className="details">
                                        {additionalBlock.data.map(this.getDetail)}
                                    </div>
                                </div>
                            ))
                            : null}
                        {controls.length ?
                            <div className="block controls-wrapper">
                                <h2 className="block-title controls-title">Zarządzaj</h2>
                                <div className="details">
                                    {controls.map(this.getControl)}
                                </div>
                            </div>
                        : null}
                        {helpImages.length 
                        ? 
                            <div className="block help-images-wrapper">
                                <h2 className="block-title help-images-title">Zdjęcia pomocnicze</h2>
                                <div className="details">
                                    {helpImages.map(this.getHelpImages)}
                                </div>
                            </div>
                        : null}
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
