import moment from 'moment';

export default class UserEffect {
    constructor(data) {
        this.id = data.id;
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.user = data.user;
        this.userId = data.userId;
        this.lead = data.lead;
        this.leadId = data.leadId;
        this.title = data.title;
        this.text = data.text;
        this.enabled = data.enabled;
        this.comment = data.comment;
        this.weightLoss = data.weightLoss;
        this.metabolicAge = data.metabolicAge;
        this.beltLoss = data.beltLoss;
        this.fatTissueLoss = data.fatTissueLoss;
        this.promoted = data.promoted;
        this.photoBefore = data.photoBefore;
        this.photoAfter = data.photoAfter;
        this.effect = data.effect;
        this.commentAdditional = data.commentAdditional;
        this.effectDetailed = data.effectDetailed;
        this.effectHeadline = data.effectHeadline;
        this.effectSubheadline = data.effectSubheadline;
        this.type = data.type;
        this.photo = data.photo;
        this.order = data.order;
        this.photoInfo = data.photoInfo;
        this.photoDetails = data.photoDetails;
        this.photoComment = data.photoComment;
    };
}