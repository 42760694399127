import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        transform: scale(.3);

        .mobile-trigger-icon {
            width: 60px;
            height: 45px;
            position: relative;
            transform: rotate(0deg);
            transition: .5s ease-in-out;
            cursor: pointer;

            span {
                display: block;
                position: absolute;
                height: 9px;
                width: 50%;
                background: white;
                border-radius: 9px;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: .25s ease-in-out;

                &:nth-of-type(even) {
                    left: 50%;
                    border-radius: 0 9px 9px 0;
                }

                &:nth-of-type(odd) {
                    left:0px;
                    border-radius: 9px 0 0 9px;
                }

                &:nth-of-type(1),
                &:nth-of-type(2) {
                    top: 0px;
                }

                &:nth-of-type(3),
                &:nth-of-type(4) {
                    top: 18px;
                }

                &:nth-of-type(5),
                &:nth-of-type(6) {
                    top: 36px;
                }
            }

            &.open {
                span {
                    &:nth-of-type(1),
                    &:nth-of-type(6) {
                        transform: rotate(45deg);
                    }

                    &:nth-of-type(2),
                    &:nth-of-type(5) {
                        transform: rotate(-45deg);
                    }

                    &:nth-of-type(1) {
                        left: 5px;
                        top: 7px;
                    }

                    &:nth-of-type(2) {
                        left: calc(50% - 5px);
                        top: 7px;
                    }

                    &:nth-of-type(3) {
                        left: -50%;
                        opacity: 0;
                    }

                    &:nth-of-type(4) {
                        left: 100%;
                        opacity: 0;
                    }

                    &:nth-of-type(5) {
                        left: 5px;
                        top: 29px;
                    }

                    &:nth-of-type(6) {
                        left: calc(50% - 5px);
                        top: 29px;
                    }
                }
            }
        }
    `;
