import { getProvider } from 'Services/Cookies'; 
import { COOKIE_AUTH_TOKEN } from 'Consts/cookies';  

export function createSession({ authToken }, req = null) {
    getProvider(req).set(COOKIE_AUTH_TOKEN, authToken);
    return getSession(req);
}

export function getSession(req = null) {
    if(!validateSession(req)) {
        return null;
    }
    
    return {
        authToken: getProvider(req).get(COOKIE_AUTH_TOKEN),
    };
}

export function validateSession(req = null) {
    return Boolean(
        getProvider(req).get(COOKIE_AUTH_TOKEN)
    );
}

export function destroySession(req = null) {
    return getProvider(req).remove(COOKIE_AUTH_TOKEN);
}
