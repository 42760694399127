import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css({
    '.logo': {
        padding: '2em',
    },
    '.user-info': {
        padding: '2em',
        backgroundColor: '#333333',
        textAlign: 'left',
        color: variables.lightColor,

        '.user-email': {
            fontSize: '1.3em',
        },
        '.user-logout-link': {
            cursor: 'pointer',
            marginTop: '.5em',
            display: 'block',

            '&:hover': {
                color: '#666666',
            },
        },
    },
    '.navigation': {
        display: 'flex',
        flexDirection: 'column',
        '.navigation-link': {
            color: variables.lightColor,
            width: '100%',
            padding: '1em 2em',
            backgroundColor: '#444444',
            borderBottom: '.1em solid #555555',
            ':hover': {
                backgroundColor: '#555555',
            },
        },
    },
});
