import { isHttps } from 'Utils/url';
import Cookies from 'universal-cookie';

export function parseValue(value) {
    return typeof value === 'undefined' || value === 'undefined' || value === 'null' ? null : value;
}

export function getProvider(req) {
    const core = req
        ? new Cookies(typeof req.headers.cookie !== 'undefined' && req.headers.cookie ? req.headers.cookie : '')
        : new Cookies('');

    return {
        get: name => {
            return parseValue(core.get(name, { path: getDefaultOptions().path }));
        },
        set: (name, value, options = {}) => {
            return core.set(name, value, { ...getDefaultOptions(), ...parseOptions(options) });
        },
        remove: name => {
            return core.remove(name, { path: getDefaultOptions().path });
        },
    };
}

function getDefaultOptions() {
    return {
        path: '/',
        expires: getDefaultExpireDate(),
        secure: isHttps(),
    };
}

function parseOptions(options) {
    return {
        ...options,
        expires: options.session ? 
            getSessionExpireDate() 
            : 
            options.expires ? options.expires : getDefaultExpireDate(),
    };
}

function getDefaultExpireDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year + 99, month, day);
}

function getSessionExpireDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year, month, day - 1);
}
