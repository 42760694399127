import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_DASHBOARD, ADEPT_DASHBOARD, TRAINER_DASHBOARD } from 'Consts/routes';
import { USER_ROLE_ADMIN,USER_ROLE_ADEPT, USER_ROLE_TRAINER } from 'Consts/userRoles';

import StyledComponent from 'Components/core/StyledComponent';
import Footer from 'Components/public/layout/Footer';

import { Switch } from 'react-router-dom';
import publicUserRoutes from 'Routes/publicUser';
import serviceRoutes from 'Routes/service';
import publicUserModals from 'Routes/publicUserModals';

export default class PublicUserWrapper extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        authToken: PropTypes.string,
        profile: PropTypes.shape({
            role: PropTypes.string.isRequired,
        }),
        actions: PropTypes.shape({
            getProfile: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {
        authToken: null,
    };

    componentDidMount = () => {
        const { actions, authToken } = this.props;

        if(authToken) {
            actions.getProfile();
            this.redirectToRole();
        }
    };

    componentDidUpdate = () => {
        this.redirectToRole();
    }

    redirectToRole = () => {
        const { authToken, profile, history } = this.props;

        if (authToken && profile) {
            switch (profile.role) {
                case USER_ROLE_ADEPT:
                    history.push(ADEPT_DASHBOARD.path);
                    break;

                case USER_ROLE_TRAINER:
                    history.push(TRAINER_DASHBOARD.path);
                    break;

                case USER_ROLE_ADMIN:
                    history.push(ADMIN_DASHBOARD.path);
                    break;
            }
        }
    }

    render() {
        const { location, history } = this.props;
        const Modal = publicUserModals[location.hash] && publicUserModals[location.hash] || null;

        return (
            <StyledComponent
                props={this.props}
                state={this.state}
                styles={require('./styles')}
                className="public-user-wrapper"

            >
                <div className="page-wrapper">
                    <Switch>
                        {publicUserRoutes}
                        {serviceRoutes}
                    </Switch>
                </div>
                <Footer />
                {Modal ? (
                    <Modal
                        location={location}
                        history={history}
                    />
                ) : null}
            </StyledComponent>
        );
    }
}
