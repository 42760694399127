import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        width: 100%;

        .modal-body-component {
            width: 34em;
            min-height: 45em;
        }

        @media (max-width: ${variables.mobileL}) {
            .modal-body-component {
                width: auto;
                min-height: 40em;
            }
        }
    `;
