import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layout/modals/ModalWrapper';
import ModalBody from 'Components/layout/modals/ModalBody';
import ModalTitle from 'Components/layout/modals/ModalTitle';
import PasswordChange from 'Components/adept/passwordChange';

export default class AdeptModalPasswordChange extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        fillToClose: PropTypes.bool,
        headline: PropTypes.string,
        title: PropTypes.string,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        headline: null,
        fillToClose: false,
        title: 'Zmiana hasła',
    };
    state = {};

    render() {
        const { location, history, isOpen, onClose, headline, fillToClose, title } = this.props;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={fillToClose ? null : onClose}
                styleVariant="default"
                showCloseButton={!fillToClose}
            >
                <StyledComponent className="adept-modal-change-password" styles={require('./styles')}>
                    <ModalBody>
                        <ModalTitle title={title} />
                        <PasswordChange
                            location={location}
                            history={history}
                            onConfirm={onClose}
                            submitButtonStyle='gradient'  
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
