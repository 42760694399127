import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, LIST_MARKUP, ELEMENT_MARKUP } from 'Consts/redux';
import {
    API_RESOURCE_USER_OFFERS,
    API_RESOURCE_USER_OFFERS_INCOME,
    API_RESOURCE_USER_OFFER,
    API_RESOURCE_USER_OFFER_LOGS,
    API_RESOURCE_USER_OFFER_USERS,
} from 'Consts/apiResources';

import UserOffer from 'Models/UserOffer';

export const initialState = {
    list: { ...LIST_MARKUP },
    incomeList: { ...LIST_MARKUP },
    logs: { ...LIST_MARKUP },    
    mapById: {},
};

export default function (state = initialState, action) {
    const replaceStateWithElement = (identifier = 'id') => ({
        ...state,
        list: {
            ...state.list,
            elements: state.list.elements
                .map(element => element[identifier] === action.params[identifier]
                    ? action.payload[API_RESOURCE_USER_OFFER]
                    : element
                ),
        },
        mapById: {
            ...state.mapById,
            [action.params[identifier]]: {
                ...ELEMENT_MARKUP,
                ...(state.mapById[action.params[identifier]] || {}),
                data: action.payload[API_RESOURCE_USER_OFFER],
            },
        },
    });
    switch (action.type) {
        case types.LIST + '_' + INIT_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    params: action.params,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.LIST + '_' + SUCCESS_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_USER_OFFERS].elements
                        .map(elem => new UserOffer(elem)),
                    meta: action.payload[API_RESOURCE_USER_OFFERS].meta,
                    error: false,
                },
            };
        case types.LIST + '_' + FAIL_FLAG:
            return {
                ...state,
                list: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };

        case types.INCOME + '_' + INIT_FLAG:
            return {
                ...state,
                incomeList: {
                    ...LIST_MARKUP,
                    ...state.incomeList,
                    params: action.params,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.INCOME + '_' + SUCCESS_FLAG:
            return {
                ...state,
                incomeList: {
                    ...LIST_MARKUP,
                    ...state.incomeList,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_USER_OFFERS],
                    income: action.payload[API_RESOURCE_USER_OFFERS_INCOME],
                    meta: null,
                    error: false,
                },
            };
        case types.INCOME + '_' + FAIL_FLAG:
            return {
                ...state,
                incomeList: {
                    ...LIST_MARKUP,
                    ...state.incomeList,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };

        case types.SINGLE + '_' + INIT_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.id] || {}),
                        isLoading: true,
                        isLoaded: false,
                    },
                },
            };
        case types.SINGLE + '_' + SUCCESS_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.id] || {}),
                        isLoading: false,
                        isLoaded: true,
                        data: new UserOffer(action.payload[API_RESOURCE_USER_OFFER]),
                        error: false,
                    },
                },
            };
        case types.SINGLE + '_' + FAIL_FLAG:
            return {
                ...state,
                mapById: {
                    ...state.mapById,
                    [action.params.id]: {
                        ...ELEMENT_MARKUP,
                        ...(state.mapById[action.params.id] || {}),
                        isLoading: false,
                        isLoaded: true,
                        data: null,
                        error: true,
                    },
                },
            };
        case types.LOGS + '_' + INIT_FLAG:
            return {
                ...state,
                logs: {
                    ...LIST_MARKUP,
                    ...state.logs,
                    params: action.params,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.LOGS + '_' + SUCCESS_FLAG:
            return {
                ...state,
                logs: {
                    ...LIST_MARKUP,
                    ...state.logs,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_USER_OFFER_LOGS].elements,
                    meta: action.payload[API_RESOURCE_USER_OFFER_LOGS].meta,
                    error: false,
                },
            };
        case types.LOGS + '_' + FAIL_FLAG:
            return {
                ...state,
                logs: {
                    ...LIST_MARKUP,
                    ...state.logs,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };
        case types.GET_SUB_USERS + '_' + INIT_FLAG:
            return {
                ...state,
                subUsers: {
                    ...LIST_MARKUP,
                    ...state.list,
                    params: action.params,
                    isLoading: true,
                    isLoaded: false,
                },
            };
        case types.GET_SUB_USERS + '_' + SUCCESS_FLAG:
            return {
                ...state,
                subUsers: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    elements: action.payload[API_RESOURCE_USER_OFFER_USERS].elements,
                    meta: action.payload[API_RESOURCE_USER_OFFER_USERS].meta,
                    error: false,
                },
            };
        case types.GET_SUB_USERS + '_' + FAIL_FLAG:
            return {
                ...state,
                subUsers: {
                    ...LIST_MARKUP,
                    ...state.list,
                    isLoading: false,
                    isLoaded: true,
                    error: true,
                },
            };

        case types.UPDATE + '_' + SUCCESS_FLAG:
        case types.CANCEL + '_' + SUCCESS_FLAG:
        case types.ACTIVATE + '_' + SUCCESS_FLAG:
        case types.EXTEND + '_' + SUCCESS_FLAG:
        case types.PAYMENT + '_' + SUCCESS_FLAG:
        case types.CLOSE + '_' + SUCCESS_FLAG:
        case types.ADD_PAYMENT + '_' + SUCCESS_FLAG:
        case types.REMOVE_USER + '_' + SUCCESS_FLAG:
            return { ...replaceStateWithElement() };

        default:
            return state;
    }
}
