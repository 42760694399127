export const LIST = 'adept/events/LIST';
export const SINGLE = 'adept/events/SINGLE';
export const STATS = 'adept/events/STATS';
export const CREATE = 'adept/events/CREATE';
export const UPDATE = 'adept/events/UPDATE';
export const CANCEL = 'adept/events/CANCEL';
export const DONE = 'adept/events/DONE';
export const LAST_LOCATION = 'adept/events/LAST_LOCATION';
export const LAST_LEAD = 'adept/events/LAST_LEAD';
export const SLOT_OCCUPATION = 'adept/events/SLOT_OCCUPATION';
export const SUGGEST_FREE_SLOTS = 'adept/events/SUGGEST_FREE_SLOTS';
export const CHECK_CYCLE_SLOTS_AVAILABILITY = 'adept/events/CHECK_CYCLE_SLOTS_AVAILABILITY';
export const CREATE_MANY = 'adept/events/CREATE_MANY';