import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        position: relative;
        height: 3em;
        width: 3em;
        -webkit-user-select: none; 
        -moz-user-select: none;    
        -ms-user-select: none;      
        user-select: none;    

        .notifications-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border-radius: 360em;
            background-color: ${variables.dpc_backgroundDarkLight};

            .notifications-control-enable {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                cursor: pointer;

                .notifications-bell-icon {
                    position: absolute;
                    font-size: 1.2em;
                    color: ${variables.dpc_backgroundWhite};
                }

                .unread-notifications-count {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 1.25em;
                    height: 1.25em;
                    background-color: #DF3846;
                    color: white;
                    border-radius: 360em;
                    top: 1.9em;
                    left: 1.9em;
                    font-size: 1em;
                    font-weight: 600;
                }
            }

            .notifications-list-wrapper {
                position: absolute;
                display: flex;
                flex-direction: column;
                top: 3.5em;
                background-color: #393939;
                border-radius: .3em;
                border: 1px solid #444;
                overflow-y: scroll;
                box-shadow: 0 0 5px 0px #00000069;
                max-height: 34em;

                .notifications-title {
                    padding: .5em;
                    color: white;
                    font-weight: 600;
                    font-size: 1.1em;
                }

                .notifications-controls {
                    padding: .5em .5em;
                    color: #8c8c8c;

                    .control {
                        cursor: pointer;

                        &.active {
                            color: #f9f9f9;
                        }
                    }
                }

                .set-all-as-read {
                    padding: 1em;
                    color: white;
                    cursor: pointer;
                    text-align: center;
                }

                .no-notification {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #666;
                    width: 25em;
                    height: 8em;

                    .fa-bell-slash {
                        margin-right: .5em;
                    }
                }

                &.unread {
                    .notification-wrapper {
                        background: black;
                    }
                }

                &::-webkit-scrollbar {
                    width: .5em;
                }

                &::-webkit-scrollbar-track {
                    background-color: #393939; 
                }

                ::-webkit-scrollbar-thumb {
                    background-color: ${variables.dpc_backgroundWhite}; 
                    border-radius: 3em;
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            position: initial;
            
            .notifications-wrapper {
                position: initial;

                .notifications-list-wrapper {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        @media (max-width: ${variables.mobileS}) {
            .notifications-wrapper {
                .notifications-list-wrapper {
                    width: 100%;

                    .notification-wrapper {
                        width: 100%;
                    }
                }
            }
        }
    `;

