import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';

import StyledComponent from 'Components/core/StyledComponent';
import InternalWrapper from 'Components/forms/InputInternalWrapper';
import ErrorMessage from 'Components/forms/ErrorMessage';
import InputLabel from 'Components/forms/InputLabel';

export default class FormTextareaComponent extends React.Component {
    static displayName = 'FormTextareaComponent';
    static propTypes = {
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        onFormChange: PropTypes.func,
        icon: PropTypes.string,
        placeholder: PropTypes.string,
        label: PropTypes.string,
        errorMessage: PropTypes.string,
        disabled: PropTypes.bool,
        value: PropTypes.string,
        required: PropTypes.bool,
        className: PropTypes.string,
        styleVersion: PropTypes.oneOf([
            1, 2,
        ]),
        size: PropTypes.oneOf([
            'medium',
        ]),
        border: PropTypes.oneOf([
            'default', 'light', 'light-2',
        ]),
    };
    static defaultProps = {
        icon: null,
        placeholder: '',
        label: '',
        errorMessage: '',
        value: '',
        required: false,
        className: '',
        style: 'default',
        size: 'medium',
        styleVersion: 1,
    }

    onChange = event => {
        const { onChange, onFormChange } = this.props;

        const data = {
            name: event.target.name,
            value: event.target.value,
        };
        if (onChange) {
            onChange(data);
        }

        if (onFormChange) {
            onFormChange(data);
        }

        return data;
    }

    render() {
        const { value, name, icon, placeholder, label, errorMessage, required, disabled, className, style, size, border, styleVersion } = this.props;

        const textareaProps = {
            id: name,
            name,
            onChange: this.onChange,
            placeholder,
            value: value || '',
            required,
            disabled,
        };

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'form-element',
                    'textarea',
                    className,
                    `style-version-${styleVersion}`,
                    `size-${size}`,
                )}
            >
                {label && <InputLabel label={label} style={style} styleVersion={styleVersion} required={required} />}
                <InternalWrapper
                    style={style}
                    error={Boolean(errorMessage)}
                    styleVersion={styleVersion}
                    border={border}
                >
                    <TextareaAutosize 
                        className={classnames(
                            `border-${border}`,
                        )}
                        {...textareaProps} 
                    />
                </InternalWrapper>
                {icon && <div className="input-icon"><img src={icon} /></div>}
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </StyledComponent>
        );
    }
}
