import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .admin-element-editor {
        .editor-wrapper {
            .forms-wrapper {
                width: 100% !important; 
                padding-right: 0 !important ;

                .form-wrapper {
                    border-top: none;
                    margin-top: 0 !important;
                    padding-top: 0 !important;

                    .form-elements {
                        flex-wrap: wrap;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .form-element {
                            color: white;
                            width: 50%;
                            margin-bottom: 2em;

                            &.date-picker {
                                .input-external-wrapper {
                                    flex-direction: column;
                                    align-items: center;

                                    .input-internal-wrapper {
                                        width: 80%;

                                        .react-datepicker-wrapper {
                                            width: 100%;

                                            input {
                                                text-align: center;
                                            }
                                        }

                                        .react-datepicker-popper {
                                            top: 3em !important;
                                            left: 50% !important;
                                            transform: translateX(-50%) !important;
                                        }
                                    }
                                }
                            }

                            .react-datepicker__time-container {
                                min-width: 12em;
                            }
                        }
                    }
                }
            }

            .data-wrapper {
                display: none;
            }
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .admin-element-editor {
            .editor-wrapper {
                .forms-wrapper {
                    width: 100% !important;
                    
                    .form-wrapper {
                        .form-elements {
                            flex-wrap: wrap;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .form-element {
                                color: white;
                                width: 100%;

                                &.date-picker {
                                    .input-external-wrapper {
                                        flex-direction: column;
                                        align-items: flex-start;

                                        .input-internal-wrapper {
                                            width: 100%;
                                            text-align: center;

                                            .react-datepicker__input-container {
                                                input {
                                                    text-align: center;
                                                }
                                            }
                                        }
                                    }
                                }
                                .react-datepicker__time-container {
                                    min-width: 12em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
