import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

export default class ModalTitleComponent extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        styleVariant: PropTypes.oneOf(['default', 'dark']),
    };
    static defaultProps = {
        styleVariant: 'default',
    };

    render() {
        const { title, styleVariant } = this.props;

        return (
            <StyledComponent 
                className={classnames({
                    'modal-title-component' : true,
                    [`style-${styleVariant}`]: true,
                })}
                styles={require('./styles')}
            >
                {title}
            </StyledComponent>
        );
    }
}
