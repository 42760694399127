import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const listConversations = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_CONVERSATIONS,
        method: 'GET',
        path: endpoints.ENDPOINT_AUTH_CHAT_CONVERSATIONS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            enabled: typeof params.enabled === 'boolean' ? params.enabled : undefined,
            active: typeof params.active === 'boolean' ? params.active : undefined,
            conversationRole: params.conversationRole || undefined,
        },
    }));
};

export const listConversationMessages = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_CONVERSATION_MESSAGES,
        method: 'GET',
        ignoreStore: params.ignoreStore || false,
        path: endpoints.ENDPOINT_AUTH_CHAT_CONVERSATION_MESSAGES
            .replace('{conversationId}', params.conversationId),
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 100,
        },
        params,
    }));
};

export const sendConversationMessages = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SEND_CONVERSATION_MESSAGES,
        method: 'POST',
        path: endpoints.ENDPOINT_AUTH_CHAT_CONVERSATION_MESSAGES
            .replace('{conversationId}', params.conversationId),
        params,
        requestParams: {
            messages: params.messages,
        },
    }));
};