export const parseCookiesToObject = () => {
    const cookies = document.cookie; 
    const cookiesObject = {};

    let cookiesArray = cookies.split(';');
    cookiesArray = cookiesArray.map(cookie => {
        const splittedCookie = cookie.split('=');
        let cookieName = splittedCookie[0];
        const cookieValue = splittedCookie[1];
        
        cookieName = cookieName.split(' ').join('');
        cookiesObject[cookieName] = cookieValue;
    });

    return cookiesObject;
};

export const setCookie = (name, value, options ) => {
    let cookies = document.cookie; 
    let optionsArray = [];

    const optionsObjectKeys = Object.keys(options);
    
    optionsObjectKeys.map(option => {
        const string = option + '=' + options[option];
        optionsArray.push(string);
    });
     
    return document.cookie = name + '=' + value + '; ' + optionsArray.join('; ');
};

export const getCookie = (cookie) => {
    const cookiesObject = parseCookiesToObject();

    return cookiesObject[cookie] || null;
};   