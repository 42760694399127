import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import Spinner from 'Components/layout/Spinner';

import { single, payment } from 'Redux/modules/public/userOffers/actions';
import { getProfile } from 'Redux/modules/user/actions';
import { payment as adeptPayment } from 'Redux/modules/adept/userOffers/actions';

export default connect(
    state => ({
        userOffersMap: state.publicUserOffers.mapById,
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            single: dispatch(single),
            payment: dispatch(payment),
            getProfile: dispatch(getProfile),
            adeptPayment: dispatch(adeptPayment),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        userOffer: stateProps.userOffersMap[ownProps.match.params.id],
    })
)(
    loadable(() => import(
        /* webpackChunkName: "page-public-ShopUserOffer" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);