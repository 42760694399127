import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layoutAdmin/Button';
import MultiSelectButton from 'Components/layoutAdmin/MultiSelectButton';

export default class AdminPaginatedListElementControls extends React.Component {
    static propTypes = {
        controls: PropTypes.arrayOf(
            PropTypes.shape({
                group: PropTypes.arrayOf(
                    PropTypes.shape({
                        visible: PropTypes.bool,
                        size: PropTypes.number,
                        type: PropTypes.oneOf(['button', 'icon', 'textBlock']),
                        label: PropTypes.string,
                        to: PropTypes.string,
                        href: PropTypes.string,
                        icon: PropTypes.string,
                        onClick: PropTypes.func,
                        style: PropTypes.string,
                        tooltip: PropTypes.string,
                        loading: PropTypes.bool,
                        disabled: PropTypes.bool,
                    }),
                ),
                visible: PropTypes.bool,
                type: PropTypes.oneOf(['button', 'icon', 'textBlock']),
                label: PropTypes.string,
                to: PropTypes.string,
                target: PropTypes.oneOf(['_self', '_blank']),
                href: PropTypes.string,
                icon: PropTypes.string,
                onClick: PropTypes.func,
                style: PropTypes.string,
                tooltip: PropTypes.string,
                loading: PropTypes.bool,
                disabled: PropTypes.bool,
            })
        ),
        controlsType: PropTypes.string,
    };
    static defaultProps = {
        controls: [],
    };

    getControlsList = controls => {
        const { controlsType } = this.props;

        if (controlsType == 'multiselect') {
            return (
                <MultiSelectButton 
                    controls={controls}
                    className="control"
                />
            );
        }

        return controls.map((control, index) => {
            if (control.hasOwnProperty('group')) {
                return (
                    //eslint-disable-next-line react/no-array-index-key
                    <div key={`group-${index}`} className="controls-group">
                        {this.getControlsList(control.group)}
                    </div>
                );
            } else {
                return control.visible !== false && (
                    //eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="control" style={{ flex: control.flexSize || undefined }}>
                        {this.getControl(control)}
                    </div>
                );
            }
        });
    }

    getControl = controlConfig => {
        switch (controlConfig.type) {
            case 'button':
                if (controlConfig.to) {
                    return (
                        <Link to={controlConfig.to}>
                            <Button
                                icon={controlConfig.icon}
                                size="small"
                                type="button"
                                style={controlConfig.style || 'faPrimary'}
                                disabled={controlConfig.disabled}
                                loading={controlConfig.loading}
                                {...(controlConfig.buttonsProps || {})}
                            >
                                {controlConfig.label}
                            </Button>
                        </Link>
                    );
                } else if (controlConfig.href) {
                    return (
                        <a href={controlConfig.href} target={controlConfig.target || '_blank'}>
                            <Button
                                icon={controlConfig.icon}
                                size="small"
                                type="button"
                                style={controlConfig.style || 'faPrimary'}
                                disabled={controlConfig.disabled}
                                loading={controlConfig.loading}
                                {...(controlConfig.buttonsProps || {})}
                            >
                                {controlConfig.label}
                            </Button>
                        </a>
                    );
                } else if (controlConfig.onClick) {
                    return (
                        <Button
                            icon={controlConfig.icon}
                            size="small"
                            type="button"
                            disabled={controlConfig.disabled}
                            style={controlConfig.style || 'faPrimary'}
                            onClick={controlConfig.onClick}
                            loading={controlConfig.loading}
                            {...(controlConfig.buttonsProps || {})}
                        >
                            {controlConfig.label}
                        </Button>
                    );
                }
                break;
            case 'icon':
                if (controlConfig.to) {
                    return (
                        <Link to={controlConfig.to}>
                            <i className={`${controlConfig.icon}`} />
                        </Link>
                    );
                } else if (controlConfig.href) {
                    return (
                        <a href={controlConfig.href} target={controlConfig.target || '_self'}>
                            <i className={`${controlConfig.icon}`} onClick={controlConfig.onClick} />
                        </a>
                    );
                } else if (controlConfig.onClick) {
                    return (
                        <i className={`${controlConfig.icon}`} onClick={controlConfig.onClick} />
                    );
                }
                break;
            case 'textBlock':
                return (<p className="control-text-block">{controlConfig.label}</p>);
        }
    }

    render() {
        const { controls } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="admin-paginated-list-element-controls"
            >
                {this.getControlsList(controls)}
            </StyledComponent>
        );
    }
}
