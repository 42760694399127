import axios from 'axios';

import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG } from 'Consts/redux';
import { LOGOUT, GET_PROFILE } from 'Redux/modules/user/types';

import { destroySession } from 'Services/Auth';
import { parseToQueryString } from 'Utils/querystring';
import { mapErrors } from 'Utils/api';

import Logger from 'Services/Logger';
import getStore from 'Redux';

export const reduxRequest = options => dispatch => {
    const store = getStore();
    options = transformOptions(options, store.getState());

    if (!options.ignoreStore) {
        dispatch({
            state: INIT_FLAG,
            type: options.reduxType + '_' + INIT_FLAG,
            params: options.params,
        });
    }

    return new Promise((resolve, reject) => {
        const requestConfig = getRequestCofnig(options);
        // Logger.log('API Request', {
        //     url: requestConfig.url,
        //     options: options,
        //     requestConfig: requestConfig,
        // });

        return axios(requestConfig)
            .then(response => {
                //Logger.log('API Response', options, response);

                return resolve(
                    options.ignoreStore ?
                        response.data
                        :
                        dispatch({
                            state: SUCCESS_FLAG,
                            type: options.reduxType + '_' + SUCCESS_FLAG,
                            payload: response.data,
                            params: options.params,
                        })
                );
            })
            .catch(error => {
                try {
                    Logger.warning('API Error', options, error.response);

                    if (error.response.status === 401 || options.reduxType === GET_PROFILE) {
                        destroySession(options.req);
                        dispatch({
                            state: SUCCESS_FLAG,
                            type: LOGOUT + '_' + SUCCESS_FLAG,
                        });
                    }

                    return reject(
                        options.ignoreStore ?
                            mapErrors(error.response)
                            :
                            dispatch({
                                state: FAIL_FLAG,
                                type: options.reduxType + '_' + FAIL_FLAG,
                                payload: mapErrors(error && error.response),
                                params: options.params,
                            })
                    );
                } catch (error) {
                    Logger.error('API Network Error', options, error);
                    return reject(
                        options.ignoreStore ?
                            mapErrors()
                            :
                            dispatch({
                                state: FAIL_FLAG,
                                type: options.reduxType + '_' + FAIL_FLAG,
                                payload: mapErrors(error && error.response),
                                params: options.params,
                            })
                    );
                }
            });
    });
};

export function redirectToApiEndpoint(path) {
    const store = getStore();
    const storeState = store.getState();
    const apiUrl = parseToQueryString(process.env.API_URL + path, {
        authToken: storeState.user.authToken,
    });

    const win = window.open(
        apiUrl,
        '_blank'
    );
    win.focus();
}

function getRequestCofnig(options) {
    return {
        method: options.method.toUpperCase(),
        url: options.url,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            ...options.headers,
        },
        data: options.data,
        timeout: options.timeout || 30000,
        responseType: 'json',
        adapter: options.adapter || undefined,
        callbackParamName: options.callbackParamName || undefined,
        validateStatus: status => {
            return status >= 200 && status < 300;
        },
        paramsSerializer: params => {
            return JSON.stringify(params);
        },
    };
}

function transformOptions(options, storeState) {
    //Attach headers
    options.headers = {
        ...options.headers || {},
    };

    if (storeState.user) {
        options.headers = {
            ...options.headers,
            authorization: storeState.user.authToken
                ? 'Bearer ' + storeState.user.authToken
                : undefined,
            'x-cid': storeState.user.cid,
            'x-sid': storeState.user.sid,
        };
    }

    //Ensure that requestParams is object
    if (typeof options.requestParams !== 'object' || !options.requestParams) {
        options.requestParams = {};
    }

    //Support for PUT request methods
    if (options.method.toUpperCase() === 'PUT' && !options.forceMethod) {
        options.method = 'POST';
        options.requestParams._method = 'PUT';
    }

    //Support for DELETE request methods
    if (options.method.toUpperCase() === 'DELETE' && !options.forceMethod) {
        options.method = 'POST';
        options.requestParams._method = 'DELETE';
    }

    if (options.method.toUpperCase() === 'GET') {
        options.path = parseToQueryString(options.path, options.requestParams);
    }

    options.data = options.requestParams;
    if (options.asFormData) {
        const formData = new FormData();
        Object.keys(options.data).forEach(key => {
            formData.append(key, options.data[key]);
        });
        options.data = formData;
    }

    //Get api path
    options.url = process.env.API_URL + options.path;
    if (options.apiUrl) {
        options.url = options.apiUrl + options.path;
    }

    return options;
}
