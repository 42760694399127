export const LIST = 'admin/userOffers/LIST';
export const INCOME = 'admin/userOffers/INCOME';
export const SINGLE = 'admin/userOffers/SINGLE';
export const CREATE = 'admin/userOffers/CREATE';
export const UPDATE = 'admin/userOffers/UPDATE';
export const CANCEL = 'admin/userOffers/CANCEL';
export const ACTIVATE = 'admin/userOffers/ACTIVATE';
export const EXTEND = 'admin/userOffers/EXTEND';
export const PAYMENT = 'admin/userOffers/PAYMENT';
export const MIGRATE = 'admin/userOffers/MIGRATE';
export const REMOVE = 'admin/userOffers/REMOVE';
export const CLOSE = 'admin/userOffers/CLOSE';
export const NOTIFICATION_SHOP_LINK = 'admin/userOffers/NOTIFICATION_SHOP_LINK';
export const TERMINATION_DATES = 'admin/userOffers/TERMINATION_DATES';
export const LOGS = 'admin/userOffers/LOGS';
export const ADD_PAYMENT = 'admin/userOffers/ADD_PAYMENT';
export const INVITE = 'admin/userOffers/INVITE';
export const REMOVE_USER = 'admin/userOffers/REMOVE_USER';
export const GET_SUB_USERS = 'admin/userOffers/GET_SUB_USERS';