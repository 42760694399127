import * as types from './types';
import { INIT_FLAG, SUCCESS_FLAG, FAIL_FLAG, LIST_MARKUP, ELEMENT_MARKUP } from 'Consts/redux';

export const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
