import { isBrowser } from 'Utils/browser';

export function isHttps() {
    return isBrowser() && window.location.protocol === 'https' ? true : false;
}

export const splitUrl = (url) => {
    return url.split('/');
};

export const isUrlInGoing = (url) => {
    return !url.includes('://');
};