import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 100%;
    z-index: 2;
    padding: .7em 0;
    background-color: ${variables.dpc_backgroundBlack};

    .layout-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                max-width: 8em;
                display: block;
            }
        }

        .nav {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .nav-buttons {
                font-size: 1.2em;
                color: white;
                font-weight: 600;
                width: 100%;
                display: flex;
                justify-content: center;

                .buttons-wrapper {
                    .nav-button {
                        padding: 0 1.75em;
                        color: white;

                        &:hover {
                            color: #EEEEEE;
                        }
                    }
                }
            }

            .nav-element {
                margin-right: 1em;

                &.nav-element-user {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: auto;

                    .user-avatar {
                        .avatar-component {
                            margin-bottom: 0;

                            a {
                                color: white;

                                img {
                                    width: 3em;
                                    height: 3em;
                                }
                            }
                        }
                    }

                    .user-details {
                        margin-left: .5em;
                        cursor: pointer;

                        .user-name {
                            color: white;
                        }
                    }
                }
                &.nav-element-login {
                    font-size: 80%;
                }
                &.nav-element-logout {
                    .logout {
                        .logout-icon {
                            svg {
                                width: 2.5em;
                                height: 2.5em;
                            }
                        }
                    }
                }
                &.nav-element-lang {
                    .lang-trigger {
                        display: flex;
                        align-items: center;
                        
                        min-width: 3rem;
                        height: 100%;
                        width: 3rem;

                        .flag-select {
                            width: 100%;

                            .flag-select__btn {
                                width: 100%;
                                cursor: pointer;
                                background: transparent;
                                border: none;

                                .flag-select__option {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .layout-container {
            .nav {
                font-size: 85%;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .nav-element {
                    &.nav-element-user {
                        .user-details {
                            display: none;
                        }
                    }
                }
            }
        }
    }
`;
