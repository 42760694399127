import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_REDIRECT_URI, API_RESOURCE_PAYMENT_METHOD, API_RESOURCE_PAYMENT_TRANSACTION } from 'Consts/apiResources';
import { PUBLIC_SHOP_PAYMENT } from 'Consts/routes';
import { PAYMENT_TRANSACTION_STATUS_COMPLETED, PAYMENT_TRANSACTION_STATUS_CANCELED } from 'Consts/paymentTransactions';

import Logger from 'Services/Logger';

import { withVariables } from 'Utils/string';
import { toSha256 } from 'Utils/crypto';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Button from 'Components/layout/Button';

export default class AdeptUserPaymentMethodsCreateForm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSuccess: PropTypes.func,
        returnRaw: PropTypes.bool,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            confirm: PropTypes.func.isRequired,
        }).isRequired,
        profile: PropTypes.shape({
            email: PropTypes.string.isRequired,
        }),
        email: PropTypes.string,
        confirm: PropTypes.bool,
    };
    static defaultProps = {
        returnRaw: false,
        onSuccess: null,
        confirm: false,
    };

    state = {
        error: false,
        isPending: false,
        isCreated: false,
    };
    script = null;

    componentDidMount = () => {
        this.placeWidget();
    }

    componentDidUpdate = prevProps => {
        const { profile, email } = this.props;

        if (JSON.stringify(profile) !== JSON.stringify(prevProps.profile) || email !== prevProps.email) {
            this.placeWidget();
        }
    }

    componentWillUnmount = () => {
        this.script && this.script.remove();
    }

    getParams = () => {
        const { email, profile } = this.props;

        return {
            'currency-code': 'PLN',
            'customer-email': email || profile && profile.email,
            'customer-language': 'pl',
            'merchant-pos-id': '1119452',
            'payu-brand': 'true',
            'recurring-payment': 'true',
            'shop-name': 'FitAdept.tv',
            'store-card': 'true',
            'total-amount': 0,
            'widget-mode': 'use',
        };
    }

    getSignature = params => {
        return toSha256(Object.values(params).join('') + '47d6f8bc5df2c7cb48d90532d35aca90');
    }

    loadWidget = params => {
        this.script = document.createElement('script');
        this.script.src = 'https://secure.payu.com/front/widget/js/payu-bootstrap.js';
        this.script.async = true;

        Object.keys(params).forEach(param => {
            this.script.setAttribute(param, params[param]);
        });

        window.onCardSaveResponse = this.onCardSaveResponse;
        this.script.setAttribute('success-callback', 'onCardSaveResponse');
        this.script.setAttribute('sig', this.getSignature(params));

        document.body.appendChild(this.script);
    }

    placeWidget = () => {
        //Remove old data
        const oldNode = document.getElementById('payu-widget');
        if (oldNode) {
            oldNode.outerHTML = '';
        }
        this.script && this.script.remove();

        //Create new data
        const parentNode = document.getElementById('payu-widget-container');
        if (parentNode) {
            const node = document.createElement('div');
            node.id = 'payu-widget';
            node.class = 'widget';
            parentNode.appendChild(node);
        }

        window.onCardSaveResponse = undefined;
        this.loadWidget(this.getParams());
    }

    onCardSaveResponse = ({ maskedCard, tokenType, type, value }) => {
        const { actions, confirm, onSuccess, returnRaw } = this.props;
        const { isPending } = this.state;

        if (isPending) {
            return null;
        }

        this.setState({
            isPending: true,
            error: false,
        });

        if (returnRaw) {
            this.setState({
                isCreated: true,
                isPending: false,
                error: false,
            });

            return onSuccess({ maskedCard, tokenType, type, value });
        } else {
            return actions.create({
                continueUrl: process.env.APP_URL,
                type,
                maskedCard,
                cardToken: value,
                cardTokenType: tokenType,
            })
                .then(response => {
                    if (confirm) {
                        return this.setState({
                            isCreated: true,
                            isPending: false,
                            error: false,
                            paymentMethod: response.payload[API_RESOURCE_PAYMENT_METHOD],
                        });
                    }

                    if (onSuccess) {
                        return onSuccess(response.payload[API_RESOURCE_PAYMENT_METHOD]);
                    }
                })
                .catch(error => {
                    Logger.error('[PaymentMethodCreateFrom] Failed to create payment method', error);

                    this.placeWidget();
                    this.setState({
                        isPending: false,
                        error: true,
                    });
                });
        }
    }

    onConfirm = () => {
        const { actions, onSuccess, history } = this.props;
        const { paymentMethod } = this.state;

        this.setState({
            isPending: true,
            error: false,
        });

        return actions.confirm({
            id: paymentMethod.id,
            continueUrl: process.env.APP_URL + PUBLIC_SHOP_PAYMENT.path,
        })
            .then(response => {
                if (response.payload[API_RESOURCE_REDIRECT_URI]) {
                    return window.location.href = response.payload[API_RESOURCE_REDIRECT_URI];
                } 

                if (response.payload[API_RESOURCE_PAYMENT_TRANSACTION]) {
                    const transaction = response.payload[API_RESOURCE_PAYMENT_TRANSACTION];

                    if ([PAYMENT_TRANSACTION_STATUS_COMPLETED].includes(transaction.status)) {
                        if (onSuccess) {
                            onSuccess(response);
                        }

                        this.setState({
                            isConfirmed: true,
                            isPending: false,
                            error: false,
                        });
                        
                        return;
                    }

                    if ([PAYMENT_TRANSACTION_STATUS_CANCELED].includes(transaction.status)) {
                        // jesli nie ma paymentMethodTrans, actions remove metody platnosci
                        this.setState({
                            isPending: false,
                            error: true,
                        });

                        return; 
                    }

                    return history.push(withVariables(PUBLIC_SHOP_PAYMENT.path, {}, { transactionId: transaction.id })); 
                }
                // jesli nie ma paymentMethodTrans, actions remove metody platnosci
                this.setState({
                    error: true,
                    isPending: false,
                });
            })
            .catch(error => {
                Logger.error('[PaymentMethodCreateFrom] Failed to confirm payment method', error);
                this.setState({
                    isPending: false,
                    error: true,
                });
            });
    }

    render() {
        const { error, isPending, isCreated, isConfirmed } = this.state;
        const { confirm } = this.props;

        return (
            <StyledComponent
                className="adept-user-payment-methods-create-form"
                styles={require('./styles')}
            >
                {isPending && (
                    <div className="pending-container">
                        <Spinner />
                        <p className="pending-info">
                            Procesujemy Twoją płatność
                        </p>
                    </div>
                )} 
                {isCreated && !error && (
                    <div className="confirm-container">
                        {isConfirmed
                            ? (
                                <h4 className="confirm-headline">
                                    Twoja karta została potwierdzona
                                </h4>
                            )
                            : (
                                <>
                                    <h4 className="confirm-headline">
                                        Twoja karta została zapisana
                                    </h4>
                                    {confirm && (
                                        <>
                                            <p className="confirm-description">
                                                Aby potwierdzić Twoją kartę musimy pobrać kwotę 1zł z Twojego konta. Kliknij potwierdź, aby dokonać potwierdzenia.
                                            </p>
                                            <Button
                                                style="gradient"
                                                onClick={this.onConfirm}
                                                size="large"
                                            >
                                                Potwierdź
                                            </Button>
                                        </>
                                    )}
                                </>
                            )
                        }
                    </div>
                )}

                {error && (
                    <h4 className="error">
                        Nie udało nam się dodać Twojej metody płatności. Spróbuj ponownie.
                    </h4>
                )}

                <div className="widget-container" id="payu-widget-container" />
            </StyledComponent>
        );
    }
}