import { connect } from 'react-redux';
import Component from './component';

import {
    list as listCategories,
    changeAssignment,
} from 'Redux/modules/admin/articleCategories/actions';

export default connect(
    state => ({
        articleCategories: state.adminArticleCategories.list,
    }),
    dispatch => ({
        actions: {
            listCategories: dispatch(listCategories),
            changeAssignment: dispatch(changeAssignment),
        },
    })
)(Component);
