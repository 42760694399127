import React from 'react';
import PropTypes from 'prop-types';

import { getFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';

export default class PanelAdeptOnboardingStepWelcome extends React.Component {
    static propTypes = {
        profile: PropTypes.object.isRequired,
        events: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    state = {}

    getFirstTrainingTrainer = () => {
        const { events } = this.props;
        const lead = events?.elements[0]?.lead || null; 

        if (lead) {
            return { 
                name: getFullName(lead, 'name').label,
                bio: lead?.bio,
                avatar: lead?.avatar || require('Theme/images/placeholders/avatar.png'),
            };
        }

        return false;
    };

    render() {
        const { profile } = this.props; 

        return (
            <StyledComponent styles={require('./styles')} className='panel-adept-onboarding-step-welcome'>
                <h1 className="headline">
                    Witaj {getFullName(profile, 'name').label}
                </h1>
                {(this.getFirstTrainingTrainer() ? (<>
                    <h3 className="subheadline">
                        Już niedługo Twoje pierwsze spotkanie FitAdept z
                    </h3>
                    <div className="trainer-info-wrapper">
                        <div className="trainer-avatar-wrapper">
                            <img className="trainer-avatar" src={this.getFirstTrainingTrainer().avatar} />
                        </div>
                        <div className="trainer-deep-info-wrapper">
                            <p className="trainer-name">
                                {this.getFirstTrainingTrainer().name}
                            </p>
                            <p className="trainer-bio">
                                {this.getFirstTrainingTrainer().bio}
                            </p>
                        </div>
                    </div>
                </>) : (<>
                    <h3 className="subheadline">
                        Cieszymy się że postanowiłeś skorzystać z naszej aplikacji!
                    </h3>
                </>))}
            </StyledComponent>
        );
    }
}