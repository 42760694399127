import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        position: relative;
        margin-bottom: 1em;
        width: 100%;

        &.style-version-1 {
            textarea {
                font-weight: 400;
                font-family: ${variables.fontPrimary};
                width: 100%;
                outline: none;
                font-size: 1em;
                min-height: 8em;
                resize: vertical;
                padding: 1.5em;
                background-color: #FFFFFF;
                color: #333333;
                border: 0.05em solid #e2e1e0;
                box-sizing: border-box;
                border-radius: .3em;

                &::placeholder {
                    color: #979797;
                    font-weight: 400;
                }
            }
        }

        &.style-version-2 {
            textarea {
                font-weight: 400;
                font-family: ${variables.fontPrimary};
                width: 100%;
                outline: none;
                font-size: 1em;
                min-height: 8em;
                resize: vertical;
                padding: 1em;
                background-color: transparent;
                color: ${variables.dpc_fontDark};
                border: 1px solid #3E3E3E;
                box-sizing: border-box;
                border-radius: .3em;

                &::placeholder {
                    color: #979797;
                    font-weight: 400;
                }

                &.border-default {  
                    border: none;
                }

                &.border-light {
                    border: 1px solid #686868;
                    background-color: transparent;
                    border-radius: .3em;
                }
                
                &.border-light-2 {
                    border: 1px solid #9e9e9e;
                    background-color: transparent;
                    border-radius: .3em;
                }

                &[disabled=""] {
                    font-weight: 300;
                    color: ${variables.dpc_fontDark};
                    border: transparent;
                    padding: 0em;

                    &::placeholder {
                        font-weight: 300;
                        color: ${variables.dpc_fontDark};
                    }
                }


            }
        }

        #form-error-message-component {
            text-align: left;
            margin-top: .2em;
            margin-left: 1em;
        }
    `;
