import { connect } from 'react-redux';
import Component from './component';

import {
    list as listSurveys
} from 'Redux/modules/admin/trainingPlanSurveys/actions';

export default connect(
    state => ({
        tpSurveys: state.adminTpSurveys.list,
    }),
    dispatch => ({
        actions: {
            listSurveys: dispatch(listSurveys),
        },
    })
)(Component);
