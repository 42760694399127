import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        width: 100%;
        color: ${variables.dpc_fontDark};
        margin-top: 1em;
        text-align: center;

        .headline {
            font-size: 1.75em;
            font-weight: 600;
        }

        .subheadline {
            font-size: 1.25em;
            font-weight: 300;
            margin-top: .25em;
        }


        .navlink {
            display: flex;
            justify-content: center;
            margin-top: 3em;
        }
    `;
