import moment from 'moment';

export default class TpProfile {
    constructor(data) {
        this.id = data.id,
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.updatedAt = data.updatedAt && moment.utc(data.updatedAt).local();
        this.name = data.name;
        this.user = data.user;
        this.survey = data.survey;
        this.percentages = data.percentages;
    };
}