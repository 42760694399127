import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

import { fromSelectObject } from 'Utils/object';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_LOCATIONS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            enabled: typeof params.enabled !== 'undefined'
                ? fromSelectObject(params.enabled)
                : undefined,
            orderBy: params.orderBy || 'createdAt',
            userId: params.userId || undefined,
            assigned: fromSelectObject(params.assigned) || undefined,
            categoryId: params.categoryId || undefined,
            type: params.type || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_LOCATION, params),
        params,
    }));
};

export const create = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CREATE,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_LOCATIONS, params),
        params,
        requestParams: params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_LOCATION, params),
        params,
        requestParams: params,
    }));
};

export const setEnabledStatus = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SET_ENABLED_STATUS,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_LOCATION_ENABLED_STATUS, params),
        params,
        requestParams: params,
    }));
};

export const assignUsers = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.ASSIGN_USERS,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_LOCATION_USERS, params),
        params,
        requestParams: params,
    }));
};

export const unassignUsers = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UNASSIGN_USERS,
        method: 'DELETE',
        path: withVariables(endpoints.ENDPOINT_ADMIN_LOCATION_USERS, params),
        params,
        requestParams: params,
    }));
};
