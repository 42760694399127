export const LIST = 'admin/users/LIST';
export const SINGLE = 'admin/users/SINGLE';
export const CREATE = 'admin/users/CREATE';
export const UPDATE = 'admin/users/UPDATE';
export const SET_PERMISSIONS = 'admin/users/SET_PERMISSIONS';
export const ASSIGN_TO_SEGMENT = 'admin/users/ASSIGN_TO_SEGMENT';
export const UNASSIGN_FROM_SEGMENT = 'admin/users/UNASSIGN_FROM_SEGMENT';
export const NOTIFICATION_ACTIVATE = 'admin/userOffers/NOTIFICATION_ACTIVATE';
export const TRANSFER = 'admin/userOffers/TRANSFER';
export const REMOVE = 'admin/users/REMOVE';
export const AVATAR_PRESIGN = 'admin/users/AVATAR_PRESIGN';
export const ASSIGN_PRODUCT = 'admin/users/ASSIGN_PRODUCT';
export const UNASSIGN_PRODUCT = 'admin/userse/UNASSIGN_PRODUCT';
export const CREATE_TV_ACCESS_CODE = 'admin/users/CREATE_TV_ACCESS_CODE';
export const ASSIGN_TO_USER = 'admin/users/ASSIGN_TO_USER';
export const LIST_NOT_ASSIGNED = 'admin/users/LIST_NOT_ASSIGNED';