import moment from 'moment';

export default class ArticleComment {
    constructor(data) {
        this.id = data.id,
        this.createdAt = data.createdAt && moment.utc(data.createdAt).local();
        this.article = data.article;
        this.comment = data.comment;
        this.author = data.author;
        this.status = data.status;
    };
}