import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        @media (max-width: ${variables.tabletL}) {
            .editor-wrapper {
                justify-content: center !important; 

                .data-wrapper { 
                    display: none;
                }
            }
        }
    `;
