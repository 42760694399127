import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ADEPT_MEDICAL_CARD_CREATE } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button/component';

export default class PanelAdeptOnboardingStepMedicalCard extends React.Component {
    static propTypes = {};
    static defaultProps = {};

    state = {}

    render() {
        return (
            <StyledComponent styles={require('./styles')} className='panel-adept-onboarding-step-medical-card'>
                <h1 className="headline">
                    Przed wydarzeniem wypełnij krótki wywiad zdrowotny
                </h1>
                <h3 className="subheadline">
                    Dzięki temu twój trener będzie mógł się do niego lepiej przygotować.
                </h3>
                <Link to={ADEPT_MEDICAL_CARD_CREATE.path} className="navlink" target="_blank">
                    <Button
                        style="hollow"
                        size="large"
                        onClick={this.setOnboardingCookie}
                    >
                        Uzupełnij formularz medyczny
                    </Button>
                </Link>
            </StyledComponent>
        );
    }
}