export function filterKeys(obj1, obj2, filterMap = []) {
    const merged = { ...obj1, ...obj2 };
    const filtered = {};

    Object.keys(merged).forEach(key => {
        if (filterMap.includes(key)) {
            filtered[key] = merged[key];
        }
    });

    return filtered;
}

export function fromSelectObject(selectValue) {
    return selectValue && typeof selectValue === 'object'
        ? selectValue.value
        : selectValue;
}