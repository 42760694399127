export const REGISTER = 'user/REGISTER';
export const LOGIN = 'user/LOGIN';
export const LOGOUT = 'user/LOGOUT';
export const REFRESH_TOKEN = 'user/REFRESH_TOKEN';
export const GET_PROFILE = 'user/GET_PROFILE';
export const UPDATE_PROFILE = 'user/UPDATE_PROFILE';
export const PASSWORD_RESET = 'user/PASSWORD_RESET';
export const PASSWORD_CHANGE = 'user/PASSWORD_CHANGE';
export const SET_VISITOR = 'user/SET_VISITOR';
export const SET_SELECTED_LOCATION = 'user/SET_SELECTED_LOCATION';
export const AVATAR_PRESIGN = 'user/AVATAR_PRESIGN';
export const LANGUAGE_CHANGE = 'user/LANGUAGE_CHANGE';
export const PASSWORD_TOKEN_VERIFY = 'users/PASSWORD_TOKEN_VERIFY';
export const RECOMMENDATION_CREATE = 'users/RECOMMENDATION_CREATE';
