import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .container {
            background-size: cover;
            background-position: center center;
            height: 100vh;
            overflow: hidden;
            position: relative;
            width: 100%;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    `;
