import * as types from './types';
import { generateUniqueId } from 'Utils/crypto';

export const push = (params = {}) => dispatch => {
    const notification = {
        id: params.id || generateUniqueId(),
        ...params,
    };

    setTimeout(() => {
        dispatch(pop(notification.id));
    }, 5000);

    return dispatch({
        type: types.PUSH,
        payload: notification,
    });
};

export const pop = (params = {}) => dispatch => {
    return dispatch({
        type: types.POP,
        payload: params,
    });
};
