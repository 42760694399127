import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { NOTIFICATION_STATUS_UNREAD, NOTIFICATION_STATUS_READ } from 'Consts/notification';

import { isUrlInGoing } from 'Utils/url';

import StyledComponent from 'Components/core/StyledComponent';

export default class PanelNotification extends React.Component {
    static propTypes = {
        notification: PropTypes.object,
        onClick: PropTypes.func,
    };

    getNotification = () => {
        const { notification, onClick } = this.props;

        return (
            <div 
                className="notification-details-wrapper"
                onClick={onClick}
            >   
                {notification?.image && (
                    <div className="notification-image-wrapper">
                        <img className="notification-image" src={notification.image}/>
                    </div>
                )}
                <div className="notification-text-wrapper">
                    <div className="notification-title">
                        {notification.title}
                    </div>
                    {notification?.subtitle && (
                        <div className="notification-subtitle">
                            {notification.subtitle} 
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { notification, onClick } = this.props;

        return (
            <StyledComponent styles={require('./styles')} className="notification-component">
                <div 
                    className={classnames(
                        'notification-wrapper',
                        `${notification 
                            ? notification.state == NOTIFICATION_STATUS_UNREAD 
                                ? NOTIFICATION_STATUS_UNREAD
                                : NOTIFICATION_STATUS_READ
                            : null
                        }`
                    )}
                >
                    {notification && (
                        <div
                            className="notification-controller"
                        >
                            {notification.state == NOTIFICATION_STATUS_UNREAD && (
                                <div className="control">
                                    <span 
                                        className="mark-as-read"
                                        onClick={onClick}
                                    >
                                        Oznacz jako przeczytana
                                    </span>
                                </div>
                            )}
                            {notification.link && (
                                <>
                                    {isUrlInGoing 
                                        ? (
                                            <NavLink className="notification-link" to={notification.link}>
                                                {this.getNotification()}
                                            </NavLink>
                                        ) : (
                                            <a 
                                                href={notification.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {this.getNotification()}
                                            </a>
                                        )
                                    }
                                </>
                            )}
                            {!notification.link && (
                                this.getNotification()
                            )}
                        </div>
                    )}
                </div>
            </StyledComponent>
        );
    }
}
