import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import {
    ADMIN_REPORTS,
} from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import BigQueryReportsList from 'Components/admin/reports/BigQueryList';
import ModalExportModels from 'Components/admin/modals/ExportSelectModels';

export const TAB_BIGQUERY = 'bigQuery';

export default class AdminReports extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            generateReports: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    state = {
        forceReload: false,
        selectModelsVisible: false,
        scope: {
            Users: true,
            LeadUsers: true,
            PaymentTransactions: true,
            Events: true,
            Invoices: true,
            LocationsUsers: true,
            Locations: true,
            Offers: true,
            OfferProducts: true,
            PaymentVendors: true,
            Products: true,
            ProductCategories: true,
            ProductsSpecializations: true,
            PromoCodes: true,
            Specializations: true,
            UsersSurveys: true,
            UserConsents: true,
            UserOffers: true,
            UsersOffersInvoiceItems: true,
            UserOffersProducts: true,
            UserPaymentMethods: true,
            UserSpecializations: true,
            UsersSources: true,
            MedicalCards: true,
            Measurements: true,
            UserOfferDiagnosticReports: true,
            EventCancelTypes: true,
            UserNotes: true,
            UserNotesFiles: true,
            UserData: true,
            UserOfferUsers: true,
            UserEffects: true,
        },
    };

    render() {
        const { location, history, actions } = this.props;
        const { forceReload, selectModelsVisible, scope } = this.state;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        const filtered = Object.keys(scope).filter(function(key) {
            return scope[key]
        });

        return (
            <StyledComponent
                className="page-reports"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_REPORTS.path),
                        label: 'Raporty',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Raporty"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === TAB_BIGQUERY),
                            key: 'generateReports',
                            label: 'Generuj raporty',
                            onClick: () => 
                                {
                                this.setState({ forceReload: true});
                                
                                actions.generateReports({ scope: filtered })
                                    .then(response => {
                                        this.setState({
                                            forceReload: false,
                                        });
                                        toast.success('Generowanie raportów się powiodło');
                                    })
                                    .catch(error => {
                                        this.setState({
                                            forceReload: false,
                                        });
                                        toast.error('Generowanie raportów się nie powiodło, spróbuj ponownie później');
                                    });
                            }
                        }, {
                            visible: Boolean(!queryObject.tab || queryObject.tab === TAB_BIGQUERY),
                            key: 'selectModels',
                            label: 'Wybierz modele do eksportu',
                            onClick: () => this.setState({ selectModelsVisible: true }),
                        }]}
                        tabs={[
                            {
                                key: TAB_BIGQUERY,
                                label: 'BigQuery',
                                children: (
                                    <BigQueryReportsList
                                        location={location}
                                        history={history}
                                        forceReload={forceReload}
                                    />
                                ),
                            }
                        ]}
                    />  
                </PageContent>
                <ModalExportModels
                    location={location}
                    history={history}
                    isOpen={selectModelsVisible}
                    onClose={() => this.setState({ selectModelsVisible: false })}
                    onSuccess={() => this.setState({ selectModelsVisible: false })}
                    onSubmit={(values) => this.setState({ scope: { ...values }, selectModelsVisible: false })}
                    data={scope}
                />
            </StyledComponent>
        );
    }
}
