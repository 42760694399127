import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        margin-bottom: 2em;
        justify-content: center;
        
        img {
            width: 7em;
            height: 7em;
            object-fit: contain;
            background: #fff;
            border-radius: 360em;
        }
    `;
