import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';

export default class SpinnerComponent extends Component {
    static propTypes = {
        text: PropTypes.string,
        className: PropTypes.string,
        fullPage: PropTypes.bool,
    };
    static defaultProps = {
        text: '',
        className: '',
        fullPage: false,
    };

    render() {
        const { className, text } = this.props;

        return (
            <StyledComponent styles={require('./styles')} className={`component-spinner animated fadeIn ${className}`} props={this.props}>
                <div className="spinner">
                    <div className="rect1" />
                    <div className="rect2" />
                    <div className="rect3" />
                    <div className="rect4" />
                    <div className="rect5" />
                </div>
                {text ? <p className="spinner-text">{text}</p> : null}
            </StyledComponent>
        );
    }
}
