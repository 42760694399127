import React from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';

import { parseQueryToObject } from 'Utils/querystring';

import Spinner from 'Components/layout/Spinner';

import { create } from 'Redux/modules/adept/userOffers/actions';
import { getProfile } from 'Redux/modules/user/actions';
import { singleBySlug as getSingleOfferBySlug } from 'Redux/modules/public/offers/actions';

export default connect(
    state => ({
        authToken: state.user.authToken,
        profile: state.user.profile,
        userOffersMap: state.adeptUserOffers.mapById,
    }),
    dispatch => ({
        actions: {
            create: dispatch(create),
            getProfile: dispatch(getProfile),
            getSingleOfferBySlug: dispatch(getSingleOfferBySlug),
        },
    }),
    (stateProps, dispatchProps, ownProps) => {
        const { location } = ownProps;
        const queryObject = parseQueryToObject(location.search);

        return {
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
            userOffer: stateProps.userOffersMap[queryObject.userOfferId],
        };
    }
)(
    loadable(() => import(
        /* webpackChunkName: "page-public-ShopStudio" */
        './component'
    ), {
        fallback: <Spinner />,
    })
);