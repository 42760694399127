import { connect } from 'react-redux';
import Component from './component';

import { logout } from 'Redux/modules/user/actions';
import { 
    list as listNotifications,
    readNotification,
    setAllAsRead,
} from 'Redux/modules/trainer/notifications/actions'; 

export default connect(
    state => ({
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            logout: dispatch(logout),
            listNotifications: dispatch(listNotifications),
            readNotification: dispatch(readNotification),
            setAllAsRead: dispatch(setAllAsRead),
        },
    })
)(Component);
