export const EVENT_ONLINE_SURVEY_STEP = 'onlineSurveyStep';
export const EVENT_ONLINE_SURVEY_SUBMIT = 'onlineSurveySubmit';

export const EVENT_ONLINE_RESERVATIONS_SLOT_SELECTED = 'onlineReservationsSlotSelected';
export const EVENT_ONLINE_RESERVATIONS_SUBMIT = 'onlineReservationsSubmit';

export const EVENT_CATEGORY_DEFAULT = 'default';
export const EVENT_CATEGORY_PRIVATE = 'private';
export const EVENT_CATEGORY_UNKNOWN = 'Nieznana';
export const EVENT_CATEGORIES = [
    {
        key: EVENT_CATEGORY_DEFAULT,
        label: 'Trening z klientem',
        stateColor: 'success',
    }, {
        key: EVENT_CATEGORY_PRIVATE,
        label: 'Prywatny',
        stateColor: 'warning',
    },
];

export const EVENT_PAID_STATUS_PAID = 'paid';
export const EVENT_PAID_STATUS_NOT_PAID = 'notPaid';
export const EVENT_PAID_STATUS_CREDIT = 'credit';
export const EVENT_PAID_STATUSES = [
    {
        key: EVENT_PAID_STATUS_PAID,
        label: 'Opłacony',
        stateColor: 'success',
    }, {
        key: EVENT_PAID_STATUS_NOT_PAID,
        label: 'Nieopłacony',
        stateColor: 'alert',
    }, {
        key: EVENT_PAID_STATUS_CREDIT,
        label: 'Kredytowany',
        stateColor: 'warning',
    },
];

export const EVENT_DONE_STATUS_DONE = 'done';
export const EVENT_DONE_STATUS_NOT_DONE = 'notDone';
export const EVENT_DONE_STATUS_CANCELED = 'canceled';
export const EVENT_DONE_STATUSES = [
    {
        key: EVENT_DONE_STATUS_DONE,
        label: 'Wykonany',
        stateColor: 'success',
    }, {
        key: EVENT_DONE_STATUS_NOT_DONE,
        label: 'Niewykonany',
        stateColor: 'alert',
    }, {
        key: EVENT_DONE_STATUS_CANCELED,
        label: 'Odwołany',
        stateColor: 'alert',
    },
];

export const PLAN_LIMIT_ADEPT_TYPE = 'days';
export const PLAN_LIMIT_ADEPT_VALUE = '10';

export const PLAN_LIMIT_TRAINER_TYPE = 'months';
export const PLAN_LIMIT_TRAINER_VALUE = '6';

export const CANCEL_DIAGNOSTIC_REASON_TO_POSTPONE = 'toPostpone';
export const CANCEL_DIAGNOSTIC_REASON_NO_ANSWER = 'noAnswer';
export const CANCEL_DIAGNOSTIC_REASON_CANCELED = 'clientCanceled';
export const CANCEL_DIAGNOSTIC_REASON_MOVED = 'moved';
export const CANCEL_DIAGNOSTIC_REASON_TRAINER_CANCELED = 'trainerCanceled';
export const CANCEL_DIAGNOSTIC_REASON_UNKNOWN = 'Nieznana';

export const CANCEL_DIAGNOSTIC_REASONS = [{
    key: CANCEL_DIAGNOSTIC_REASON_TO_POSTPONE,
    label: 'Odwołany - Do przełożenia',
}, {
    key: CANCEL_DIAGNOSTIC_REASON_NO_ANSWER,
    label: 'Odwołany - Brak kontaktu z klientem',
}, {
    key: CANCEL_DIAGNOSTIC_REASON_CANCELED,
    label: 'Odwołany - Klient nie chce',
}, {
    key: CANCEL_DIAGNOSTIC_REASON_MOVED,
    label: 'Przełożony',
}, {
    key: CANCEL_DIAGNOSTIC_REASON_TRAINER_CANCELED,
    label: 'Odwołany przez trenera',
}];