import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const generateReports = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.GENERATE_REPORTS,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_REPORTS_BIGQUERY_GENERATE, params),
        params,
        requestParams: params,
    }));
};

export const listBigQuery = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST_BIGQUERY,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_REPORTS_LIST_BIGQUERY,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
        },
    }));
}
