import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

const PanelSidebarElement = ({ title, icon, link, onClick, name, isLinkOutgoing, isIconPng }) => (
    <StyledComponent
        styles={require('./styles')}
        className={classnames(
            'panel-sidebar-link',
            name,
        )}
    >   
        <span className="bg" />
        {isLinkOutgoing ? (
            <a
                href={link} 
                className="link"
                onClick={onClick}
                target="_blank"
                rel="noopener noreferrer"
            >
                <ReactSVG className="icon" src={icon} />
                <span className="title">{title}</span>
            </a>
        ) : link ? (
            <NavLink 
                to={link} 
                className="link"
                onClick={onClick}
            >
                {isIconPng ? (
                    <img className="icon" src={icon}></img>
                ) : (
                    <ReactSVG className="icon" src={icon} />
                )}
                <span className="title">{title}</span>
            </NavLink>
        ) : (
            <div
                className="link"
                onClick={onClick}
            >
                <ReactSVG className="icon" src={icon} />
                <span className="title">{title}</span>
            </div>
        )} 
    </StyledComponent>
);

PanelSidebarElement.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
    isLinkOutgoing: PropTypes.bool,
};
PanelSidebarElement.defaultProps = {
    isLinkOutgoing: false,
};
export default PanelSidebarElement;